<template>
  <div class>
    <div class="header fColor1">
      <p class="fl">
        {{ $t('account.totalassets') }}：
        <span class="asset_num">{{ totle | toFixeds }}</span>
        <span class="asset_name"> USDT</span>
      </p>
      <p class="fr right_text">
        <!-- <span class="record" @click="record">财务记录</span> -->
        <span class="address hide" @click="withdraw_address">{{ $t('account.upaddress') }}</span>
      </p>
    </div>
    <div class="content fColor1 ft12">
      <div class="content_top flex alcenter fColor2">
        <p class="flex1 tc">
          {{ $t('market.currency') }}
          <i></i>
        </p>
        <p class="flex1 tc">{{ $t('center.available') }}</p>
        <p class="flex1 tc">{{ $t('account.freezes') }}</p>
        <!-- <p class="flex1 tc">BTC估值<i></i></p> -->
        <!-- <p class="flex1 tc">锁仓</p> -->
        <p class="flex1 tc">{{ $t('account.conversion') }}(USD)</p>
        <p class="flex1 tc">{{ $t('do') }}</p>
      </div>
      <ul class="content_ul">
        <li
            v-for="(item,index) in asset_list"
            v-if="item.is_match == 1"
            :key="index"
        >
          <div class="content_li flex alcenter between">
            <p class="flex1 tc">{{ item.currency_name }}</p>
            <p class="flex1 tc">{{ item.change_balance || '0.00' | toFixeds }}</p>
            <p class="flex1 tc">{{ item.lock_change_balance || '0.00' | toFixeds }}</p>
            <p class="flex1 tc">{{ (item.usdt_price * item.change_balance || '0.00') |toFixedTwo }}</p>
            <!-- <p class="flex1 tc">{{item.valuation}}</p> -->
            <!-- <p class="flex1 tc">{{item.lock_position}}</p> -->
            <p class="flex1 tc operation">
            <!-- 提币区域 -->
              <span v-if="item.currency_name==='BTC'||item.currency_name==='USDT'||item.currency_name==='ETH'" @click="excharge2(index,item.currency)">{{ $t('account.charging') }}</span>
              <span v-if="item.currency_name==='BTC'||item.currency_name==='USDT'||item.currency_name==='ETH'"
                    @click="withdraw(index,item.currency)">{{ $t('account.withdraw') }}</span>
              <span @click="rec(index,item.currency)">{{ $t('account.record') }}</span>
            </p>
          </div>
          <!--充币区-->
          <div class="hide_div2" v-if="index == active">
            <div style="width:600px; text-align: center; margin:0 auto;">
              <p class="fColor2 ft14">{{item.currency_name}} {{ $t('account.cgaddress') }}</p>
              <img class="mt10 mb15" style="width:200px;"  :src="'/api/qrcode?text='+excharge_address_btc" v-show="currency==1">
				<img class="mt10 mb15" style="width:200px;"  :src="'/api/qrcode?text='+excharge_address_eth" v-show="currency==2">
				<img class="mt10 mb15" style="width:200px;"  :src="'/api/qrcode?text='+excharge_address_usdt" v-show="currency==3">
              <p class=" mb50">
                <span class="ft18 fColor1 excharge_address" :class="{'bg':flags}" v-if="currency==1">{{ excharge_address_btc }}</span>
				<span class="ft18 fColor1 excharge_address" :class="{'bg':flags}" v-if="currency==2">{{ excharge_address_eth }}</span>
				<span class="ft18 fColor1 excharge_address" :class="{'bg':flags}" v-if="currency==3">{{ excharge_address_usdt }}</span>
                <br>
                <span id="copy" @click="copy" class="copy ft14" v-if="item.currency_name==='BTC'||item.currency_name==='USDT'||item.currency_name==='ETH'">{{ $t('account.copy') }}</span>
                <span class="ewm_wrap">
<!--                <span class="ewm ft14" @click="show_ewm">{{ $t('account.code') }}</span>-->
<!--                <div class="ewm_img" id="code" :class="{'hide':isHide}"></div>-->
              </span>
              </p>
            </div>
            <!--下面这部分是手动充时要用的提交信息 -->
            <!--
			<el-form ref="form" :model="form" label-width="160px" label-style="color:#fff;">
              <el-form-item class="hide2" :label="$t('account.amount')">
                <el-input v-model="form.num" :placeholder="$t('account.amount')"></el-input>
              </el-form-item>
              <el-form-item class="hide2" :label="$t('account.cgaddress')">
                <el-input v-model="form.from" :placeholder="$t('account.cgaddress')"></el-input>
              </el-form-item>
              <el-form-item class="hide" :label="$t('withdrawList.hash')">
                <el-input v-model="form.hash" :placeholder="$t('withdrawList.hash')"></el-input>
              </el-form-item>
              <el-form-item class="hide2" :label="$t('account.picture')">
                <el-upload
                    ref="uploader"
                    class="upload-demo"
                    :action="uploadUrl"
                    :headers="uploadHeaders"
                    :http-request="uploadFile"
                    :multiple="false"
                    :limit="1"
                    list-type="picture"
                    :file-list="form.pic">
                  <el-button size="mini" type="success">{{ $t('account.choose') }}</el-button>
                  <div slot="tip" class="el-upload__tip"></div>
                </el-upload>
              </el-form-item>
              <el-form-item class="hide2">
                <el-button type="primary" size="medium" @click="submitChargeInfo(item.currency)">
                  {{ $t('account.submitRecharge') }}
                </el-button>
              </el-form-item>
            </el-form>
-->
            <p class="ft12 fColor2 mb15">{{ $t('account.notice') }}</p>
            <ul class="tips_ul ft12 fColor2" style="list-style:disc inside">
              <li class="tips_li" style="list-style:disc inside">
                {{ $t('account.a1') }}{{ item.currency_name }}{{ $t('account.a2') }}
              </li>
              <li v-if="item.currency_type=='eth'" class="tips_li" style="list-style:disc inside">
                {{ $t('account.a4') }}
              </li>
              <li v-else-if="item.currency_type=='btc'" class="tips_li" style="list-style:disc inside">
                {{ $t('account.a9') }}
              </li>
              <li v-else-if="item.currency_type=='usdt'" class="tips_li" style="list-style:disc inside">
                {{ $t('account.a4') }}
              </li>
              <li v-else-if="item.currency_type=='omni'" class="tips_li" style="list-style:disc inside">
                USDT{{ $t('account.a3') }}
              </li>
            </ul>
          </div>
          <!--提币区-->
          <div class="hide_div" v-if="index == active01">
            <p class="fColor2 ft12 mb15">{{ $t('account.chaddress') }}</p>
            <input :placeholder="tibi_msg" class="address_inp fColor1 mb30" type="text" v-model="address2">
            <p class="fColor2 ft12 mb15 flex between alcenter">
              <span>{{ $t('number') }}</span>
              <span>
                {{ $t('center.available') }}：
                <span class="use_num">{{ balance || '0.00' | toFixeds }}</span>
                {{ coinname }}
                <span>
                </span>
              </span>
            </p>
            <label class="num_lab flex between mb30">
              <input style="color: white" class="fColor1" type="text" :placeholder="min_number" v-model="number">
              <span>{{ coinname }}</span>
            </label>
            <div class="flex mb50">
              <div class="left_inp_wrap flex1">
                <p class="fColor2 ft12 mb15">
                  <span>{{ $t('rate') }}</span>
                  <span></span>
                </p>
                <label class="range_lab flex alcenter between">
                  <!-- <input class="fColor1" type="text" v-model="rate"> -->
                  <span>{{ ratenum }}</span>
                  <span>{{ coinname }}</span>
                </label>
              </div>
              <div class="right_inp_wrap flex1">
                <p class="mb15">
                  <span class="fColor2 ft12">{{ $t('account.havenum') }}</span>
                </p>
                <label class="get_lab flex alcenter between">
                  <span>{{ number - ratenum | toFixeds }}</span>
                  <!-- <input class="fColor1" disabled v-model="reachnum" type="number"> -->
                  <span>{{ coinname }}</span>
                </label>
              </div>
            </div>
            <div class="flex">
              <div class="flex2">
                <p class="ft12 fColor2 mb15">{{ $t('account.notice') }}</p>
                <ul class="tips_ul ft12 fColor2" style="list-style:disc inside">
                  <li class="tips_li" style="list-style:disc inside">
                    {{ $t('account.minnum') }}：{{ min_number_txt }}{{ coinname }}。{{
                      $t('account.oncemaxnum')
                    }}：{{ max_number }}{{ coinname }}。{{ $t('account.daynum') }}{{ day_limit }}{{ coinname }}
                  </li>
                  <li class="tips_li" style="list-style:disc inside">{{ $t('account.call') }}</li>
                </ul>
              </div>
              <div class="flex1 tc">
                <button class="withdraw_btn" @click="mention">{{ $t('account.withdraw') }}</button>
              </div>
            </div>
          </div>
          <!--记录区-->
          <div class="hide_div rec-box" v-if="index == active02">
			 <div class="onerow" v-if="item.currency_name===systembi">
				 <div class="newtb">
					 <div class="tb" :class="isshowsystembi?'':'cur'" @click="isshowsystembi=false">{{ $t("apitip.资产记录") }}</div>
					 <div class="tb" :class="isshowsystembi?'cur':''" @click="isshowsystembi=true">{{ $t("apitip.申购记录") }}</div>
					 <div class="tb2" v-show="isshowsystembi">1 {{systembi}}={{huilv}} USDT</div>
				 </div>
				 <div class="rec-con" v-show="!isshowsystembi">
				   <div class="rec-title">
				     <span>{{ $t('number') }}</span>
				     <span>{{ $t('account.record') }}</span>
				     <span>{{ $t('time') }}</span>
				   </div>
				   <ul class="rec-list">
				     <li v-for="(reItem,reIndex) in recData" :key="reIndex">
				       <span>{{ reItem.value || '0.00' | toFixeds }}</span>
				       <span>{{ fanyi(reItem.info) }}</span>
				       <span>{{ reItem.created_time }}</span>
				     </li>
				   </ul>
				   <p class="more" @click="moreData()">{{ more }}</p>
				 </div>
				 <div class="rec-con" v-show="isshowsystembi">
				   <div class="lsbox">
				   	<div class="nodata" v-if="systemlist.length<1">{{$t("apitip.暂无记录")}}</div>
				   	<div class="" v-else>
				   		<table class="newtable">
				   			<thead>
				   				<tr>
				   					<th>{{$t("apitip.认购币种")}}</th>
				   					<th>{{$t("apitip.认购时间")}}</th>
				   					<th>{{$t("apitip.认购价格")}}</th>
				   					<th>{{$t("apitip.认购数量")}}</th>
				   					<!-- <th>{{$t("apitip.结束时间")}}</th> -->
				   					<th>{{$t("apitip.预计收益")}}</th>
				   					<th>{{$t("apitip.结束时间")}}{{$t("apitip.倒计时")}}</th>
				   					<th>{{$t("apitip.操作")}}</th>
				   				</tr>
				   			</thead>
				   			<tbody>
				   				<tr v-for="(item,itin) in systemlist">
				   					<td>{{item.currency_name}}</td>
				   					<td>{{item.created_at}}</td>
				   					<td>{{item.price}} USDT</td>
				   					<td>{{item.amount}} {{item.currency_name}}</td>
				   					<!-- <td>{{item.endtime_at}}</td> -->
				   					<td v-if="item.status<3"><span style="color:#f00" v-if="yingku(item.amount,item.price)>0">{{yingku(item.amount,item.price)}}</span><span style="color:#00A97F" v-if="yingku(item.amount,item.price)<0">{{yingku(item.amount,item.price)}}</span><span v-if="yingku(item.amount,item.price)==0">{{yingku(item.amount,item.price)}}</span></td>
									<!-- <td v-else>{{shiji(item.amount,item.price,item.price_sell)}}</td> -->
									<td v-else>-</td>
				   					<td>{{daojishi2(item.created_at,item.endtime_at)}} {{$t("apitip.天")}}</td>
				   					<td>
				   						<div class="btn btn-ok" v-if="item.status==3">{{$t("apitip.已完成")}}</div>
				   						<div class="btn btn-info" v-if="item.status==1">{{$t("apitip.已锁仓")}}</div>
				   						<div class="btn btn-err" v-if="item.status==2" @click="jiesuo(item,itin)">{{$t("apitip.解锁")}}</div>
				   					</td>
				   				</tr>
				   			</tbody>
				   		</table>
				   	</div>
				   </div>
				 </div>
			 </div>
            <div class="rec-con" v-else>
              <div class="rec-title">
                <span>{{ $t('number') }}</span>
                <span>{{ $t('account.record') }}</span>
                <span>{{ $t('time') }}</span>
              </div>
              <ul class="rec-list">
                <li v-for="(reItem,reIndex) in recData" :key="reIndex">
                  <span>{{ reItem.value || '0.00' | toFixeds }}</span>
                  <span>{{ fanyi(reItem.info) }}</span>
                  <span>{{ reItem.created_time }}</span>
                </li>
              </ul>
              <p class="more" @click="moreData()">{{ more }}</p>
            </div>
          </div>
        </li>
      </ul>
      <!-- <div class="tc ft16 fColor1 mt50" v-show="asset_list.length<=0"></div> -->
    </div>


	<!--弹窗-->
	<div class="czpopbox" v-if="showAutoC">
		<div class="mask"></div>
		<div class="content">
			<div class="ttop">
				<div class="title">{{$t("account.charging")}}</div>
				<div class="close" @click="showAutoC=false">X</div>
			</div>
			<div class="con">
				<div class="seltabsbox">
						<div class="li" @click="currency_id=item.id" :class="item.id==currency_id?'cur':''" v-for="(item,index) in address">{{item.type}}</div>
				</div>

				<div class="qrcodebox">
								  <div class="" v-for="(item,index) in address">
									  <img :src="'/api/qrcode?text='+item.address" v-show="item.id==currency_id" class="qr2img">
									  <div class="center address" v-show="item.id==currency_id">{{item.address}}</div>
									  <div style="text-align: center;"><button class="copy"  flat :data-clipboard-text="item.address"  v-show="item.id==currency_id"  @click="copy">{{ $t('account.copy') }}</button></div>
								  </div>
				</div>
				<div class="tipsboxx">
								  <div v-html="tihaun(autoin_tips)"></div>
				</div>
			</div>
		</div>
	</div>
	<!--弹窗-->
	<div class="czpopbox" v-if="showSC">
		<div class="mask"></div>
		<div class="content">
			<div class="ttop">
				<div class="title">{{$t("account.charging")}}</div>
				<div class="close" @click="showSC=false">X</div>
			</div>
			<div class="con2 clearfix">
				<div class="left">
					<div class="seltabsbox">
								<div class="li" @click="selint(index,item.currency_id)" :class="index==selindex?'cur':''" v-for="(item,index) in address">{{item.title}}</div>
						</div>

						<div class="qrcodebox">
							  <div class="" v-for="(item,index) in address">
								  <img :src="item.qrcode" v-show="selindex==index" class="qr2img">
								  <div class="center address" v-show="selindex==index">{{item.address}}</div>
								  <div style="text-align: center;"><button class="copy"  flat :data-clipboard-text="item.address"  v-show="selindex==index"  @click="copy">{{ $t('account.copy') }}</button></div>

							  </div>
						</div>
				</div>
				<div class="right">
					<el-form ref="form" :model="form" label-width="160px" label-style="color:#fff;">
					  <el-form-item class="hide2" :label="$t('account.amount')">
					    <el-input v-model="form.num" :placeholder="$t('account.amount')"></el-input>
					  </el-form-item>
					  <el-form-item class="hide" :label="$t('account.cgaddress')">
					    <el-input v-model="form.from" :placeholder="$t('account.cgaddress')"></el-input>
					  </el-form-item>
					  <el-form-item class="hide" :label="$t('withdrawList.hash')">
					    <el-input v-model="form.hash" :placeholder="$t('withdrawList.hash')"></el-input>
					  </el-form-item>
					  <el-form-item class="hide2" :label="$t('account.picture')">
					    <el-upload
					        ref="uploader"
					        class="upload-demo"
					        :action="uploadUrl"
					        :headers="uploadHeaders"
					        :http-request="uploadFile"
					        :multiple="false"
					        :limit="1"
					        list-type="picture"
					        :file-list="form.pic">
					      <el-button size="mini" type="success">{{ $t('account.choose') }}</el-button>
					      <div slot="tip" class="el-upload__tip"></div>
					    </el-upload>
					  </el-form-item>
					  <el-form-item class="hide2">
					    <el-button type="primary" size="medium" @click="submitChargeInfo">
					      {{ $t('account.submitRecharge') }}
					    </el-button>
					  </el-form-item>
					</el-form>
					<div class="rtips" v-for="(item,index) in address">
						<div class="tipsboxx" v-show="selindex==index">
												  <div v-html="tihaun(item.tips)"></div>
								  </div>
					</div>
				</div>

			</div>
		</div>
	</div>
  </div>
</template>
<script>
import indexHeader from "@/view/indexHeader";
import left from "@/view/left";
import "@/lib/clipboard.min.js";
import "@/lib/jquery.qrcode.min.js";
import axios from "axios";

import mixin from "@/mixin";
export default {
  mixins: [mixin],
  name: "finance",
  filters: {
    toFixeds: function (value) {
      value = Number(value);
      return value.toFixed(8);
    },
    toFixedTwo: function (value) {
      value = Number(value);
      return value.toFixed(2);
    }
  },
  data() {
    return {
      totle: "",
      recData: [],
      token: "",
      flags: false,
      flag: false,
      isHide: true,
      active: "a",
      active01: "a",
      tibi_msg: '',//this.$t("account.tibi"),
      active02: "a",
      addr: "",
      url: "",
      excharge_currency: "",
      excharge_address: "",
      number: "",
      rate: "",
      coinname: "",
      balance: "",
      ratenum: "",
      reachnum: "",
      min_number: "",
      permissions: [
        "account_number:13888125345" //用于判断是是否游客登录使用
      ],
      min_number_txt: "",
      asset_list: [],
      tip_list: [
        this.$t('account.a1') + "USDT" + this.$t('account.a2'),
        "USDT" + this.$t('account.a3')
      ],
      tip_list01: [
        this.$t('account.a1') + "USDT" + this.$t('account.a2'),
        "USDT" + this.$t('account.a3')
      ],
      page: 1,
      more: this.$t("more"),
      balanceList: [this.$t('account.laccount'), this.$t('usercenter.lever')],
      transferData: {
        modalShow: false,
        transferName: "",
        transferBalance: "",
        start: "",
        end: ""
      },
      status: '',
      form: {
        from: '',
        num: '',
        pic: [],
        hash: '',

      },
      uploadUrl: '',
      uploadHeaders: {},
	  qrcodeimg:"",
	  excharge_address_btc:"",
	  excharge_address_eth:"",
	  excharge_address_usdt:"",
	  qrcodeimg_btc:"",
	  qrcodeimg_eth:"",
	  qrcodeimg_usdt:"",
	  systembi:"",
	  systemlist:[],
	  isshowsystembi:false,
	  huilv:0,
	  userchongbi_type:"",
	  autoin_tips:"",
	  address:[],
      currency: "",
	  currency_id:1,
	  showAutoC:false,
	  showSC:false,
	  selindex:0,
	  address2:"",//提币时用的
    };
  },
  components: {
    indexHeader,
    left
  },
  methods: {

	  	  tihaun:function(str){
			  let aaa={
			  	"1":"BTC",
				"2":"ETH",
				"3":"USDT"
			  };
	  		  let bi=this.currency_id;
			 // console.log(bi)
	  		  return str.replace("<币种名称>",aaa[bi]);
	  	  },
	  jiesuo:function(data,index){
	  	const loading = this.$loading()
	  	let that = this;
	  	this.$http({
	  	    method: 'post',
	  	    url: '/api/user/unlock',
	  		data:{
	  			id:data.id,
	  		},
	  	    headers: {
	  	        Authorization: localStorage.getItem("token")
	  	    }
	  	}).then(res => {
	  		loading.close();
	  	    if(res.data.type=="ok"){
	  			this.systemlist[index].status=3;
	  			this.$toast.success(this.$t("diy.解锁成功"));
	  		}else{
	  			this.$toast.error(this.$t("diy.解锁失败"));
	  		}
	  	})
	  },
    goRecord() {
      this.$router.push({name: "coinRecord"});
    },
    init() {

      var that = this;
      that.uploadHeaders = {Authorization: localStorage.getItem('token')};
      that.uploadUrl = that.$utils.back_api + 'api/upload';
      // console.log('上传地址',that.uploadUrl)
      var clipboard = new Clipboard(".copy");
      clipboard.on("success", function (e) {
        layer.alert(that.$t("lay.copys"));
      });
      clipboard.on("error", function (e) {
        alert(that.$t("lay.fcopy"));
      });
    },
	selint:function(index, currency_id){
		this.currency_id=currency_id;
		this.selindex=index;
	},
	excharge2:function(index, currency){
		if(this.userchongbi_type=="2"){
			this.currency_id=currency;
			this.showAutoC=true;
		}else{
			if(this.address.length>0){
				this.currency_id=this.address[0].currency_id;
				this.selindex=0;
				this.showSC=true;
			}else{
				layer.alert(this.$t('notopen'))
			}
		}
	},
    //充币显示
    excharge(index, currency) {
		//return;//暂时停止充币
      //console.log(index, currency);
      if (this.status == 1) {
        this.currency = currency;
        if (this.flag) {
          this.flag = false;
          this.active = "a";
          this.active01 = "a";
          this.active02 = "a";
        } else {
          this.flag = true;
          this.active = index;
          this.active01 = "a";
          this.active02 = "a";
        }
		if(this.active != "a"){
			this.sendData(currency);
		}
        //
      } else {
        layer.alert(this.$t('notopen'))
      }

    },
	getMyaddress:function(){
		var that = this;
		this.$http({
		  url: "/api/" + "wallet/get_in_address",
		  method: "post",
		  data: {currency: 1,"lang":localStorage.getItem("lang")},
		  headers: {Authorization: that.token}
		})
		    .then(res => {
		      if (res.data.type == "ok") {
					/*that.excharge_address_btc=res.data.message.btc;
					that.excharge_address_eth=res.data.message.eth;
					that.excharge_address_usdt=res.data.message.usdt;*/
					let userchongbi_type=res.data.message.userchongbi_type;
					that.userchongbi_type=userchongbi_type;
					that.address =res.data.message.address;
					if(userchongbi_type==2){
						that.autoin_tips=res.data.message.tips;
					}
		      } else {

		      }

		    })
		    .catch(error => {

		      console.log(error);
		    });
	},
    sendData(currency) {
		return;
			this.qrcodeimg="";
      var that = this;
      this.$http({
        url: "/api/" + "wallet/get_in_address",
        method: "post",
        data: {currency: currency},
        headers: {Authorization: that.token}
      })
          .then(res => {
            if (res.data.type == "ok") {

              if (currency == 1 || currency == 3 || currency == 2) {
				  if(currency==1){
					  that.excharge_address=res.data.message.btc;
				  }
				  if(currency==2){
				  		that.excharge_address=res.data.message.eth;
				  }
				  if(currency==3){
				  		that.excharge_address=res.data.message.usdt;
				  }
				  that.excharge_currency=currency;
				  that.getqrcodeimg(that.excharge_address)
               // that.excharge_currency = currency == 1 ? 'BTC' : 'USDT_ERC20';
                //that.excharge_address = currency == 1 ? res.data.message.btc : res.data.message.usdt;
                // 生成二维码
                // $("#code").qrcode({
                //   width: 130, //宽度
                //   height: 130, //高度
                //   text: that.excharge_address
                // });
              } else {
                that.excharge_address = this.$t("apitip.暂不支持该币种");
                // $("#code").qrcode({
                //   width: 130, //宽度
                //   height: 130, //高度
                //   text: that.excharge_address
                // });
              }
            } else {

            }

          })
          .catch(error => {

            console.log(error);
          });
    },
	getqrcodeimg(eexcharge_address){
		const loading = this.$loading({
		  lock: true,
		  text: 'Loading',
		  spinner: 'el-icon-loading',
		  background: 'rgba(0, 0, 0, 0.7)'
		});
		//this.qrcodeimg='/api/qrcode?text='+that.eexcharge_address;
		this.$http({
		  url:'/api/qrcode?text='+eexcharge_address,
		  method: "get",
		  data:"",
		})
		    .then(res => {
		      this.qrcodeimg='/api/qrcode?text='+this.eexcharge_address;
			  loading.close()
		    })
		    .catch(error => {  loading.close()  });
	},
	shiji:function(num,price,price_sell){
		return ((price_sell-price)*num).toFixed(8);
	},
    //提币
    withdraw(index, currency) {
      if (this.status == 1) {
        this.currency = currency;
        if (this.flag) {
          this.flag = false;
          this.active = "a";
          this.active01 = "a";
          this.active02 = "a";
        } else {
          this.flag = true;
          this.active01 = index;
          this.active = "a";
          this.active02 = "a";
        }
        this.getNum(currency);
      } else {
        layer.alert(this.$t('notopen'));
      }

    },
    //记录
    rec(index, currency) {
      this.currency = currency;
      this.recData = [];
      if (this.flag) {
        this.flag = false;
        this.active = "a";
        this.active01 = "a";
        this.active02 = "a";
      } else {
        this.flag = true;
        this.active02 = index;
        this.active = "a";
        this.active01 = "a";
        this.$http({
          url: "/api/wallet/legal_log",
          method: "post",
          data: {type: 2, currency: currency, page: 1},
          headers: {Authorization: this.token}
        }).then(res => {
          if (res.data.type == "ok") {
            this.recData = res.data.message.list;
          }
        });
      }
    },
	daojishi2:function(s,e){
		let s1=Date.parse(s)/1000;
		let e1=Date.parse(e)/1000;
		return Math.ceil((e1-s1)/86400)
		//return 1;
	},
    getNum(currency) {
      var that = this;
      this.$http({
        method: "POST",
        url: "/api/" + "wallet/get_info",
        data: {
          currency: currency
        },
        headers: {
          "Authorization": localStorage.getItem('token')
        }
      }).then(res => {
        res = res.data;
        that.coinname = res.message.name;
        that.balance = res.message.change_balance;
        that.min_number =
            that.$t('account.minnum') + parseFloat(res.message.min_number).toFixed(2)
            // + "   " + that.$t('account.oncemaxnum') + parseFloat(res.message.max_number).toFixed(2)
            // + "   " + that.$t('account.daynum') + parseFloat(res.message.day_limit).toFixed(2);
        that.min_number_txt = parseFloat(res.message.min_number).toFixed(8);
        that.minnumber = res.message.min_number;
        that.max_number = res.message.max_number;
        that.day_limit = res.message.day_limit;
        that.ratenum = res.message.rate;
        that.reachnum = 0.0;
        that.rate = res.message.rate;
      });
    },
    // 提币按钮
    mention() {
      var that = this;
      var currency = this.currency;
	  let charge="BTC";
	  switch(currency){
		  case 1:
			charge="BTC";
			break;
		  case 2:
			charge="ETH";
			break;
		  case 3:
			charge="USDT";
			break;

	  }
      var address = this.address2;
      var number = this.number;
      var rate = this.rate;
      var min_number = this.minnumber;
      if (!address) {
        layer.msg(that.$t('lay.caddress'), {icon: 5});
        return;
      }
      if (!number) {
        layer.msg(that.$t("lay.cnumber"), {icon: 5});
        return;
      }
      if (number - 0 < min_number) {
        return layer.msg(that.$t("lay.minnum"), {icon: 5});
      }
		let arg={
			currency: this.currency,
			  number: this.number,
			  address: address,
			  rate: this.rate,
			  type:0,
			  charge:charge
		}
		const loading=this.$loading();
      this.$http({
        method: "POST",
        url: "/api/" + "wallet/out",
        data: arg,
		dataType: "json",
		contentType: "application/json",
        headers: {
          "Authorization": localStorage.getItem('token'),
		  "lang": localStorage.getItem('lang')
        }
      }).then(res => {
        res = res.data;

        if (res.type == "ok") {
		  layer.msg(res.message,{icon: 1});
          setTimeout(() => {
            window.location.reload();
          }, 1500);
        } else {
          layer.msg(res.message,{icon: 5});
        }
			loading.close();
      });
    },
    exchange() {
    },
    //复制
    copy() {
      var that = this;
		switch(that.currency){
			case 1:
				that.excharge_address=that.excharge_address_btc
				break;
			case 2:
				that.excharge_address=that.excharge_address_eth
				break;
			case 3:
				that.excharge_address=that.excharge_address_usdt
				break;

		}
		//alert(that.excharge_address)
      var clipboard = new Clipboard(".copy", {
        text: function () {
          return that.excharge_address;
        }
      });
      clipboard.on("success", function (e) {
        that.flags = true;
        layer.msg(that.$t("lay.copys"));
      });
      clipboard.on("error", function (e) {
        that.flags = false;
        layer.msg(that.$t("lay.fcopy"));
      });
    },
    record() {
      this.$router.push({name: "finanrec"});
    },
    withdraw_address() {
      this.$router.push({name: "withdraw_address"});
    },
    show_ewm() {
      if (this.isHide) {
        this.isHide = false;
      } else {
        this.isHide = true;
      }
    },
	gethl:function(){
			  this.$http({
			    url: "/api/" + "bi/price",
			    data: {},
			    method: "post",
			  	headers: {'Authorization': localStorage.getItem('token')},
			  }).then(res => {
			  		let data=res.data.message;
					this.huilv=data.price;

			  });
	},
	intvalHl:function(){
			   clearInterval(this.intervalId8888)
			   this.intervalId8888 = setInterval(() => {
					this.gethl();
			   }, 1000)
	},
	getsystembilist() {
	  var that = this;
	  this.$http({
	    url: "/api/" + "user/uplists",
	    method: "post",
	    data: {
			"limit":500,
			page:1
		},
	    headers: {Authorization: that.token}
	  })
	      .then(res => {
	         this.systemlist=res.data.message.data;
	      })
	      .catch(error => {
	        console.log(error);
	      });
	},
    getdata() {
      var that = this;
      this.$http({
        url: "/api/" + "wallet/list",
        method: "post",
        data: {},
        headers: {Authorization: that.token}
      })
          .then(res => {
            if (res.data.type == "ok") {
			let mytotal=res.data.message.change_wallet.usdt_totle+res.data.message.legal_wallet.usdt_totle+res.data.message.lever_wallet.usdt_totle+res.data.message.micro_wallet.usdt_totle;
			localStorage.setItem("mytotal",mytotal);
              //console.log('法币余额', res.data.message);
              that.asset_list = res.data.message.change_wallet.balance;
              that.totle = res.data.message.change_wallet.usdt_totle;
              that.status = res.data.message.is_open_CTbi;
			  that.systembi=res.data.message.systembi;
            } else {
              return;
            }
          })
          .catch(error => {
            console.log(error);
          });
    },
    // 加载更多
    moreData() {
      let that = this;
      that.page = that.page + 1;
      this.$http({
        url: "/api/wallet/legal_log",
        method: "post",
        data: {type: "2", currency: that.currency, page: that.page},
        headers: {Authorization: this.token}
      }).then(res => {
        if (res.data.type == "ok") {
          var datas = that.asset_list;
          for (let i in datas) {
            if (that.currency == datas[i].currency) {
              that.active02 = i;
              if (res.data.message.list.length > 0) {
                that.recData = that.recData.concat(res.data.message.list);
              } else {
                that.more = that.$t('nomore');
              }

            }
          }
        }
      });
    },
    // 划转
    transfer(index, currency) {
      let that = this;
      that.transferData.modalShow = true;
    },
    uploadFile(f) {
      let that = this;
      let formData = new FormData();
      formData.append("file", f.file);

      this.$http.post("/api/upload", formData, {
        headers: {"Content-Type": "multipart/form-data"}
      }).then(res => {
        let msg = res.data;
        if (msg.type == 'ok') {
          that.form.pic = [{name: '已上传', url: msg.message}];
        } else {
          layer.msg(msg.message)
        }
      });
    },
    submitChargeInfo() {
      let data = {};
	    let currency=this.currency_id
      data.currency = currency;
      data.acc = this.form.from;
      data.hash = this.form.hash;
      data.amount = this.form.num;

      // 这里影响充值
/*      if (!data.acc  || !data.amount || !data.currency || this.form.pic.length<1) {
        layer.msg(this.$t("lay.canshucuowu"));
        return;
      }*/

      // 判断是否游客账户


      if(this.form.pic.length>0){
        data.pic = this.form.pic[0].url;
      }else{
        data.pic=""
      }

      let that = this;
	  const loading=this.$loading()
      this.$http({
        url: "/api/wallet/charge_req",
        method: "post",
        data: data,
        headers: {Authorization: this.token}
      }).then(res => {
        if (res.data.type == "ok") {
          layer.msg(this.$t("lay.chongzhiyitijiao"));
          that.form.num = '';
          that.form.from = '';
          that.form.hash = '';
          that.form.pic = [];
		  this.showSC=false;
        } else {
          layer.msg(this.$t("lay.chongzhisqb"));
        }
		loading.close();
      });
    },
	yingku:function(num,price){
		let huilv=this.huilv;

		return (num*(huilv-price)).toFixed(8);
	}
  },
  created() {
  this.token = localStorage.getItem("token") || "";
	this.getMyaddress();
	this.intvalHl();
	this.getsystembilist();
  this.account_number = this.$route.query.account_number || '13888125345';//用于判断游客登录提示
  },

  mounted() {
    var that = this;
    that.getdata();
    that.init();
  }
};
</script>
<style lang="scss">
	.czpopbox{position: fixed;z-index: 888;top:0;left:0;right:0;bottom: 0;color: #fff;display: flex;flex-direction: column;align-items: center;}
	.czpopbox .mask{cursor: pointer;position: absolute;z-index: 0;left:0;top:0;right:0;bottom:0;background:rgba(255,255,255,.75);}
	.czpopbox .content{position: relative;z-index: 2;margin: auto;background:rgba(0,0,0,.9);border-radius: 8px;}
	.czpopbox .content .con{width:600px;padding:15px 20px;}
	.czpopbox .content .con2{width:1000px;padding:15px 20px;}
	.czpopbox .ttop{display: flex;flex-direction: row;align-items: center;position: relative;padding:15px 0 15px 40px;border-bottom: 1px solid #555;}
	.czpopbox .ttop .close{margin-left: auto;width:40px;cursor: pointer;}
	.czpopbox .ttop .title{flex: 1;text-align: center;font-size: 18px;font-weight: bold;}
	.czpopbox .address{padding:8px 0;text-align: center;}
	.qr2img{display: block;margin:15px auto;padding:8px;border: 1px solid #e4e4e4;border-radius: 4px;width: 240px;height: 240px;}
	.czpopbox .copy{margin: auto;border: 1px solid #fff;padding:8px 40px;border-radius: 4px;display: block;cursor: pointer;background: #fff;}
	.tipsboxx{text-align: justify;text-align-last: left;padding:15px 0px;font-size: 14px;}
	.tipsboxx .br{margin:3px 0;height: 1px;display: block;}
	.seltabsbox{padding:10px 15px 0 15px;display: flex;flex-direction: row;align-items: center;flex-wrap: wrap;}
	.seltabsbox .li{background: #eee;margin-right: 10px;white-space: nowrap;border-radius: 3px;padding:0 10px;line-height: 30px;color: #888;cursor: pointer;}
	.seltabsbox .li.cur{background: #007AFF;color:#fff;}
	.seltabsbox .li:first-child{margin-left: auto;}
	.seltabsbox .li:last-child{margin-right: auto;}
	.clearfix::after{display: table;clear: both;content: "";}

	.czpopbox .left{float: left;width:440px;}
	.czpopbox .right{float: right;width:480px;}





	.onerow{}
	.onerow::after{content: "";display: table;clear: both;}
	.onerow .w50b{float: left;width:50%;}
	.newtb{}
	.newtb::after{content: "";display: table;clear: both;}
	.newtb .tb{display: inline-block;margin-right: 15px;cursor: pointer;height: 32px;line-height: 32px;border-radius: 4px;padding:0 15px;}
	.newtb .tb.cur{color: #ffc90c;
    background-color: #181d25;}
	.newtb .tb2{display: inline-block;padding-left: 100px;color: #Fff;}
	.nodata{text-align: center;line-height: 80px;}
	.lsbox{min-height: 540px;}
	.newtable{width: 100%;border-collapse:collapse;}
	.newtable th,.newtable tr,.newtable td{border-collapse:collapse;}
	.newtable tr{border-bottom: 1px solid #333333;}
	.newtable th,.newtable td{padding: 12px 10px;}
	.btn{cursor: default;color: #fff;border-radius: 3px;background: #666;text-align: center;line-height: 28px;height: 28px;}
	.btn.btn-ok{background:#4caf50;}
	.btn.btn-err{background:#f44336;cursor: pointer;}

.el-form-item__label {
  color: #637085 !important;
}

.el-input__inner {
  border-color: #4d5373 !important;
  background-color: #181d25 !important;
  color: #cecfd0 !important;
}
</style>
<style lang="scss" scoped>
.header {
  padding: 15px 30px;
  overflow: hidden;
}


.min_lab {
  margin: 0 10px;
}

.min_lab input {
  margin-right: 3px;
}

.inp_lab {
  border: 1px solid #6b80ae;
  border-radius: 2px;
  padding: 3px 5px;
}

.inp_lab input {
  background: none;
  border: none;
  width: 120px;
  color: #fff;
}

.right_text {
  color: #5697f4;
}

.right_text span {
  cursor: pointer;
}

.record {
  margin-right: 10px;
}

.content_ul {
  padding: 0 20px;
}

.content_top {
  padding: 10px 20px;
  // background: #161617c7;
}

.content_li {
  padding: 15px 0;
  border-bottom: 1px solid #1e2c42;
}

.operation,
.ewm {
  color: #ffc90c;
}



.copy:hover {
  cursor: pointer;
}

.ewm:hover {
  cursor: pointer;
}

.operation span {
  cursor: pointer;
  margin: 0 5px;
}

.hide_div {
  border: 1px solid #1e2c42;
  padding: 20px;
}

.excharge_record {
  color: #5697f4;
}

input {
  background: none;
  border: none;
}

.address_inp {
  width: 100%;
  border: 1px solid #6b80ae;
  border-radius: 3px;
  padding: 15px 15px;
}

.num_lab {
  display: flex;
  width: 100%;
  border: 1px solid #6b80ae;
  border-radius: 3px;
  padding: 15px;
}

.num_lab input {
  width: 100%;
}

.range_lab,
.get_lab {
  border: 1px solid #6b80ae;
  border-radius: 3px;
  padding: 15px;
}

.get_lab {
  background: #1e2c42;
}

.right_inp_wrap {
  margin-left: 20px;
}

.use_num,
.advance {
  color: #5697f4;
}

.use_num {
  margin-right: 5px;
}

.advance {
  margin-left: 5px;
}

.withdraw_btn {
  background-color: #7a98f7;
  color: #fff;
  padding: 15px 70px;
  border: none;
  border-radius: 5px;
}

.withdraw_btn:hover {
  cursor: pointer;
}

.bg {
  background: #2b3c71;
}

.ewm_wrap {
  position: relative;
}

.ewm_img {
  width: 150px;
  height: 150px;
  position: absolute;
  top: 25px;
  left: -45px;
  border: 10px solid #fff;
  z-index: 1;
}

.hide {
  display: none;
}

.rec-box {
  .rec-con {
    margin: 10px;
    padding: 0 20px;
    background: #262a42;

    span {
      flex: 1;
      text-align: center;
      line-height: 3;
    }

    .rec-title {
      display: flex;
      justify-content: space-between;
      font-size: 14px;
      color: #fff;
      line-height: 1.5;
    }

    li {
      display: flex;

      justify-content: space-between;
      font-size: 12px;
      color: #728daf;
      border-top: 1px solid #2e394c;
    }
  }
}

.more {
  width: 100%;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
}

.transfer-modal {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  overflow: hidden;
  justify-content: center;
  align-items: center;
}

.transfer-mask {
  width: 500px;
  background-color: #262a42;
  margin: 0 auto;
  border-radius: 5px;
}

.transfer-header {
  line-height: 40px;
  text-align: center;
  position: relative;
}

.transfer-header p {
  position: absolute;
  right: 10px;
  top: 0;
}

.num_lab input::-webkit-input-placeholder {
  color: #aaa;
  font-size: 12px;
}
</style>
