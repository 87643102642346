<template>
  <div class="cbai">
    <indexHeader></indexHeader>
    <div class="sgbox wapp">
		<div class="info1">
			<div class="bigtitle">{{ $t("apitip.开始申购") }} {{biInfo.c_name}}</div>
			<div class="flex_row" style="padding-left: 50px;">
				<div class="ajia">
					<!--<div class="tit">{{ $t("apitip.申购币种")}}</div>
					<div class="text">USDT</div>
					 <div class="tit">{{ $t("apitip.预计上线时间")}}</div>
					<div class="text">{{biInfo.line_time}}</div> -->
					<div class="tit">{{ $t("apitip.开始申购时间")}}</div>
					<div class="text">{{biInfo.start_time2}}</div>
				</div>
				<div class="ajia">
					<div class="tit">{{ $t("apitip.结束申购时间")}}</div>
					<div class="text">{{biInfo.end_time2}}</div>
				</div>
			</div>
		</div>
		<div class="info">
			<div style="height: 72px;"></div>
			<div class="">
				<div>
					<select v-model="sel.type" @change="seltype">
						<option value="USDT" :selected="sel.type=='USDT'">USDT</option>
						<!-- <option value="BTC" :selected="sel.type=='BTC'">BTC</option>
						<option value="ETH" :selected="sel.type=='ETH'">ETH</option> -->
					</select>
					<input type="number" id="buy_number" class="ipt" v-model="sel.num">	
					<button class="btnnn  mr888" @click="selall">{{ $t("apitip.全部")}}</button>
					<button class="btnnn" @click="gobuy">{{ $t("apitip.立即申购")}}</button>
				</div>
				<div class="nowbii">
					<div style="display: flex;">
						<div style="flex:1">
							<div>1 {{sel.type}}= {{num2fixed(1/huilv)}} {{biInfo.c_name}}</div>
							<!-- <div>{{user[sel.type]}} {{sel.type}} = {{num2fixed(user[sel.type]/huilv)}} {{biInfo.c_name}}</div> -->
							<div>{{sel.num}} {{biInfo.c_name}} = {{num2fixed(sel.num*biInfo.buymoney)}} {{sel.type}}</div>
						</div>
						<div style="flex:1">
							<div>{{$t("apitip.冻结剩余时间")}}</div>
							<div>{{biInfo.iepn_scz}} {{$t("apitip.小时")}}</div>
						</div>
					</div>
					
				</div>
			</div>
		</div>
	</div>
	<div style="height: 80px;"></div>
	<div class="wapp">
		<div class="bigtitle">{{$t("apitip.申购周期")}}</div>
		<div class="guochengbox">
			<div class="agc cur">
				<div class="ico"><span>1</span><i></i></div>
				<div class="txt1">{{$t("apitip.项目预热")}}</div>
				<div class="time"></div>
				<em></em>
			</div>
			<div class="agc" :class="biInfo.step>1?'cur':''">
				<div class="ico"><span>2</span><i></i></div>
				<div class="txt1">{{$t("apitip.开始申购")}}</div>
				<div class="time">{{biInfo.start_time2}}</div>
				<em></em>
			</div>
			<div class="agc" :class="biInfo.step>2?'cur':''">
				<div class="ico"><span>3</span><i></i></div>
				<div class="txt1">{{$t("apitip.结束申购")}}</div>
				<div class="time">{{biInfo.end_time2}}</div>
				<em></em>
			</div>
			<div class="agc" :class="biInfo.step>2?'cur':''">
				<div class="ico"><span>4</span><i></i></div>
				<div class="txt1">{{$t("apitip.公布结果")}}</div>
				<div class="time">{{biInfo.end_time2}}</div>
				<em></em>
			</div>
		</div>
	</div>
	<div class="wapp">
		<div style="height: 80px;"></div>
		<div class="bigtitle">{{$t("apitip.项目详情")}}</div>
		<div  v-html="biInfo.content"></div>
	</div>
	
	
    <indexFooter></indexFooter>
	<div style="display: none;">{{abc}}</div>
	<el-dialog
	  :title="$t('apitip.申购确认')"
	  :visible.sync="dialogVisible"
	  :close-on-click-modal="false"
	  :close-on-press-escape="false"
	  :show-close="false"
	  width="588px">
	  <div class="poptbox">
		  <div class="t">{{$t("apitip.您计划申购")}}</div>
		  <div class="c">
			 {{jihua.num}} {{biInfo.c_name}} = {{jihua.huilv}} {{jihua.type}}
		  </div>
	  </div>
	  <span slot="footer" class="dialog-footer">
	    <el-button @click="dialogVisible = false">{{$t("apitip.取消")}}</el-button>&nbsp;&nbsp;&nbsp;&nbsp;
	    <el-button type="primary" @click="togobuy">{{$t("apitip.确定")}}</el-button>
	  </span>
	</el-dialog>
  </div>

</template>

<script>
import indexHeader from "@/view/indexHeader";
import indexFooter from "@/view/indexFooter";

import mixin from "@/mixin";
export default {
	mixins: [mixin],
  name: "dealCenter",
  components: {
    indexHeader,indexFooter,
    load: 1,
  },
  data() {
    return {
      uid:null,
	  biInfo:{
		  line_time:"",
		  start_time2:"",
		  end_time2:"",
		  c_name:"",
		  step:1,
		  buymoney:0,
		  iepn_scz:0
	  },
	  user:{
		  USDT:0,
		  BTC:0,
		  ETH:0
	  },
	  huilv:0,
	  sel:{
		  type:"USDT",
		  num:0,
		  value:0
	  },
	  dialogVisible:false,
	  jihua:{
		  type:"",
		  num:0,
		  huilv:0
	  },
	  pages:{
		limit:10,
		page:1  
	  }
	  
    }
  },
  created() {
	  clearInterval(this.intervalId8888)
    this.init();
  },
  mounted() {
	
  },
  computed:{
	  abc:function(){
		  //this.seltype();
		  if(!this.sel.num){
			  this.sel.num=0;
		  }
		  this.sel.num=this.sel.num*1;
	  }
  },
  methods: {
	  init:function(){
		 let uid=localStorage.getItem("user_id");
		 if(!uid){
			 this.$message.error(this.$t("apitip.请登录"));
			 //this.$router.push("/components/login");
			 window.location.href="/#/components/login"
		 }else{
			 this.gethl();
			 this.intvalHl();
			 this.getinfo();
		 }
		 /*const loading = this.$loading({
		   lock: true,
		   text: 'Loading',
		   spinner: 'el-icon-loading',
		   background: 'rgba(0, 0, 0, 0.7)'
		 });*/
		 //this.getlist();
	  },
	  gobuy:function(){
		  if(!this.sel.num){
			  this.$message.error(this.$t("apitip.请输入申购数量"));
			  return;
		  }
		  let arg={
			  type:this.sel.type,
			  num:this.sel.num,
			  huilv:(this.sel.num*this.biInfo.buymoney).toFixed(6)
		  }
		  console.log(this.sel.num*this.huilv)
		  if(arg.num>this.sel.num*arg.huilv){
			  //this.$message.error(this.$t("apitip."+arg.type+"余额不足"));
			  //return;
		  }
		  this.jihua=arg;
		  this.dialogVisible=true;
		  //console.log(JSON.stringify(arg))  
	  },
	  togobuy:function(){
		  this.dialogVisible=false;
		  const loading = this.$loading({
		    lock: true,
		    text: 'Loading',
		    spinner: 'el-icon-loading',
		    background: 'rgba(0, 0, 0, 0.7)'
		  });
		  let arg={"amount":this.jihua.num,"buymoney":this.huilv}
		 
		  this.$http({
		    url: "/api/" + "user/up_order",
		    data: arg,
		    method: "post",
		  	headers: {'Authorization': localStorage.getItem('token')},
		  }).then(res => {
		  	let r=res.data;
			if(r.type=="ok"){
				this.$message.success(this.$t("apitip.申购成功"))
				loading.close();
				//this.$router.push("/change");
				this.$router.push("/uplist");
				//this.getinfo();
				//this.getlist()
			}else{
				this.$message.error(this.$t("apitip."+r.message))
				loading.close();
			}
		  });
	  },
	  getinfo:function(){
		  //const loading=this.$loading()
		  this.$http({
		    url: "/api/" + "user/upstart",
		    data: {},
		    method: "get",
			headers: {'Authorization': localStorage.getItem('token')},
		  }).then(res => {
			  
			  //console.log(JSON.stringify(data.message))
				this.biInfo=res.data.message;
				this.user.USDT=res.data.message.detail.balance;
				//console.log(res.data.detail.balance)
				//this.user=data.user;
		  });
	  },
	  seltype:function(e){
		 this.sel.num=0
	  },
	  gethl:function(){
		  this.$http({
		    url: "/api/" + "bi/price",
		    data: {},
		    method: "post",
		  	headers: {'Authorization': localStorage.getItem('token')},
		  }).then(res => {
		  		let data=res.data.message;
				this.huilv=data.price;
				
		  });
	  },
	  intvalHl:function(){
		   clearInterval(this.intervalId8888)
		   this.intervalId8888 = setInterval(() => {
				this.gethl();
		   }, 1000)
	  },
	  selall(){
	  		  //this.sel.num=this.num2fixed(this.user[this.sel.type]/this.huilv,8)
			   this.sel.num=this.num2fixed(this.user[this.sel.type]/this.biInfo.buymoney,8)
	  },
	  num2fixed:function(num,ff=6){
		  let newnum=0;
		  if(!num){
			  newnum=0;
		  }
		  newnum=num.toFixed(ff)
		  return newnum;
	  },
	  getlist:function(){
		  this.$http({
		    url: "/api/" + "user/uplists",
		    data: this.pages,
		    method: "post",
		  			headers: {'Authorization': localStorage.getItem('token')},
		  }).then(res => {
		  			  this.list=res.data.message.data;
					  this.pages={
						  limit:res.data.message.limit,
						  page:res.data.message.page+1
					  }
		  });
	  }
	  
  }
};
</script>

<style scoped lang="scss">
	.poptbox{text-align: center;line-height: 34px;}
	.poptbox .t{}
	.poptbox .c{font-size: 20px;}
	.wapp{width: 1200px;margin: auto;font-size: 14px;}
	.cbai{color:#fff;}
	.guochengbox{width:820px; display: flex;flex-direction: row;margin: auto;}
	.guochengbox .agc{flex: 1;display: block;text-align: center;position: relative;}
	.guochengbox .agc .ico{display: block;width: 48px;height: 48px;margin: auto;position: relative;line-height: 48px;margin-bottom: 30px;}
	.guochengbox .agc .ico>i{display: none;position: absolute;left:0;top:0;bottom:0;right:0;background: url(../assets/images/sel_cur.png) center center no-repeat;border-radius: 50%;}
	.guochengbox .agc .ico>span{display: block;position: absolute;left:0;top:0;right:0;bottom: 0; background: #516285;color: #fff;border-radius: 50%;font-size: 22px;}
	.guochengbox .agc.cur .ico>i{display: block;}
	.guochengbox .agc.cur .ico>span{display: none;}
	.guochengbox .agc em{position: absolute;content: "";width:120px;height: 2px;background: #516285;top:23px;left:50%;margin-left: 44px;}
	.guochengbox .agc.cur em{background: #3573ff;}
	.guochengbox .agc:last-child em{display: none;}
	.guochengbox .agc .txt1{font-size: 18px;height: 36px;}
	.guochengbox .agc .time{color: rgba(132,149,183,1);}
	.ajia:first-child{margin-right: 40px;}
	.ajia {line-height: 20px;white-space: nowrap;display: block;}
	.ajia .tit{color:rgba(132,149,183,1);white-space: nowrap;display: block;height: 27px;}
	.ajia .text{color:#b4c1da;white-space: nowrap;display: block;margin-bottom: 20px;}
	.sgbox{display: flex;flex-direction: row;padding: 60px 0 20px 0;color: #fff;}
	.bigtitle{color:#ff;font-size: 18px; padding-bottom: 30px;}
	.info1{flex:1;}
	.info{flex:2;}
	.flex_row{display: flex;flex-direction: row;}
	.nowbii{line-height: 24px;}
	select{
		border: none;
		    background: none;
		    font-size: 16px;
		    font-family: PingFangSC-Regular,PingFang SC;
		    font-weight: 400;
		    color: rgba(81,98,133,1);
		    line-height: 22px;
		    padding: 5px 20px;
		    border-bottom: 1px solid #516285;
	}
	.ipt{margin: 10px 0;
		padding: 6px 0;
		border: none;
		border-bottom: 1px solid #516285;
		background: none;
		color: #fff;
	}
	
	.btnnn{
		height: 36px;
		    background: linear-gradient(90deg,rgba(54,124,239,1) 0%,rgba(47,175,240,1) 100%);
		    border-radius: 2px;
		    border: none;
		    font-size: 14px;
		    font-family: PingFangSC-Regular,PingFang SC;
		    font-weight: 400;
		    color: rgba(255,255,255,1);
		    line-height: 20px;
		    padding: 0 15px;cursor: pointer;
	}
	.btnnn.mr888{margin-right: 80px;}
</style>
