export default{
	"siteName": "NEWBIT",
	"scan": "일소 하 다",
	"exchangeRate": "환율",
	"none": "",
	"code": "인증서 보내기",
	"send": "인증서 보내기",
	"pwd": "비밀번호",
	"inMobile":"이동 단 에서 피드백 해 주세요",
	"resend": "{number} 초 후 다시 보 내기",
	"welcome": "Hi,NEWBIT 어서 오 세 요",
	"accounts": "계좌 번호",
	"registers": "등록",
	"regiterStep1": "지금 등록하세요",
	"regiterStep2": "데이터를 저장",
	"xieyi": "을 읽었으며 이에 동의합니다",
	"xieyi2": "\"사용자 계약\"",
	"transferFromChange":"화폐 계좌 에서 전입 하 다",
	"transferChange":"화폐 계좌 로 이체 하 다",
	"transferOk":"이월 완료",
	"xieyi1": {
	"first": "등록 후 국적 정보 수정 불가",
		"second": "확인 이메일이 스팸으로 판단 될 수 있습니다",
		"third": "계정 정보와 비밀번호를 올바르게 보관하십시오",
		"fourth": "다른 웹 사이트와 동일한 로그인 비밀번호를 사용하지 마십시오",
		"fifth": ""
},
	"confirm": "확인",
	"logins": "로그인",
	"notice": "공고",
	"more": "더 많이 불러오기",
	"nomore": "더 이상 없음",
	"time": "시간",
	"price": "가격",
	"number": "수",
	"info": "기본 정보",
	"link": "관련 링크",
	"cfprice": "ICO가격",
	"totalc": "유통 총량",
	"bname": "화폐의 종류 명칭",
	"query": "지역적 조회",
	"dealed": "거래가 이미 이루어지다",
	"notdeal": "미결합",
	"handicap": "반구",
	"rtt": "실시간 거래",
	"timescreen": "시간 필터",
	"dealscreen": "교역 대별 선별",
	"dirscreen": "방향 선별",
	"to": "이르다",
	"startdate": "시작 날짜",
	"overdate": "종료 날짜",
	"do": "조작",
	"revoke": "철회하다",
	"nodata": "일시 데이터 없음",
	"loading": "가재중",
	"status": "상태",
	"rate": "수수료",
	"back": "되돌아가다",
	"alipay": "알리 페이",
	"wechat": "위챗",
	"bankcard": "은행 카드",
	"inpCur": "화폐의 명칭을 입력하십시오",
	"minAm": "최소 수량",
	"regidters": "등록을 환영하다",
	"alltread": "전체 역 거래",
	"treadnum": "거래 횟수",
	"treaddetail": "거래 내역",
	"tnum": "거래 횟수",
	"lastweek": "지난주",
	"reload": "새로 고침",
	"dealtime": "거래 시간",
	"way": "위임 방법",
	"torg": "교환",
	"dealno": "커미션 번호",
	"ttotal": "거래 금액",
	"auto": "셀프 서비스 수수료",
	"sum": "합계",
	"traedoff": "거래 쌍이 제거되었습니다. 거래 쌍을 다시 전환하십시오",
	"piece": "장",
	"hand": "손",
	"pbuynum": "구매 횟수를 입력하십시오",
	"bestsell": "최고의 시장 가격으로 판매",
	"lowbuy": "시장에서 가장 저렴한 가격으로 구매",
	"transin": "양도",
	"transout": "전송",
	"tansinmoney": "교장에게 양도",
	"tansoutmoney": "교장을 양도",
	"from": "부터",
	"jingdu": "정밀",
	"m": "최소",
	"day": "일",
	"week": "주",
	"year": "착용",
	"mon": "월",
	"hour": "시간",
	"header": {
	"home": "홈 페이지",
		"c2c": "법적화페 거래",
		"match": "계약 콘테스트",
		"currency": "교역",
		"assets": "나의 자산",
		"setting": "안전 설정",
		"bbs": "포럼",
		"help": "도움말 센터",
		"complaint": "고소 메시지",
		"in": "로그인",
		"shop": "나의 가게",
		"up": "등록",
		"current": "당면 위탁",
		"inTourist": "관광객 등록",
		"his": "역사 위탁",
		"code": "초청 코드",
		"out": "로그인 탈퇴",
		"orders": "주문서",
		"center": "개인 센터",
		"pro": "프로젝트 공시",
		"cun": "은행",
		"vote": "투표하다",
		"sgr": "SGR 수요",
		"inset": "수금 설정",
		"tradelog": "거래 일지",
		"fastrecharge": "빠른 재충전"
},
	"footer": {
	"hxex": "NEWBIT 소개하다",
		"legalTrade": "법정 거래",
		"tool": "링크",
		"about": "에 관해",
		"join": "가입하다",
		"connect": "연락 주세요",
		"download": "APP 다운로드",
		"explain": "조항 설명",
		"xieyi": "사용자 프로토콜",
		"yinsi": "프라이버시 조항",
		"legal": "법률 성명",
		"feilv": "거래비",
		"notice": "공고센터",
		"nhelp": "풋내기 도움",
		"support": "사용자 지원",
		"workorder": "인보이스",
		"apply": "상환 신청",
		"gonggao": "사이트 공고",
		"community": "집단",
		"wechat": "위챗",
		"weibo": "웨이 보",
		"email": "비즈니스 이메일",
		"email_info": "메 일 박스：btchedge8899@gmail.com",
		"email_pre": "고객센터：",
		"address": "싱가포르© 2013-2020 NEWBIT",
		"feedback": "문제 피드백",
		"about2": "회사 개요",
		"coin": "디지털 자산 소개",
		"service": "서비스",
		"apply_url": "https://qwe.shushuiji.net/dist/#/components/noticeDetail?id=199",
		"feedback_url": "https://qwe.shushuiji.net/dist/#/components/noticeDetail?id=204",
		"about2_url": "https://qwe.shushuiji.net/dist/#/components/noticeDetail?id=210",
		"coin_url": "https://qwe.shushuiji.net/dist/#/components/noticeDetail?id=215",
		"yinsi_url": "https://qwe.shushuiji.net/dist/#/components/noticeDetail?id=220",
		"feilv_url": "https://qwe.shushuiji.net/dist/#/components/noticeDetail?id=225",
		"legal_url": "https://qwe.shushuiji.net/dist/#/components/noticeDetail?id=230"
},
	"home": {
	"hourday": "24H Vol",
		"with": "과",
		"myMarkets": "자선",
		"markets": "거래 구역",
		"pair": "교역대",
		"market": "시장",
		"price": "가격",
		"change": "등락",
		"high": "최고가",
		"min": "최저가",
		"volume": "거래량",
		"trade": "조작",
		"toTrade": "거래를 하다",
		"c1": "글로벌 의 디지털 자산 설정 및 교역 서비스",
		"c2": "전 세계 의 프로젝트 확장 및 운영 관리 체계 를 널리 퍼뜨리다",
		"c3": "여러 나라에 로컬 거래 서비스 센터가 설치되어 있다",
		"c4": "서비스 130개국 수백만 사용자",
		"c5": "SMART-Chain자산 평가 모델",
		"c6": "독립 전공 의 구덩이 사슬 자산 연구 평가 체계",
		"c7": "장기간 산업체인 추적 및 최권위 중립의 자산 분석",
		"c8": "한 정거장의 프로젝트 진도 추적 및 정보 노출 시스템",
		"c9": "4년의 디지털 자산안전풍에 의존하여 경험을 통제하다",
		"c10": "선행 배상 메커니즘을 세우다",
		"c11": "투자자 보호 기금 을 설립 하다",
		"c12": "언제 어디서나 다플랫폼 터미널 거래",
		"c13": "iOS, Android, Windows 다중 플랫폼을 덮어 전체 업무 기능을 지원합니다",
		"c14": "홍콩 서울 싱가포르 도쿄",
		"c15": "세계 각지의 서비스 센터 에 널리 퍼져 있다",
		"c16": "즉시 거래",
		"c17": "우리 팀 가입 환영, 공동 향상, 더 좋은 자기, 더 나은 인생 가치 창조",
		"access": "다중 플랫폼 터미널 접속",
		"cover": "IOS, Android, Windows 다중 플랫폼을 덮어 전체 업무 기능을 지원합니다",
		"cooper": "협력 기구",
		"vol": "24H량",
		"c01": "세계 에서 앞선 디지털 자산 거래 플랫폼",
		"c02": "세계 130개 국가를 넘어선 수백만 사용자에게 안전을 제공하고 신뢰할 수 있는 디지털 자산 거래 및 자산 관리 서비스를 제공하다",
		"c03": "안전은 신뢰할 수 있다",
		"c04": "5년 디지털 자산 금융 서비스 경험",
		"c05": "전문 분산식 구조와 방도스 공격 시스템",
		"c06": "세계 생태 포석",
		"c07": "여러 나라 가 현지화 거래 서비스 센터 를 설립하다",
		"c08": "다업무형태를 일체의 구체의 생태권으로 만들다",
		"c09": "사용자 지상",
		"sweep": "다운로드",
		"android": "Android",
		"pinput": "이메일 을 입력 하 세 요",
		"atrade": "NEWBIT GROUP 글로벌 계정 등록 절차",
		"Gcoin": "NEWBIT",
		"eamil1": "비즈니스 협동 메일박스",
		"eamil2": "캐리어",
		"kefu": "온라인 고객지원",
		"img1": "1_han.jpg",
		"img2": "2_han.jpg",
		"img3": "3_han.jpg",
		"d1": "NEWBIT Global, 24시간 거래량<b>{number}</b>USD",
		"d2": "세계 130개 국가를 넘어선 수백만 사용자에게 안전을 제공하고 신뢰할 수 있는 디지털 자산 거래 및 자산 관리 서비스를 제공하다",
		"d3": "거래량 글로벌 톱3",
		"d4": "강력한 유동성 및 거래 종류 완비",
		"d5": "6년간 경험 거래소",
		"d6": "6년간 디지털 자산 금융 서비스 경험",
		"d7": "전문 분산된 구조 및 DDOS 공격 방지 시스템",
		"d8": "천만명 유저 제공",
		"d9": "선행 배상 시스템 마련, 투자자 보호 기금 설립",
		"dsp1": "금융 급 안전",
		"dsp10": "전방위 금융 바람 제어 시스템 과 도 난 방지 시스템, 냉 열 지갑, 다 중 서명 시스템 은 자금 안전 을 보장 합 니 다",
		"dsp2": "신속 충전",
		"dsp20": "충전 현금 인출 이 빠 르 면 3 분 안에 완성 하고 24H 인공 온라인 심 사 를 통 해 고객 이 최고의 투자 기 회 를 놓 치지 않도록 보호 합 니 다",
		"dsp3": "글로벌 서비스",
		"dsp30": "전 세계 업무 서비스 네트워크 가 겹 쳐 서 귀하 가 전 세계 암호 화 자산 에 투자 하고 전 세계 사용자 와 거래 하도록 도 와 드 립 니 다",
		"dsp4": "자산 을 엄선 하 다",
		"dsp40": "질 좋 은 암호 화 프로젝트 를 엄 격 히 선택 하여 귀하 께 80% 높 은 위험 항목 을 걸 러 드 립 니 다",
		"dsp5": "언제 어디서나 안전 거래 를 시작 합 니 다",
		"dsp50": "iOS, Android, Windows 여러 플랫폼 을 커버 하여 업무 전 체 를 지원 합 니 다"
},
"loginTourist": {
	"touristLogin" : "관광객 등록 주의사항",
	"invite": "1. 관광객 계정 모든 기능 사용 가능",
	"invite1": "2. 플레이 계정 충전불가",
	"invite2": "3. 게임 계정 현금인출불가",
	"invite3": "4. 플레이 계정은 충전과 현금 인출을 금지합니다！",
	"invite4": "5. ",
	"autologin": "자동 로그인"
},
	"login": {
	"email": "메일박스 등록",
		"phone": "휴대폰 로그인",
		"welcome": "등록을 환영하다",
		"account": "계좌 번호",
		"forget": "비밀번호 잊기",
		"dont": "아직 등록되지 않았습니까?",
		"invite": "즉시 등록하여 전 세계에서 앞선 디지털 자산 거래 플랫폼에서 거래를 시작하다",
		"autologin": "자동 로그인"
},
	"register": {
	"phone": "휴대폰 번호 등록",
		"email": "메일박스 등록",
		"phonenum": "핸드폰 번호 입력해주세요",
		"emailnum": "메일박스 입력",
		"codenum": "인증 코드 입력",
		"notice": "우선 사용자 프로토콜을 확인하고 선택된 상자를 체크해서 인증 코드를 발송할 수 있습니다",
		"sendcode": "인증 코드 보내기",
		"logpwd": "로그인 비밀번호를 입력하십시오",
		"repwd": "비밀번호 확인",
		"invitecode": "초대장 코드 입력",
		"xuan": "고르다",
		"agree": "나는 이미 읽고 동의했다",
		"pwd": "비밀번호는 6 -16위 사이로 숫자와 자모로 구성되어 있다",
		"mian": "《사용자 협의 》",
		"gologin": "이미 계정이 있습니다. 로그인으로 이동하십시오"
},
	"forget": {
		"fpwd": "비밀번호 잊기",
		"getcode": "인증번호",
		"setpwd": "비밀번호 설정",
		"inpwd": "암호를 입력하십시오",
		"repwd": "비밀번호를 다시 입력하십시오"
},
	"lpwd": {
	"pwd": "암호를 입력하십시오",
		"setpwd": "자금 비밀번호 설정",
		"resetpwd": "비밀번호 초기화",
		"oldpwd": "비밀번호를 입력하십시오",
		"newpwd": "새 암호를 입력하십시오",
		"repwd": "비밀번호를 다시 입력하십시오",
		"spwd": "비밀번호 설정",
		"rpwd": "비밀번호 초기화"
},
	"bdemail": {
	"bindemail": "메일박스를 묶다",
		"emailbox": "메일박스",
		"surebind": "납치 확인"
},
	"bdphone": {
	"bindphone": "핸드폰 번호를 바인딩 하다",
		"phone": "핸드폰 번호"
},
	"lgpwd": {
	"setpwd": "비밀번호 수정",
		"renewpwd": "새 비밀번호를 다시 입력하십시오"
},
	"security": {
	"lever": "계좌 보안 등급 : ",
		"wan": "더 많은 자료, 계좌 안전 보장",
		"invite": "초대장",
		"copy": "초대장 복사",
		"phone": "핸드폰 번호 수정",
		"email": "메일박스를 묶다",
		"logpwd": "로그인 암호",
		"zipwd": "자금 암호",
		"idcard": "신분 인증",
		"suggest": "계좌를 보호하기 위해 비밀번호를 정기적으로 변경할 것을 건의합니다.",
		"notbaind": "납치",
		"havebind": "납치됨",
		"gobind": "납치하러 가다",
		"set": "고치다",
		"haveau": "인증",
		"reau": "거부됨 (다시 인증)",
		"goau": "인증하다",
		"auing": "심사 중",
		"goset": "설정",
		"accountset": "계정 설정",
		"setlogin": "로그인 인증 방식 설정",
		"setting": "설치",
		"setemail": "메일박스 수정",
		"tbpw": "지폐 암호",
		"tip1": "인화폐 전용 비밀번호를 설정하여 비밀번호가 로그인 암호를 구별할 것을 건의합니다",
		"google": "구글 검증기",
		"bindGoogle": "구글 검증기",
		"tip2": "로그인, 제폐, 비밀번호 찾기, 안전 설정을 수정할 때 안전 검증을 합니다",
		"beforename": "먼저 실명 인증 부탁드립니다.",
		"authentication": "인증",
		"myaddress": "나의 주소",
		"low": "낮다",
		"fbPhone": "핸드폰 번호를 묶어주세요.",
		"fbEmail": "일단 메일박스를 묶어 주세요.",
		"googleKey": "구글 키"
},
	"auth": {
	"auth": "아이디 인증",
		"back": "돌아오다",
		"refause": "거부 이유",
		"name": "성명",
		"idnum": "증명서 번호",
		"img": "증명서의 앞면과 소지하고 있는 증명서의 정면사진을 올려 주십시오.",
		"ising": "평가 중...",
		"ised": "인증되었습니다！",
		"truename": "실제 이름을 입력하십시오",
		"truenum": "증명서 번호를 입력 하 십시오",
		"up": "제출",
		"note": "신분증, 여권과 면허증 사진을 올려주세요."
},
	"auth2": {
	"tip": "사용자 인증 정보는 수정이 불가능한 것으로 확인되므로 정확한 정보를 정확하게 기입하고 개인 정보를 꼼꼼히 기입해 주시기 바랍니다",
		"name": "인증 이름",
		"id": "개인 식별 인증서",
		"number": "증명서 번호",
		"type": "증명서 유형",
		"content": "컨텐츠를 입력하십시오",
		"complete": "실명 인증을 완료하신 것을 축하드립니다",
		"nextstep": "다음 단계로 인증",
		"positive": "증명서가 정면으로 찍히다",
		"mb": "jpg만 지원, png, 최대 4mb",
		"click": "단추를 누르 다",
		"back": "증명서 뒷면",
		"hand": "소지품 사진을 업로드하다",
		"tip2": "동일 사진에 \" NEWBIT \", 날짜 및 서명이있는 주석이 포함 된 사진을 제공 하는 사진을 제공 합니다. 얼굴이 선명 하고 모든 문서 상세 정보를 읽을 수있는지 확인 하 십시오",
		"face": "또렷하고 또렷한 얼굴",
		"clearly": "사진 선명도",
		"notes": "[NEWBIT]가 있는 문자 주석",
		"complete2": "축하 드립니다 인증이 완료 되 었 습니다",
		"complete3": "다음 단계 자산 인증",
		"inaudit": "아이디 인증서 제출 성공, 평가 중",
		"notpass": "ID 인증 승인 실패",
		"again": "자격 증명을 다시 실행하십시오",
		"asset": "자산 인증 검토 중",
		"nasset": "자산 인증 실패",
		"again2": "자산 인증을 다시 받으십시오",
		"complete4": "축하합니다， 자산 인증 심사를 통과했습니다",
		"reminder": "온화 하고 온화 하 다",
		"tip3": "당신의 자산 평가치가 20BTC를 초과할 경우, 제 3등급 실명 인증을 받아 더 많은 RK 한도를 획득할 수 있습니다",
		"tip4": " 본인의 NEWBIT 계정에 대한 자산 세부 정보 캡쳐 또는 사진을 입력 하 십시오. 사진에는 계정이름, uid, 토너 카트리지 상세 정보, 현재 시간이 포함 되 어있 습니다. 모든 정보를 읽을 수있 습니다.",
		"screenshot": "자산 캡처 정보 업로드",
		"nowLever": "현재 인증 수준",
		"authCard": "ID 번호",
		"hu": "여권",
		"driverCard": "운전면허증",
		"other": "기타",
		"one": "일급",
		"two": "이수",
		"three": "삼급",
		"selectType": "증명서 유형을 선택하십시오",
		"enterCardId": "증명서 번호를 기입해 주세요",
		"noWord": "증명 번호에는 한자가 포함될 수 없습니다",
		"enterRealName": "실제이름을 입력 하 십시오",
		"noNumber": "이름에는 숫자가 포함될 수 없습니다",
		"noFour": "이미지는 4 M을 초과 할 수 없 습니다.",
		"frontImg": "증명사진을 정면으로 올려주세요",
		"reverseImg": "사진을 찍 어 주 세요",
		"handImg": "신분증을 소지해 주세요",
		"assetImg": "자산 캡처 업로드"
},
	"legal": {
	"buy": "구입",
		"sell": "팔다",
		"shoper": "상가",
		"number": "양",
		"limit": "한도액",
		"price": "단가",
		"pay": "결제 방법",
		"do": "조작",
		"record": "주문 기록",
		"traderecord": "거래 기록",
		"tredetype": "거래 유형",
		"orderstatus": "주문 상태",
		"nofinish": "미완료",
		"finished": "완료 됨",
		"ceiled": "취소 됨",
		"payed": "취소 됨",
		"paywait": "취소 됨",
		"waitplease": "구매자에게 문의 하 십시오",
		"ordercomplete": "주문 완료",
		"orderceiled": "취소 된 주문",
		"orderpayed": "바이어가 지불했으니 확인 후 확인해 주세요",
		"totalmoney": "거래 총액",
		"time": "시간",
		"contact": "연락처",
		"buyer": "바이어",
		"seller": "판매자가 판매 하",
		"ordertime": "주문 시간",
		"reference": "참조 번호",
		"ceilorder": "거래 취소",
		"surepay": "수금 확정",
		"confirmceil": "거래 취소 확인",
		"ceil": "취소",
		"confirm": "확인",
		"paysure": "지불 확인",
		"otherpay": "구매자가 사용자에게 지급 했 음을 확인 하 십시오",
		"payplease": "결제를 부탁드립니다. 아래 계좌로 결제해 주세요",
		"payedwait": "결제, 수금 확정 대기 중",
		"tprice": "거래 단가",
		"tnumber": "거래 수량",
		"cardnum": "은행 카드 번호",
		"wechat": "위",
		"alipay": "보물을 지불하다",
		"account": "통장",
		"phone": "전화",
		"orderceil": "주문 취소",
		"mypayed": "지불 한 것으로 확인 하고 확인을 클릭 하 십시오",
		"notceil": "바이어에게 지불했다면 절대로 거래를 취소하지 마세요",
		"youpayed": "바이어에게 결제가 되었는지 확인해 주세요",
		"freeze": "클릭 하 면 직접 계좌 동결",
		"transaction": "거래",
		"sellall": "모두 팔 다",
		"buyall": "모두 매입",
		"conactceil": "결제는 15분 이내에 판매자에게 연락해주시면 15분 후부터 취소됩니다",
		"autoceil": "자동 취소",
		"placeorder": "주문",
		"inwant": "다음을 입력 하 십시오",
		"total": "총액",
		"ceilnot": "바이어가 당신에게 결제를 했다면 절대 거래를 취소하지 마세요",
		"shopaccount": "상인계좌",
		"bankName": "계좌를 개설하다",
		"payinfo": "지불 정보"
},
	"legaltrade": {
	"paymoney": "지불 통화",
		"paytype": "결제 방법",
		"select": "선택 하 십시오",
		"back": "돌아오다",
		"desc": "소개",
		"connect": "상대방에게 연락 하 다",
		"cankao": "참조 번호",
		"zhihang": "계좌를 개설한 은행 지점",
		"enterzhihang": "신용 카드를 입력 하 십시오",
		"money_code": "수금 코드",
		"confirm_tips": "가능 한 빨리 15 분이 내에 작업을 확인 하 십시오",
		"cancel_tips": "주 : 매일 3 회를 취소 하 면 법 적 거래가 제한 된다",
		"send": "보내다",
		"money_change": "자금 변경",
		"depth": "깊은도량도"
},
	"shop": {
	"myshop": "내가게",
		"name": "이름",
		"tolegal": "소속 법폐",
		"retime": "등록 기간",
		"balance": "상가 잔액",
		"lockbalance": "상가 잔액 동결",
		"goshop": "점포에 들어가다",
		"allorder": "총 단수",
		"dayorder": "30일 주문",
		"comorder": "완료 주문서",
		"comrate": "준수",
		"release": "게시",
		"email": "사서함 인증",
		"phone": "휴대폰",
		"truename": "실명 인증",
		"high": "고급 인증",
		"type": "유형",
		"mysell": "내 판매",
		"mybuy": "내 구매",
		"abnormal": "이상",
		"seeorder": "주문 보 기",
		"choosetype": "유형을 선택 하 십시오",
		"sell": "팔다",
		"buy": "구상",
		"pchoose": "선택 하 십시오",
		"minnum": "최소 거래 수량",
		"maxnum": "최대 거래 수량",
		"ordercom": "주문 완료",
		"orderceil": "취소 된 주문",
		"buyerpayed": "바이어가 지불했으니 확인 후 확인해 주세요",
		"sellerwait": "판매자가 확인할 때까지 기다려주세요",
		"receivepay": "수금 확정",
		"onsell": "온라인 판매",
		"onbuy": "온라인 구매",
		"fatotal": "법폐총액",
		"sceil": "초 후 자동으로 취소",
		"nomin": "최소 한도 미만이 어야 합니다",
		"nomax": "최대 한도를 초과할 수 없습니다",
		"nomaxnum": "최대 수량을 초과 할 수 없 습니다",
		"cancle": "철회하다"
},
	"lgset": {
	"lgset": "법인 거래 설정"
},
	"market": {
	"quotation": "시장 가격",
		"market": "시장",
		"currency": "통화",
		"lastprice": "최신 가격",
		"change": "최신 가격",
		"zoushi": "경향"
},
	"center": {
	"xian": "가격제한거래",
		"shi": "시가상거래",
		"available": "사용 가능",
		"buyprice": "매가",
		"sellprice": "매각가격",
		"buynumber": "매입량",
		"sellnumber": "매출",
		"tradetotal": "거래액",
		"buyin": "사다",
		"sellout": "팔다",
		"inbuy": "매입",
		"outsell": "매각",
		"all": "전부",
		"cdel": "현재 위임",
		"hdeal": "역사적 위임",
		"pairs": "거래 쌍",
		"direction": "방향",
		"dealtotal": "총 위임",
		"tprice": "거래가격",
		"nothing": "무",
		"contran": "거래 성사",
		"newprice": "최신 가격",
		"alltrade": "전체 사이트 거래",
		"volume": "거래량",
		"realtime": "일시적",
		"or": "또는",
		"start": "거래 시작",
		"hour": "24시간 자금 변경",
		"inflow": "주력 유입",
		"outflow": "주력 부대",
		"inmoney": "24유입 자금",
		"outmoney": "24 자금 유출"
},
	"lever": {
	"selHand": "금액",
		"phands": "금액",
		"transaction": "계약",
		"nowentrust": "대기중인 주문",
		"hisentrust": "역사 순서",
		"lvchi": "위치",
		"all": "모두",
		"or": "또는",
		"gotrade": "거래 시작",
		"tdrecord": "거래 내역",
		"dealed": "거래",
		"notdeal": "취급되지 않음",
		"loading": "불러오는 중 ...",
		"entotal": "주문량",
		"canuse": "유효한",
		"std": "시장",
		"xtd": "한도",
		"pprice": "가격을 입력하십시오",
		"phand": "구매 금액",
		"pwd": "암호",
		"ppwd": "거래 비밀번호를 입력하십시오",
		"psw": "거래 비밀번호",
		"equal": "같은",
		"times": "X",
		"timed": "활용",
		"handbuy": "최소 구매 ",
		"handsell": "최소 판매 ",
		"hands": "금액",
		"ptimes": "레버리지를 선택하십시오",
		"contractVal": "총계",
		"bail": "보험 기금",
		"canBail": "사용 가능한 자금",
		"charge": "서비스 수수료",
		"domore": "구매 / 장기",
		"doshort": "매도 / 매도",
		"dmore": "긴",
		"dshort": "짧게",
		"sureOd": "주문 장소 확인",
		"noless": "이상",
		"nomore": "더 이상",
		"tdnum": "거래 금액",
		"risk": "위험률",
		"allloss": "모든 P / L",
		"onehouse": "닫기",
		"type": "타입",
		"entrustPrice": "위탁 가격",
		"openPrice": "시가",
		"nowPrice": "현재 가격",
		"styPrice": "스티 가격",
		"stsPrice": "Sts 가격",
		"openTime": "영업 시간",
		"weituoTime": "위탁 시간",
		"closeTime": "마감 시간",
		"closePrice": "창고 평 가",
		"revokeTime": "취소 시간",
		"rate": "수수료",
		"nightFee": "야간 요금",
		"loss": "P/L",
		"setloss": "중지 / 제한",
		"expectProfit": "이익을 기대하십시오",
		"expectLoss": "손실 예상",
		"allClose": "모두 닫기",
		"moreClose": "더 가까이",
		"nullClose": "널 가까이",
		"sureClose": "닫으시겠습니까？",
		"thanZearo": "숫자는 0보다 커야합니다",
		"listin": "리스팅",
		"tdin": "거래",
		"closein": "결산",
		"closed": "휴무",
		"revoked": "해지",
		"revokeOrder": "주문을 취소 하시겠습니까?",
		"ping": "닫기",
		"revoke": "주문 취소",
		"sureping": "닫으시겠습니까?",
		"thanzone": "숫자는 0보다 커야합니다",
		"zheng": "정수를 입력하십시오",
		"buyMin": "최소 구매",
		"buyMax": "최대 구매",
		"enterBuyPrice": "구매 가격을 입력하십시오",
		"sellMin": "최소 판매",
		"sellMax": "최대 판매",
		"enterSellPrice": "판매 가격을 입력하십시오",
		"bestprice": "시장 최 우수 가격"
},
	"account": {
	"mult100": "수량은 100의 정수여야 합니다",
		"exchange": "변환",
		"exchangeNum": "환전 금액",
		"hideSm": "소액 자산 숨기기",
		"assets": "자산",
		"taccount": "거래 계좌",
		"laccount": "프랜차이즈 계좌",
		"letransaction": "초 계약 계좌",
		"totalassets": "총자산 할인",
		"upaddress": "공제 주소 관리",
		"freezes": "고정",
		"conversion": "접다",
		"charging": "충전화폐",
		"charrecord": "통화 기록",
		"fstaus": "추적 상태",
		"view": "보기",
		"withdraw": "티를 내 다",
		"record": "기록",
		"copy": "복제",
		"code": "QR코드",
		"amount": "충전 수량",
		"picture": "계좌 이체 캡 처",
		"choose": "선택 하 다",
		"select": "파일 을 선택 하 세 요",
		"selected": "선택 됨",
		"submitRecharge": "충전 정보 제출",
		"cgaddress": "화폐주소를 충전하다",
		"whaddress": "추첨주소",
		"tibi": "정확한 BTC-OMNI 지폐 주소를 기입해 주세요.",
		"notice": "온화 하고 온화 하 다",
		"chaddress": "비트코인 주소 선택",
		"range": "범위",
		"havenum": "대금정리",
		"minnum": "최소 공제 수량은 다음과 같습니다",
		"oncemaxnum": "단일 주문의 최대 동전 수는",
		"daynum": "당일 최대 코인 한도",
		"uppwd": "트랜잭션 암호",
		"call": "자금의 안전을 보장하기 위해, 계좌 보안 정책이 변경되고 비밀번호가 수정되면 수작업으로 인출하도록 하겠습니다. 근무자 전화나 우편으로 연락이 올 때까지 기다려 주십시오.",
		"a1": "위 주소에 값을 입력 하 지 마십시오",
		"a2": "자산, 그렇지 않으면 자산을 되찾을 수 없다.",
		"a3": "충전은 simple send의 방법만 지원하며, 다른 방법(send all)을 사용하는 충전은 일시적으로 입금이 되지 않습니다. 양해해 주시기 바랍니다.",
		"a4": "위 주소로 충전한 후 전체 네트워크 노드 확인이 필요하며, 1회 확인 후 입금, 6회 확인 후 재화폐가 가능합니다.",
		"a5": "최소 충당 금액:",
		"a6": "최소 금액 미만의 충당금은 회계되지 않을 것이며 돌려받을 수도 없다.",
		"a7": "충전 주소는 자주 변경되지 않으며 반복해서 충전할 수 있습니다. 변경 사항이 있을 경우 웹 사이트 게시나 이메일을 통해 알려 드리겠습니다.",
		"a8": "정보가 변조되거나 유출되는 것을 방지하기 위해 컴퓨터와 브라우저의 안전성을 확인하는 것이 중요합니다.",
		"a9": "위의 주소로 재충전 한 후에는 전체 네트워크 노드의 확인이 필요합니다. 네트워크가 확인되면 계정에 도착합니다 .2 개의 네트워크 확인 후 통화를 인출 할 수 있습니다.",
		"a10": "충전 정 보 를 제출 한 후, 인내심 을 가지 고 작업 자의 심 사 를 기 다 려 주 십시오",
		"legal": "C2C",
		"trade": "거래",
		"lever": "계약",
		"choosecoin": "통화 선택",
		"huanum": "분할 수량",
		"mrecord": "재무 기록",
		"allRec": "총 재무 기록",
		"transfer": "젓다"
},
	"set": {
	"secset": "보안 설정",
		"msgcer": "정보 인증",
		"turemsg": "거래가 원활하게 진행될 수 있도록 정확한 정보를 기입해 주시기 바랍니다",
		"tname": "실제이름",
		"kname": "계정 개설 행 선택",
		"cnum": "은행 카드 번호",
		"ailipay": "알리 페이 계정",
		"wename": "닉네임",
		"wenum": "위계정",
		"bank_address": "계좌 개설 주소",
		"ailipay_nickname": "알리 페이 별명",
		"attestation": "인증 상세 정보",
		"setpay": "수금 유형 설정",
		"remarks": "주석",
		"palipay": "지불 바우처를 입력하십시오",
		"premarks": "주석 정보를 입력 하 십시오",
		"addalipaycode": "알리 페이 수금 코드 추가",
		"pwenum": "위 챗을 입력 하 십시오",
		"nameplease": "이름을 입력 하 십시오",
		"addwenumcode": "마이크로메일 수금 코드 추가",
		"add": "추가 하 기"
},
	"ad": {
	"adrecord": "메모 레코드",
		"release": "게시",
		"reply": "응답",
		"none": "잠정",
		"releaseit": "메시지를 게시하다",
		"con": "메시지 내용을 기입해 주세요",
		"up": "제출"
},
	"lay": {
	"paccount": "계정 번호를 입력하십시오!",
		"notcode": "인증 코드는 비워둘 수 없습니다!",
		"pwdliu": "비밀번호는 6자리보다 작을 수 없습니다!",
		"slogin": "로그인 성공",
		"noemail": "귀하의 사서함이 규칙을 준수하지 않습니다",
		"phonenot": "당신이 입력한 핸드폰 계정은 규칙에 맞지 않습니다!",
		"nophone": "휴대폰 번호를 입력하십시오",
		"nemail": "휴대폰 번호를 입력하십시오",
		"inpwd": "암호를 입력 하 십시오",
		"pwdlength": "비밀번호는 6~16비트 사이여야 합니다",
		"pwdcom": "암호는 숫자와 문자로 구성되어야 합니다",
		"repwd": "암호를 다시 입력 하 십시오",
		"twopwd": "두 개의 암호가 일치하지 않습니다",
		"inprice": "구입 가격을 입력하십시오",
		"noinprice": "매입가격은 0이 될 수 없 다",
		"innumber": "매입 수량을 입력 하 십시오",
		"outprice": "입찰 가격을 입력하십시오",
		"outinprice": "판매가격은 0이 될 수 없습니다",
		"outnumber": "매출을 입력하십시오",
		"plogin": "로그인하십시오",
		"revoke": "철수를 확인 하 다?",
		"sure": "확정",
		"ceil": "취소",
		"notopen": "아직 공개되지 않음",
		"caddress": "인센티브 주소를 선택해 주세요",
		"cnumber": "토큰을 입력하십시오",
		"cpwd": "지폐 패스워드를 입력해주세요",
		"minnum": "최소 개수를 입력하십시오",
		"copys": "복사 성공",
		"fcopy": "복제 실패",
		"recopy": "다시 복사하십시오",
		"huanum": "대차 회전 수를 입력하십시오",
		"truename": "진짜 성명은 반드시 기입하여야 한다",
		"pliu": "메시지 내용을 기입해 주세요",
		"nameplease": "이름을 입력하십시오",
		"idplease": "증명서 번호를 입력 하 십시오!",
		"comid": "증명서를 업로드해 주세요!",
		"intermediate": "중",
		"high": "높다",
		"strong": "강력 한 성능",
		"buymoney": "총 구매 금액을 입력 하 십시오",
		"buynum": "구입 할 수량을 입력 하 십시오",
		"sellmoney": "총 금액을 입력 하 십시오",
		"sellnum": "판매할 수량을 입력하십시오",
		"tname": "실명인증을 먼저 하시고 주문해 주세요",
		"payset": "먼저 수금 방법을 설정하십시오",
		"mpay": "결제 방법 선택",
		"mprice": "가격을 입력 하 십시오",
		"mnum": "수량을 입력 하 십시오",
		"lessnum": "최소 수량보다 작을 수 없습니다",
		"morenum": "수량을 초과할 수 없습니다",
		"notnum": "수량은 총 수량보다 클 수 없 습니다",
		"confirm": "Please confirm your operation!",
		"canshucuowu":"매개 변수 오류",
		"chongzhiyitijiao":"충전 신청서가 제출되었습니다.",
		"chongzhisqb":"재충전 신청서를 제출하지 못했습니다.",
		"nodi100":"입력 한 전송 횟수는 100 개 미만일 수 없습니다.",
		"pinputjine":"금액을 입력하세요",
		"selcztype":"충전 방법을 선택하세요",
		"updateok":"업데이트 완료",
		"ishuazhuan":"이전을 확인하고 있습니까?",
		"pinputokphone":"유효한 전화 번호를 입력하세요",
		"usernofind":"사용자를 찾을 수 없습니다."
},
	"forLegalPsw": {
	"fpwd": "자금 비밀번호 분실",
		"setpwd": "자금 암호 설정"
},
	"cfc": {
	"balance": "누계",
		"account": "이체",
		"num": "이전 수량",
		"pla_account": "전출 계정을 입력 하 십시오",
		"pla_num": "출력 수량을 입력하십시오"
},
	"tpc": {
	"hot": "인기 공증",
		"all": "일체통증",
		"nodata": "데이터 없음",
		"search": "수색",
		"tips": "*현재 페이지에 표시된 내용은 프로젝트 측에서 직접 작성하며, NEWBIT 는 이에 대한 신뢰성에 대한 책임을 지지 않습니다",
		"time": "발행 날짜",
		"total": "총 발행",
		"circulation": "토큰 유통량",
		"price": "발행가격",
		"web": "홈페이지",
		"book": "백서",
		"tool": "소셜 네트워킹",
		"recommend": "추천 기관",
		"introduce": "공증소개",
		"notice": "공고",
		"rr": "통증연구",
		"news": "뉴스 콘텐츠",
		"author": "작성자 :",
		"browse": "조 회수："
},
	"finance": {
	"selectState": "잠금 상태 선택",
		"front": "변경 전",
		"after": "변경 후",
		"info": "정보"
},
	"uc": {
	"code": "초대 코드",
		"mymoney": "내 자금",
		"entrust": "위임된 관리",
		"deal": "거래 관리",
		"pay": "거래 관리",
		"id": "아이디 인증",
		"safe": "보안 설정",
		"invitate": "손 님을 초대 하 다",
		"finance": "재무 기록",
		"loginlog": "로그 온로 그"
},
	"usercenter": {
	"change": "화폐계좌",
		"legal": "OTC계좌",
		"lever": "계약계좌"
},
	"account2": {
	"list": "공제 목록",
		"address": "주소 탭",
		"code": "인증 코드",
		"addtime": "시간 추가",
		"suretime": "확인 시간",
		"explain": "설명"
},
	"withdrawList": {
	"sel": "통화 필터링",
		"submitTime": "커밋 시간",
		"checkTime": "평가 시간",
		"tibiNum": "공제액",
		"applying": "신청 중",
		"hash": "해시치",
		"agreeWait": "동의함, 체인 대기 중",
		"lianSucc": "체인에서 성공",
		"lianFail": "체인에 장애가 발생 했",
		"bohui": "거부 됨",
		"zhanSucc": "역내에서 성공하다"
},
	"wAddress": {
	"enterAddress": "토큰 주소를 입력하십시오",
		"enterMemo": "주소 레이블을 입력하십시오",
		"enterMark": "메모를 입력하십시오",
		"tips": "포근 한 팁 : USDT는 btc 통화로 주소를 추가 하고 ERC 20 대신 ETH 주소를 사용 하여 추가 합니다",
		"addressList": "주소 목록",
		"memo": "주소 목록",
		"del": "삭제",
		"nospace": "토큰 주소는 공백을 포함할 수 없습니다",
		"nospace02": "주소 레이블에는 공백을 사용할 수 없습니다",
		"nospace03": "주소 레이블은 숫자 또는 문자와 숫자 조합이어야 합니다",
		"allNum": "주소 레이블은 순수 숫자여야 합니다",
		"bankNum": "카드 계좌 번호",
		"enterCardNum": "카드 번호를 입력 하 십시오",
		"addCard": "은행 수금 코드 추가",
		"addcode": "결제번호 사진을 업로드해 주세요",
		"moreAdd": "최대 업로드",
		"pics": "장 그림"
},
	"authDetail": {
	"payType": "수금 방법",
		"noreceiveType": "수금 방법을 추가하지 않았습니다. 추가하십시오!"
},
	"identity": {
	"anquan": "계정 안전",
		"verify": "로그인 인증",
		"openVerify": "인증 켜 기",
		"closeVerify": "인증 해제",
		"enterGooole": "구글 키를 입력 하 십시오",
		"verityType": "인증 방법 선택"
},
	"caiwu": {
	"lock": "잠금",
		"nolock": "잠금 해제됨",
		"status": "잠금 상태를 선택하십시오",
		"selCu": "통화를 선택하십시오"
},
	"lang": {
		"en": "English",
		"kr": "한글",
		"jp": "日本語",
		"zh": "简体中文",
		"hk": "繁體中文",
		"spa": "España",
		"ara":"Arabia"
},
	"google": {
	"code": "구글은 검증 코드를 검증 하고",
		"entergoogle": "구글 인증 코드를 입력하십시오"
},
	"transfer": {
	"select": "스트로크 방향을 선택해주세요",
		"change": "코인 계좌에서 프랜차이즈 계좌로",
		"legal": "프랜차이즈 계좌에서 코인 계좌로",
		"change_balance": "통화 비용 계정 사용가능",
		"pswType": "비밀번호는 6자리 순수 숫자입니다",
		"newPsw": "새 암호는 6자리 숫자여야 합니다",
		"changePhone": "휴대폰 번호 변경",
		"changeEmail": "사서함 변경",
		"emailCode": "사서함 검증 번호를 입력 하 십시오",
		"newPhone": "새 휴대 전화 번호를 입력 하 십시오",
		"newEmail": "새 사서함 번호를 입력하십시오",
		"phoneCode": "전화 번호를 입력 하 십시오",
		"selCu": "통화를 선택하십시오",
		"ok": "자금 이체 에 성공 하 다",
		"error": "자금 이체 실패"
},
	"register01": {
	"country": "소속 국가",
		"selectCountry": "국적 정보 등록 후 수정 할 수 없 으며, 반드시 선택 해야 합니다",
		"noAccount": "아직 계정이 없 다"
},
	"invite": {
	"welcome": "함께 고액 의 수익 을 얻다",
		"effective": "유효",
		"invalid": "유효하지 않음",
		"list": "리스트",
		"commission": "커미션을 받다",
		"link": "내 전담 마케팅 링크",
		"share": "링크 공유 복사",
		"mypromotion": "내 마케팅 기록",
		"account": "초청계좌",
		"nomore": "추가 데이터 없음",
		"rules": "활동 규칙",
		"tip1": "초대된 사람은 반드시 2차 인증을 받아야 한다;",
		"tip2_0": "친구의 요청을 수락하면 진짜 거래 수수료가 발생할 때마다",
		"tip2_1": "에 대한 리베이트",
		"tip3": "결제 방식은요청 제도에 따라 결제",
		"tip4_0": "초대인이 실제로 받은 커미션은",
		"tip4_1": "할인 계산 후 초청인에게 반환되는 거래 계좌리베이트액",
		"tip4_2": " = 실제 거래량 * 수수료 * 리턴 비율.",
		"tip5_0": "플랫폼은 매일 베이징 시간으로 08:00에 상응하는 통화 거래의 종가로 진행됩니다",
		"tip5_1": "환산"
},
	"crowd": {
	"mujiNum": "모집 수량",
		"cur": "통화 지원",
		"endTime": "종료 시간",
		"intro": "프로젝트 소개 보 기",
		"can_edu": "이 장에서 주문할 수 있는 한도",
		"now_edu": "현재 요청 금액",
		"my_edu": "내 요청서 금액",
		"his": "역사",
		"price": "요청 가격",
		"money": "요청서",
		"yue": "계정 잔액",
		"enterMoney": "요청서 금액을 입력하십시오",
		"total": "총 요청서",
		"rule": "요청서 세부 사항",
		"startBuyTime": "사재기를 시작 하 다",
		"endBuyTime": "구매 종료 시간"
},
	"dep": {
	"dep_rec": "예금기록",
		"dep": "화폐보화",
		"tip": "저축한 화폐로 이윤을 얻고, 한푼으로는 생재를 한다.돈이 좋지 않다 해도 보물을 낳을 수는 없다!",
		"plan": "하늘이 무너지 다",
		"timer": "사재기를 하 다",
		"rate": "연간이익 률",
		"lock": "잠김",
		"join": "사람 참여",
		"end": "사재기가 이미 끝났다",
		"buy": "곧 올 것이 다",
		"date": "돈을 모으 다",
		"day": "하늘",
		"total_money": "사재기 총액",
		"sup_num": "잔여 한도",
		"top": "예금고령",
		"one_num": "1회 주문 수량",
		"num": "예금량",
		"min": "최소",
		"max": "최대",
		"yuji": "예상 수익",
		"qiang": "사재기",
		"depEndTime": "예금 마감 시간",
		"jixiTime": "이자 시간",
		"lockTime": "잠금 해제 시간",
		"enterNum": "사재 수량을 입력하십시오",
		"his": "예금통화역사",
		"money": "돈을 모으 다",
		"cur_name": "통화명",
		"do": "조작",
		"ti": "추출",
		"suoTime": "사일로 잠금 시간",
		"leiji": "누적 이익",
		"rateNum": "수수료 수량:",
		"ti_num": "추출가능 한 수량 :",
		"tiYet": "추출 여부",
		"to": "계좌로 입금 하 시 겠 습니까?",
		"yes": "예",
		"no": "아니오"
},
	"vote": {
	"di": "제",
		"period": "기일투표",
		"explain": "규칙 설명",
		"free": "현재 무료로 표를 얻을 수있 습니다",
		"have": "보유",
		"num": "양",
		"people": "지원 인원 수",
		"tickets": "표 적 표",
		"detail": "상세 정보 보기",
		"jiangli": "투표 장려",
		"fen": "나누다",
		"sup_people": "나누다",
		"piao": "표",
		"vote": "투표",
		"enter": "투표 표를 입력 하 십시오",
		"vote_cur": "표상화폐",
		"paiming": "서열",
		"intro": "프로젝트 소개",
		"taken": "Token 개요 및 할당",
		"total": "세대를 대신 하 다.",
		"cur_total": "현재 총 유통량",
		"price": "릴리즈가격",
		"link": "관련 링크",
		"web": "공식 사이트",
		"zh": "중국어 ( 간 체 ) 백서",
		"en": "영문 백서"
},
	"td1": {
	"do": "조작",
		"more": "추가 로드",
		"loading": "로드 중",
		"nomore": "더는 없어",
		"nodata": "데이터 없음",
		"canceil": "취소",
		"confirm": "확인",
		"status": "상태"
},
	"accountset": {
	"name": "성함을 기입해 주세요"
},
	"sgr": {
	"sgrinfo": "SGR 수요 소개",
		"lock": "현재 개인 SGR 잠금 금액: ",
		"signup": "가입하세요",
		"sgrget": "현재 등록 된 KYC는 즉시",
		"cir": "총 순환 수량",
		"loc": "총 잠금 수량",
		"richman": "오늘의 부자",
		"user": "사용자",
		"amount": "승리",
		"time": "시간",
		"todayrelease": "오늘 개인 SGR 릴리스 량",
		"releaserecords": "출시 기록",
		"invite": "SGR 초대",
		"link": "내 초대 링크",
		"copy": "복사",
		"totallock": "획득 한 현재 총 SGR 잠금 수",
		"onelevel": "현재 한 수준의 초대장 수",
		"twolevel": "현재 2 단계 초대장 수",
		"therelevel": "현재 거기 레벨 초대의 수",
		"get": "입수 ",
		"rebate": " 리베이트"
},
	"miscro": {
	"quotation": "시세.",
		"transaction": "초 계약 거래",
		"symbol": "거래 팀",
		"balance": "잔금",
		"miscroAccount": "초 계약 계좌",
		"trade": "거래 중",
		"buyPrice": "구입 가격",
		"finshPrice": "거래 가",
		"loss": "예상 손익",
		"times": "카운트다운",
		"mode": "거래 패턴",
		"num": "창 고 를 여 는 수량.",
		"rate": "수익 률",
		"up": "붇다",
		"down": "넘어지다",
		"openNum": "창 고 수량 을 입력 하 세 요",
		"success": "주문 성공",
		"c2c": "c2c 계 정",
		"complaint": "신고 건의",
		"reply": "질문 을 적어 주시 면 저희 가 빠 른 시일 내 에 답 해 드 리 겠 습 니 다.",
		"complaintList": "신고 건의 리스트",
		"complaintReply": "회답 하 다.：",
		"complaintDescription": "설명 내용 을 입력 하 세 요",
		"resetpwd": "비밀번호 변경",
		"foundedOn": "에 창설",
		"commonProblem": "흔 한 문제",
		"statement": "설명 하 다.",
		"about": "KiBiEx 에 대해 서.",
		"everyone": "사람마다 CEO 다.",
		"program": "KiBiEx 글로벌 파트너 프로젝트",
		"myMine": "내 광산：",
		"friend": "내 친구：",
		"myRank": "누계 커미션：",
		"accumulated": "누계 커미션：",
		"copyLinks": "홍보 링크 복사",
		"moneyVein": "홍보 링크 복사",
		"example": "커미션 예시",
		"enterQuantity": "수량 을 입력 하 세 요",
		"contractBalance": "계약 계좌 잔액",
		"submitRepeatedly": "제출 중 이 니 반복하여 제출 하지 마 십시오",
		"alipayCode": "Alipay 결제 코드",
		"wechatCode": "wechat 코드",
		"realPrice": "실시간 가격",
		"currencyExchange": "통화 사용",
		"currencyExchangeIn": "환전 통화",
		"cashableBalance": "현금 잔액：",
		"minimumCashable": "최소 상환 가능：",
		"maximumCashable": "최소 상환 가능：",
		"automaticallys": "교환을 클릭하면 자동으로 완료되며 반환 할 수 없습니다",
		"match": "교환",
		"title": "자산 교환",
		"holdAssets": "보유 자산",
		"dangerousCurrency": "보험",
		"convertibleQuantity": "교환 가능 수량",
		"currencyExchanges": "환율：",
		"insuranceCurrency": "보험 통화",
		"insuranceType": "보험 유형",
		"contractAsset": "계약 자산 보험",
		"warehouses": "금고 수",
		"availableAssets": "사용 가능한 자산：",
		"insuredAssets": "보험 자산：",
		"insuredAssets1": "보험 자산",
		"insuranceAssets": "보험 자산：",
		"purchaseInsurance": "보험 구매",
		"insuranceClaims": "보험 청구",
		"insuranceCancellation": "보험 종료",
		"coinWallet": "보험 코인 지갑",
		"bmbWallet": "AITB 지갑",
		"cumulativeCoins": "축적 된 코인：",
		"availableQuantity": "이용 가능한 수량：",
		"rawCurrency": "통화 기록",
		"contractInsurance": "계약 보험",
		"tenThousand": "만",
		"runningLow": "잔액 불충분",
		"purchase": "입력 한 수량이 규정을 충족하지 않으며 구매 한도는",
		"reach": "에",
		"between": "중에서",
		"onlyEnter": "당신은 입력 할 수 있습니다",
		"integersBetween": "사이의 정수",
		"notReturned": "보험을 구매하려면 클릭하십시오. 보험 조약을 자세히 이해하고 보험 창고가 자동으로 효력을 발생하는 데 동의 한 것으로 간주되며 반환 할 수 없습니다.",
		"settled": "보험에 가입 한 자산이 주문을 할 수 없을 정도로 잃을 때 보험 청구",
		"profitable": "자산이 수익성이있는시기",
		"terminated": "보험이 자동 해지되는 경우。",
		"automatically": "보험 자산이 50 % 손실되면 보험금 청구를 신청해야합니다. 그렇지 않으면 거래 할 수 없습니다. 이익이 100 % 일 경우 보험은 자동 해지됩니다. 보험 창고는 하루에 두 번 이상 지불 할 수 있습니다. , t + 1",
		"termination": "보험 계약이 종료 된 후 보험에 가입 된 자산은 교환 만 가능하며, 계약이 종료되면 계약 위반으로 간주되며 보험 창고는 자동으로 청산됩니다.",
		"just": "양",
		"back": "안티",
		"lowestNumber": "최소 수량은",
		"confirmExchange": "사용 확인?",
		"contractValuation": "계약 계정 자산 평가",
		"secondValuation": "두 번째 계약 계정 자산 평가",
		"falshValuation": "통화 계정 자산 평가",
		"c2cValuation": "피아트 계정 자산 평가",
		"recordWithdrawal": "동전 충전 기록",
		"category": "범주",
		"safetyCenter": "보안 센터",
		"safeText1": "등록, 비밀번호 변경, 보안 설정시 확인 SMS를 받으려면",
		"safeText2": "인터넷 계정이 도용 될 위험이 있습니다. 계정 보안을 위해 정기적으로 비밀번호를 변경하는 것이 좋습니다.",
		"flashTrading": "환전소",
		"assetCenter": "자산 센터",
		"promotionCode": "내 프로모션 코드",
		"loginAgain": "로그인이 오래되었습니다. 다시 로그인하십시오.",
		"text10": "AITB 기반 실시간 가격",
		"text11": "지불 바우처"
},
	"td": {
	"buy": "구입 하 다.",
		"sell": "판매 하 다.",
		"currency": "화폐 종류.",
		"num": "수량.",
		"time": "시간.",
		"limit": "한도.",
		"price": "단가",
		"method": "지불 방식",
		"total": "총액",
		"inwant": "입력 욕",
		"do": "조작 하 다.",
		"more": "더 많이 불 러 오기",
		"nomore": "더 없어.",
		"nodata": "데이터 없 음",
		"trade": "거래 하 다.",
		"buynum": "구 매 수량",
		"sellout": "팔다",
		"buyin": "사다",
		"all": "전부.",
		"allbuy": "전부 매입 하 다",
		"allsell": "몽땅 팔아먹다",
		"buytotal": "구 매 하고 자 하 는 총액 을 입력 하 세 요",
		"selltotal": "판매 하고 자 하 는 총액 을 입력 하 세 요",
		"buyallnum": "구 매 하고 자 하 는 수량 을 입력 하 세 요",
		"sellallnum": "판매 하고 자 하 는 수량 을 입력 하 세 요",
		"tradeTotal": "거래 총액",
		"doceil": "초 자동 취소",
		"place": "주문 하 다.",
		"waitpay": "지불 을 기다리다.",
		"finished": "완료 됨",
		"ceiled": "취소 됨",
		"payed": "이미 지불 하 다",
		"nofinish": "미 완성",
		"buyer": "바이어",
		"seller": "파 는 사람",
		"callWay": "연락처",
		"placeTime": "주문 시간",
		"renum": "참조 번호",
		"canceil": "취소 하 다.",
		"confirm": "확인 하 다.",
		"pwd": "거래 비밀 번 호 를 입력 하 세 요",
		"coincode": "화폐 단위",
		"pcoin": "화폐 단 위 를 선택 하 세 요",
		"examine": "상가 에서 심사 중 이 니, 인내심 을 가지 고 기 다 려 주 십시오."
},
	"cuy": {
	"direction": "방향.",
		"total": "총계 하 다",
		"price": "가격.",
		"sell": "팔다.",
		"buy": "사다.",
		"allStation": "전 역 거래",
		"buyPrice": "매입 가격",
		"buynum": "매입 량",
		"sellPrice": "매출 가",
		"sellnum": "매출 량",
		"tdPrice": "거래액",
		"or": "혹시",
		"tdStart": "거래 를 시작 하 다",
		"pbPrice": "매입 가 입력",
		"pbNum": "매입 량 입력",
		"psPrice": "판매 가 를 입력 하 세 요",
		"psNum": "판매 가 를 입력 하 세 요",
		"fixPrice": "제한 거래",
		"Mtrans": "시가 거래",
		"available": "사용 가능 하 다.",
		"revoke": "철회 하 다.",
		"loading": "로드 중",
		"confirmCancel": "취소 하 시 겠 습 니까?",
		"confirm": "확정 하 다.",
		"cancel": "취소 하 다.",
		"nomore": "더 이상 데이터 가 없습니다.",
		"evelPrice": "평균 거래 가 성립 되다."
},
	"iepn": {
	"htitle": "많은 수의 코인이 판매 중이며 친구를 초대하여 함께 코인을 얻습니다",
		"subtitle": "플랫폼은 많은 수의 코인을 팔고, 즉시 와서 구매하세요. 수량이 제한되어 있습니다 ~",
		"netname": "스마트 전염병 예방 네트워크",
		"plannum": "계획된 유통",
		"totalnum": "계획된 유통",
		"pushednum": "발행 수량",
		"remindnum": "잉여 수량",
		"hotcommond": "인기 추천",
		"ctitle": "글로벌 정부 의료 관리 기관",
		"cdsp": "의료는 국민의 생계를 의미하며 정부의 역할을 피하는 것은 불가능합니다. 지능형 전염병 예방 네트워크의 투명한 체인 특성은 글로벌 의료 관리 기관이 의료 기관을 더 잘 관리하고, 국가 건강을 모니터링하며, 빠르고 유연한 의료 자원 할당을 수행하는 데 도움이 될 수 있습니다",
		"starttime": "시작 시간",
		"startprice": "시작 가격",
		"endtime": "종료 시간",
		"seemore": "더보기",
		"baipishu": "기술 백서",
		"gobuy": "구 매 하 러 가다"
},
"c2c": {
	"myTc2c": "제가 거래하는",
		"pc2c": "발표",
		"mypc2c": "제가 올린 것은",
		"timer": "시간",
		"type": "유형",
		"price": "가격",
		"num": "수량",
		"totalmoney": "총금액",
		"minNums": "최소 수량",
		"payType": "지불 방식",
		"cancelp": "취소",
		"buy": "구매",
		"sell": "판매",
		"currency": "화폐",
		"onePrice": "단가",
		"minTime": "최소 시간",
		"pTime": "배포 시간",
		"opera": "조작",
		"money": "금액",
		"enter": "입력",
		"numIs": "수량이",
		"rangeL": "사이",
		"moneyIs": "금액",
		"cancel": "취소",
		"maxNum": "수량이 초과해서는 안 된다.",
		"minNum": "수량이 적어서는 안 된다.",
		"maxMoney": "금액은 초과할 수 없다.",
		"minMoney": "금액이 적어서는 안 된다.",
		"notMore": "잠시 더 이상은 없다",
		"state": "상태",
		"cancelOrder": "주문 취소",
		"conPay": "지불을 확인하다",
		"conReceive": "수금을 확인하다",
		"payed": "지불을 완료하다",
		"completed": "완료",
		"canceled": "취소됨",
		"detail": "자세 한 정보",
		"payAccount": "지급 계정",
		"selCu": "통화를 선택하십시오.",
		"ailipay": "Alipay",
		"wx": "WeChat",
		"bankcard": "은행 카드",
		"pBuy": "구입을 게시하다",
		"pSell": "발포 판매",
		"enterPrice": "수입 가격",
		"enterNum": "입력 갯수",
		"enterMinnum": "최소 개수 입력",
		"tips": "최소 수량은 총 수량보다 커서는 안 된다",
		"name": "이름",
		"wxName": "닉네임",
		"bankName": "은행 명칭",
		"connect": "연락처"
},
"apitip":{
	"参数错误":"매개 변수 오류",
	"数据未找到":"데이터를 찾을 수 없습니다",
	"请选择需求类型":"요구 사항 유형을 선택하십시오",
	"请选择交易方式":"거래 방법을 선택하세요",
	"请填写单价":"단가를 입력하세요.",
	"请填写数量":"수량을 입력 해주세요",
	"请选择币种":"통화를 선택하세요",
	"请输入正确的交易数量或价格":"정확한 거래 수량 또는 가격을 입력하세요",
	"您还没有设置收款信息":"결제 정보를 설정하지 않았습니다",
	"取消次数已超出":"취소 횟수 초과",
	"次,明天再发布":"시간, 내일 출시",
	"用户钱包不存在":"사용자 지갑이 존재하지 않습니다",
	"对不起，您的钱包余额不足":"죄송합니다. 지갑 잔액이 부족합니다",
	"用户发布c2c交易法币出售，扣除法币余额":"사용자는 법정 통화를 판매하기 위해 c2c 거래를 발행하고 법정 통화의 잔액을 공제합니다",
	"用户发布c2c交易法币出售,锁定余额增加":"사용자가 법정 통화를 판매하기 위해 c2c 거래를 해제하고 잠긴 잔액이 증가했습니다",
	"发布成功":"성공한",
	"无此用户":"해당 사용자 없음",
	"用户不存在":"사용자가 존재하지 않습니다",
	"参数错误2":"매개 변수 오류 2",
	"无此币种":"그런 통화 없음",
	"该币不是法币":"통화는 법적 통화가 아닙니다",
	"无此记录":"그러한 기록 없음",
	"您还没有通过实名认证":"실명 인증을 통과하지 않았습니다",
	"此条交易已完成":"이 거래는 완료되었습니다",
	"未找到该发布用户":"게시 사용자를 찾을 수 없습니다",
	"不能操作自己的":"스스로 운영 할 수 없습니다",
	"您无此钱包账号":"이 지갑 계정이 없습니다",
	"您的钱包已被锁定，请联系管理员":"지갑이 잠겼습니다. 관리자에게 문의하십시오",
	"检测有未完成交易，请完成后再来！":"완료되지 않은 거래를 확인하고 완료 후 다시 방문하십시오!",
	"您的余额不足":"잔액이 부족합니다",
	"您的法币冻结资金异常,请查看您是否有正在进行的挂单":"피아트 동결 자금이 비정상입니다. 보류중인 주문이 있는지 확인하십시오",
	"出售给商家法币,余额减少":"법정 화폐로 상인에게 판매, 잔액 감소",
	"出售给商家法币,锁定余额增加":"법정 화폐로 상인에게 판매, 고정 잔액 증가",
	"您发布的求购信息有用户出售啦，请去 APP 查看吧～":"게시 한 구매 정보는 사용자가 판매하고 있습니다. APP에서 확인하세요",
	"您发布的出售信息有用户购买啦，请去 APP 查看吧～":"게시 한 판매 정보는 사용자가 구매 한 것입니다. APP에서 확인하세요",
	"Operation successful，请联系商家确认订单":"작업이 성공했습니다. 판매자에게 연락하여 주문을 확인하십시오",
	",错误位于第":", 오류는",
	",行":"，열",
	"用户信息不正确":"사용자 정보가 올바르지 않습니다",
	"该订单已操作过，请勿重复操作":"이 주문은 이미 실행되었습니다. 작업을 반복하지 마십시오",
	"对不起，您无权操作":"죄송합니다. 운영 할 권한이 없습니다",
	"buy":"구입",
	"Operation successful，请联系卖家确认":"작업이 성공했습니다. 판매자에게 문의하여 확인하십시오",
	"Operation successful，订单已取消":"작업이 성공하고 주문이 취소되었습니다",
	"您今天的取消次数已超出":"오늘 취소 횟수를 초과했습니다",
	"该订单已操作，请勿取消":"주문이 처리되었습니다. 취소하지 마십시오",
	"对不起，这不是您的发布信息":"죄송합니다. 귀하의 게시물이 아닙니다",
	"该订单还未付款或已经操作过":"주문이 결제되지 않았거나 처리되었습니다",
	"您不能确认该订单":"주문을 확인할 수 없습니다",
	"订单异常":"주문 예외",
	"该用户没有此币种钱包":"사용자는이 통화 지갑을 가지고 있지 않습니다",
	"该买家没有此币种钱包":"구매자는이 통화로 된 지갑을 가지고 있지 않습니다",
	"出售法币成功,扣除锁定余额":"법적 통화를 성공적으로 판매하고 고정 된 잔액을 공제 ",
	"购买法币成功，增加法币余额":"법정 화폐의 성공적인 구매, 법정 화폐의 잔고 증가 ",
	"在":"에",
	"确认成功":"성공 확인 ",
	"卖出法币成功":"법적 통화를 성공적으로 판매 ",
	"购买法币成功":"법정 화폐의 성공적인 구매 ",
	"确认成功":"성공 확인 ",
	"该发布信息下有交易产生无法删除":"게시 된 정보에 따라 삭제할 수없는 거래가 있습니다",
	"对不起，您无权撤销此记录":"죄송합니다.이 기록을 취소 할 권한이 없습니다",
	"用户钱包不存在":"사용자 지갑이 존재하지 않습니다 ",
	"对不起，您的账户冻结资金不足":"죄송합니다. 귀하의 계정에 고정 된 자금이 부족합니다",
	"商家撤回发布法币出售":"상인은 판매를 위해 명목 화폐의 출시를 철회합니다",
	"商家撤回发布法币出售":"상인은 판매를 위해 명목 화폐의 출시를 철회합니다",
	"撤回成功":"성공적으로 출금",
	"参数错误!":"매개 변수 오류!",
	"转账账户不存在!":"이체 계좌가 없습니다!",
	"不能给自己转账!":"본인에게 송금 할 수 없습니다!",
	"转账数量大于剩余数量!":"이체 금액이 남은 금액보다 큽니다!",
	"余额不足!":"잔액 불충분!",
	"转账数量不能小于":"이체 금액은 다음보다 적을 수 없습니다",
	"转账数量不能大于":"이체 금액은 다음보다 클 수 없습니다",
	"通证转账成功!":"패스 전송이 성공했습니다!",
	"必填项信息不完整":"필수 정보가 불완전합니다",
	"两次输入密码不一致":"두 암호가 일치하지 않습니다",
	"此用户不存在":"이 사용자는 존재하지 않습니다",
	"此交易账号已经设置":"이 거래 계좌번호는 이미 설정되었다",
	"此账号已经存在":"이 계정이 이미 존재합니다",
	"交易账号设置成功":"거래 계좌 설정이 성공하였습니다",
	"验证码错误":"인증코드 오류",
	"手机绑定成功":"휴대폰 바인딩에 성공하다",
	"邮箱绑定成功":"메일박스 바인딩이 성공함",
	"手势密码至少连接6个点":"제스처 비밀번호 최소 6점 연결",
	"两次手势密码不相同":"비밀번호가 일치하지 않습니다",
	"手势密码添加成功":"제스처 암호를 성공적으로 추가하였습니다",
	"取消手势密码成功":"제스처 암호를 취소했습니다",
	"密码只能在6-16位之间":"비밀번호는 6-16자리까지만 가능합니다",
	"两次密码不一致":"비밀번호가 일치하지 않습니다",
	"交易密码设置成功":"거래 비밀번호 설정이 성공하였습니다",
	"暂时还没有邀请排行榜，快去邀请吧":"아직 순위표를 요청하지 않았으니 빨리 가서 요청하세요",
	"会员未找到":"회원을 찾을 수 없습니다",
	"你已绑定，不可更改!":"이미 바인딩되었으니 변경할 수 없습니다!",
	"该地址已被绑定,请重新输入":"주소가 이미 바인딩되었으니 다시 입력하십시오",
	"绑定成功!":"바인딩이 성공하였습니다!",
	"暂无钱包":"지갑이 없다",
	"请提交完整的信息":"전체 정보를 제공하시기 바랍니다",
	"请输入合法的身份证号码":"합법적인 신분증 번호를 입력해 주십시오",
	"该身份证号已实名认证过!":"이 신분증 번호는 이미 실명확인을 하셨습니다!",
	"您已经申请过了":"당신은 이미 신청을 하셨습니다",
	"提交成功，等待审核":"제출한 다음 심사를 기다리십시오",
	"支付密码错误":"암호가 잘못되었습니다",
	"退出登录成功":"로그인 완료",
	"请输入支付密码":"지불 비밀번호를 입력하십시오",
	"余额不足":"잔액이 부족하다",
	"无需升级":"업그레이드 필요 없어",
	"升级成功":"업그레이드에 성공하다",
	"升级会员":"회원으로 업그레이드하다",
	"没有此用户":"이 사용자가 없음",
	"该地址别人已经绑定过了":"이 주소는 다른 사람이 이미 끼워 넣었다",
	"更新成功":"업데이트 성공",
	"请重试":"다시 시도해 보십시오",
	"请把参数填写完整":"매개변수를 완전히 기입하십시오",
	"推荐用户不存在":"추천 사용자가 존재하지 않음",
	"转入账户不能为空":"입금 계정이 비어 있어서는 안 됩니다",
	"转入数量不能为空":"전입량을 공것으로 할 수 없다",
	"密码不能为空":"암호가 비어 있지 않습니다",
	"用户密码错误":"정확하지 않은 사용자 비밀번호",
	"美丽链法币交易余额转入":"메이리롄법폐거래잔액이이입",
	"美丽链币币交易余额转入":"벨리 머니 거래 잔고가 이월하다",
	"美丽链杠杆交易余额转入":"메이리롄레버리지거래잔액이이입",
	"转入成功":"성공으로 접어들다",
	"密码修改成功":"비밀번호 변경 성공",
	"用户未找到":"사용자가 찾을 수 없음",
	"绑定成功":"바인딩이 성공함",
	"发送成功":"성공적으로 보내기",
	"m月d日 H:i":"m 월 d 일 H:i",
	"币未找到":"화폐를 찾을 수 없습니다",
	"暂无数据":"많은 증거를 잠시 열거하다",
	"文件大小超出":"파일 크기 초과",
	"该文件已存在":"파일이 이미 존재합니다",
	"文件类型不对":"잘못된 파일 형식",
	"上传失败":"업로드에 실패했습니다",
	"内容不能为空":"내용이 비어 있어서는 안 됩니다",
	"提交成功，我们会尽快给你回复":"성공적으로 제출, 빠른 시일 내에 답변해 드리겠습니다",
	"非法参数":"잘못된 인자",
	"该币种不支持购买保险":"이 화폐는 보험 구매를 지지하지 않는다",
	"错误的金额！":"잘못된 금액!",
	"不存在的险种！":"존재하지 않는 위험 종!",
	"不存在的币种！":"존재하지 않는 화폐 종류!",
	"该币种不支持购买保险":"이 화폐는 보험 구매를 지지하지 않는다",
	"已经购买了该币种的险种！":"해당 화폐의 위험 종류는 이미 구입했다！",
	"可用余额不足，无法购买！":"이용가능잔액이 부족하여 구매가 안되네요!",
	"购买成功！":"구입이 완료되었습니다",
	"购买失败！原因：":"구매에 실패했습니다!",
	"未找到该保险":"해당 보험을 찾을 수 없습니다",
	"该保险正在处理中":"이 보험은 한창 처리 중이다",
	"申请索赔失败":"클레임을 청구하는데 실패했습니다",
	"申请索赔成功！":"클레임 신청 성공!",
	"超出今日索赔次数!":"금일 클레임 횟수가 초과되었습니다!",
	"存在未平仓订单":"플레이오프된 주문서가 존재한다",
	"受保资产为零":"피보험자산이 0이다",
	"受保资产不符合可申请索赔条件1":"피보험자산이 청구가능조건에 부합되지 않음 1",
	"受保资产不符合可申请索赔条件2":"피보험자산이 청구가능조건에 부합되지 않음 2",
	"未知的险种类型":"알려지지 않은 위험 형태",
	"保险赔偿用户[清除受保金额]":"보험배상가입자 [보험료포함금액 청산]",
	"保险赔偿用户[赔偿受保金额]":"보험배상가입자 [보험받는 금액 배상]",
	"保险解约，扣除保险金额":"보험해약, 보험금액 공제",
	"未知受保金额去向状态":"보험금 지급 상태 알 수 없음",
	"处理成功！":"잘 처리했습니다!",
	"处理失败：":"처리 실패:",
	"未找到该保险":"해당 보험을 찾을 수 없습니다",
	"该保险已失效":"이 보험은 이미 효력을 상실하였다",
	"该保险正在索赔处理中":"이 보험은 현재 클레임 처리 중이다",
	"解约失败，存在未平仓订单":"해약 실패, 미결제 주문서 존재",
	"保险解约，赔付金额":"보험 해약, 배상 금액",
	"保险解约，扣除受保金额":"보험해약, 보장 금액 공제",
	"保险解约，扣除保险金额":"보험해약, 보험금액 공제",
	"解约成功！":"해약 성공!",
	"解约失败:":"해약 실패",
	"订单取消成功":"주문 취소 성공",
	"请选择需求类型":"요구 종류를 선택하세요",
	"请选择交易方式":"거래 방식을 선택하십시오",
	"请填写单价":"단가를 써주십시오",
	"请填写数量":"수량을 기입해 주십시오",
	"请填写最小交易数量":"최소한의 교역량을 기입해 주십시오",
	"请选择币种":"화폐 종류를 선택하세요",
	"最小交易数量不能大于总数量":"최소 거래 수량이 전체 수량보다 많아서는 안 된다",
	"请填写最大交易量":"최대 거래량을 기입해 주십시오",
	"请填写正确的最大交易量":"정확한 최대 거래량을 기입해 주십시오",
	"币种信息有误":"화폐 종류 정보에 오류가 있다",
	"对不起，您不是该法币的商家":"미안합니다만, 당신은 법폐를 파는 상인이 아닙니다",
	"对不起，您的商家账户不足":"죄송합니다, 상가계좌가 부족합니다",
	"商家发布出售":"상인이 판매를 발표하다",
	"商家发布出售,冻结增加":"사업가가 판매를 발표하여 동결되었다",
	"发布成功":"배포가 성공하다",
	"无此商家":"이런 상점이 없다",
	"您不是商家":"당신은 사업가가 아닙니다",
	"该币不是法币":"그 돈은 법폐가 아니다",
	"无此币种":"화폐의 종류는 없다",
	"购买参数错误":"파라메타 구매 오류",
	"无此记录":"이 기록 없음",
	"请填写购买额":"구매액을 기입해 주십시오",
	"购买额请填写数字":"구매액은 숫자를 기입해 주십시오",
	"您还没有通过实名认证":"아직 실명 인증을 받지 않으셨습니다",
	"您还没有设置收款信息":"수신정보가 없습니다",
	"未完成单子超过3单，请完成后再操作!":"완료하지 못한 리스트가 3개를 초과합니다. 완성된 후에 다시 조작하십시오!",
	"商家挂单状态异常,暂时不能交易":"상인이 주문을 거는 상태가 이상해서 잠시 거래할 수 없다",
	"商家挂单剩余可交易数量不足":"상인이 주문을 외워 쓸 수 있는 물량이 부족하다",
	"此条交易已完成":"이 교역은 이미 완료되었다",
	"非法提交，数量必须大于0":"불법으로 제출, 0보다 많은 수",
	"您低于最低限额":"당신은 최저한도액보다 낮다",
	"您高于最高限额":"당신은 최고 한도액을 초과하고 있다",
	"您高于最大限制数量":"최대 제한 물량보다 많음",
	"未找到该商家":"해당 사업가를 찾을 수 없음",
	"不能操作自己的":"할 수 없는 것은",
	"您无此钱包账号":"이 지갑 계정이 없습니다",
	"您的钱包已被锁定，请联系管理员":"지갑이 잠겨 있습니다. 관리자에게 연락하십시오",
	"您的法币余额不足":"당신의 법폐 잔액이 부족하다",
	"您的法币冻结资金异常,请查看您是否有正在进行的挂单":"법폐의 동결자금이 이상하니 현재 확인 중인 공약이 있는지 확인 바랍니다",
	"您出售数量大于商家剩余数量!":"당신이 파는 수량이 상점보다 많아 남은 수량!",
	"出售给商家法币:扣除余额":"상인에게 판매하는 법페:여액공제",
	"出售给商家法币:增加冻结":"상가의 법페 판매:동결조치 증가",
	"您购买数量大于商家剩余数量!":"구매량이 상점보다 많아서 남은 수량!",
	",错误位于第":"오류는 첫 번째에 위치한다",
	"Operation successful，请联系商家确认订单":"수술이 잘되면, 사업자와 연계해 주문서를 확인하시기 바랍니다",
	"行":"행",
	"该订单已操作过，请勿重复操作":"이 주문서는 이미 조작을 거쳤으니, 다시 조작을 하지 마십시오",
	"请上传支付凭证":"지불증을 올려주세요",
	"对不起，您无权操作":"죄송합니다, 당신은 조작할 권리가 없습니다",
	"Operation successful，请联系卖家确认":"수술이 잘되면 판매자에게 연락 주십시오",
	"该订单已操作，请勿取消":"이 주문서는 이미 조작을 거쳤으니, 취소하지 마시기 바랍니다",
	"Operation successful，订单已取消":"Operation 잘됨, 주문서 취소",
	"对不起您不是商家":"미안합니다만, 당신은 상인이 아닙니다",
	"对不起，您不是该商家":"미안합니다만, 당신은이 사업가가 아닙니다",
	"该订单还未付款或已经操作过":"본 주문서는 아직 대금을 지불하지 않았거나 조작한 적이 있다",
	"对不起，您的商家冻结余额不足,当前余额:":"죄송합니다, 상사에서는 잔액이 부족하므로 현재 잔액을 동결합니다",
	"您不能确认该订单":"이 주문서를 확인할 수 없습니다",
	"订单异常":"주문서 이상",
	"该用户没有此币种钱包":"해당 사용자는 지갑이 없습니다",
	"法币交易:在商家":"법폐 거래:상가에서",
	"购买法币成功":"법폐 구입에 성공하다",
	"法币交易:卖出成功":"법폐 거래:매출 성공",
	"确认成功":"성공 확인",
	"该订单还未付款或已经操作过":"본 주문서는 아직 대금을 지불하지 않았거나 조작한 적이 있다",
	"您不能确认该订单":"이 주문서를 확인할 수 없습니다",
	"该用户没有此币种钱包":"해당 사용자는 지갑이 없습니다",
	"法币交易:用户":"법폐 거래:사용자",
	"向商家出售法币成功":"상가에 법폐를 판매하는 데 성공하다",
	"法币交易:购买成功":"법페 거래:구매성공",
	"确认成功":"성공 확인",
	"此状态下无法下架":"이 상태에서는 뺄 방법이 없다",
	"对不起，您不是该法币的商家":"미안합니다만, 당신은 법폐를 파는 상인이 아닙니다",
	"发布下架成功,将不会再与新用户匹配":"업데이트 해제 성공, 새 가입자와 더 이상 일치하지 않을 것",
	"必须下架后才可以撤销":"철거한 후에야 철거할 수 있다",
	"已撤回发布":"이미 발표를 철회하다",
	"当前发布状态无法撤销":"현재 발표 상태를 취소할 수 없습니다",
	"当前发布剩余数量不足,无法撤销":"현재 배포된 잔여 물량이 부족하여 취소할 수 없습니다",
	"该发布信息下有交易正在进行中,请等待交易结束再撤回":"이 정보 아래에 거래 가 진행 중입니다. 거래 종료 때까지 기다려 주시기 바랍니다",
	"对不起，您不是该法币的商家":"미안합니다만, 당신은 법폐를 파는 상인이 아닙니다",
	"对不起，您的商家账户冻结资金不足":"미안합니다, 당신의 상가계좌에 자금이 부족합니다",
	"撤回失败:减少冻结资金失败":"실패 철회 = 동결자금 감소 실패",
	"商家撤回":"상인들이 철수하다",
	"撤回失败:增加余额失败":"실패 철회하기:잔액 증가 실패",
	"撤回成功":"성공을 철회하다",
	"该发布信息无异常":"이 메시지는 이상이 없습니다",
	"该发布信息下有交易未完成，不能标记为异常":"이 메시지는 거래가 완료되지 않았으므로 예외로 표기할 수 없습니다",
	"该发布剩余数量不足,不法标记异常":"당해 발표 잔여 수량 부족, 불법표기 이상",
	"当前发布状态无法标记异常":"현재 배포 상태에서 이상을 표시할 수 없습니다",
	"对不起，您的商家账户冻结资金不足":"미안합니다, 당신의 상가계좌에 자금이 부족합니다",
	"处理异常失败:减少冻结资金失败":"이상 처리 실패:동결자금 감소 실패",
	"处理异常失败:增加余额失败":"이상 처리 실패:잔액 증가 실패",
	"商家处理异常":"상가에서 이상 처리를하다",
	"商家处理异常":"상가에서 이상 처리를하다",
	"缺少参数或传值错误":"인자 또는 전송 오류",
	"指定交易对不存在":"지정 거래쌍이 존재하지 않음",
	"您未开通本交易对的交易功能":"귀하는 본 거래 대역의 거래 기능을 개통하지 않았습니다",
	"手数必须是大于0的整数":"소수수는 0보다 큰 완수여야 한다",
	"手数不能低于":"소수보다 적어서는 안 된다",
	"手数不能高于":"손수가 높아서는 안 된다",
	"选择倍数不在系统范围":"배수가 다른 시스템에서 선택",
	"您有正在平仓中的交易,暂不能进行买卖":"지금 쌓아 놓은 교역이 있으니 잠시 매매를 할 수 없습니다",
	"交易类型错误":"거래 형식 오류",
	"该功能暂未开放":"이 기능은 아직 열려 있지 않습니다",
	"限价交易价格必须大于0":"제한가격거래가격은 0보다 커야 한다",
	"当前没有获取到行情价格,请稍后重试":"현재 시세를 얻지 못했습니다. 나중에 다시 시도하십시오",
	"限价交易卖出不能低于当前价":"제한 가격 거래에 있어 현 가격보다 낮을 수 없다",
	"限价交易买入价格不能高于当前价":"제한가격거래 매입가격이 당기 가격보다 높아서는 안 된다",
	"钱包未找到,请先添加钱包":"지갑을 찾을 수 없습니다. 지갑을 먼저 추가하시기 바랍니다",
	"余额不足,不能小于":"잔액이 부족하면, 작게는 안 된다",
	"(手续费:":"(수속비",
	"提交失败":"보내기 실패",
	"扣除保证金失败":"보증금 공제 실패",
	"扣除手续费失败":"수수료 공제 실패",
	"此功能系统未开放":"이 기능 시스템이 열려 있지 않습니다",
	"止盈止损价格不能为0":"손익대비 손익가격은 0이 될 수 없다",
	"找不到该笔交易":"이 교역을 찾을 수 없다",
	"买入(做多)止盈价不能低于开仓价和当前价":"매입 (많이 하는 것) 손익분기점 가격은 발매 가격과 현재 가격보다 낮아서는 안 된다",
	"买入(做多)止亏价不能高于开仓价和当前价":"매입가격 (많이 하는 것) 정지손익가격은 발매 가격이나 현재 가격보다 높아서는 안 된다",
	"卖出(做空)止盈价不能高于开仓价和当前价":"투매 (공매) 중단 가격은 출고 가격이나 현재 가격보다 높아서는 안 된다",
	"卖出(做空)止亏价不能低于开仓价和当前价":"매도 (공매) 정지손익가격은 개찰가나 현재 가격보다 낮아서는 안 된다",
	"设置成功":"설정을 성공하였습니다",
	"设置失败":"설정이 실패했습니다",
	"数据未找到":"데이터를 찾을 수 없음",
	"无权操作":"조작 권한 없음",
	"交易状态异常,请勿重复提交":"거래 상태가 이상하니, 중복 제출을 하지 마시오",
	"平仓失败,请重试":"포지션을 평정하는 데 실패하였으니 다시 시도해 주십시오",
	"交易异常，无法平仓":"교역이 비정상적이어서 평상시를 이룰수 없다",
	"买入方向传参错误":"매입 방향 인삼 오류",
	"提交成功,请等待系统处理":"성공적으로 제출했습니다. 시스템 처리를 기다려 주십시오",
	"未找到需要平仓的交易":"평창 거래를 찾을 수 없습니다",
	"提交成功,请等待系统处理":"성공적으로 제출했습니다. 시스템 처리를 기다려 주십시오",
	"未找到需要平仓的交易":"평창 거래를 찾을 수 없습니다",
	"交易不存在或已撤单,请刷新后重试":"거래가 존재하지 않거나 철수했습니다. 새로 고침 후 다시 시도하십시오",
	"撤单失败:用户钱包不存在":"사용자가 영어를 찾을 수 없음",
	"撤单失败":"철수에 실패하다",
	"撤单失败:变更状态失败":"취소 실패:상태 변경이 실패했습니다",
	"撤单成功":"어음을 성공적으로 취소하다",
	"请输入账号":"계좌 번호를 입력해 주십시오",
	"请输入密码":"비밀번호를 입력하십시오",
	"手势密码错误":"잘못된 제스처 비밀번호",
	"账号已锁定！请联系管理员":"계좌번호는 이미 잠금되었습니다!관리자에게 연락하세요",
	"两次密码不一致":"비밀번호가 일치하지 않습니다",
	"密码只能在6-16位之间111111":"비밀번호는 6-16자리 사이 111111번입니다",
	"验证码错误":"인증코드 오류",
	"账号已存在":"계정이 이미 존재합니다",
	"请填写正确的邀请码":"정확한 초청 번호를 기입해 주십시오",
	"请输入账号":"계좌 번호를 입력해 주십시오",
	"请输入密码或确认密码":"비밀번호를 입력하거나 입력하십시오",
	"输入两次密码不一致":"비밀번호가 일치하지 않게 두번 입력하였습니다",
	"验证码不正确":"인증번호가 올바르지 않습니다",
	"账号不存在":"계정이 존재하지 않습니다",
	"修改密码成功":"비밀번호 변경 성공",
	"请输入验证码":"인증번호를 입력하십시오",
	"验证码错误":"인증코드 오류",
	"验证成功":"성공적으로 검증",
	"下单失败:超过最大持仓笔数限制":"발주 실패:최대 포지션 제한 초과",
	"当前未获取到行情":"현재 시세를 얻지 못했다",
	"尚未申购或理赔保险":"아직 구입 또는 배상 신청을 하지 않았다",
	"申购的保险T+1生效":"구매신청을 한 보험 T+1이 효력을 발생한다",
	"受保资产为零":"피보험자산이 0이다",
	"受保资产小于等于可下单条件":"피보험자산이 발주 조건보다 작다",
	"您已超过持仓限制，暂停下单。":"당신은 이미 포지션 보유한도를 초과해서 발주를 잠시 중단합니다",
	"未知的险种类型":"알려지지 않은 위험 형태",
	"超过最大持仓数量限制":"최대 포지션 보유량 제한을 초과하다",
	"交易中的订单大于最大挂单数量":"거래 중 주문이 최대 주문서 수보다 많다",
	"新闻不存在":"뉴스는 존재하지 않습니다",
	"无此币种":"화폐의 종류는 없다",
	"该币不是法币":"그 돈은 법폐가 아니다",
	"用户名不能为空":"사용자 이름이 비어 있을 수 없음",
	"名称不能为空":"이름이 비어 있을 수 없음",
	"账号不能为空":"계정이 비어 있을 수 없습니다",
	"资产不能为空":"자산이 비어 있어서는 안 된다",
	"找不到此交易账号的用户":"이 거래 계좌 사용자의 이름을 찾을 수 없습니다",
	"此用户还未通过实名认证":"이 사용자는 아직 실명 인증을 통과하지 못했다",
	"币种不存在":"화폐 종류는 존재하지 않는다",
	"该币不是法币":"그 돈은 법폐가 아니다",
	"商家名称已存在":"상가이름이 이미 존재합니다",
	"此法币":"이 법폐",
	"此用户已是此法币商家":"이 사용자는 이미이 법폐 상가이다",
	"申请成为商家，扣除USDT":"상인이 되길 원하지만 USDT를 빼면",
	"当前未获取到行情":"현재 시세를 얻지 못했다",
	"系统配置错误，请联系系统管理员":"시스템 설정이 잘못되었습니다. 시스템 관리자에게 문의하십시오",
	"账号不能为空":"계정이 비어 있을 수 없습니다",
	"发送成功":"성공적으로 보내기",
	"账号不能为空":"계정이 비어 있을 수 없습니다",
	"账号错误":"계정 오류",
	"账号已存在":"계정이 이미 존재합니다",
	"请填写账号":"계좌 번호를 적어 주시기 바랍니다",
	"【BEF】若非您本人操作，请及时修改密码。":"【NEWBIT】 당신이 조작하지 않았다면, 제때에 비밀번호를 수정하십시오",
	"发送成功":"성공적으로 보내기",
	"账号不能为空":"계정이 비어 있을 수 없습니다",
	"账号错误":"계정 오류",
	"账号已存在":"계정이 이미 존재합니다",
	"发送成功":"성공적으로 보내기",
	"发送失败":"보내기 실패",
	"ip参数不正确":"ip 인자가 올바르지 않습니다",
	"邮箱不能为空":"빈 편지함을 사용할 수 없습니다",
	"账号错误":"계정 오류",
	"账号已存在":"계정이 이미 존재합니다",
	"短信验证码":"문자메시지 인증번호",
	"您还没有交易记录":"거래 기록이 없습니다",
	"非法操作,不能撤回非自己发布的信息":"불법조작, 스스로 배포하지 않은 정보 철회할 수 없어",
	"非法操作:(":"잘못된 사용:",
	"取销卖出交易失败":"딜링 실패",
	"取消买入交易,解除币币余额锁定":"매입 거래를 취소하고, 통화 잔고 잠금 해제",
	"取消买入交易,解除法币余额锁定":"매입 거래 취소, 법폐 잔고 잠금 해제",
	"用户未找到":"사용자가 찾을 수 없음",
	"取消成功":"성공 취소",
	"取销卖出交易失败":"딜링 실패",
	"取消卖出交易,解除交易余额锁定":"매각대역을 취소하고 거래잔액 잠금 해제",
	"取消卖出交易,解除交易余额锁定":"매각대역을 취소하고 거래잔액 잠금 해제",
	"取消成功":"성공 취소",
	"类型错误":"형식 오류",
	"交易不存在":"거래가 없다",
	"资金不足":"자금이 부족하다",
	"取消卖出交易,解除交易余额锁定":"매각대역을 취소하고 거래잔액 잠금 해제",
	"取消卖出交易,解除交易余额锁定":"매각대역을 취소하고 거래잔액 잠금 해제",
	"数据未找到":"데이터를 찾을 수 없음",
	"请先添加钱包":"먼저 지갑을 추가하세요",
	"您的币不足":"당신의 돈이 부족합니다",
	"提交卖出记录扣除":"매출 기록 공제 제출",
	"你今天的交易额度已达到上限!":"당신은 오늘 거래 한도가 이미 상한선에 다다랐어요!",
	"数据未找到":"데이터를 찾을 수 없음",
	"余额不足":"잔액이 부족하다",
	"提交卖入记录扣除":"매출기록부를 제출하여 공제하다",
	"提交卖出记录(增加冻结)":"매출 기록 제출 (추가 동결)",
	"价格和数量必须大于0":"가격과 수량이 0보다 커야 합니다",
	"您的币不足":"당신의 돈이 부족합니다",
	"您的冻结资金异常，禁止挂卖":"동결된 자금은 이상하므로, 거래를 금지합니다",
	"真实姓名必须填写":"실명은 반드시 기입해야 한다",
	"收款信息至少选填一项":"수납정보 중 적어도 하나를 선택해서 기입하다",
	"保存成功":"저장에 성공함",
	"密码错误":"비밀번호 오류",
	"当前行情小于等于零!":"현재 시세가 영이다!",
	"资产兑换,减少持有币法币:":"자산교환, 법률화페 보유량 감소",
	"兑换数量大于持有资产!":"보유 자산보다 교환 수량 많네요!",
	"资产兑换,减少":"자산의 태환, 감소",
	"资产兑换,增加USDT杠杆币":"자산 데환, USDT 레버드 증가",
	"扣除手续费":"수수료를 떼다",
	"法币数量:":"프랑스화폐의 수량",
	"兑换数量大于剩余数量!":"나머지보다 교환 수량 많네요!",
	"资产兑换成功!":"자산 교환 성공!",
	"参数错误!":"인자 오류!",
	"通证兑换,杠杆币增加":"환전, 레버리지머니 증가",
	"通证兑换杠杆币增加失败":"레버리지머니 교환 실패",
	"通证兑换成功!":"환전에 성공함!",
	"必填项信息不完整":"필수 항목 정보가 완전하지 않습니다",
	"两次输入密码不一致":"비밀번호가 일치하지 않습니다",
	"此用户不存在":"이 사용자는 존재하지 않습니다",
	"此交易账号已经设置":"이 거래 계좌번호는 이미 설정되었다",
	"此账号已经存在":"이 계정이 이미 존재합니다",
	"交易账号设置成功":"거래 계좌 설정이 성공하였습니다",
	"邮箱绑定成功":"메일박스 바인딩이 성공함",
	"手机绑定成功":"휴대폰 바인딩에 성공하다",
	"手势密码添加成功":"제스처 암호를 성공적으로 추가하였습니다",
	"两次手势密码不相同":"비밀번호가 일치하지 않습니다",
	"取消手势密码成功":"제스처 암호를 취소했습니다",
	"手势密码至少连接6个点":"제스처 비밀번호 최소 6점 연결",
	"密码只能在6-16位之间":"비밀번호는 6-16자리까지만 가능합니다",
	"两次密码不一致":"비밀번호가 일치하지 않습니다",
	"交易密码设置成功":"거래 비밀번호 설정이 성공하였습니다",
	"暂时还没有邀请排行榜，快去邀请吧":"아직 순위표를 요청하지 않았으니 빨리 가서 요청하세요",
	"会员未找到":"회원을 찾을 수 없습니다",
	"该身份证号已实名认证过":"이 신분증 번호는 이미 실명확인을 하였다",
	"你已绑定，不可更改!":"이미 바인딩되었으니 변경할 수 없습니다!",
	"该地址已被绑定,请重新输入":"주소가 이미 바인딩되었으니 다시 입력하십시오",
	"绑定成功!":"바인딩이 성공하였습니다!",
	"请提交完整的信息":"전체 정보를 제공하시기 바랍니다",
	"提交成功，等待审核":"제출한 다음 심사를 기다리십시오",
	"退出登录成功":"로그인 완료",
	"请输入支付密码":"지불 비밀번호를 입력하십시오",
	"参数错误":"인자 오류",
	"无需升级":"업그레이드 필요 없어",
	"兑换成功":"태환 성공",
	"暂无钱包":"지갑이 없다",
	"支付密码错误":"암호가 잘못되었습니다",
	"余额不足":"암호가 잘못되었습니다",
	"升级成功":"업그레이드에 성공하다",
	"该地址别人已经绑定过了":"이 주소는 다른 사람이 이미 끼워 넣었다",
	"更新成功":"업데이트 성공",
	"请重试":"다시 시도해 보십시오",
	"推荐用户不存在":"추천 사용자가 존재하지 않음",
	"请把参数填写完整":"매개변수를 완전히 기입하십시오",
	"转入账户不能为空":"입금 계정이 비어 있어서는 안 됩니다",
	"密码不能为空":"암호가 비어 있지 않습니다",
	"转入数量不能为空":"전입량을 공것으로 할 수 없다",
	"密码修改成功":"비밀번호 변경 성공",
	"暂时还没有添加币种":"당분간은 화폐를 첨가하지 않았다",
	"此币种不存在":"이 화폐는 존재하지 않는다",
	"已经有此提币地址":"이미이 화폐인출 주소가 있습니다",
	"添加提币地址成功":"인출된 주소를 추가하였습니다",
	"此提币地址不存在":"이 인출의 주소가 존재하지 않습니다",
	"您没有权限删除此地址":"이 주소를 삭제할 권한이 없습니다",
	"删除提币地址成功":"인출된 주소를 삭제했습니다",
	"输入的金额不能为负数":"입력한 금액은 마이너스가 될 수 없습니다",
	"您有正在进行中的杆杠交易,不能进行此操作":"현재 실행 중인 막대봉 거래를 할 수 없습니다",
	"钱包不存在":"지갑이 존재하지 않음",
	"划转成功":"수지가 맞다",
	"操作失败:":"작동 실패",
	"操作失败":"작동 실패",
	"操作失败:Insufficient wallet balance":"작동 실패:지갑 잔액 부족",
	"Insufficient wallet balance":"지갑 잔액 부족",
	"下单数量必须是10的整数倍":"주문 수량은 10의 정수 배수 여야합니다.",
	"Parameter error":"인자 오류",
	"提币申请已成功，等待审核":"화폐 공출 신청은 이미 성공하여 심사를 기다린다",
	"两次密码不一致":"비밀번호가 일치하지 않습니다",
	"数量不能小于等于0":"수량이 0보다 작거나 같아서는 안 된다",
	"价格不能小于等于0":"가격은 0보다 작거나 같아서는 안 된다",
	"兑换数量必须大于0":"교환수량이 0 이상이여야 한다",
	"钱包不存在":"지갑이 존재하지 않음",
	"转账至交易所钱包":"거래소 지갑으로 이체하기",
	"支付密码错误":"암호가 잘못되었습니다",
	"钱包已添加,请勿重复添加":"지갑은 이미 추가되었습니다. 다시 추가하지 마십시오",
	"您还没有钱包":"지갑이 없습니다",
	"接收来自交易所的转账":"거래소의 이체를 받다",
	"地址输入有误":"주소 수입에 오류가 있다",
	"请输入正确的值":"정확한 값을 입력하십시오",
	"地址输入有误1":"주소 입력 오류 1",
	"不能转账给自己":"이체할 수 없습니다",
	"支付密码错误":"암호가 잘못되었습니다",
	"扣除卖方!":"매도인을 공제하다!",
	"买方增加!":"구매자 증가!",
	"申请提币冻结余额!":"인출에 대한 동결을 신청하고 잔고를 동결한다!",
	"扣除卖方":"매도인을 공제하다",
	"买方增加":"구매자가 증가하다",
	"申请提币冻结余额":"화폐 공제를 신청하여 잔액을 동결하다",
	"币币 划转 币币":"화폐를 화폐로 전환하다",
	"币币 划转 合约":"화폐의 화폐대체계약",
	"币币 划转 秒合约":"화폐교환 계약",
	"币币 划转 法币":"화폐를 법폐로 바꾸다",
	"法币 划转 法币":"법폐 법폐 이리저리 바꾸다",
	"法币 划转 币币":"법폐 화폐로의 전환",
	"法币 划转 合约":"프랑스화폐대체계약",
	"法币 划转 秒合约":"프랑스화폐교환 계약",
	"秒合约 划转 秒合约":"1초 계약 2초 계약",
	"秒合约 划转 币币":"화폐교환 계약",
	"秒合约 划转 法币":"화폐교환 계약",
	"秒合约 划转 合约":"계약서 바로가기",
	"合约 划转 合约":"계약서의 이월",
	"合约 划转 币币":"계약서의 화폐전환",
	"合约 划转 秒合约":"계약체결 2초 전 계약",
	"合约 划转 法币":"계약서의 법폐 이월",
	"提交卖出记录(增加冻结)":"매출 기록 제출 (추가 동결)",
	"提交卖出记录扣除":"매출 기록 공제 제출",
	"平仓资金处理":"오버나이트 펀드",
	"请先勾选同意用户协议":"사용자 협의를 먼저 선택하십시오",
	"您有未平仓的交易,操作禁止":"당신은 미숙한 교역이므로, 조작 금지입니다",
	"操作失败:您有未平仓的交易,操作禁止":"작업 실패 : 공개 거래가 있습니다. 작업이 금지됩니다",
	"购买失败，购买金额必须大于":"구매에 실패했습니다. 구매금액이보다 커야 합니다",
	"并且小于":"작은 병원보다",
	"用户购买保险":"가입자보험",
	"您的验证码是：":"인증서는 다음과 같습니다:",
	"发送失败:":"보내는데 실패했습니다:",
	"提交":"제출",
	"杠杆交易,价格":"레버리지 거래, 가격",
	",扣除保证金":"보증금을 떼다",
	"杠杆交易,扣除手续费":"레버리지거래, 수수료 공제",
	"修改邮箱":"편지함을 수정하다",
	"您输入的邮箱或手机号不符合规则":"입력 한 이메일 또는 전화 번호가 규정에 맞지 않습니다",
	"验证码":"확인 코드",
	"秒":"둘째",
	"卖方增加法币":"판매자는 법정 화폐를 증가시킵니다",
	"后台审核充值通过":"백 스테이지 검토 및 재충전 통과",
	"申请提币扣除余额":"잔액 공제를위한 출금 신청",
	"扣除卖方":"매도인을 공제하다",
	"买方增加":"구매자가 증가하다",
	"申请提币冻结余额":"화폐 공제를 신청하여 잔액을 동결하다",
	"卖方增加法币":"파는 쪽이 법폐를 늘리다",
	"后台审核充值通过":"배후 심사에서 통과",
	"申请提币扣除余额":"화폐 공제를 신청하여 잔액을 공제하다",
	"秒合约订单平仓,盈利结算":"초속 계약서는 평상상태로, 흑이결제",
	"秒合约订单,亏损结算":"초당 계약 오더, 결손결제",
	"秒合约下单扣除":"스톱 삭감",
	"下单扣除":"주문 공제",
	"%手续费":"% 수수료",
	"秒合约下单扣除本金":"초당 계약서에서 원금을 공제하다",
	"停止售出":"중지 ",
	"买方扣除币币余额":"구매자가 통화 잔액을 공제합니다",
	"买方扣除法币余额":"구매자는 법정 화폐 잔액을 공제합니다",
	"买方扣除合约余额":"구매자가 계약 잔액을 공제",
	"买方扣除秒合约余额":"구매자는 두 번째 계약 잔액을 공제합니다",
	"用户中心":"사용자 센터",
	"注册成功,钱包状态：1":"등록 성공, 지갑 상태 : 1",
	"注册成功,钱包状态：2":"등록 성공, 지갑 상태 : 2",
	"注册成功,钱包状态：0":"등록 성공, 지갑 상태 : 0",
	"调节":"맞추다",
	"账户余额:":"계정 잔액 ",
	"账户锁定余额:":"계정 잠금 잔액 ",
	"秒合约":"두 번째 계약",
	"法币":"피아트 통화",
	"杠杆":"지렛대",
	"币币":"코인",
	"请实名认证":"실명 인증 부탁드립니다",
	"您的实名认证还未通过！":"실명 인증이 통과되지 않았습니다!",
	"暂不支持该币种":"이 통화는 현재 지원되지 않습니다",
	"充值":"재충전",
	"余额不足":"잔액 불충분",
	"操作成功":"성공적인 운영",
	"取消成功":"성공 취소",
	"提交成功":"성공적으로 제출되었습니다",
	"最大下单数量不能超过:":"최대 주문 수는 다음을 초과 할 수 없습니다:",
	"投资数额 的最小长度为":"투자 금액의 최소 길이는",
	"位":"비트",
	"杠杆买入委托撤单,退回手续费":"레버리지 구매 주문 취소, 취급 수수료 환불",
	"杠杆买入委托撤单,退回保证金":"레버리지 구매 주문 취소 및 마진 환불",
	"秒合约买入委托撤单,退回手续费":"2 차 계약 구매 주문 취소, 취급 수수료 환불",
	"秒合约买入委托撤单,退回保证金":"두 번째 계약 구매 주문 취소, 반품 마진",
	"法币买入委托撤单,退回手续费":"법정 화폐 구매 주문 취소, 취급 수수료 환불",
	"法币买入委托撤单,退回保证金":"피아트 구매 주문 취소 및 마진 환불",
	"币币买入委托撤单,退回手续费":"토큰 구매 주문 취소, 수수료 환불",
	"币币买入委托撤单,退回保证金":"토큰 구매 주문 취소 및 보증금 환불",
	"订单平仓结算,平局结算":"주문 마감 정산, 타이 정산",
	"订单平仓,盈利结算":"주문 마감, 이익 정산",
	"Account already exists":"계정이 이미 있습니다",
	"投资数额 字段必须填写":"투자 금액 필드를 입력해야합니다",
	"提币成功":"성공적으로 출금",
	"Operation successful":"작업이 성공했습니다.",
	"加载中...":"로드 중 ...",
	"加载更多":"더로드",
	"没有更多了...":"더 이상은 없어...",
	"确定平仓？":"포지션을 닫으시겠습니까?",
	"发行量":"순환",
	"请登录":"로그인 해주세요",
	"开始申购":"구매 시작",
	"申购币种":"구독 통화",
	"开始申购时间":"구매 시작 시간",
	"预计上线时间":"온라인 접속 예상 시간",
	"结束申购时间":"구독 종료 시간",
	"项目预热":"프로젝트 워밍업",
	"公布结果":"결과 발표",
	"USDT余额不足":"불충분 한 USDT 잔액",
	"BTC余额不足":"부족한 BTC 잔액",
	"ETH余额不足":"ETH 잔액이 부족합니다",
	"申购成功":"성공적인 구독",
	"兑换成功":"성공적인 사용",
	"全部":"모두",
	"立即申购":"지금 구독하세요",
	"结束申购":"구독 종료",
	"申购周期":"구독주기",
	"项目详情":"프로젝트 세부 정보",
	"请输入申购数量":"구매 수량을 입력하세요",
	"申购确认":"구매 확인",
	"确定":"결정",
	"取消":"취소",
	"您计划申购":"구독 할 계획입니다",
	"BEF钱包未找到":"BEF 지갑을 찾을 수 없습니다",
	"USDT钱包未找到":"USDT 지갑을 찾을 수 없습니다",
	"BTC钱包未找到":"BTC 지갑을 찾을 수 없습니다",
	"ETH钱包未找到":"ETH 지갑을 찾을 수 없습니다",
	"USDT 兑换 BEF ":"USDT에서 BEF ",
	"BTC 兑换 BEF ":"BTC에 BEF ",
	"ETH 兑换 BEF ":"ETH를 BEF로 교환 ",
	"申购历史":"구매 내역",
	"认购价格":"구독 가격",
	"认购数量":"구독 수량",
	"认购时间":"구독 시간",
	"结束时间":"종료 시간",
	"状态":"상태",
	"解锁":"터놓다",
	"已完成":"완료",
	"已锁仓":"잠김",
	"暂无记录":"기록 없음",
	"操作":"운영",
	"价值":"값",
	"认购币种":"구독 통화",
	"申购记录":"구매 기록",
	"可用余额不足":"사용 가능한 잔액이 부족합니다",
	"申购数量异常":"비정상적인 구독 수량",
	"认购异常":"구독 예외",
	"认购成功":"성공적으로 구독했습니다",
	"下单数量必须是100的整数倍":"주문 수량은 100의 정수 배수 여야합니다",
	"认购解锁到账":"구독 잠금 해제",
	"充值自动到账":"충전은 자동으로 계정에 적립됩니다.",
	"发送失败。未包含正确的手机号":"보내지 못했습니다. 올바른 전화 번호가 포함되어 있지 않습니다",
	"发送失败。余额不足":"보내지 못했습니다. 잔액 불충분",
	"认购付款":"구독 결제",
	"当前价值":"현재 가치",
	"解锁时间":"시간 잠금 해제",
	"天":"日",
	"倒计时":"카운트 다운",
	"预计收益":"예상 수입",
	"资产记录":"자산 기록",
	"知道了":"알았다",
	"冻结剩余时间":"남은 시간 고정",
	"小时":"시",
	"aaaaaaaaaaaa":"1111111"
}
}