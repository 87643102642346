export default {
	"siteName": "NEWBIT",
	"scan": "Barrer",
	"inMobile":"Envíe sus comentarios sobre el dispositivo móvil",
	"exchangeRate": "tipo de cambio",
	"none": "",
	"code": "Enviar el código de verificación",
	"send": "Enviar el código de verificación",
	"pwd": "contraseña",
	"accounts": "cuenta",
	"registers": "Registrarse",
	"welcome": "Hi,Bienvenido a NEWBIT",
	"resend": "{number}Reenviar en segundos",
	"xieyi": "He leído y estoy de acuerdo",
	"xieyi2": "《Acuerdo del Usuario》",
	"transferFromChange":"Transferencia desde cuenta de monedas",
	"transferChange":"Transferencia a cuenta de monedas",
	"transferOk":"transferencia completada",
	"xieyi1": {
	"first": "La información de nacionalidad no se puede modificar después del registro",
		"second": "El correo electrónico de verificación puede considerarse spam, revíselo detenidamente.",
		"third": "Por favor, mantenga segura la información de su cuenta y su contraseña.",
		"fourth": "No utilice la misma contraseña de inicio de sesión que otros sitios web",
		"fifth": ""
	},
	"confirm": "confirmar",
	"regiterStep1": "Regístrate ahora",
	"regiterStep2": "guardar datos",
	"logins": "Iniciar sesión",
	"notice": "anuncio",
	"more": "carga más",
	"nomore": "no más",
	"time": "hora",
	"price": "precio",
	"number": "cantidad",
	"info": "Información básica",
	"link": "enlaces relacionados",
	"cfprice": "precio de financiación colectiva",
	"totalc": "circulación total",
	"bname": "nombre de la moneda",
	"query": "consulta de bloque",
	"dealed": "Trato hecho",
	"notdeal": "no comercializado",
	"handicap": "Desventaja",
	"rtt": "transacción en tiempo real",
	"timescreen": "filtro de tiempo",
	"dealscreen": "Filtro de par comercial",
	"dirscreen": "filtro de dirección",
	"to": "a",
	"startdate": "fecha de inicio",
	"overdate": "fecha final",
	"do": "funcionar",
	"revoke": "Revocar",
	"nodata": "Aún no hay datos",
	"loading": "Cargando",
	"status": "Estado",
	"rate": "Tarifas",
	"back": "atras",
	"alipay": "Alipay",
	"wechat": "WeChat",
	"bankcard": "Tarjeta Bancaria",
	"inpCur": "Buscar",
	"minAm": "Cantidad mínima",
	"regidters": "Bienvenido a registrarse",
	"alltread": "Comercio en todo el sitio",
	"treadnum": "Número de transacciones",
	"treaddetail": "Detalles de la transacción",
	"tnum": "Número de transacciones",
	"lastweek": "La semana pasada",
	"reload": "Actualizar",
	"dealtime": "Hora de la oferta",
	"way": "Método de delegación",
	"torg": "Intercambio",
	"dealno": "Trato No.",
	"ttotal": "Importe de la transacción",
	"auto": "Delegación de autoservicio",
	"sum":"total",
	"traedoff": "El par comercial se eliminó de la lista, cambie el par comercial nuevamente",
	"piece": "Zhang",
	"hand": "mano",
	"pbuynum": "Por favor ingrese el número de compras",
	"bestsell": "Vender al mejor precio del mercado",
	"lowbuy": "Comprar al precio más bajo del mercado",
	"transin": "Transferir a",
	"transout": "Transout",
	"tansinmoney": "Transferir capital",
	"tansoutmoney": "Transferir capital",
	"from": "desde",
	"jingdu": "Precisión",
	"m": "minutos",
	"day":"día",
	"week": "semana",
	"year": "año",
	"mon": "mes",
	"hour": "hora",
	"header": {
	"num": "Número de posiciones abiertas",
		"mode": "modo de transacción",
		"quotation": "Citas",
		"home": "página delantera",
		"c2c": "Transacción en moneda fiduciaria",
		"match": "Competencia de contratos",
		"currency": "comercio de divisas",
		"assets": "mis activos",
		"setting": "Configuraciones de seguridad",
		"help": "Centro de ayuda",
		"bbs": "foro",
		"complaint": "mensaje de queja",
		"in": "Iniciar sesión",
		"up": "Registrarse",
		"inTourist": "Registro de visitantes",
		"current": "mandato actual",
		"his": "comisión histórica",
		"code": "Código de invitación",
		"shop": "mi tienda",
		"out": "desconectar",
		"orders": "Pedido",
		"center": "centro personal",
		"pro": "anuncio de proyecto",
		"cun": "depositar tesoro",
		"vote": "Votar por la lista",
		"sgr": "reembolso SGR",
		"inset": "Configuración de recibo",
		"tradelog": "Registro de transacciones",
		"fastrecharge": "Recarga Rápida"
	},
	"footer": {
	"openNum": "Ingrese la cantidad de la posición abierta",
		"finshprice": "Transaction Price",
		"times": "Countdown",
		"loss": "Expected Profit and loss",
		"buy Price": "Buy Price",
		"num": "cantidad",
		"symbol": "Transaction pair",
		"trade": "in Transaction",
		"up": "Buy up",
		"down": "Buy down",
		"balance": "balance",
		"rate": "Profit rate",
		"hxex": "Introducción a NEWBIT",
		"legalTrade legal": "transacciones en moneda francesa",
		"tool": "Link",
		"about": "about",
		"join": "join us",
		"connect": "contact us",
		"download": "Client Download",
		"explain": "descripción de los términos",
		"xieyi": "User Protocol",
		"yinsi": "Privacy clause",
		"legal": "Legal statement",
		"feilv": "tasa de transacción",
		"notice": "Bulletin Center",
		"nhelp": "newbie Help",
		"support": "User support",
		"workorder de trabajo": "enviar orden de trabajo",
		"apply": "Application in currency",
		"gonggao": "website Bulletin",
		"community": "comunidad",
		"wechat": "Wechat",
		"weibo": "weibo",
		"email": "Business mail",
		"email pre": "Customer Service:",
		"email Info": "Official mail: FacebookNEWBIT0@gmail.com ",
		"feedback": "problem Feedback",
		"address": "Singapur ©  NEWBIT 2013 - 2020 ",
		"about 2": "Corporate Profile",
		"service": "servicio",
		"coin": "Digital Asset Introduction",
		"apply_url": "https://qwe.shushuiji.net/dist/#/components/noticeDetail?id=195",
		"feedback_url": "https://qwe.shushuiji.net/dist/#/components/noticeDetail?id=200",
		"about2_url": "https://qwe.shushuiji.net/dist/#/components/noticeDetail?id=206",
		"coin_url": "https://qwe.shushuiji.net/dist/#/components/noticeDetail?id=211",
		"yinsi_url": "https://qwe.shushuiji.net/dist/#/components/noticeDetail?id=216",
		"feilv_url": "https://qwe.shushuiji.net/dist/#/components/noticeDetail?id=221",
		"legal_url": "https://qwe.shushuiji.net/dist/#/components/noticeDetail?id=226"
},
	"home": {
	"with": "Sí.",
		"myMarkets": "Self - choice",
		"markets": "Trade Zone",
		"par": "par de monedas",
		"market": "",
		"price": "precio",
		"change": "up and down",
		"high": "the highest Price",
		"min": "precio más bajo",
		"volume": "volumen de negociación",
		"trade": "operaciones",
		"to trade": "to trade",
		"c1": "Global Digital Asset Allocation and Transaction Services",
		"c2": "Project Expansion and operation management systems across the World",
		"c3": "centros locales de servicios comerciales en varios países",
		"c4": "millones de usuarios que prestan servicios en más de 130 países",
		"c5": "SMART Chain Asset Evaluation Model",
		"c6": "sistema independiente y profesional de evaluación de la investigación sobre activos de la cadena de bloques",
		"c7": "seguir la cadena industrial a largo plazo y proporcionar el análisis de activos más autorizado y neutral",
		"c8": "One - Stop project progress Tracking and Information Disclosure System",
		"c9": "basado en 4 años de experiencia en seguridad y control de riesgos de activos digitales",
		"c10": "establecimiento de un mecanismo de pago anticipado",
		"c11": "establecimiento de un fondo de protección de los inversores",
		"c12": "multiplataforma terminal Transaction at any time and at any place",
		"c13": "override multiple Platforms of Ios, Android, Windows, Support full Business Function",
		"c14": "Hong Kong, Seúl, Singapur, Tokio",
		"c15": "centros de servicios en todo el mundo",
		"c16": "Trading Now",
		"c17": "Bienvenido a unirse a nuestro equipo, mejorar juntos, más allá de uno mismo, crear un mejor valor de vida",
		"access": "acceso a terminales multiplataforma",
		"cover": "override multiple Platforms of Ios, Android, Windows, Support full Business Function",
		"cooper": "Cooperative Agency",
		"vol": "cantidad 24h",
		"c01": "Global Leading digital Asset Trading Platform",
		"c02": "servicios seguros y fiables de comercio de activos digitales y gestión de activos para millones de usuarios en más de 130 países de todo el mundo",
		"c03": "seguro y confiable",
		"c04": "5 años de experiencia en servicios financieros de activos digitales",
		"c05": "Professional Distributed Architecture and Anti - DDoS attack System",
		"c06": "Global Ecological layout",
		"c07": "muchos países establecen centros de servicios de transacciones locales",
		"c08": "Creating a Block Chain Ecosystem with multi - Business form in one",
		"c09": "User First",
		"sweep": "Scan Code Download",
		"android": "Android",
		"pinput": "please enter your mail",
		"atrade": "registre una cuenta de estación global del Grupo NEWBIT para iniciar el viaje de transacción",
		"gcoin": "NEWBIT",
		"eamil1": "Business Cooperation mail",
		"eamil2": "Service mail",
		"kefu": "servicio al cliente en línea",
		"img1": "1_zh.png",
		"img2": "2_zh.png",
		"img3": "3_zh.png",
		"d1": "NEWBIT global Station, nearly 24 hours Trading < B > {number} < / B > USD",
		"d2": "servicios seguros y fiables de comercio de activos digitales y gestión de activos para millones de usuarios en más de 130 países de todo el mundo",
		"d3": "Top 3 in Global Trade Volumes",
		"d4": "una amplia gama de transacciones de liquidez fuertes",
		"d5": "Bolsa de seis años",
		"d6": "6 años de experiencia en servicios financieros de activos digitales",
		"d7": "Professional Distributed Architecture and Anti - DDoS attack System",
		"d8": "Escala de usuarios de 10 millones de niveles",
		"d9": "establecer un mecanismo de pago anticipado para establecer un fondo de protección de los inversores",
		"dsp1": "seguridad financiera",
		"dsp10": "All - around Financial Wind Control System and Theft - proof System, cold and cold Wallet, multi - SIGN system to guarantee fund Security",
		"dsp2": "lifting extremely Fast",
		"dsp20": "la recuperación de recarga se completará en un plazo máximo de 3 minutos, y la auditoría manual en línea de 24 horas se llevará a cabo para proteger a los clientes de las mejores oportunidades de inversión",
		"dsp3": "Global Services",
		"dsp30": "Global Business Services Network coverage, help you Invest global Encryption assets, and Global User Transaction",
		"dsp4": "selección estricta de activos",
		"dsp40": "seleccione estrictamente los elementos de encriptación de alta calidad y filtre el 80% de los elementos de alto riesgo para usted",
		"dsp5": "iniciar transacciones seguras en cualquier momento y en cualquier lugar",
		"dsp50": "override multiple Platforms of Ios, Android, Windows, Supporting full Business features"
	},
"loginTourist": {
		"touristLogin": "Tourist login Notices",
		"invite": "1. Todas las funciones están disponibles en la cuenta de visitantes",
		"invite1": "2. La cuenta de prueba no es recargable",
		"invite2": "3. La cuenta de prueba no se puede retirar",
		"invite3": "4. La cuenta de prueba está prohibida para recargar y retirar!",
		"invite4": "5.",
		"Autologin": "autologin"
	},
	"login": {
	"email": "mail login",
		"phone": "Phone login",
		"welcome": "Welcome to log on NEWBIT",
		"account": "número de cuenta",
		"forget": "forget the password",
		"dont": "not yet registered?",
		"invite": "regístrese ahora y comience a comerciar en las principales plataformas de negociación de activos digitales del mundo",
		"autologin": "autologin"
	},
	"register": {
	"phone": "número de teléfono móvil registrado",
		"email": "registro de correo electrónico",
		"phonenum": "please enter the phone number",
		"emailnum": "please enter the mailbox",
		"codenum": "please enter a validation Code",
		"notice": "Please see the user Protocol and check the option Box before Click to send the Authentication Code",
		"sendcode": "sendcode",
		"logpwd": "please enter a login password",
		"repwd": "Please confirm the password",
		"invitecode": "introduzca el Código de invitación",
		"xuan": "Optional",
		"agree": "I have Read and agreed",
		"pwd": "password between 6 - 16 bits, composed of numbers and letters",
		"mian": "User Agreement",
		"gologin": "cuenta existente, vaya a iniciar sesión"
},
	"forget": {
	"fpwd": "forget password",
		"getcode": "Get validation Code",
		"setpwd": "set password",
		"inpwd": "please enter a password",
		"repwd": "please enter the password Again"
},
	"lpwd": {
	"pwd": "Introduzca la contraseña",
		"setpwd": "set Fund password",
		"resetpwd": "reset Fund password",
		"oldpwd": "please enter the original password",
		"new pwd": "please enter a New password",
		"repwd": "please enter the password Again",
		"spwd": "set password",
		"rpwd": "restablecer contraseña"
	},
	"bdemail": {
	"bindemail": "Enlazar buzón de correo",
		"emailbox": "Buzón de correo",
		"surebind": "Confirmar Unión"
},
	"bdphone": {
	"bindphone": "Número de teléfono vinculado",
		"phone": "Número de teléfono móvil"
},
	"lgpwd": {
	"setpwd": "Cambiar contraseña",
		"renewpwd": "Introduzca una nueva contraseña de nuevo"
},
	"security": {
	"lever": "Nivel de Seguridad de su cuenta : ",
		"Wan": "mejorar más información, garantizar la seguridad de la cuenta",
		"invite": "mi código de invitación",
		"copy": "Copy invitation Link",
		"phone": "modificar el número de teléfono móvil",
		"email": "Bind mailbox",
		"logpwd": "login password",
		"zipwd": "contraseña del Fondo",
		"idcard": "Identity authentication",
		"suggest": "se recomienda que cambie su contraseña periódicamente para proteger su cuenta.",
		"notbaind": "unbound",
		"havebind": "Bind",
		"gobind": "unbind",
		"set": "Modify",
		"haveau": "authenticated",
		"reau": "rejected (Please re - authenticate)",
		"goau": "to authenticate",
		"auing": "under Audit",
		"goset": "to set",
		"accountset": "account Settings",
		"setlogin": "set login Authentication Mode",
		"setting": "Setting",
		"setmail": "Modify mailbox",
		"tbpw": "contraseña de moneda",
		"tip1": "por favor, establezca una contraseña especial para la presentación de monedas. Se recomienda que la contraseña de presentación sea diferente de la contraseña de inicio de sesión.",
		"google": "Google authenticator",
		"bindGoogle": "Bind Google authenticator",
		"tip2": "utilizado para la autenticación de Seguridad al iniciar sesión, retirar dinero, recuperar contraseñas y modificar la configuración de Seguridad.",
		"beforename": "por favor, autentifique el nombre real primero.",
		"authentication": "autenticación",
		"myaddress": "My address",
		"low": "Low",
		"fbphone": "Please Bind your phone number First",
		"fbemail": "Please Bind the mailbox First",
		"google key": "Google key"
	},
	"auth": {
	"auth": "Autenticación de la identidad",
		"back": "Return",
		"refause": "Reasons for rejection",
		"Nombre": "nombre",
		"idnum": "número de documento",
		"iMG": "Please UPLOAD THE Positive and Reverse of the document and the positive Photo of the document in hand.",
		"ising": "under Audit...",
		"ised": "authenticated!",
		"truname": "please enter the true Name",
		"truenum": "please enter the certificate number",
		"up": "submit",
		"note": "Please upload ID Positive and Reverse, the first is Positive, the second is Reverse."
	},
	"auth2": {
	"tip": "Por favor, tenga en cuenta que la información de autenticación del usuario no puede ser modificada después de la verificación. Por favor, rellene la información exacta y rellene la información personal cuidadosamente.",
		"name": "nombre autenticado",
		"id": "identificación personal",
		"number": "número de documento",
		"type": "tipo de documento",
		"content": "please enter content",
		"complete": "felicitaciones por completar la autenticación del nombre real",
		"nextstep": "identify the next step",
		"positive": "document illustrates positive",
		"mb": "sólo soporta jpg, PNG, no más de 4 MB",
		"click": "click to upload certificate photo",
		"back": "Back of the certificate",
		"hand": "upload hand certificate photo",
		"tip2": "por favor, proporcione una foto de su documento de mano, incluyendo notas sobre NEWBIT , la fecha y su firma en la misma foto. Asegúrese de que su cara es claramente visible y todos los detalles del documento son legibles",
		"face": "face is clear and Visible without obstruction",
		"clearly": "the picture is clearly visible",
		"notes": "text Notes with [NEWBIT]",
		"complete2": "felicitaciones por completar la autenticación",
		"complete3": "proceder a la siguiente autenticación de activos",
		"inaudit": "Authentication submitted successfully, under Audit",
		"notpass": "Authentication Trial failed",
		"again": "Please re - authenticate",
		"asset": "Asset Certification Audit",
		"nasset": "Asset Certification failed",
		"again 2": "Please re - authenticate assets",
		"complete4": "felicitaciones, la certificación de activos ha pasado",
		"reminder": "Warm Tip",
		"tip3": "cuando la valoración de su activo es superior a 20 BTC, puede realizar la autenticación de nombre real de tercer nivel, obtener más límite de retirada de moneda.",
		"tip 4": "por favor, proporcione una captura de pantalla o una foto de su cuenta NEWBIT, incluyendo su nombre de cuenta, UID, detalles de posición, hora actual. Toda la información es legible.",
		"screenshot": "upload Asset Screenshot information",
		"nowLever": "Current Certification Level",
		"authCard": "id",
		"hu": "pasaporte",
		"driverCard": "License",
		"other": "Other",
		"one": "One Level",
		"two": "secondary level",
		"three": "Three levels",
		"selectType": "Please Select the document type",
		"enterCardId": "Please fill the certificate number",
		"noWord": "el número de certificado no puede contener caracteres chinos",
		"enterRealName": "Please fill the true Name",
		"noNumber": "el nombre no puede contener un número",
		"noFour": "la imagen no puede exceder de 4M",
		"frontImg": "Please upload your document to the Front",
		"reverseImg": "Please fill the back of the upload certificate",
		"handImg": "Please hold a certificate photo",
		"assetImg": "Please upload Asset Screenshot"
},
	"legal": {
		"buy": "Buy",
		"sell": "sell",
		"shoper": "merchant",
		"number": "número",
		"limit": "límite",
		"price": "precio unitario",
		"pay": "pay Way",
		"do": "operación",
		"record": "Order Record",
		"traderecord": "Transaction Record",
		"tredetype": "tipo de transacción",
		"orderstatus": "orderstatus",
		"nofinish": "not completed",
		"finished": "completed",
		"ceiled": "cancelled",
		"payed": "pagado",
		"paywait": "pending payment",
		"waitplease": "please wait for Payment",
		"order complete": "Order complete",
		"orderceiled": "Order cancelled",
		"orderpayed": "the buyer has paid, please confirm after verifying",
		"total money": "total Transaction",
		"time": "time",
		"contact": "datos de contacto",
		"buyer": "buyer",
		"seller": "seller",
		"ordertime": "ordertime time",
		"reference": "número de referencia",
		"ceilorder": "cancel the transaction",
		"surepay": "confirm receipt",
		"confirmceil": "confirm cancel Transaction",
		"ceil": "cancel",
		"confirm": "confirmar",
		"paysure": "payment Confirmation",
		"otherpay": "Please confirm that the buyer has paid to you",
		"payplease": "Please Pay, please Pay to the following Account",
		"payedwait": "payed, waiting for the Merchant to confirm the collection",
		"tprice": "precio unitario de transacción",
		"tnumber": "Number of Transactions",
		"cardnum": "número de tarjeta bancaria",
		"wechat": "Wechat",
		"alipay": "Alipay",
		"account": "cuenta",
		"phone": "teléfono",
		"orderceil": "cancel Order",
		"mypayed": "I have payed, Click to confirm",
		"notceil": "por favor, no cancele la transacción si ha pagado al vendedor",
		"youpayed": "Please confirm that you have paid to the seller",
		"freeze": "malicious click will freeze the account directly",
		"transaction": "Transaction",
		"sellall": "sellall all",
		"buyall": "Buy all",
		"conactceil": "por favor, póngase en contacto con el vendedor en 15 minutos para el pago, más de 15 minutos se cancelará automáticamente",
		"autoceil": "auto - cancel",
		"placeorder": "orden de pedido",
		"inwant": "please enter to",
		"total": "total",
		"ceilnot": "si el comprador le ha pagado, por favor no cancele la transacción",
		"shopaccount": "Business Account",
		"bankName": "Banco de apertura de cuentas"
	},
	"legaltrade": {
	"paymoney": "Moneda de pago",
		"paytype": "Método de pago",
		"select": "Please select",
		"back": "Return",
		"desc": "Introduction",
		"connect": "contact the other party",
		"cankao": "Ref.",
		"zhang": "sucursal del banco depositario",
		"enterzhihang": "please enter the account Bank Sub - Branch",
		"money_code": "Collection Code",
		"confirm_tips": "Please confirm the operation in 15 minutes as soon as possible",
		"cancel_tips": "Note: after cancelled Three Times a day, the transaction of French currency will be restricted",
		"send": "enviar",
		"money change": "money change",
		"depth": "depth map"
	},
	"shop": {
	"myshop": "Mi tienda",
		"name": "Name",
		"tolegal": "The Legal currency to which it belongs",
		"retime": "tiempo de registro",
		"balance": "Balance comercial",
		"lockbalance": "saldo de congelación del comerciante",
		"goshop": "Enter the Shop",
		"allorder": "total singular",
		"dayorder": "orden del día 30",
		"comorder": "orden de terminación",
		"comrate": "Completion rate",
		"release": "release",
		"email": "mail authentication",
		"phone": "teléfono móvil",
		"truename": "real name authentication",
		"high": "Advanced Certification",
		"type": "Type",
		"my sell": "My sale",
		"mybuy": "My Buy",
		"abnormal": "exception",
		"seeorder": "ver orden",
		"choosetype": "Please Select type",
		"sell": "sell",
		"buy": "Buy",
		"cancel": "cancel",
		"pchoose": "Please choose",
		"minnum": "número mínimo de transacciones",
		"maxnum": "número máximo de transacciones",
		"ordercom": "ordercom completed",
		"orderceil": "Order cancelled",
		"buyerpayed": "buyerpayed by buyers, please verify and confirm",
		"sellerwait": "please wait to be confirmed by the seller",
		"receivepay": "confirm receipt",
		"onsell": "Online sale",
		"onbuy": "Buy Online",
		"fatotal": "total en moneda francesa",
		"sceil": "auto cancel after Seconds",
		"nomin": "no puede ser inferior al límite mínimo",
		"nomax": "no puede exceder el límite máximo",
		"nomaxnum": "no se puede exceder el número máximo"
	},
	"lgset": {
	"lgset": "Configuración de las transacciones en moneda francesa"
	},
	"market": {
	"quotation": "Mercado",
		"market": "market",
		"currency": "currency",
		"lastprice": "The latest Price",
		"change": "gain",
		"zoushi": "Trend map"
	},
	"center": {
		"xin": "Trading Limited Price",
		"shi": "Trading at market",
		"available": "disponible",
		"buyprice": "Buy Price",
		"sellprice": "sell Price",
		"buynumber": "Buying quantity",
		"sellnumber": "cantidad vendida",
		"trade total": "Transaction amount",
		"buyin": "Buying",
		"sellout": "sellout",
		"inbuy": "Buy",
		"outsell": "sell",
		"all": "all",
		"cdel": "Current Delegate",
		"hdeal": "Historical delegation",
		"pairs": "Transaction pairs",
		"direction": "dirección",
		"dealtotal": "total Delegate",
		"tprice": "precio medio de transacción",
		"price": "precio de transacción",
		"nada": "nada",
		"contran": "concluded",
		"newprice": "The latest Price",
		"alltrade": "All - site Trading",
		"volume": "volumen de negociación",
		"realtime": "Tiempo compartido",
		"or": "or",
		"start": "Start Trading",
		"hour": "cambio de fondos las 24 horas del día",
		"inflow": "Main streams",
		"outflow": "Outflow of main force",
		"inmoney": "24 entradas de capital",
		"outmoney": "24 salidas"
	},
	"account": {
		"mult100": "la cantidad debe ser un múltiplo entero de 100",
		"exchange": "exchange",
		"exchangeNum": "converted amount",
		"hideSm": "hide Small assets",
		"assets": "My assets",
		"taccount": "Contract Account",
		"let ransaction": "Second contract Account",
		"laccount": "cuenta en moneda francesa",
		"total assets": "total Asset conversion",
		"upaddress": "Currency address Management",
		"freezes": "freeze",
		"conversion": "conversión",
		"charging": "cargo",
		"charrecord": "Money Filling Record",
		"fstaus": "trace State",
		"view": "View",
		"with draw": "Raising money",
		"record": "Record",
		"copy": "Copy",
		"code": "2d Code",
		"cgaddress": "Currency address",
		"whaddress": "Currency address",
		"amount": "cantidad recargada",
		"picture": "Transfer Screenshot",
		"choose": "select pictures",
		"select": "Please Select pictures",
		"selected": "Selected",
		"submitRecharge": "submitting recargable information",
		"notice": "Warm Tip",
		"chaddress": "select currency address",
		"range": "Range",
		"havenum": "Number of receipts",
		"minnum": "el número mínimo de monedas es",
		"oncemaxnum": "the maximum number of money Raising for a single sum is",
		"daynum": "límite máximo de retirada de moneda del día",
		"uppwd": "Transaction password",
		"tibi": "Please fill the correct BTC - Omni currency address",
		"call": "para garantizar la seguridad de los fondos, cuando cambie la política de Seguridad de su cuenta, cambie la contraseña, revisaremos manualmente la retirada de dinero, por favor espere pacientemente a que el personal se ponga en contacto por teléfono o correo electrónico.",
		"a1": "no recargar ningún no a la dirección anterior",
		"a2": "activo, de lo contrario el activo no será recuperable.",
		"a3": "el método de llenado de moneda sólo soporta el envío simple. El llenado de moneda con otro método (enviar todo) no se puede cargar temporalmente, por favor entienda.",
		"a4": "después de recargar la dirección anterior, se requiere la confirmación de todo el nodo de red. Después de la confirmación de la red una vez, la cuenta se recibe, y la moneda se puede retirar después de la confirmación de la red seis veces.",
		"a5": "importe mínimo de recarga:",
		"a6": "los recargas inferiores a la cantidad mínima no se registrarán y no se devolverán.",
		"a7": "su dirección de recarga no cambiará a menudo, puede recargar repetidamente; si hay algún cambio, trataremos de informarle a través de anuncios web o correo electrónico.",
		"a8": "asegúrese de que su computadora y su navegador sean seguros para evitar que la información sea manipulada o divulgada.",
		"a9": "después de recargar a la dirección anterior, se requiere la confirmación de todo el nodo de red. Una vez que la Red ha sido confirmada, la cuenta es recibida, y la moneda se puede retirar después de dos veces la Red ha sido confirmada.",
		"a10": "por favor, espere pacientemente la revisión del personal después de enviar la información de recarga",
		"legal": "legal",
		"trade": "moneda",
		"lever": "contract",
		"choosecoin": "select currency",
		"huanum": "cantidad transferida",
		"mrecord": "financial records",
		"allRec": "total financial records",
		"transfer": "Transfer"
	},
	"set": {
		"secset": "Security Settings",
		"msgcer": "Information authentication",
		"turemsg": "para asegurarse de que su transacción se lleva a cabo correctamente, por favor complete su información real",
		"tname": "nombre real",
		"kname": "select account Bank",
		"cnum": "número de tarjeta bancaria",
		"ailipay": "Alipay Account",
		"wename": "apodo Wechat",
		"wenum": "cuenta Wechat",
		"bank_address": "Bank address",
		"ailipay_nickname": "Alipay nickname",
		"attestation": "Certification details",
		"setpay": "set Collection type",
		"remarks": "Remarks",
		"palipay": "please enter Alipay",
		"premarks": "please enter Remarks",
		"addalipaycode": "addalipay Collection Code",
		"pwenum": "please enter Wechat",
		"nameplease": "please enter a name",
		"addwenumcode": "add Wechat Collection Code",
		"add": "add"
	},
	"aD": {
		"adrecord": "Message Record",
		"release": "release",
		"reply": "REPLY",
		"none": "Now",
		"releaseit": "release message",
		"con": "Please fill the message",
		"up": "submit"
	},
	"lay": {
		"paccount": "please enter account number!",
		"notcode": "Validation Code cannot be empty!",
		"pwdliu": "password can not be less than six bits!",
		"slogin": "login Successful",
		"noemail": "su buzón no cumple con las reglas",
		"phonenot": "The phone number account you entered is not in conformity with the Rules!",
		"nophone": "please enter the phone number",
		"nemail": "please enter a mailbox",
		"inpwd": "please enter a password",
		"pwdlength": "passwords can only be between 6 - 16 bits",
		"pwdcom": "la contraseña debe consistir en números y letras",
		"repwd": "please enter the password Again",
		"twopwd": "The two passwords entered are inconsistent",
		"inprice": "por favor, introduzca el precio de compra",
		"noinprice": "el precio de compra no puede ser 0",
		"innumber": "please enter Buy quantity",
		"outprice": "introduzca el precio de venta",
		"outinprice": "el precio de venta no puede ser 0",
		"outnumber": "please enter the sales",
		"plogin": "Please log on",
		"revoke": "Estás seguro de que quieres cancelar?",
		"sure": "OK",
		"ceil": "cancel",
		"notopen": "not open at the time",
		"caddress": "Please choose the currency address",
		"cnumber": "please enter the number of currency raising",
		"cpwd": "please enter the currency Raising password",
		"minnum": "please enter the minimum number",
		"copys": "Copy Successful",
		"fcopy": "Copy failed",
		"recopy": "Please re - copy",
		"huanum": "please enter the transfer quantity",
		"truename": "verdadero nombre debe ser llenado",
		"pliu": "Please fill the message",
		"nameplease": "please enter a name!",
		"idplease": "please enter the certificate number!",
		"comid": "Please upload The Complete document!",
		"intermedio": "Medio",
		"high": "High",
		"strong": "Strong",
		"buymoney": "please enter the total amount to buy",
		"buynum": "por favor, introduzca la cantidad que desea comprar",
		"sellmoney": "please enter the total amount to be sold",
		"sellnum": "please enter the quantity to be sold",
		"tname": "Please authenticate the real name before Order",
		"payset": "Please set Collection Method First",
		"mpay": "Please choose payment method",
		"mprice": "please enter Price",
		"mnum": "please enter the quantity",
		"lessnum": "el número mínimo no puede ser inferior a",
		"morenum": "la cantidad no puede exceder",
		"notnum": "la cantidad no puede ser mayor que la cantidad total",
		"confirm": "Please confirm your Action!",
		"canshucuowu": "Parameter error",
		"chongzhiyitijiao": "se ha presentado una solicitud de recarga",
		"chongzhisqb": "falló la presentación de la solicitud de recarga",
		"nodi100": "el número de transferencias introducidas no puede ser inferior a 100",
		"pinputjine": "please enter the amount",
		"selcztype": "Please choose a recargable Way",
		"updateok": "updateok Successful",
		"ishuazhuan": "confirm Transfer?",
		"pinputokphone": "please enter the correct phone number",
		"usernofind": "User not found"
	},
	"c2c": {
		"myTc2c": "c2c I Trading",
		"pc2c": "release c2c",
		"mypc2c": "c2c I release",
		"timer": "time",
		"type": "Type",
		"price": "precio",
		"num": "cantidad",
		"total money": "total amount",
		"minNums": "Minimum number",
		"payType": "pay Way",
		"cancelp": "unrelease",
		"buy": "Buy",
		"sell": "sell",
		"currency": "currency",
		"onePrice": "precio unitario",
		"minTime": "tiempo mínimo",
		"pTime": "Publish time",
		"opera": "operación",
		"money": "cantidad",
		"enter": "please enter",
		"numIs": "quantity in",
		"rangeL": "entre",
		"moneyIs": "Amount in",
		"cancel": "cancel",
		"maxNum": "la cantidad no puede exceder",
		"minNum": "cantidad no inferior a",
		"maxMoney": "Amount can not exceed",
		"minMoney": "Amount must not be less than",
		"notMore": "No More at the time",
		"state": "State",
		"cancel Order": "cancel Order",
		"conPay": "confirm Payment",
		"conReceive": "acuse de recibo",
		"payed": "pagado",
		"completed": "completed",
		"canceled": "cancelado",
		"detail": "detalles",
		"payAccount": "pay Account",
		"selCu": "Please choose currency",
		"ailipay": "Alipay",
		"wx": "Wechat",
		"bankcard bancaria": "tarjeta bancaria",
		"pBuy": "Publish Buy",
		"pSell": "release sale",
		"enterPrice": "please enter Price",
		"enterNum": "please enter the quantity",
		"enterMinnum": "please enter a minimum number",
		"tips": "la cantidad mínima no puede ser mayor que la cantidad total",
		"name": "nombre",
		"wxname": "apodo Wechat",
		"bankName": "nombre del Banco",
		"connect": "contact information"
	},
	"forLegalPsw": {
	"fpwd": "Olvida la contraseña del Fondo.",
		"setpwd": "Establecer contraseña de fondos"
	},
	"cfc": {
	"balance": "Saldo",
		"account": "Cuenta de transferencia",
		"num": "Cantidad transferida",
		"pla_account": "Introduzca la cuenta de transferencia",
		"pla_num": "Introduzca el número de salidas"
	},
	"tpc": {
		"hot": "hot item",
		"all": "all projects",
		"nodata": "no data at the time",
		"search": "Search",
		"tips": "* El contenido mostrado en la página actual es llenado por el propietario del proyecto, y NEWBIT no es responsable de la autenticidad del contenido",
		"time": "release time",
		"total": "total Issue",
		"circulation": "circulación de fichas",
		"price": "precio de emisión",
		"web": "Official Website",
		"book": "Libro Blanco",
		"tool": "herramientas sociales",
		"recommend": "recommended Agency",
		"introduce": "Introducción al proyecto",
		"notice": "notice",
		"rr": "Project Research Report",
		"news": "News information",
		"author": "autor:",
		"browse": "navegar:"
	},
	"finance": {
		"selectState": "select locked state",
		"front": "before change",
		"after": "After Changes",
		"info": "Info"
	},
	"uc": {
		"code": "Código de invitación",
		"mymoney": "My Fund",
		"entrust": "Delegate Management",
		"deal": "Transaction Management",
		"pay": "pay Way",
		"id": "authentication",
		"safe": "Security Settings",
		"invitate": "invitación a un descuento",
		"finance": "registros financieros",
		"loginlog": "loginlog"
	},
	"usercenter": {
		"change": "Currency Account",
		"legal": "cuenta en moneda francesa",
		"lever": "Contract Account",
		"second": "Second contract Account"
	},
	"account2": {
		"list": "lista de monedas",
		"address": "etiqueta de dirección",
		"code": "Código de verificación",
		"addtime": "add time",
		"suretime": "confirm time",
		"explain": "explain"
	},
	"withdrawList": {
		"sel": "Currency Filtering",
		"submitTime": "submit time",
		"checkTime": "Audit time",
		"tibiNum": "Number of Currents raised",
		"applying": "in Application",
		"hash": "hash value",
		"agreeWait": "agreed to wait for chain",
		"lianSucc": "Success on the chain",
		"lianFail": "Failed on the chain",
		"bohui": "rejected",
		"zhanSucc": "Success in the station"
	},
	"wAddress": {
		"enterAddress": "please enter the currency address",
		"enterMemo": "please enter the address tag",
		"enterMark": "please enter Notes",
		"tips": "hot tips: usdt Please address with BTC currency, erc20 tokens Please address with ETH address",
		"addressList": "addresslist",
		"memo": "address tag",
		"del": "suprimir",
		"nospace": "the currency address cannot contain Spaces",
		"nospace02": "la etiqueta de dirección no puede contener espacios",
		"nospace03": "la etiqueta de dirección debe ser un número o una letra o una combinación de letras y números",
		"allNum": "la etiqueta de dirección debe ser un número puro",
		"bankNum": "Bank Card account number",
		"enterCardNum": "please enter the Bank Card account number",
		"add Card": "add Bank Collection Code",
		"addcode": "Please upload Collection Code pictures",
		"moreAdd": "Max upload",
		"pics": "pics"
	},
	"authDetail": {
		"payType": "Collection method",
		"noreceiveType": "You have not added Collection Method, please go to add!"
	},
	"identity": {
		"anquan": "account Security",
		"verify": "login authentication",
		"openVerify": "Open verify",
		"closeVerify": "Close verify",
		"enterGooole": "please enter the Google key",
		"verityType": "Please choose a validation Way"
	},
	"caiwu": {
	"lock": "Cerradura [UNK]",
		"nolock": "Desbloqueado [UNK]",
		"status": "Seleccione el Estado de bloqueo",
		"selCu": "Seleccione moneda [UNK]"
},
	"lang": {
		"en": "English",
		"kr": "한글",
		"jp": "日本語",
		"zh": "简体中文",
		"hk": "繁體中文",
		"spa": "España",
		"ara": "Arabia"
},
	"google": {
	"code": "Código de verificación de Google",
		"entergoogle": "Código de verificación de canciones"
},
	"transfer": {
	"select": "Por favor, seleccione transferencia",
		"change": "from the currency account to the French currency Account",
		"legal": "from French currency account to currency Account",
		"change_balance": "Currency account available",
		"pswType": "password is a 6 - bit pure number",
		"newPsw": "The New password must be a 6 - bit pure number",
		"change Phone": "Change phone number",
		"changeEmail": "Change mailbox",
		"emailCode": "introduzca el Código de verificación del buzón de correo",
		"newPhone": "please enter a New phone number",
		"newEmail": "please enter a New mail number",
		"phoneCode": "please enter the Phone validation Code",
		"selCu": "Please choose currency",
		"ok": "transferencia exitosa de fondos",
		"error": "transferencia de fondos fallida"
},
	"register01": {
		"country": "país al que pertenece",
		"selectCountry": "la información sobre la nacionalidad no puede modificarse después del registro, debe seleccionarse fielmente",
		"noAccount": "no account yet, go"
	},
	"invite": {
		"welcome": "Get High Returns Together",
		"effective": "efectiva",
		"invalid": "inválido",
		"list": "list",
		"commission": "Comisión recibida",
		"link": "My Exclusive Promotion Link",
		"share": "Copy link Sharing",
		"mypromotion": "My Promotion Record",
		"account": "número de cuenta del invitado",
		"nomore": "no more data at the time",
		"rules": "reglas de actividad",
		"tip1": "El invitado debe estar certificado en el nivel 2;",
		"tip2_0": "cada vez que un amigo acepta una invitación, se genera un cargo de transacción real",
		"tip2_1": "The return home of",
		"tip3": "Settlement is settled in accordance with the invitation System",
		"tip4_0": "la Comisión realmente recibida por el invitado será",
		"tip4_1": "a contract account returned to the inviter after the conversion Calculation",
		"tip4_2": "= volumen real de transacciones generadas * Porcentaje de comisiones * Porcentaje de reembolso de comisiones.",
		"tip5_0": "la Plataforma se llevará a cabo a las 08: 00 hora de Beijing todos los días al precio de cierre de las transacciones monetarias correspondientes",
		"tip5_1": "conversión"
	},
	"crowd": {
		"mujiNum": "cantidad recaudada",
		"cur": "Support currency",
		"endTime": "End time",
		"intro": "View Project Introduction",
		"can_edu": "The amount of this field can be purchased",
		"now_edu": "currently purchased amount",
		"my_edu": "The amount of my Subscription",
		"his": "History",
		"price": "precio de compra",
		"money": "importe de la suscripción",
		"yue": "saldo de la cuenta",
		"enterMoney": "please enter the purchase amount",
		"total": "total de esta compra",
		"rule": "reglas de suscripción",
		"startBuyTime": "START buytime",
		"endBuyTime": "End buytime"
	},
	"dep": {
		"dep_rec": "Currency Record",
		"dep": "Deposit Money Treasure",
		"tip": "el dinero ahorrado gana dinero, el dinero ocioso gana dinero. El dinero no es bueno, también puede producir tesoro!",
		"plan": "Plan de depósito de efectivo",
		"timer": "ciclo de compras rápidas",
		"rate": "rendimiento anualizado",
		"lock": "Locked",
		"join": "participating",
		"end": "la compra rápida ha terminado",
		"buy": "Buy Now",
		"date": "fecha de depósito",
		"day": "day",
		"total_money": "total amount for Buying",
		"sup_num": "cantidad restante",
		"top": "Currency limit",
		"one_num": "single START - up quantity",
		"num": "Number of Currents deposited",
		"min": "Min",
		"max": "Max",
		"yuji": "ingresos previstos",
		"qiang": "Buy - in",
		"depEndTime": "Currency deadline time",
		"jixiTime": "interest - based time",
		"lockTime": "unlock time",
		"enterNum": "please enter the pre - purchase quantity",
		"his": "Money Deposit History",
		"money": "cantidad de dinero depositado",
		"cur_name": "Currency Name",
		"do": "operación",
		"ti": "Extract",
		"suoTime": "Locking time",
		"leiji": "ingresos acumulados obtenidos",
		"rateNum": "The amount of charges should be deducted:",
		"ti_num": "cantidad extraíble:",
		"tiYet": "Extract or not",
		"to": "to Account?",
		"yes": "sí",
		"no": "no"
	},
	"vote": {
		"di": "artículo",
		"period": "período de votación",
		"explain": "Rule description",
		"free": "currently Free tickets",
		"have": "Hold",
		"num": "cantidad",
		"people": "Support number",
		"tickets": "Support votes",
		"detail": "ver detalles",
		"jiangli": "vote Award",
		"fen": "Split",
		"sup_people": "supported by People",
		"piao": "ticket",
		"vote": "voto",
		"enter": "please enter the vote number",
		"vote_cur": "vote on Currency",
		"paiming": "ranking",
		"intro": "Project Introduction",
		"taken": "taken profile and allocation",
		"total": "total de fichas",
		"cur_total": "total Current circulation",
		"price": "precio de emisión",
		"link": "links related",
		"web": "Official Website",
		"zh": "Chinese White Paper",
		"en": "libro blanco en inglés"
	},
	"lever": {
		"selHand": "select hands",
		"yijianping": "One Key Closing",
		"phands": "Please Select the number of hands",
		"yugu": "Estimating strong parities",
		"transaction": "transacción contractual",
		"nowentrust": "Current Delegate",
		"hisentrust": "Historical delegation",
		"lvchi": "posición contractual",
		"all": "all",
		"or": "or",
		"gotrade": "Start Trading",
		"tdrecord": "Transaction Record",
		"dealed": "transacted",
		"notdeal": "notdeal",
		"loading": "loading...",
		"entotal": "total de delegados",
		"canuse": "available",
		"std": "Trading at market",
		"xtd": "Trading Limited Price",
		"pprice": "please enter Price",
		"phand": "please enter the number of buyers",
		"pwd": "password",
		"ppwd": "please enter the transaction password",
		"psw": "contraseña de transacción",
		"equal": "igual",
		"times": "X",
		"timed": "Multiple",
		"handbuy": "Buy minimum",
		"handsell": "Minimum sell",
		"hands": "quantity",
		"ptimes": "Please choose multiple",
		"contractVal": "Transaction amount",
		"bail": "margen",
		"canBail": "margen disponible",
		"charge": "cargo por servicio",
		"domore": "Buy (make long)",
		"doshort": "sell (Short)",
		"dmore": "make long",
		"dshort": "Short",
		"sureOd": "confirm Order",
		"noless": "The number of Hands cannot be Lower",
		"nomore": "el número de manos no puede ser mayor que",
		"tdnum": "volumen de transacciones",
		"risk": "Risk rate",
		"all loss": "total Profit and Loss of Position",
		"onehouse": "One Key close",
		"type": "Type",
		"entrustPrice": "entrustprice",
		"open Price": "Open Price",
		"nowPrice": "Current Price",
		"close Price": "Close Price",
		"styPrice": "Stop Profit Price",
		"stsPrice": "Stop loss Price",
		"openTime": "Open time",
		"weituoTime": "tiempo de espera",
		"closeTime": "closetime",
		"revokeTime": "revoketime",
		"rate": "tasa",
		"nightFee": "nightfee",
		"loss": "Profit and loss",
		"set loss": "set up Stop loss",
		"expectProfit": "Expected Profit",
		"expectLoss": "Expected loss",
		"allClose": "all close",
		"moreClose": "Just more simple",
		"nullClose": "Only Flat single",
		"sureClose": "confirm close?",
		"thanZearo": "The value set must be greater than 0",
		"listin": "in the list",
		"tdin": "in Transaction",
		"closein": "in closing",
		"closed": "cerrado",
		"revoked": "revoked",
		"revokeOrder": "confirm revokeorder?",
		"ping": "Ping",
		"revoke": "revoke",
		"sureping": "Securing closure?",
		"thanzone": "Set Value must be greater than 0",
		"zheng": "please enter a integer",
		"buyMin": "el número de compradores no puede ser inferior a",
		"buyMax": "el número de compradores no puede ser mayor que",
		"enterBuy Price": "please enter Buy Price",
		"sellMin": "el número de manos vendidas no puede ser inferior a",
		"sellMax": "el número de manos vendidas no puede ser mayor que",
		"enterSell Price": "please enter the price of sale",
		"bestprice": "Market Best Price"
	},
	"second": {
		"transaction": "Second contract Transaction",
		"newprice": "The latest Price",
		"zhangfu": "gain",
		"hourday": "cantidad 24h",
		"conversion": "convertida en RMB"
	},
	"td1": {
		"do": "operación",
		"more": "load more",
		"loading": "loading",
		"nomore": "no more",
		"nodata": "no data at the time",
		"canceil": "cancel",
		"confirm": "confirmar",
		"status": "Estado"
	},
	"accountset": {
		"name": "por favor, rellene el nombre"
	},
	"sgr": {
		"sgrinfo": "SGR Demand Model Introduction",
		"lock": "Current total number of individual Locked sgrs:",
		"signup": "Register Now",
		"sgrget": "número de SGR bloqueados disponibles para el registro actual:",
		"cir": "cantidad de circulación en toda la estación",
		"loc": "Total Station Locked number",
		"richman": "richman Today",
		"user": "User",
		"amount": "cantidad ganada",
		"time": "time",
		"today release": "Number of individual SGR to be released Today",
		"releaserecords": "release Records",
		"invite": "invite Friends",
		"link": "My invitation Link",
		"copy": "Copy Link",
		"total lock": "Number of SGR locks received currently through invitation",
		"onelevel": "Number of First level invitations",
		"twolevel": "Number of Secondary invitations",
		"therelevel": "Three level invitations",
		"get": "Get",
		"rebate": "Invita a un descuento"
	},
	"miscro": {
		"quotation": "quotation",
		"transaction": "Second contract Transaction",
		"symbol": "Transaction pair",
		"balance": "balance",
		"miscroAccount": "Second contract Account",
		"trade": "in Transaction",
		"buy Price": "Buy Price",
		"finshPrice": "Transaction Price",
		"loss": "Expected Profit and loss",
		"times": "Countdown",
		"mode": "Trading Mode",
		"num": "cantidad de apertura",
		"rate": "Profit rate",
		"up": "Buy up",
		"down": "Buy down",
		"openNum": "please enter the quantity of Opening",
		"success": "Order Successful!",
		"c2c": "cuenta c2c",
		"complaint": "Complaint recommendations",
		"reply": "Please write your Questions and we will respond to you as soon as possible...",
		"complaintList": "List of complaint recommendations",
		"complaintReply": "reply:",
		"complaintDescription": "introduzca la descripción",
		"resetpwd": "modificar contraseña",
		"foundedOn": "created in",
		"commonProblem": "faq",
		"statement": "explicación",
		"about": "about kibiex",
		"everyone": "Everyone is CEO",
		"program": "kibiex Global Partnership Program",
		"myMine": "My Mine:",
		"friend": "My Mine Friend:",
		"myRank": "My Rank:",
		"accumulated": "Accumulated Commission:",
		"copyLinks": "Copy Promotion links",
		"moneyVein": "People Change Money lines",
		"example": "ejemplo de Comisión",
		"enterQuantity": "please enter quantity",
		"contractBalance": "Contract account balance",
		"submitRepeatedly": "in submission, please don 't submit repeatedly",
		"alipayCode": "paypal payment Code",
		"wechat Code": "Wechat payment Code",
		"real Price": "real Price",
		"currencyExchange": "exchange currency",
		"currencyExchangeIn": "exchange in currency",
		"cashableBalance": "saldo disponible:",
		"minimumCashable": "Minimum refutable:",
		"maximumCashable": "maximum refundable:",
		"automaticallys": "haga clic en el intercambio para completar automáticamente, no puede volver",
		"match": "Match",
		"title": "intercambio de activos",
		"holdAssets": "Hold assets",
		"dangerousCurrency": "bearing a risk of Crude currency",
		"convertibleQuantity": "cantidad convertible",
		"currencyExchanges": "Currency exchange rate:",
		"insuranceCurrency": "Insurance currency",
		"insuranceType": "tipo de seguro",
		"contractAsset": "Contract Asset Insurance amount",
		"warehouses": "Number of Insurance Warehouses",
		"availableAssets": "activos disponibles:",
		"insuredAssets": "activos asegurados:",
		"insuredAssets1": "activos asegurados",
		"insuranceAssets": "activos de seguros:",
		"purchaseInsurance": "Purchase Insurance",
		"insuranceClaims": "reclamaciones de seguros",
		"insuranceCancellation": "cancelación de seguros",
		"coinWallet": "Insurance Money Wallet",
		"bmbWallet": "aitb Wallet",
		"cumulativeCoins": "Cumulative Current:",
		"availableQuantity": "cantidad disponible:",
		"rawcurrency": "rawcurrency Record",
		"contract Insurance": "Contract Insurance",
		"ten Thousand": "Wan",
		"running Low": "balance Deficient",
		"purchase": "The amount you entered is not in conformity with the Rule, Your Purchasing amount is in",
		"rEACH": "to",
		"between": "between",
		"only Enter": "You can only Enter",
		"integersBetween": "integer between",
		"notReturned": "click to purchase Insurance, considered to understand the Insurance Treaty in detail and agree that the Insurance position will be automatically effective and cannot Return.",
		"settled": "Insurance claim settled when Insured assets Lost so that they could not Order",
		"profitable": "Times when the asset is Profitable",
		"terminated": "When Insurance is automatically uncontracted.",
		"automatically": "cuando el activo asegurado pierde el 50%, debe solicitar la liquidación de la reclamación del seguro, de lo contrario no puede negociar, cuando el beneficio es del 100%, el seguro se cancela automáticamente, la posición del seguro sólo permite pagar dos veces al día, más de dos veces, t + 1 paga",
		"termination": "After the Insurance is released, The Insurance Asset is convertible, and After the Insurance is released, it is considered as a default and the Insurance position is cleared automatically.",
		"just": "Just",
		"back": "Reverse",
		"lowestNumber": "The Minimum amount is",
		"confirmExchange": "confirm Exchange?",
		"contractValuation": "Contract account Asset Valuation",
		"secondValuation": "Second contract account Asset Valuation",
		"falshValuation": "Currency account Asset Valuation",
		"c2cValuation": "Asset Valuation of French currency Account",
		"recordWithdrawal": "fully raised currency Record",
		"category": "categoría",
		"safetyCenter": "Security Center",
		"safeText1": "registro, modificación de contraseñas y configuración de Seguridad para recibir mensajes de autenticación",
		"safeText2": "hay un riesgo de robo en su cuenta de Internet. Se recomienda que cambie su contraseña regularmente para proteger su cuenta.",
		"flashTrading": "Currency Exchange",
		"assetCenter": "Asset Center",
		"promotionCode": "mi código de promoción",
		"loginAgain": "login is Obsolete, please re - login",
		"text10": "precios en tiempo real basados en aitb",
		"text11": "documento de pago"
	},
	"td": {
		"buy": "Buy",
		"sell": "sell",
		"currency": "currency",
		"num": "cantidad",
		"time": "time",
		"limit": "límite",
		"price": "precio unitario",
		"method": "método de pago",
		"total": "total",
		"inwant": "please enter the desire",
		"do": "operación",
		"more": "load more",
		"nomore": "no more",
		"nodata": "no data at the time",
		"trade": "comercio",
		"buynum": "cantidad comprada",
		"sellout": "sellout",
		"buyin": "Buying",
		"all": "all",
		"allbuy": "Buy all",
		"allsell": "all Sell",
		"buytotal": "please enter the total amount to buy",
		"selltotal": "please enter the total amount to be sold",
		"buyallnum": "please enter the amount to buy",
		"sellallnum": "please enter the amount to be sold",
		"tradeTotal": "total Transaction",
		"doceil": "Second auto cancel",
		"place": "Order",
		"waitpay": "pending payment",
		"finished": "completed",
		"ceiled": "cancelled",
		"payed": "pagado",
		"nofinish": "not completed",
		"buyer": "buyer",
		"seller": "seller",
		"callWay": "contact information",
		"placeTime": "Order time",
		"renum": "Ref.",
		"canceil": "cancel",
		"confirm": "confirmar",
		"pwd": "please enter the transaction password",
		"coincode": "Currency Unit",
		"pcoin": "Please Select currency Unit",
		"examine": "por favor, espere pacientemente durante la auditoría del negocio"
	},
	"cuy": {
		"direction": "dirección",
		"total": "total",
		"price": "precio",
		"sell": "sell",
		"buy": "Buy",
		"all Station": "all Station Trading",
		"buy Price": "Buy Price",
		"buynum": "Buying quantity",
		"sell Price": "sell Price",
		"sellnum": "cantidad vendida",
		"tdPrice": "Transaction amount",
		"or": "or",
		"tdStart": "Start Trading",
		"pbPrice": "please enter Buy Price",
		"pbNum": "please enter Buy quantity",
		"psPrice": "please enter the sale Price",
		"psNum": "por favor, introduzca la cantidad vendida",
		"fixPrice": "Trading Limited Price",
		"mtrans": "Market Trading",
		"available": "disponible",
		"revoke": "revoke",
		"loading": "loading",
		"confirmCancel": "Estás seguro de que quieres deshacerlo?",
		"confirm": "aceptar",
		"cancel": "cancel",
		"nomore": "no hay más datos",
		"evelPrice": "precio medio de venta"
	},
	"iepn": {
		"htitle": "invite a sus amigos a ganar dinero juntos en la venta de grandes cantidades de dinero",
		"subtitle": "plataforma para vender un gran número de nuevas monedas, comprar inmediatamente, un número limitado de Oh",
		"netname": "red inteligente de prevención de epidemias",
		"plannum": "circulación prevista",
		"total num": "total Issue",
		"pushednum": "issued",
		"remindnum": "remaining number",
		"hotcommond": "hot recommendations",
		"ctitle": "Global Government Health Administration",
		"cdsp": "la atención de la salud está relacionada con los medios de vida de las personas y no puede eludir el papel del Gobierno. La transparencia de la cadena de la red de prevención episódica inteligente puede ayudar a las autoridades sanitarias mundiales a gestionar mejor las instituciones médicas, supervisar la salud nacional y hacer una asignación rápida y flexible de los recursos médicos.",
		"starttime": "starttime",
		"startprice": "precio de venta",
		"endtime": "End Time of sale",
		"seemore": "seemore",
		"baipishu": "Technical White Paper",
		"gobuy": "comprar"
	},
	"apitip":{
	"参数错误":"参数错误",
	"数据未找到":"数据未找到",
	"请选择需求类型":"请选择需求类型",
	"请选择交易方式":"请选择交易方式",
	"请填写单价":"请填写单价",
	"请填写数量":"请填写数量",
	"请选择币种":"请选择币种",
	"请输入正确的交易数量或价格":"请输入正确的交易数量或价格",
	"您还没有设置收款信息":"您还没有设置收款信息",
	"取消次数已超出":"取消次数已超出",
	"次,明天再发布":"次,明天再发布",
	"用户钱包不存在":"用户钱包不存在",
	"对不起，您的钱包余额不足":"对不起，您的钱包余额不足",
	"用户发布c2c交易法币出售，扣除法币余额":"用户发布c2c交易法币出售，扣除法币余额",
	"用户发布c2c交易法币出售,锁定余额增加":"用户发布c2c交易法币出售,锁定余额增加",
	"发布成功":"发布成功",
	"无此用户":"无此用户",
	"用户不存在":"用户不存在",
	"参数错误2":"参数错误2",
	"无此币种":"无此币种",
	"该币不是法币":"该币不是法币",
	"无此记录":"无此记录",
	"您还没有通过实名认证":"您还没有通过实名认证",
	"此条交易已完成":"此条交易已完成",
	"未找到该发布用户":"未找到该发布用户",
	"不能操作自己的":"不能操作自己的",
	"您无此钱包账号":"您无此钱包账号",
	"您的钱包已被锁定，请联系管理员":"您的钱包已被锁定，请联系管理员",
	"检测有未完成交易，请完成后再来！":"检测有未完成交易，请完成后再来！",
	"您的余额不足":"您的余额不足",
	"您的法币冻结资金异常,请查看您是否有正在进行的挂单":"您的法币冻结资金异常,请查看您是否有正在进行的挂单",
	"出售给商家法币,余额减少":"出售给商家法币,余额减少",
	"出售给商家法币,锁定余额增加":"出售给商家法币,锁定余额增加",
	"您发布的求购信息有用户出售啦，请去 APP 查看吧～":"您发布的求购信息有用户出售啦，请去 APP 查看吧～",
	"您发布的出售信息有用户购买啦，请去 APP 查看吧～":"您发布的出售信息有用户购买啦，请去 APP 查看吧～",
	"Operation successful，请联系商家确认订单":"操作成功，请联系商家确认订单",
	",错误位于第":"，错误位于第",
	",行":"，行",
	"用户信息不正确":"用户信息不正确",
	"该订单已操作过，请勿重复操作":"该订单已操作过，请勿重复操作",
	"对不起，您无权操作":"对不起，您无权操作",
	"buy":"买",
	"Operation successful，请联系卖家确认":"操作成功，请联系卖家确认",
	"Operation successful，订单已取消":"操作成功，订单已取消",
	"您今天的取消次数已超出":"您今天的取消次数已超出",
	"该订单已操作，请勿取消":"该订单已操作，请勿取消",
	"对不起，这不是您的发布信息":"对不起，这不是您的发布信息",
	"该订单还未付款或已经操作过":"该订单还未付款或已经操作过",
	"您不能确认该订单":"您不能确认该订单",
	"订单异常":"订单异常",
	"该用户没有此币种钱包":"该用户没有此币种钱包",
	"该买家没有此币种钱包":"该买家没有此币种钱包",
	"出售法币成功,扣除锁定余额":"出售法币成功,扣除锁定余额",
	"购买法币成功，增加法币余额":"购买法币成功，增加法币余额",
	"在":"在",
	"确认成功":"确认成功",
	"卖出法币成功":"卖出法币成功",
	"购买法币成功":"购买法币成功",
	"该发布信息下有交易产生无法删除":"该发布信息下有交易产生无法删除",
	"对不起，您无权撤销此记录":"对不起，您无权撤销此记录",
	"用户钱包不存在":"用户钱包不存在",
	"对不起，您的账户冻结资金不足":"对不起，您的账户冻结资金不足",
	"商家撤回发布法币出售":"商家撤回发布法币出售",
	"撤回成功":"撤回成功",
	"参数错误!":"参数错误!",
	"转账账户不存在!":"转账账户不存在!",
	"不能给自己转账!":"不能给自己转账!",
	"转账数量大于剩余数量!":"转账数量大于剩余数量!",
	"余额不足!":"余额不足",
	"转账数量不能小于":"转账数量不能小于",
	"转账数量不能大于":"转账数量不能大于",
	"通证转账成功!":"通证转账成功",
	"必填项信息不完整":"必填项信息不完整",
	"两次输入密码不一致":"两次输入密码不一致",
	"此用户不存在":"此用户不存在",
	"此交易账号已经设置":"此交易账号已经设置",
	"此账号已经存在":"此账号已经存在",
	"交易账号设置成功":"交易账号设置成功",
	"验证码错误":"验证码错误",
	"手机绑定成功":"手机绑定成功",
	"邮箱绑定成功":"邮箱绑定成功",
	"手势密码至少连接6个点":"手势密码至少连接6个点",
	"两次手势密码不相同":"两次手势密码不相同",
	"手势密码添加成功":"手势密码添加成功",
	"取消手势密码成功":"取消手势密码成功",
	"密码只能在6-16位之间":"密码只能在6-16位之间",
	"两次密码不一致":"两次密码不一致",
	"交易密码设置成功":"交易密码设置成功",
	"暂时还没有邀请排行榜，快去邀请吧":"暂时还没有邀请排行榜，快去邀请吧",
	"会员未找到":"会员未找到",
	"你已绑定，不可更改!":"你已绑定，不可更改!",
	"该地址已被绑定,请重新输入":"该地址已被绑定,请重新输入",
	"绑定成功!":"绑定成功",
	"暂无钱包":"暂无钱包",
	"请提交完整的信息":"请提交完整的信息",
	"请输入合法的身份证号码":"请输入合法的身份证号码",
	"该身份证号已实名认证过!":"该身份证号已实名认证过",
	"您已经申请过了":"您已经申请过了",
	"提交成功，等待审核":"提交成功，等待审核",
	"支付密码错误":"支付密码错误",
	"退出登录成功":"退出登录成功",
	"请输入支付密码":"请输入支付密码",
	"余额不足":"余额不足",
	"无需升级":"无需升级",
	"升级成功":"升级成功",
	"升级会员":"升级会员",
	"没有此用户":"没有此用户",
	"该地址别人已经绑定过了":"该地址别人已经绑定过了",
	"更新成功":"更新成功",
	"请重试":"请重试",
	"请把参数填写完整":"请把参数填写完整",
	"推荐用户不存在":"推荐用户不存在",
	"转入账户不能为空":"转入账户不能为空",
	"转入数量不能为空":"转入数量不能为空",
	"密码不能为空":"密码不能为空",
	"用户密码错误":"用户密码错误",
	"美丽链法币交易余额转入":"美丽链法币交易余额转入",
	"美丽链币币交易余额转入":"美丽链币币交易余额转入",
	"美丽链杠杆交易余额转入":"美丽链杠杆交易余额转入",
	"转入成功":"转入成功",
	"密码修改成功":"密码修改成功",
	"用户未找到":"用户未找到",
	"绑定成功":"绑定成功",
	"发送成功":"发送成功",
	"m月d日 H:i":"m月d日 H:i",
	"币未找到":"币未找到",
	"暂无数据":"暂无数据",
	"文件大小超出":"文件大小超出",
	"该文件已存在":"该文件已存在",
	"文件类型不对":"文件类型不对",
	"上传失败":"上传失败",
	"内容不能为空":"内容不能为空",
	"提交成功，我们会尽快给你回复":"提交成功，我们会尽快给你回复",
	"非法参数":"非法参数",
	"该币种不支持购买保险":"该币种不支持购买保险",
	"错误的金额！":"错误的金额",
	"不存在的险种！":"不存在的险种",
	"不存在的币种！":"不存在的币种",
	"已经购买了该币种的险种！":"已经购买了该币种的险种",
	"可用余额不足，无法购买！":"可用余额不足，无法购买",
	"购买成功！":"购买成功",
	"购买失败！原因：":"购买失败！原因：",
	"未找到该保险":"未找到该保险",
	"该保险正在处理中":"该保险正在处理中",
	"申请索赔失败":"申请索赔失败",
	"申请索赔成功！":"申请索赔成功",
	"超出今日索赔次数!":"超出今日索赔次数",
	"存在未平仓订单":"存在未平仓订单",
	"受保资产为零":"受保资产为零",
	"受保资产不符合可申请索赔条件1":"受保资产不符合可申请索赔条件1",
	"受保资产不符合可申请索赔条件2":"受保资产不符合可申请索赔条件2",
	"未知的险种类型":"未知的险种类型",
	"保险赔偿用户[清除受保金额]":"保险赔偿用户[清除受保金额]",
	"保险赔偿用户[赔偿受保金额]":"保险赔偿用户[赔偿受保金额]",
	"保险解约，扣除保险金额":"保险解约，扣除保险金额",
	"未知受保金额去向状态":"未知受保金额去向状态",
	"处理成功！":"处理成功",
	"处理失败：":"处理失败：",
	"该保险已失效":"该保险已失效",
	"该保险正在索赔处理中":"该保险正在索赔处理中",
	"解约失败，存在未平仓订单":"解约失败，存在未平仓订单",
	"保险解约，赔付金额":"保险解约，赔付金额",
	"保险解约，扣除受保金额":"保险解约，扣除受保金额",
	"保险解约，扣除保险金额":"保险解约，扣除保险金额",
	"解约成功！":"解约成功",
	"解约失败:":"解约失败:",
	"订单取消成功":"订单取消成功",
	"请选择需求类型":"请选择需求类型",
	"请选择交易方式":"请选择交易方式",
	"请填写单价":"请填写单价",
	"请填写数量":"请填写数量",
	"请填写最小交易数量":"请填写最小交易数量",
	"请选择币种":"请选择币种",
	"最小交易数量不能大于总数量":"最小交易数量不能大于总数量",
	"请填写最大交易量":"请填写最大交易量",
	"请填写正确的最大交易量":"请填写正确的最大交易量",
	"币种信息有误":"币种信息有误",
	"对不起，您不是该法币的商家":"对不起，您不是该法币的商家",
	"对不起，您的商家账户不足":"对不起，您的商家账户不足",
	"商家发布出售":"商家发布出售",
	"商家发布出售,冻结增加":"商家发布出售,冻结增加",
	"发布成功":"发布成功",
	"无此商家":"无此商家",
	"您不是商家":"您不是商家",
	"该币不是法币":"该币不是法币",
	"无此币种":"无此币种",
	"购买参数错误":"购买参数错误",
	"无此记录":"无此记录",
	"请填写购买额":"请填写购买额",
	"购买额请填写数字":"购买额请填写数字",
	"您还没有通过实名认证":"您还没有通过实名认证",
	"您还没有设置收款信息":"您还没有设置收款信息",
	"未完成单子超过3单，请完成后再操作!":"未完成单子超过3单，请完成后再操作!",
	"商家挂单状态异常,暂时不能交易":"商家挂单状态异常,暂时不能交易",
	"商家挂单剩余可交易数量不足":"商家挂单剩余可交易数量不足",
	"此条交易已完成":"此条交易已完成",
	"非法提交，数量必须大于0":"非法提交，数量必须大于0",
	"您低于最低限额":"您低于最低限额",
	"您高于最高限额":"您高于最高限额",
	"您高于最大限制数量":"您高于最大限制数量",
	"未找到该商家":"未找到该商家",
	"不能操作自己的":"不能操作自己的",
	"您无此钱包账号":"您无此钱包账号",
	"您的钱包已被锁定，请联系管理员":"您的钱包已被锁定，请联系管理员",
	"您的法币余额不足":"您的法币余额不足",
	"您的法币冻结资金异常,请查看您是否有正在进行的挂单":"您的法币冻结资金异常,请查看您是否有正在进行的挂单",
	"您出售数量大于商家剩余数量!":"您出售数量大于商家剩余数量",
	"出售给商家法币:扣除余额":"出售给商家法币:扣除余额",
	"出售给商家法币:增加冻结":"出售给商家法币:增加冻结",
	"您购买数量大于商家剩余数量!":"您购买数量大于商家剩余数量",
	",错误位于第":",错误位于第",
	"Operation successful，请联系商家确认订单":"操作成功，请联系商家确认订单",
	"行":"行",
	"该订单已操作过，请勿重复操作":"该订单已操作过，请勿重复操作",
	"请上传支付凭证":"请上传支付凭证",
	"对不起，您无权操作":"对不起，您无权操作",
	"Operation successful，请联系卖家确认":"操作成功，请联系卖家确认",
	"该订单已操作，请勿取消":"该订单已操作，请勿取消",
	"Operation successful，订单已取消":"操作成功，订单已取消",
	"对不起您不是商家":"对不起您不是商家",
	"对不起，您不是该商家":"对不起，您不是该商家",
	"该订单还未付款或已经操作过":"该订单还未付款或已经操作过",
	"对不起，您的商家冻结余额不足,当前余额:":"对不起，您的商家冻结余额不足,当前余额:",
	"您不能确认该订单":"您不能确认该订单",
	"订单异常":"订单异常",
	"该用户没有此币种钱包":"该用户没有此币种钱包",
	"法币交易:在商家":"法币交易:在商家",
	"购买法币成功":"购买法币成功",
	"法币交易:卖出成功":"法币交易:卖出成功",
	"确认成功":"确认成功",
	"法币交易:用户":"法币交易:用户",
	"向商家出售法币成功":"向商家出售法币成功",
	"法币交易:购买成功":"法币交易:购买成功",
	"此状态下无法下架":"此状态下无法下架",
	"对不起，您不是该法币的商家":"对不起，您不是该法币的商家",
	"发布下架成功,将不会再与新用户匹配":"发布下架成功,将不会再与新用户匹配",
	"必须下架后才可以撤销":"必须下架后才可以撤销",
	"已撤回发布":"已撤回发布",
	"当前发布状态无法撤销":"当前发布状态无法撤销",
	"当前发布剩余数量不足,无法撤销":"当前发布剩余数量不足,无法撤销",
	"该发布信息下有交易正在进行中,请等待交易结束再撤回":"该发布信息下有交易正在进行中,请等待交易结束再撤回",
	"对不起，您不是该法币的商家":"对不起，您不是该法币的商家",
	"对不起，您的商家账户冻结资金不足":"对不起，您的商家账户冻结资金不足",
	"撤回失败:减少冻结资金失败":"撤回失败:减少冻结资金失败",
	"商家撤回":"商家撤回",
	"撤回失败:增加余额失败":"撤回失败:增加余额失败",
	"撤回成功":"撤回成功",
	"该发布信息无异常":"该发布信息无异常",
	"该发布信息下有交易未完成，不能标记为异常":"该发布信息下有交易未完成，不能标记为异常",
	"该发布剩余数量不足,不法标记异常":"该发布剩余数量不足,不法标记异常",
	"当前发布状态无法标记异常":"当前发布状态无法标记异常",
	"处理异常失败:减少冻结资金失败":"处理异常失败:减少冻结资金失败",
	"处理异常失败:增加余额失败":"处理异常失败:增加余额失败",
	"商家处理异常":"商家处理异常",
	"缺少参数或传值错误":"缺少参数或传值错误",
	"指定交易对不存在":"指定交易对不存在",
	"您未开通本交易对的交易功能":"您未开通本交易对的交易功能",
	"手数必须是大于0的整数":"手数必须是大于0的整数",
	"手数不能低于":"手数不能低于",
	"手数不能高于":"手数不能高于",
	"选择倍数不在系统范围":"选择倍数不在系统范围",
	"您有正在平仓中的交易,暂不能进行买卖":"您有正在平仓中的交易,暂不能进行买卖",
	"交易类型错误":"交易类型错误",
	"该功能暂未开放":"该功能暂未开放",
	"限价交易价格必须大于0":"限价交易价格必须大于0",
	"当前没有获取到行情价格,请稍后重试":"当前没有获取到行情价格,请稍后重试",
	"限价交易卖出不能低于当前价":"限价交易卖出不能低于当前价",
	"限价交易买入价格不能高于当前价":"限价交易买入价格不能高于当前价",
	"钱包未找到,请先添加钱包":"钱包未找到,请先添加钱包",
	"余额不足,不能小于":"余额不足,不能小于",
	"(手续费:":"(手续费:",
	"提交失败":"提交失败",
	"扣除保证金失败":"扣除保证金失败",
	"扣除手续费失败":"扣除手续费失败",
	"此功能系统未开放":"此功能系统未开放",
	"止盈止损价格不能为0":"止盈止损价格不能为0",
	"找不到该笔交易":"找不到该笔交易",
	"买入(做多)止盈价不能低于开仓价和当前价":"买入(做多)止盈价不能低于开仓价和当前价",
	"买入(做多)止亏价不能高于开仓价和当前价":"买入(做多)止亏价不能高于开仓价和当前价",
	"卖出(做空)止盈价不能高于开仓价和当前价":"卖出(做空)止盈价不能高于开仓价和当前价",
	"卖出(做空)止亏价不能低于开仓价和当前价":"卖出(做空)止亏价不能低于开仓价和当前价",
	"设置成功":"设置成功",
	"设置失败":"设置失败",
	"数据未找到":"数据未找到",
	"无权操作":"无权操作",
	"交易状态异常,请勿重复提交":"交易状态异常,请勿重复提交",
	"平仓失败,请重试":"平仓失败,请重试",
	"交易异常，无法平仓":"交易异常，无法平仓",
	"买入方向传参错误":"买入方向传参错误",
	"提交成功,请等待系统处理":"提交成功,请等待系统处理",
	"未找到需要平仓的交易":"未找到需要平仓的交易",
	"交易不存在或已撤单,请刷新后重试":"交易不存在或已撤单,请刷新后重试",
	"撤单失败:用户钱包不存在":"撤单失败:用户钱包不存在",
	"撤单失败":"撤单失败",
	"撤单失败:变更状态失败":"撤单失败:变更状态失败",
	"撤单成功":"撤单成功",
	"请输入账号":"请输入账号",
	"请输入密码":"请输入密码",
	"手势密码错误":"手势密码错误",
	"账号已锁定！请联系管理员":"账号已锁定！请联系管理员",
	"两次密码不一致":"两次密码不一致",
	"密码只能在6-16位之间111111":"密码只能在6-16位之间",
	"验证码错误":"验证码错误",
	"账号已存在":"账号已存在",
	"请填写正确的邀请码":"请填写正确的邀请码",
	"请输入密码或确认密码":"请输入密码或确认密码",
	"输入两次密码不一致":"输入两次密码不一致",
	"验证码不正确":"验证码不正确",
	"账号不存在":"账号不存在",
	"修改密码成功":"修改密码成功",
	"请输入验证码":"请输入验证码",
	"验证成功":"验证成功",
	"下单失败:超过最大持仓笔数限制":"下单失败:超过最大持仓笔数限制",
	"当前未获取到行情":"当前未获取到行情",
	"尚未申购或理赔保险":"尚未申购或理赔保险",
	"申购的保险T+1生效":"申购的保险T+1生效",
	"受保资产为零":"受保资产为零",
	"受保资产小于等于可下单条件":"受保资产小于等于可下单条件",
	"您已超过持仓限制，暂停下单。":"您已超过持仓限制，暂停下单",
	"未知的险种类型":"未知的险种类型",
	"超过最大持仓数量限制":"超过最大持仓数量限制",
	"交易中的订单大于最大挂单数量":"交易中的订单大于最大挂单数量",
	"新闻不存在":"新闻不存在",
	"无此币种":"无此币种",
	"该币不是法币":"该币不是法币",
	"用户名不能为空":"用户名不能为空",
	"名称不能为空":"名称不能为空",
	"账号不能为空":"账号不能为空",
	"资产不能为空":"资产不能为空",
	"找不到此交易账号的用户":"找不到此交易账号的用户",
	"此用户还未通过实名认证":"此用户还未通过实名认证",
	"币种不存在":"币种不存在",
	"商家名称已存在":"商家名称已存在",
	"此法币":"此法币",
	"此用户已是此法币商家":"此用户已是此法币商家",
	"申请成为商家，扣除USDT":"申请成为商家，扣除USDT",
	"系统配置错误，请联系系统管理员":"系统配置错误，请联系系统管理员",
	"发送成功":"发送成功",
	"账号错误":"账号错误",
	"账号已存在":"账号已存在",
	"请填写账号":"请填写账号",
	"【BEF】若非您本人操作，请及时修改密码。":"【NEWBIT】若非您本人操作，请及时修改密码。",
	"发送失败":"发送失败",
	"ip参数不正确":"ip参数不正确",
	"邮箱不能为空":"邮箱不能为空",
	"短信验证码":"短信验证码",
	"您还没有交易记录":"您还没有交易记录",
	"非法操作,不能撤回非自己发布的信息":"非法操作,不能撤回非自己发布的信息",
	"非法操作:(":"非法操作:(",
	"取销卖出交易失败":"取销卖出交易失败",
	"取消买入交易,解除币币余额锁定":"取消买入交易,解除币币余额锁定",
	"取消买入交易,解除法币余额锁定":"取消买入交易,解除法币余额锁定",
	"用户未找到":"用户未找到",
	"取消成功":"取消成功",
	"取消卖出交易,解除交易余额锁定":"取消卖出交易,解除交易余额锁定",
	"取消卖出交易,解除交易余额锁定":"取消卖出交易,解除交易余额锁定",
	"类型错误":"类型错误",
	"交易不存在":"交易不存在",
	"资金不足":"资金不足",
	"数据未找到":"数据未找到",
	"请先添加钱包":"请先添加钱包",
	"您的币不足":"您的币不足",
	"提交卖出记录扣除":"提交卖出记录扣除",
	"你今天的交易额度已达到上限!":"你今天的交易额度已达到上限",
	"余额不足":"余额不足",
	"提交卖入记录扣除":"提交卖入记录扣除",
	"提交卖出记录(增加冻结)":"提交卖出记录(增加冻结)",
	"价格和数量必须大于0":"价格和数量必须大于0",
	"您的冻结资金异常，禁止挂卖":"您的冻结资金异常，禁止挂卖",
	"真实姓名必须填写":"真实姓名必须填写",
	"收款信息至少选填一项":"收款信息至少选填一项",
	"保存成功":"保存成功",
	"密码错误":"密码错误",
	"当前行情小于等于零!":"当前行情小于等于零!",
	"资产兑换,减少持有币法币:":"资产兑换,减少持有币法币:",
	"兑换数量大于持有资产!":"兑换数量大于持有资产!",
	"资产兑换,减少":"资产兑换,减少",
	"资产兑换,增加USDT杠杆币":"资产兑换,增加USDT杠杆币",
	"扣除手续费":"扣除手续费",
	"法币数量:":"法币数量",
	"兑换数量大于剩余数量!":"兑换数量大于剩余数量",
	"资产兑换成功!":"资产兑换成功",
	"参数错误!":"参数错误",
	"通证兑换,杠杆币增加":"通证兑换,杠杆币增加",
	"通证兑换杠杆币增加失败":"通证兑换杠杆币增加失败",
	"通证兑换成功!":"通证兑换成功",
	"必填项信息不完整":"必填项信息不完整",
	"两次输入密码不一致":"两次输入密码不一致",
	"此用户不存在":"此用户不存在",
	"此交易账号已经设置":"此交易账号已经设置",
	"此账号已经存在":"此账号已经存在",
	"交易账号设置成功":"交易账号设置成功",
	"邮箱绑定成功":"邮箱绑定成功",
	"手机绑定成功":"手机绑定成功",
	"手势密码添加成功":"手势密码添加成功",
	"两次手势密码不相同":"两次手势密码不相同",
	"取消手势密码成功":"取消手势密码成功",
	"手势密码至少连接6个点":"手势密码至少连接6个点",
	"密码只能在6-16位之间":"密码只能在6-16位之间",
	"两次密码不一致":"两次密码不一致",
	"交易密码设置成功":"交易密码设置成功",
	"暂时还没有邀请排行榜，快去邀请吧":"暂时还没有邀请排行榜，快去邀请吧",
	"会员未找到":"会员未找到",
	"该身份证号已实名认证过":"该身份证号已实名认证过",
	"你已绑定，不可更改!":"你已绑定，不可更改",
	"该地址已被绑定,请重新输入":"该地址已被绑定,请重新输入",
	"绑定成功!":"绑定成功",
	"请提交完整的信息":"请提交完整的信息",
	"提交成功，等待审核":"提交成功，等待审核",
	"退出登录成功":"退出登录成功",
	"请输入支付密码":"请输入支付密码",

	"参数错误":"参数错误",
	"无需升级":"无需升级",
	"兑换成功":"兑换成功",
	"暂无钱包":"暂无钱包",
	"支付密码错误":"支付密码错误",
	"余额不足":"余额不足",
	"升级成功":"升级成功",
	"更新成功":"更新成功",
	"请重试":"请重试",
	"推荐用户不存在":"推荐用户不存在",
	"请把参数填写完整":"请把参数填写完整",
	"转入账户不能为空":"转入账户不能为空",
	"密码不能为空":"密码不能为空",
	"转入数量不能为空":"转入数量不能为空",
	"密码修改成功":"密码修改成功",
	"暂时还没有添加币种":"暂时还没有添加币种",
	"此币种不存在":"此币种不存在",
	"已经有此提币地址":"已经有此提币地址",
	"添加提币地址成功":"添加提币地址成功",
	"此提币地址不存在":"此提币地址不存在",
	"您没有权限删除此地址":"您没有权限删除此地址",
	"删除提币地址成功":"删除提币地址成功",
	"输入的金额不能为负数":"输入的金额不能为负数",
	"您有正在进行中的杆杠交易,不能进行此操作":"您有正在进行中的杆杠交易,不能进行此操作",
	"钱包不存在":"钱包不存在",
	"划转成功":"划转成功",
	"操作失败:":"操作失败",
	"操作失败:Insufficient wallet balance":"操作失败：钱包余额不足",
	"Insufficient wallet balance":"钱包余额不足",
	"下单数量必须是10的整数倍":"下单数量必须是10的整数倍",
	"Parameter error":"Parameter error",
	"提币申请已成功，等待审核":"提币申请已成功，等待审核",
	"两次密码不一致":"两次密码不一致",
	"数量不能小于等于0":"数量不能小于等于0",
	"价格不能小于等于0":"价格不能小于等于0",
	"兑换数量必须大于0":"兑换数量必须大于0",
	"转账至交易所钱包":"转账至交易所钱包",
	"支付密码错误":"支付密码错误",
	"钱包已添加,请勿重复添加":"钱包已添加,请勿重复添加",
	"您还没有钱包":"您还没有钱包",
	"接收来自交易所的转账":"接收来自交易所的转账",
	"地址输入有误":"地址输入有误",
	"请输入正确的值":"请输入正确的值",
	"地址输入有误1":"地址输入有误1",
	"不能转账给自己":"不能转账给自己",
	"扣除卖方!":"扣除卖方",
	"扣除卖方":"扣除卖方",
	"买方增加":"买方增加",
	"买方增加!":"买方增加",
	"申请提币冻结余额":"申请提币冻结余额",
	"申请提币冻结余额!":"申请提币冻结余额",
	"币币 划转 币币":"币币 划转 币币",
	"币币 划转 合约":"币币 划转 合约",
	"币币 划转 秒合约":"币币 划转 秒合约",
	"币币 划转 法币":"币币 划转 法币",
	"法币 划转 法币":"法币 划转 法币",
	"法币 划转 币币":"法币 划转 币币",
	"法币 划转 合约":"法币 划转 合约",
	"法币 划转 秒合约":"法币 划转 秒合约",
	"秒合约 划转 秒合约":"秒合约 划转 秒合约",
	"秒合约 划转 币币":"秒合约 划转 币币",
	"秒合约 划转 法币":"秒合约 划转 法币",
	"秒合约 划转 合约":"秒合约 划转 合约",
	"合约 划转 合约":"合约 划转 合约",
	"合约 划转 币币":"合约 划转 币币",
	"合约 划转 秒合约":"合约 划转 秒合约",
	"合约 划转 法币":"合约 划转 法币",
	"提交卖出记录(增加冻结)":"提交卖出记录(增加冻结)",
	"提交卖出记录扣除":"提交卖出记录扣除",
	"平仓资金处理":"平仓资金处理",
	"请先勾选同意用户协议":"请先勾选同意用户协议",
	"您有未平仓的交易,操作禁止":"您有未平仓的交易,操作禁止",
	"操作失败:您有未平仓的交易,操作禁止":"操作失败:您有未平仓的交易,操作禁止",
	"购买失败，购买金额必须大于":"购买失败，购买金额必须大于",
	"并且小于":"并且小于",
	"用户购买保险":"用户购买保险",
	"您的验证码是：":"您的验证码是：",
	"发送失败:":"发送失败:",
	"提交":"提交",
	"杠杆交易,价格":"杠杆交易,价格",
	",扣除保证金":",扣除保证金",
	"杠杆交易,扣除手续费":"杠杆交易,扣除手续费",
	"修改邮箱":"修改邮箱",
	"您输入的邮箱或手机号不符合规则":"您输入的邮箱或手机号不符合规则",
	"验证码":"验证码",
	"秒":"秒",

	"扣除卖方":"扣除卖方",
	"买方增加":"买方增加",
	"申请提币冻结余额":"申请提币冻结余额",

	"卖方增加法币":"卖方增加法币",
	"后台审核充值通过":"后台审核充值通过",
	"申请提币扣除余额":"申请提币扣除余额",
	"秒合约订单平仓,盈利结算":"秒合约订单平仓,盈利结算",
	"秒合约订单,亏损结算":"秒合约订单,亏损结算",
	"秒合约下单扣除":"秒合约下单扣除",
	"下单扣除":"下单扣除",
	"%手续费":"%手续费",
	"秒合约下单扣除本金":"秒合约下单扣除本金",
	"停止售出":"停止售出",
	"买方扣除币币余额":"买方扣除币币余额",
	"买方扣除法币余额":"买方扣除法币余额",
	"买方扣除合约余额":"买方扣除合约余额",
	"买方扣除秒合约余额":"买方扣除秒合约余额",
	"用户中心":"用户中心",
	"注册成功,钱包状态：1":"注册成功,钱包状态：1",
	"注册成功,钱包状态：2":"注册成功,钱包状态：2",
	"注册成功,钱包状态：0":"注册成功,钱包状态：0",
	"调节":"调节",
	"账户余额:":"账户余额 ",
	"账户锁定余额:":"账户锁定余额 ",
	"秒合约":"秒合约",
	"法币":"法币",
	"杠杆":"杠杆",
	"币币":"币币",
	"请实名认证":"请实名认证",
	"您的实名认证还未通过！":"您的实名认证还未通过！",
	"暂不支持该币种":"暂不支持该币种",
	"充值":"充值",
	"余额不足":"余额不足",
	"操作成功":"操作成功",
	"取消成功":"取消成功",
	"提交成功":"提交成功",
	"最大下单数量不能超过:":"最大下单数量不能超过",
	"投资数额 的最小长度为":"投资数额 的最小长度为",
	"位":"位",
	"杠杆买入委托撤单,退回手续费":"杠杆买入委托撤单,退回手续费",
	"杠杆买入委托撤单,退回保证金":"杠杆买入委托撤单,退回保证金",
	"秒合约买入委托撤单,退回手续费":"秒合约买入委托撤单,退回手续费",
	"秒合约买入委托撤单,退回保证金":"秒合约买入委托撤单,退回保证金",
	"法币买入委托撤单,退回手续费":"法币买入委托撤单,退回手续费",
	"法币买入委托撤单,退回保证金":"法币买入委托撤单,退回保证金",
	"币币买入委托撤单,退回手续费":"币币买入委托撤单,退回手续费",
	"币币买入委托撤单,退回保证金":"币币买入委托撤单,退回保证金",
	"订单平仓结算,平局结算":"订单平仓结算,平局结算",
	"订单平仓,盈利结算":"订单平仓,盈利结算",
	"Account already exists":"账户已存在",
	"投资数额 字段必须填写":"投资数额 字段必须填写",
	"提币成功":"提币成功",
	"Operation successful":"操作成功",
	"加载中...":"加载中...",
	"加载更多":"加载更多",
	"没有更多了...":"没有更多了...",
	"确定平仓？":"确定平仓？",
	"发行量":"发行量",
	"请登录":"请登录",
	"开始申购":"开始申购",
	"申购币种":"申购币种",
	"开始申购时间":"开始申购时间",
	"预计上线时间":"预计上线时间",
	"结束申购时间":"结束申购时间",
	"项目预热":"项目预热",
	"公布结果":"公布结果",
	"USDT余额不足":"USDT余额不足",
	"BTC余额不足":"BTC余额不足",
	"ETH余额不足":"ETH余额不足",
	"申购成功":"申购成功",
	"兑换成功":"兑换成功",
	"全部":"全部",
	"立即申购":"立即申购",
	"结束申购":"结束申购",
	"申购周期":"申购周期",
	"项目详情":"项目详情",
	"请输入申购数量":"请输入申购数量",
	"申购确认":"申购确认",
	"确定":"确定",
	"取消":"取消",
	"您计划申购":"您计划申购",
	"BEF钱包未找到":"BEF钱包未找到",
	"USDT钱包未找到":"USDT钱包未找到",
	"BTC钱包未找到":"BTC钱包未找到",
	"ETH钱包未找到":"ETH钱包未找到",
	"USDT 兑换 BEF ":"USDT 兑换 BEF ",
	"BTC 兑换 BEF ":"BTC 兑换 BEF ",
	"ETH 兑换 BEF ":"ETH 兑换 BEF ",
	"申购历史":"申购历史",
	"认购价格":"认购价格",
	"认购数量":"认购数量",
	"认购时间":"认购时间",
	"结束时间":"结束时间",
	"状态":"状态",
	"解锁":"解锁",
	"已完成":"已完成",
	"已锁仓":"已锁仓",
	"暂无记录":"暂无记录",
	"操作":"操作",
	"价值":"价值",
	"认购币种":"认购币种",
	"申购记录":"申购记录",
	"可用余额不足":"可用余额不足",
	"申购数量异常":"申购数量异常",
	"认购异常":"认购异常",
	"认购成功":"认购成功",
	"下单数量必须是100的整数倍":"下单数量必须是100的整数倍",
	"认购解锁到账":"认购解锁到账",
	"充值自动到账":"充值自动到账",
	"发送失败。未包含正确的手机号":"发送失败。未包含正确的手机号",
	"发送失败。余额不足":"发送失败。余额不足",
	"认购付款":"认购付款",
	"当前价值":"当前价值",
	"解锁时间":"解锁时间","天":"天","倒计时":"倒计时",
	"预计收益":"预计收益",
	"资产记录":"资产记录",
	"知道了":"Lo tengo.",
	"冻结剩余时间":"冻结剩余时间",
	"小时":"小时",
	"币币[锁定]":"币币[锁定]",
	"aaaaaaaaaaaa":"1111111"
}
}

