export default {
	"siteName": "NEWBIT",
	"scan": "scan",
	"exchangeRate": "ExchangeRate",
	"none": "",
	"code": "Verification",
	"send": "Verification",
	"resend": "Resend in {number} second",
	"welcome": "Hi,Welcome to NEWBIT",
	"pwd": "Password",
	"accounts": "Account",
	"registers": "Sign up",
	"inMobile":"Please give feedback on the mobile terminal",
	"regiterStep1": "Sign up now",
	"regiterStep2": "Save info",
	"xieyi": "I have read and agree to the",
	"xieyi2": " \"User Agreement\"",
	"transferFromChange":"Transfer from a currency account",
	"transferChange":"Transfer to a currency account",
	"transferOk":"Transfer completed",
	"xieyi1": {
	"first": "Nationality cannot be modified after registered",
		"second": "The verification email may be judged as spam, please check",
		"third": "Please keep your account information and password properly",
		"fourth": "Do not use the same login password as other websites",
		"fifth": ""
},
	"confirm": "Confirm",
	"logins": "Log in",
	"notice": "The Announcement",
	"more": "To Load More",
	"nomore": "No more",
	"time": "Time",
	"price": "Price",
	"number": "Amount",
	"info": "Info",
	"link": "Link",
	"cfprice": "ICO price",
	"totalc": "Total circulation",
	"bname": "Coin Name",
	"query": "Block Explorer",
	"dealed": "Dealed",
	"notdeal": "Not Deal",
	"handicap": "Order book",
	"rtt": "Market trades",
	"timescreen": "Sorted by Time",
	"dealscreen": "Sorted by Deal",
	"dirscreen": "Sorted by Direction",
	"to": "to",
	"startdate": " Start Date",
	"overdate": "End Date",
	"do": "Operate",
	"revoke": "Revoke",
	"nodata": "No data",
	"loading": "Loading",
	"status": "Status",
	"rate": "Fee rate",
	"back": "Back",
	"alipay": "Alipay",
	"wechat": "Wechat",
	"bankcard": "bankcard",
	"inpCur": "Search",
	"minAm": "Minimum quantity",
	"regidters": "Sign up",
	"alltread": "Total station",
	"treadnum": "Number",
	"treaddetail": "Record",
	"tnum": "volume",
	"lastweek": "week",
	"reload": "Refresh",
	"dealtime": "Time",
	"way": "Entrust",
	"torg": "exchange",
	"dealno": "number",
	"ttotal": "Turnover",
	"auto": "Entrust",
	"sum": "Total",
	"traedoff": "The deal is off the shelf",
	"piece": "Cont",
	"hand": "Hand",
	"pbuynum": "Amount (Cont)",
	"bestsell": "Sell at market price",
	"lowbuy": "以Market buying",
	"transin": "Enter",
	"transout": "Out",
	"tansinmoney": "Gold entry",
	"tansoutmoney": "Gold yield",
	"from": "from",
	"jingdu": "accuracy",
	"m": "min",
	"day": "day",
	"week": "week",
	"year": "wear",
	"mon": "mon",
	"hour": "hour",
	"header": {
	"quotation": "Quotation",
		"home": "Home",
		"c2c": "Fiat",
		"match": "Contract Contest",
		"bbs": "Forum",
		"currency": "Exchange",
		"assets": "My Assets",
		"setting": "Safety Setting",
		"help": "Help",
		"complaint": "Complaint",
		"in": "Log in",
		"shop": "My Shop",
		"up": "Sign up",
		"inTourist": "Visitor login",
		"current": "Current Entrust",
		"his": "History Entrust",
		"code": "Invitation Code",
		"out": "Log out",
		"orders": "Orders",
		"center": "My Center",
		"pro": "Project Info",
		"cun": "Coin Bank",
		"vote": "Listing Vote",
		"sgr": "SGR Demand",
		"inset": "Receivables",
		"tradelog": "Transaction log",
		"fastrecharge": "Quick recharge"
},
	"footer": {
	"hxex": "NEWBIT Introduction",
		"legalTrade": "Fiat",
		"tool": "Link",
		"about": "About",
		"join": "Join Us",
		"connect": "Connect us",
		"download": "APP Download",
		"explain": "Terms",
		"xieyi": "User Agreement",
		"yinsi": "Privacy Policy",
		"legal": "Legal Statement",
		"feilv": "Trade Fee",
		"notice": "Notice",
		"nhelp": "Help",
		"support": "Support",
		"workorder": "Submit request",
		"apply": "Apply for Listing",
		"gonggao": "Announcement",
		"email_pre": "Customer service:",
		"email_info": "Email：btchedge8899@gmail.com",
		"address": "Singapore© 2013-2020 NEWBIT\t",
		"community": "Community",
		"wechat": "Wechat",
		"weibo": "WeiBo",
		"email": "Business email",
		"feedback": "Problem feedback",
		"about2": "Company profile",
		"coin": "Introduction to digital assets",
		"service": "Service",
		"apply_url": "https://qwe.shushuiji.net/dist/#/components/noticeDetail?id=196",
		"feedback_url": "https://qwe.shushuiji.net/dist/#/components/noticeDetail?id=201",
		"about2_url": "https://qwe.shushuiji.net/dist/#/components/noticeDetail?id=207",
		"coin_url": "https://qwe.shushuiji.net/dist/#/components/noticeDetail?id=212",
		"yinsi_url": "https://qwe.shushuiji.net/dist/#/components/noticeDetail?id=217",
		"feilv_url": "https://qwe.shushuiji.net/dist/#/components/noticeDetail?id=222",
		"legal_url": "https://qwe.shushuiji.net/dist/#/components/noticeDetail?id=227"
},
	"home": {
	"hourday": "24H Vol",
		"with": "with",
		"myMarkets": "Favor",
		"markets": "Markets",
		"pair": "Pair",
		"market": "Market",
		"price": "Price",
		"change": "Change",
		"high": "High",
		"min": "Low",
		"volume": "Volume",
		"trade": "Trade",
		"toTrade": "Trade now",
		"c1": "Globalized Digital Asset allocation and Trading Services",
		"c2": "Project Development and Operation Management system all over the world",
		"c3": "Local trading service centres in multiple countries",
		"c4": "Serving millions of users in more than 130 countries",
		"c5": "SMART-ChainAsset evaluation model",
		"c6": "Research and Evaluation system of Block chain assets for Independent Professionals",
		"c7": "Tracking the industrial chain for a long time and providing the most authoritative and neutral asset analysis",
		"c8": "One-stop Project Progress tracking and Information Disclosure system",
		"c9": "Based on 4 years of experience in digital asset security risk control",
		"c10": "Establishment of advance compensation mechanism",
		"c11": "Establishment of an investor protection fund",
		"c12": "Multi-platform terminal trade anytime, anywhere",
		"c13": "Covers multiple platforms of iOS,Android,Windows to support full business functions",
		"c14": "Hong Kong Seou Singapore Tokyo",
		"c15": "Service centers all over the world",
		"c16": "Trade now",
		"c17": "Welcome to join our team, improve together, go beyond yourself and create better value in life",
		"access": "Multi-platform terminal access",
		"cover": "Covers multiple platforms of IOS,Android,Windows to support full business functions",
		"cooper": "cooperative institution",
		"vol": "24H quantity",
		"c01": "The world's leading digital asset trading platform",
		"c02": "Provide secure, reliable digital asset trading and asset management services to millions of users in more than 130 countries around the world",
		"c03": "Safe and trustworthy",
		"c04": "5 years experience in digital asset financial services",
		"c05": "Professional distributed Architecture and Anti-DDOS attack system",
		"c06": "Global ecological layout",
		"c07": "Local trading service centres established in several countries",
		"c08": "Building a Block chain Biosphere with Multi-business form as one",
		"c09": "User first",
		"sweep": "Scan code download",
		"android": "Android",
		"pinput": "Please enter your email",
		"atrade": "Start Your Cryptocurrency Journey Today",
		"Gcoin": "NEWBIT",
		"eamil1": "Business cooperation mailbox",
		"eamil2": "Customer service mailbox",
		"kefu": "Online customer service",
		"img1": "1_en.png",
		"img2": "2_en.png",
		"img3": "3_en.png",
		"d1": "NEWBIT Global station, nearly 24 hours trading volume<b>{number}</b>USD",
		"d2": "Provide secure, reliable digital asset trading and asset management services to millions of users in more than 130 countries around the world",
		"d3": "Top 3 trading volume in the world",
		"d4": "Strong liquidity transaction range",
		"d5": "Six year old exchange",
		"d6": "6 years of experience in digital asset financial services",
		"d7": "Professional distributed architecture and anti DDoS Attack System",
		"d8": "Scale of tens of millions of users",
		"d9": "Establishing the mechanism of compensation in advance and establishing investor protection fund",
		"dsp1": "Financial security",
		"dsp10": "Comprehensive financial risk control system and anti-theft system, hot and cold wallet, multi signature system to ensure the safety of funds",
		"dsp2": "Fast charging",
		"dsp20": "Recharge withdrawal can be completed in 3 minutes at the earliest, and 24h manual online audit can protect customers from missing the best investment opportunities",
		"dsp3": "Global services",
		"dsp30": "Global business services network coverage helps you invest in global encryption assets and trade with global users",
		"dsp4": "Strictly select assets",
		"dsp40": "Strictly select high-quality encryption projects, and filter 80% high-risk projects for you",
		"dsp5": "Open secure transactions anytime, anywhere",
		"dsp50": "It covers IOS, Android and windows platforms and supports full-service functions"
},
	"login": {
	"email": "Mailbox login",
		"phone": "Mobile phone login",
		"welcome": "Welcome Log in NEWBIT",
		"account": "account number ",
		"forget": "Forget the password",
		"dont": "Not registered yet?",
		"invite": "Register immediately and start trading on the world's leading digital asset trading platform",
		"autologin": "Auto login"
},
	"register": {
	"phone": "Phone",
		"email": "EMail",
		"phonenum": "Please enter your phone number",
		"emailnum": "Please enter the email",
		"codenum": "Please enter a passcode",
		"notice": "Please view the user agreement and check the option box before you can click on the send verification code",
		"sendcode": "Verification",
		"logpwd": "Please enter the login password",
		"repwd": "Please confirm the password",
		"invitecode": "Please enter the invitation code",
		"xuan": "Selective filling",
		"agree": "I have read and agree",
		"pwd": "The password is between 6 and 16 bits, consisting of numbers and letters",
		"mian": "《User Agreement》",
		"gologin": "Already have an account, go to login"
},
"loginTourist": {
		"touristLogin" : "Visitor login instructions",
		"invite": "1. All functions are available for the tourist account",
		"invite1": "2. The trial account cannot be recharged",
		"invite2": "3. The trial account cannot be withdrawn",
		"invite3": "4. Recharge and withdrawal of trial account are prohibited！",
		"invite4": "5. ",
		"autologin": "automatic logon"
	},
	"forget": {
	"fpwd": "Forget the password",
		"getcode": "Verification",
		"setpwd": "Set the password",
		"inpwd": "Enter Your password",
		"repwd": "Please enter your password again"
},
	"lpwd": {
	"pwd": "Enter Your password",
		"setpwd": "Set the fund password",
		"resetpwd": "Reset fund password",
		"oldpwd": "Please enter the original password",
		"newpwd": "Please enter a new password",
		"repwd": "Please enter your password again",
		"spwd": "Set the password",
		"rpwd": "Reset password"
},
	"bdemail": {
	"bindemail": "Bind email",
		"emailbox": "Email address",
		"surebind": "EMail verification"
},
	"bdphone": {
	"bindphone": "Bind cell phone number",
		"phone": "cell-phone number"
},
	"lgpwd": {
	"setpwd": "Modify the password",
		"renewpwd": "Please enter the new password again"
},
	"lever": {
	"selHand": "Amount",
		"phands": "Amount",
		"transaction": "Contracts",
		"nowentrust": "Pending order",
		"hisentrust": "History order",
		"lvchi": "Position",
		"all": "all",
		"or": "or",
		"gotrade": "Start trade",
		"tdrecord": "Transacation history",
		"dealed": "Dealed",
		"notdeal": "Not dealed",
		"loading": "Loading...",
		"entotal": "Order amount",
		"canuse": "Available",
		"std": "Market",
		"xtd": "Limit",
		"pprice": "Please enter price",
		"phand": "Buy amount",
		"pwd": "password",
		"ppwd": "Please enter trades password",
		"psw": "Trade password",
		"equal": "equal",
		"times": "X",
		"timed": "Leverage",
		"handbuy": "Minimum buy ",
		"handsell": "Minimum sell ",
		"hands": "Amount",
		"ptimes": "Please select leverage",
		"contractVal": "Total",
		"bail": "Bond",
		"canBail": "Available Fund",
		"charge": "Fee",
		"domore": "Buy/Long",
		"doshort": "Sell/Short",
		"dmore": "Long",
		"dshort": "Short",
		"sureOd": "Order place confirmation",
		"noless": "No less than",
		"nomore": "No more than",
		"tdnum": "Transacation amount",
		"risk": "Risk rate",
		"allloss": "All P/L",
		"onehouse": "Close",
		"type": "Type",
		"entrustPrice": "Entrust price",
		"openPrice": "Open price",
		"nowPrice": "Current price",
		"closePrice": "Close Price",
		"styPrice": "Sty price",
		"stsPrice": "Sts price",
		"openTime": "Open time",
		"weituoTime": "Commission time",
		"closeTime": "Close time",
		"revokeTime": "Revoke Time",
		"rate": "Fee",
		"nightFee": "Night fee",
		"loss": "P/L",
		"setloss": "Stop/Limit",
		"expectProfit": "Expect profit",
		"expectLoss": "Expect loss",
		"allClose": "All Close",
		"moreClose": "More close",
		"nullClose": "Null close",
		"sureClose": "Are you sure you want to close？",
		"thanZearo": "Number must more than 0",
		"listin": "Listing",
		"tdin": "Trading",
		"closein": "Closing",
		"closed": "Closed",
		"revoked": "Revoked",
		"revokeOrder": "Are you sure you want to revoke order？",
		"ping": "Close",
		"revoke": "revoke order",
		"sureping": "Are you sure you want to close？",
		"thanzone": "Number must more than 0",
		"zheng": "Please input a integer",
		"buyMin": "Buy min",
		"buyMax": "Buy max",
		"enterBuyPrice": "Please input buying price",
		"sellMin": "Sell min",
		"sellMax": "Sell max",
		"enterSellPrice": "Please input selling price",
		"bestprice": "Market optimal price"
},
	"security": {
	"lever": "Your account security level : ",
		"wan": "Add more information to improve account security",
		"invite": "My invitation code",
		"copy": "Copy invitation code",
		"phone": "Modify the cell phone number",
		"email": "Bind mailbox",
		"logpwd": "Login password",
		"zipwd": "Trade password",
		"idcard": "Verification",
		"suggest": "It is recommended that you change your password regularly to protect your account.",
		"notbaind": "Not binded",
		"havebind": "Already binded",
		"gobind": "Go bind now",
		"set": "Modify",
		"haveau": "Verified",
		"reau": "Rejected (please re-vertify)",
		"goau": "To verify",
		"auing": "Verification in progress",
		"goset": "Setting",
		"accountset": "Account setup",
		"setlogin": "Set login verification mode",
		"setting": "set up",
		"setemail": "Modify mailbox",
		"tbpw": "Withdrawal password",
		"tip1": "Please set the special password for withdrawal. It is recommended that the withdrawal password be different from the login password.",
		"google": "Google verifier",
		"bindGoogle": "Bind Google verifier",
		"tip2": "Used to log in, withdraw coins, retrieve passwords, modify security settings for security verification.",
		"beforename": "Please carry on identity verification first.",
		"authentication": "verify",
		"myaddress": "My address",
		"low": "low",
		"fbPhone": "Please bind the cell phone number first",
		"fbEmail": "Please bind the mailbox first",
		"googleKey": "Google key"
},
	"auth": {
	"auth": "Identity verification ",
		"back": "back",
		"refause": "refause",
		"name": "name",
		"idnum": "ID card number",
		"img": "Please upload the front and back of the ID card and your photo with the card in your hand.",
		"ising": "In the process of examination...",
		"ised": "verified!",
		"truename": "Please enter your name",
		"truenum": "Please enter the ID card number",
		"up": "Submit",
		"note": "Please upload your ID card, passport and driver's license photos."
},
	"auth2": {
	"tip": "Please note that the user information cannot be modified once verified, please be sure to fill in the accurate information truthfully and carefully in some personal information",
		"name": "Name",
		"id": "Personal identity verification",
		"number": "ID card number",
		"type": "Document type",
		"content": "Please enter content",
		"complete": "Congratulations on completing the verification.",
		"nextstep": "Proceed to the next step of identity verification",
		"positive": "ID card front photo",
		"mb": "Only jpg,png, does not exceed 4mb",
		"click": "Click to upload ID photo",
		"back": "ID card back photo",
		"hand": "Upload your photo holding the card",
		"tip2": "Please provide a photo of you with the card in your hand. In the same photo, should also include \"NEWBIT\", date and your signature. Please make sure that your face is visible and that all document details are readable",
		"face": "The face is clearly visible without occlusion",
		"clearly": "The photos are clearly visible",
		"notes": "photo with [NEWBIT] text",
		"complete2": "Congratulations on completing your verification",
		"complete3": "Proceed to the next step of asset verification",
		"inaudit": "Identity verification submitted successfully, in audit",
		"notpass": "Identity verification failed",
		"again": "Please verify again",
		"asset": "Asset verification audit",
		"nasset": "Asset verification failed",
		"again2": "Please verify the asset again",
		"complete4": "Congratulations, the asset verification audit passed",
		"reminder": "kindly reminder",
		"tip3": "kindly reminder",
		"tip4": " Please provide a screenshot or photo of the assets of your NEWBIT account, including your account name, uid, position details and current time. All the information is clearly readable.",
		"screenshot": "Upload asset screenshot information",
		"nowLever": "Current verification level",
		"authCard": "ID card",
		"hu": "passport",
		"driverCard": "driving license",
		"other": "other",
		"one": "level one",
		"two": "level two",
		"three": "level three",
		"selectType": "Please select the type of document",
		"enterCardId": "Please fill in the ID number",
		"noWord": "The ID number cannot contain Chinese characters",
		"enterRealName": "Please fill in your name",
		"noNumber": "Names cannot contain numbers",
		"noFour": "Pictures should not exceed 4M",
		"frontImg": "Please upload the front photo of the card",
		"reverseImg": "Please upload the back photo of the card",
		"handImg": "Please take a photo of yourself with the card in your hand",
		"assetImg": "Please upload a screenshot of the asset"
},
	"legal": {
	"buy": "Buy",
		"sell": "Sell",
		"shoper": "Shoper",
		"number": "Amount",
		"limit": "Limit",
		"price": "Price",
		"pay": "Method of payment ",
		"do": "Operate",
		"record": "Order record ",
		"traderecord": "trade record",
		"tredetype": "trade type",
		"orderstatus": "Order status",
		"nofinish": "Unfinished",
		"finished": "Finished",
		"ceiled": "Canceled",
		"payed": "Payment complete",
		"paywait": "pending",
		"waitplease": "Please wait for the payment",
		"ordercomplete": "The order has been completed",
		"orderceiled": "The order has been canceled",
		"orderpayed": "The buyer has paid, please check and confirm",
		"totalmoney": "Total trade amount",
		"time": "Time",
		"contact": "Contact",
		"buyer": "Buyer",
		"seller": "Seller",
		"ordertime": "Order time",
		"reference": "reference number ",
		"ceilorder": "Cancel the trade",
		"surepay": "Confirmation of receipt",
		"confirmceil": "Confirm cancel the trade",
		"ceil": "Cancel",
		"confirm": "Confirm",
		"paysure": "Confirmation of payment",
		"otherpay": "Please confirm that the buyer has paid",
		"payplease": "Please pay to the following account",
		"payedwait": "Payment has been completed, please wait for confirmation",
		"tprice": "trade unit price",
		"tnumber": "Number of trades",
		"cardnum": "Bank card number",
		"wechat": "Wechat",
		"alipay": "Alipay",
		"account": "account ",
		"phone": "telephone",
		"orderceil": "cancel the order ",
		"mypayed": "I have paid, click to confirm",
		"notceil": "If you have paid the seller, please do not cancel the trade",
		"youpayed": "Please confirm that you have paid the seller",
		"freeze": "Malicious click will freeze the account directly",
		"trade": "business",
		"sellall": "Sell all",
		"buyall": "buy all",
		"conactceil": "Please complete payment in 15 minutes, or it will be cancelled automatically",
		"autoceil": "Automatic cancellation",
		"placeorder": "place an order",
		"inwant": "Please enter",
		"total": "total",
		"ceilnot": "If the buyer has paid, please do not cancel the trade",
		"shopaccount": "Merchant account",
		"bankName": "bank of deposit",
		"payinfo": "Payment information"
},
	"legaltrade": {
	"paymoney": "Currency of payment",
		"paytype": "Method of payment ",
		"select": "Please select",
		"back": "back",
		"desc": "brief introduction",
		"connect": "Contact",
		"cankao": "reference number ",
		"zhihang": "Bank of deposit branch",
		"enterzhihang": "Please enter the bank of deposit branch",
		"money_code": "Receipt code",
		"confirm_tips": "Please confirm the operation within 15 minutes",
		"cancel_tips": "Note: after canceling three times a day, Fiat will be restricted",
		"send": "Send",
		"money_change": "Capital change",
		"depth": "Depth map "
},
	"shop": {
	"myshop": "My shop",
		"name": "name",
		"tolegal": "Fiat",
		"retime": "registration time",
		"balance": "Merchant balance",
		"lockbalance": "Frozen balance",
		"goshop": "Enter Store",
		"allorder": "Total order",
		"dayorder": "30 days order",
		"comorder": "Completion order",
		"comrate": "completion rate",
		"release": "release",
		"email": "E-mail verification",
		"phone": "phone",
		"truename": "Name verification",
		"high": "Advanced verification",
		"type": "type",
		"mysell": "My sale",
		"mybuy": "My buy",
		"abnormal": "error",
		"seeorder": "Check an order",
		"choosetype": "Please select a type",
		"sell": "Sell",
		"buy": "Buy",
		"pchoose": "Please select",
		"minnum": "Minimum number of trades",
		"maxnum": "Maximum number of trades",
		"ordercom": "The order has been completed",
		"orderceil": "The order has been cancelled",
		"buyerpayed": "The buyer has paid, please check and confirm",
		"sellerwait": "Please wait for the seller to confirm",
		"receivepay": "Confirmation of receipt",
		"onsell": "Online sale",
		"onbuy": "Online purchase",
		"fatotal": "Total amount",
		"sceil": "Automatic cancellation in seconds",
		"nomin": "Can not less than the minimum",
		"nomax": "You can't exceed the maximum.",
		"nomaxnum": "Cannot exceed the maximum",
		"cancle": "Cancle"
},
	"lgset": {
	"lgset": "Fiat setting"
},
	"market": {
	"quotation": "Market quotation",
		"market": "Market",
		"currency": "Coin",
		"lastprice": "Price",
		"change": "Change",
		"zoushi": "Trend"
},
	"center": {
	"xian": "Limit",
		"shi": "Market",
		"available": "Usable",
		"buyprice": "Price",
		"sellprice": "Price",
		"buynumber": "Amount",
		"sellnumber": "Amount",
		"tradetotal": "Volume",
		"buyin": "Buy",
		"sellout": "Sell",
		"inbuy": "Inbuy",
		"outsell": "Outsell",
		"all": "All",
		"cdel": "Pending order",
		"hdeal": "Order history",
		"pairs": "Trade pairs",
		"direction": "Direction",
		"dealtotal": "Total (USDT)",
		"tprice": "Average price",
		"nothing": "Nothing",
		"contran": "Deal completed",
		"newprice": "Latest price",
		"alltrade": "Alltrade",
		"volume": "Amount",
		"realtime": "Realtime",
		"or": "or",
		"start": "Staring trade ",
		"hour": "24-hour change in funds",
		"inflow": "Main inflow",
		"outflow": "Main outflow",
		"inmoney": "24-hour inflow",
		"outmoney": "2-hour outflow "
},
	"account": {
	"mult100": "The quantity should be 100x",
		"exchange": "Exchange",
		"exchangeNum": "Exchange quantity",
		"hideSm": "Hide small assets",
		"assets": "Assets",
		"taccount": "Trading account",
		"laccount": "Fiat account",
		"totalassets": "Total assets",
		"letransaction": "Second account",
		"upaddress": "Withdrawal address management",
		"freezes": "Freeze",
		"conversion": "Convert into",
		"charging": "Deposit",
		"charrecord": "Deposit Record",
		"fstaus": "Tracking status",
		"view": "Look over",
		"withdraw": "Withdraw",
		"record": "Record",
		"copy": "copy",
		"amount": "Recharge quantity",
		"picture": "Transfer screenshot",
		"choose": "Choose",
		"select": "Please selecte picture",
		"selected": "Selected",
		"submitRecharge": "Submit",
		"tibi": "Please fill in the correct btc-omni withdrawal address",
		"code": "code",
		"cgaddress": "Deposit address",
		"whaddress": "Withdrawal address",
		"notice": "kindly reminder",
		"chaddress": "Select the address",
		"range": "range",
		"havenum": "Arrived amount",
		"minnum": "Minimum amount",
		"uppwd": "trade password",
		"oncemaxnum": "The maximum number of coins in a single order is",
		"daynum": "Maximum coin limit for the day",
		"call": "In order to ensure the security of funds, when your account security policy changes, password changes, we will manually audit the coin, please wait patiently for the staff to contact you by phone or email.",
		"a1": "Do not recharge any non-value to the above address",
		"a2": "Do not recharge any non-value to the above address",
		"a3": "Coin charging only supports simple send method, using other methods (send all) charging can not be accounted for the time being, please forgive me.",
		"a4": "After you recharge to the above address, you need the confirmation of the whole network node, after one network confirmation, you can withdraw the coin after 6 network confirmation.",
		"a5": "Minimum recharge amount：",
		"a6": "Recharge less than the minimum amount will not be accounted for and will not be refundable.",
		"a7": "Your recharge address will not change often, you can repeat the recharge; if there are changes, we will try our best to inform you through website announcements or emails.",
		"a8": "Be sure to make sure your computer and browser are secure to prevent information from being tampered with or leaked.",
		"a9": "After you recharge to the above address, you need the confirmation of the entire network node. Once the network is confirmed, it will arrive at the account. After 2 network confirmations, you can withdraw the currency.",
		"a10": "Please wait patiently for the staff to review after submitting the recharge information",
		"legal": "Fiat",
		"trade": "Exchange",
		"lever": "Contracts",
		"choosecoin": "Select currency",
		"huanum": "Transfer number",
		"mrecord": "Financial records",
		"allRec": "Total financial records",
		"transfer": "Transfer"
},
	"set": {
	"secset": "Security setting",
		"msgcer": "verification of message ",
		"turemsg": "To ensure that your trade is going on properly, please fill in your information",
		"tname": "Name",
		"kname": "Choose bank of deposit",
		"cnum": "Bank Card Number",
		"ailipay": "Account",
		"wename": "WeChat nickname",
		"wenum": "WeChat account",
		"bank_address": "Bank of deposit address",
		"ailipay_nickname": "Alipay nickname",
		"attestation": "Certification details",
		"setpay": "Type",
		"remarks": "Remarks",
		"palipay": "Please enter Alipay",
		"premarks": "Please enter comment information",
		"addalipaycode": "QR code",
		"pwenum": "Please enter WeChat",
		"nameplease": "Please enter a name",
		"addwenumcode": "QR code",
		"add": "add"
},
	"ad": {
	"adrecord": "Message recording",
		"release": "publish",
		"reply": "reply",
		"none": "none",
		"releaseit": "Post a message",
		"con": "Please fill in the message",
		"up": "submit"
},
	"lay": {
	"paccount": "Please enter your account number!",
		"notcode": "The verification code cannot be empty!",
		"pwdliu": "The password cannot be less than six digits!",
		"slogin": "Login success",
		"noemail": "Your mailbox does not conform to the rules",
		"phonenot": "The account number you entered does not conform to the rules!",
		"nophone": "Please enter your phone number",
		"nemail": "Please enter the E-mail",
		"inpwd": "Enter Your PIN",
		"pwdlength": "The password can only be between 6 and 16 bits.",
		"pwdcom": "Passwords must consist of numbers and letters",
		"repwd": "Please enter your password again",
		"twopwd": "The password entered twice is inconsistent",
		"inprice": "Please enter the purchase price",
		"noinprice": "The purchase price cannot be 0",
		"innumber": "Please enter the amount of purchase",
		"outprice": "Please enter the selling price",
		"outinprice": "The selling price cannot be 0",
		"outnumber": "Please enter the quantity of sale",
		"plogin": "Please log in",
		"revoke": "Are you sure you want to withdraw the order?",
		"sure": "Sure",
		"ceil": "cancel",
		"notopen": "Not yet open",
		"caddress": "Please select the pick-up address",
		"cnumber": "Please enter the number of withdrawals",
		"cpwd": "Please enter the withdrawal password",
		"minnum": "Please enter a minimum quantity",
		"copys": "Replication success",
		"fcopy": "Replication failure",
		"recopy": "Please copy it again",
		"huanum": "Please enter the number of transfers",
		"truename": "The real name must be filled in",
		"pliu": "Please fill in the message",
		"nameplease": "Please enter your name!",
		"idplease": "Please enter the ID number!",
		"comid": "Please upload the complete ID!",
		"intermediate": "Middle",
		"high": "High",
		"strong": "Strong",
		"buymoney": "Please enter the total amount you want to buy",
		"buynum": "Please enter the quantity you want to buy",
		"sellmoney": "Please enter the total amount you want to sell",
		"sellnum": "Please enter the quantity you want to sell",
		"tname": "Please certify the real name before placing the order",
		"payset": "Please set up the method of collection first",
		"mpay": "Please choose the method of payment",
		"mprice": "Please enter the price",
		"mnum": "Please enter the quantity",
		"lessnum": "The number must not be less than",
		"morenum": "The quantity cannot be exceeded",
		"notnum": "Quantity cannot be greater than total quantity",
		"confirm": "Please confirm your operation!",
		
		"canshucuowu":"Parameter error",
		"chongzhiyitijiao":"Top-up application has been submitted",
		"chongzhisqb":"Failed to submit the recharge application",
		"nodi100":"The transfer quantity entered cannot be less than 100",
		"pinputjine":"Please enter the amount",
		"selcztype":"Please select a recharge method",
		"updateok":"update completed",
		"ishuazhuan":"Are you confirming the transfer?？",
		"pinputokphone":"please enter a valid phone number",
		"usernofind":"User not found"
},
	"forLegalPsw": {
	"fpwd": "Forget the money password",
		"setpwd": "Set the fund password"
},
	"cfc": {
	"balance": "Balance",
		"account": "Transfer out of account number",
		"num": "Transfer quantity",
		"pla_account": "Please enter and transfer account number",
		"pla_num": "Please enter the number of transfers"
},
	"tpc": {
	"hot": "Hot project",
		"all": "All project",
		"nodata": "No data",
		"search": "Search",
		"tips": "*The content displayed on the current page is filled out by the project party, and NEWBIT is not responsible for the authenticity of the content",
		"time": "Issue time",
		"total": "Total amount of issue",
		"circulation": "Token circulation",
		"price": "Price",
		"web": "Official website",
		"book": "White paper",
		"tool": "Social media",
		"recommend": "Recommend organization",
		"introduce": "General certificate introduction",
		"notice": "Announcement",
		"rr": "General certificate research report",
		"news": "News information",
		"author": "Author:",
		"browse": "Views:"
},
	"finance": {
	"selectState": "Select state",
		"front": "Before change",
		"after": "After change",
		"info": "information"
},
	"uc": {
	"code": "Invitation code",
		"mymoney": "My money",
		"entrust": "Pending orders ",
		"deal": "Order history",
		"pay": "Method of payment ",
		"id": "Authentication ",
		"safe": "Security setting",
		"invitate": "Invite friends",
		"finance": "Financial records",
		"loginlog": "Login log"
},
	"usercenter": {
	"change": "Exchange account",
		"legal": "Fiat account",
		"lever": "Contract account"
},
	"account2": {
	"list": "Withdrawal records",
		"address": "Address label",
		"code": "identifying code ",
		"addtime": "Addition time",
		"suretime": "Confirm time",
		"explain": "explain"
},
	"withdrawList": {
	"sel": "Currency screening",
		"submitTime": "submission time",
		"checkTime": "Check time",
		"tibiNum": "Amount of coin drawn",
		"applying": "In the application",
		"hash": "Hash value",
		"agreeWait": "Agreed, please wait",
		"lianSucc": "Success",
		"lianFail": "Failure",
		"bohui": "reject",
		"zhanSucc": "Station success"
},
	"wAddress": {
	"enterAddress": "Please enter the withdrawal address",
		"enterMemo": "Please enter an address",
		"enterMark": "Please enter remark",
		"tips": "Warm Tip: USDT please use btc currency to add address, ERC20 token please use ETH address to add",
		"addressList": "Address list",
		"memo": "Address label",
		"del": "delete",
		"nospace": "The coin pick-up address should not contain spaces",
		"nospace02": "Address labels cannot contain spaces",
		"nospace03": "The address label must be a combination of letters and numbers. ",
		"allNum": "The address label must be number",
		"bankNum": "Bank card account number",
		"enterCardNum": "Please enter your bank card account number",
		"addCard": "Add bank collection code",
		"addcode": "Please upload a picture of the cashier code",
		"moreAdd": "Maximum upload",
		"pics": " picture"
},
	"authDetail": {
	"payType": "collection mode",
		"noreceiveType": "You haven't added the method of collection yet, please go and add it!"
},
	"identity": {
	"anquan": "Account number security",
		"verify": "login verify",
		"openVerify": "Open verification",
		"closeVerify": "Turn off validation",
		"enterGooole": "Please enter the Google key",
		"verityType": "Please select the verification method"
},
	"caiwu": {
	"lock": "lock",
		"nolock": "Unlocked",
		"status": "Please select the lock status",
		"selCu": "Please select the currency"
},
	"lang": {
		"en": "English",
		"kr": "한글",
		"jp": "日本語",
		"zh": "简体中文",
		"hk": "繁體中文",
		"spa": "España",
		"ara":"Arabia"
},
	"google": {
	"code": "Google verification code",
		"entergoogle": "Please enter Google verification code"
},
	"transfer": {
	"select": "Please select the swivel direction",
		"change": "From a Fiat account to a Fiat account",
		"legal": "From a Fiat account to a Fiat account",
		"change_balance": "Fiat account available",
		"pswType": "The password is a 6-digit number",
		"newPsw": "The new password must be a 6-digit number",
		"changePhone": "Change the cell phone number",
		"changeEmail": "Change mailbox",
		"emailCode": "Please enter mailbox verification code",
		"newPhone": "Please enter the new cell phone number",
		"newEmail": "Please enter a new mailbox number",
		"phoneCode": "Please enter the cell phone verification code",
		"selCu": "Please select the currency",
		"ok": "Successful transfer of funds",
		"error": "Fund transfer failure"
},
	"register01": {
	"country": "Country",
		"selectCountry": "Nationality cannot be revised after registration.",
		"noAccount": "Go sign up: "
},
	"invite": {
	"welcome": "Earn high returns together",
		"effective": "effective",
		"invalid": "inavailable",
		"list": "List",
		"commission": "Get ",
		"link": "My sharing link",
		"share": "Copy link",
		"mypromotion": "My promotion history",
		"account": "Invitations account number",
		"nomore": "No more data",
		"rules": "Activity rules",
		"tip1": "The invitee must pass the secondary certification;",
		"tip2_0": "After invitation accepted, every trade will ",
		"tip2_1": "commission",
		"tip3": "The settlement method is in accordance with the invitation method",
		"tip4_0": "The commission received by the invitee will be based on",
		"tip4_1": "The trade account returned to the inviting person after the conversion calculation. Comission amount",
		"tip4_2": " = actual volume of trades * fee * commission percentage.",
		"tip5_0": "The platform will be held at the closing price of the currency at 08:00 Beijing time every day",
		"tip5_1": "changing"
},
	"crowd": {
	"mujiNum": "Total amount raised",
		"cur": "Support Coin",
		"endTime": "End time",
		"intro": "Introduction",
		"can_edu": "Available amount",
		"now_edu": "Current requisition amount",
		"my_edu": "The amount of my application",
		"his": "The amount of my application",
		"price": "Purchase price",
		"money": "Purchase amount",
		"yue": "Account balance",
		"enterMoney": "Please enter the purchase amount",
		"total": "The total amount of purchase",
		"rule": "Purchase rules",
		"startBuyTime": "Start purchase time",
		"endBuyTime": "End of purchase time"
},
	"dep": {
	"dep_rec": "Deposit record",
		"dep": "Deposit amount",
		"tip": "Deposit coins and treasures!",
		"plan": "days plan",
		"timer": "duration",
		"rate": "APR",
		"lock": "locked",
		"join": "joined",
		"end": "The rush is over",
		"buy": "Rush to buy immediately",
		"date": "Date of deposit",
		"day": "Day",
		"total_money": "Total amount",
		"sup_num": "Remain amount",
		"top": "Limit of deposit",
		"one_num": "minimum of deposit",
		"num": "Amount of coins stored",
		"min": "minimum",
		"max": "maximum",
		"yuji": "expect income ",
		"qiang": "rush purchase",
		"depEndTime": "Deposit deadline",
		"jixiTime": "Benefit time",
		"lockTime": "Unlock time",
		"enterNum": "Please enter the quantity purchase",
		"his": "Deposit history",
		"money": "Amount of deposit",
		"cur_name": "Currency name",
		"do": "do",
		"ti": "withdraw",
		"suoTime": "Freeze time",
		"leiji": "Total gain",
		"rateNum": "Commission fee:",
		"ti_num": "Extractable quantity:",
		"tiYet": "Are you sure to extract",
		"to": "To the account？",
		"yes": "yes",
		"no": "no"
},
	"vote": {
	"di": "The",
		"period": " round voting",
		"explain": "Rule declaration ",
		"free": "The number of free votes",
		"have": "Hold",
		"num": " amount",
		"people": "Number of support",
		"tickets": "Number of votes",
		"detail": "Check details",
		"jiangli": "Voting reward",
		"fen": "share",
		"sup_people": "support",
		"piao": "ticket",
		"vote": "vote",
		"enter": "Please enter the number of votes",
		"vote_cur": "Vote in coins",
		"paiming": "ranking ",
		"intro": "Project introduction",
		"taken": "Taken Overview and distribution",
		"total": "Total amount",
		"cur_total": "Current total circulation",
		"price": "issue price",
		"link": "Related link",
		"web": "web",
		"zh": "Chinese version of White Paper",
		"en": "English version of the White Paper"
},
	"td1": {
	"do": "do",
		"more": "Load more",
		"loading": "Loading",
		"nomore": "no more",
		"nodata": "no data",
		"canceil": "cancel",
		"confirm": "confirm",
		"status": "status"
},
	"accountset": {
	"name": "Please fill in your name"
},
	"sgr": {
	"sgrinfo": "SGR Demand introduce",
		"lock": "Current personal SGR locked amount: ",
		"signup": "Go sign up",
		"sgrget": "The current registered and KYC will immediately get",
		"cir": "Total Circulation Quantity",
		"loc": "Total Locked Quantity",
		"richman": "The rich man of today",
		"user": "User",
		"amount": "Win",
		"time": "Time",
		"todayrelease": "Amount of Personal  SGR Releases Today",
		"releaserecords": "Release Records",
		"invite": "SGR invite",
		"link": "My Invite link",
		"copy": "Copy",
		"totallock": "Current total SGR amount of locks obtained",
		"onelevel": "Numbers of current one level invitations",
		"twolevel": "Numbers of current two level invitations",
		"therelevel": "Numbers of current there level invitations",
		"get": "Get ",
		"rebate": " rebate"
},
	"miscro": {
	"quotation": "Markets",
		"transaction": "Second",
		"symbol": "Pair",
		"balance": "Second Contract Account",
		"miscroAccount": "Second Contract Account",
		"trade": "Transaction",
		"buyPrice": "Purchase price",
		"finshPrice": "Transaction price",
		"loss": "Estimated profit",
		"times": "Count down",
		"mode": "Transaction mode",
		"num": "Opening quantity",
		"rate": "Profit rate",
		"up": "Up",
		"down": "Fall",
		"openNum": "Please enter the opening quantity",
		"success": "Success in placing orders!",
		"c2c": "c2c account",
		"complaint": "Complaint suggestion",
		"reply": "Please write down your questions and we will reply to you as soon as possible...",
		"complaintList": "Complaint Suggestion List",
		"complaintReply": "Reply:",
		"complaintDescription": "Please enter a description",
		"resetpwd": "Modify password",
		"foundedOn": "Founded on",
		"commonProblem": "Common problem",
		"statement": "Interpretative statement",
		"about": "About KiBiEx",
		"everyone": "Everyone is CEO",
		"program": "KiBiEx Global Partners Program",
		"myMine": "My mine:",
		"friend": "My mine friend:",
		"myRank": "My rank:",
		"accumulated": "Accumulated commission:",
		"copyLinks": "Copy Promotion Links",
		"moneyVein": "People's relationship changes into money vein",
		"example": "Example of commission",
		"enterQuantity": "Please enter quantity",
		"contractBalance": "Contract account balance",
		"submitRepeatedly": "In submission, please do not submit repeatedly",
		"alipayCode": "Alipay payment code",
		"wechatCode": "Wechat Receipt Code",
		"realPrice": "Real time price",
		"currencyExchange": "Currency exchange",
		"currencyExchangeIn": "Currency exchange",
		"cashableBalance": "Cashable balance：",
		"minimumCashable": "Minimum Cashable：",
		"maximumCashable": "Maximum Cashable：",
		"automaticallys": "After clicking on the exchange, it will be automatically completed and cannot be returned.",
		"match": "Match",
		"title": "Asset convertibility",
		"holdAssets": "Holding assets",
		"dangerousCurrency": "Holding dangerous currency",
		"convertibleQuantity": "Convertible Quantity",
		"currencyExchanges": "Currency exchange rate：",
		"insuranceCurrency": "Insurance currency",
		"insuranceType": "Insurance type",
		"contractAsset": "Contract Asset Insurance",
		"warehouses": "Number of Warehouses",
		"availableAssets": "Available assets:",
		"insuredAssets": "Insured assets:",
		"insuredAssets1": "Insured assets",
		"insuranceAssets": "Insurance assets:",
		"purchaseInsurance": "Purchase insurance",
		"insuranceClaims": "Insurance claims",
		"insuranceCancellation": "Insurance cancellation",
		"coinWallet": "Insurance Coin Wallet",
		"bmbWallet": "AITB Wallet",
		"cumulativeCoins": "Cumulative coins：",
		"availableQuantity": "Available Quantity:",
		"rawCurrency": "Record of raw currency",
		"contractInsurance": "Contract insurance",
		"tenThousand": "ten thousand",
		"runningLow": "Sorry, your credit is running low",
		"purchase": "The quantity you enter does not conform to the rules. Your purchase quota is",
		"reach": "reach",
		"between": "between",
		"onlyEnter": "You can only enter",
		"integersBetween": "Integers between",
		"notReturned": "Click to purchase insurance, as a detailed understanding of the insurance treaty and agree that the warehouse will automatically enter into force, can not be returned.",
		"settled": "When the insured assets lose money and cannot place an order, the insurance claims shall be settled.",
		"profitable": "When assets are profitable",
		"terminated": "at that time, the insurance will be automatically terminated.",
		"automatically": "When the insured assets lose 50%, they must apply for insurance claims, otherwise they cannot be traded. When the profits are 100%, the insurance will automatically cancel the contract. The warehouse can only pay twice a day, more than twice, t+1.",
		"termination": "After the termination of the insurance contract, the insured assets can be converted. After the termination of the contract, the insured assets are deemed to be in breach of contract, and the warehouse is cleared automatically.",
		"just": "just",
		"back": "back",
		"lowestNumber": "The lowest number is",
		"confirmExchange": "Do you confirm the exchange?",
		"contractValuation": "Valuation of Contract Account Assets",
		"secondValuation": "Second Contract Account Asset Valuation",
		"falshValuation": "Valuation of assets in Coin account",
		"c2cValuation": "Asset Valuation of Fiat Account",
		"recordWithdrawal": "Record of withdrawal of money",
		"category": "category",
		"safetyCenter": "Safety Center",
		"safeText1": "Registration, password modification, and security settings for receiving validated SMS",
		"safeText2": "Internet accounts are at risk of being stolen. It is recommended that you change your password regularly to protect account security.",
		"flashTrading": "Currency exchange",
		"assetCenter": "Asset Center",
		"promotionCode": "My Promotion Code",
		"loginAgain": "Logon is out of date, please login again",
		"text10": "Real-time Price Based on AITB",
		"text11": "Payment voucher"
},
	"td": {
	"buy": "Buy",
		"sell": "Sell",
		"currency": "Currency",
		"num": "Number",
		"time": "Time",
		"limit": "Limit",
		"price": "Unit Price",
		"method": "Pay way",
		"total": "Total",
		"inwant": "Please input",
		"do": "Operation",
		"more": "Load more",
		"nomore": "No more",
		"nodata": "No data",
		"trade": "Trade",
		"buynum": "Purchase quantity",
		"sellout": "Sell ",
		"buyin": "Buy ",
		"all": "All",
		"allbuy": "Buy all",
		"allsell": "Sell all",
		"buytotal": "Please enter the total amount you want to purchase.",
		"selltotal": "Please enter the total amount you want to sell.",
		"buyallnum": "Please enter the quantity you want to buy.",
		"sellallnum": "Please enter the quantity you want to sell.",
		"tradeTotal": "Total transaction volume",
		"doceil": "Automatically cancel",
		"place": "Place an order",
		"waitpay": "Pending payment",
		"finished": "Completed",
		"ceiled": "Cancelled",
		"payed": "Paid",
		"nofinish": "incomplete",
		"buyer": "Buyer",
		"seller": "seller",
		"callWay": "contact way",
		"placeTime": "Order time",
		"renum": "Reference number",
		"canceil": "Cancel",
		"confirm": "Confirm",
		"pwd": "Please enter the transaction password.",
		"coincode": "Monetary unit",
		"pcoin": "Please choose monetary unit.",
		"examine": "Please wait patiently in the process of business audit"
},
	"cuy": {
	"direction": "Direction",
		"total": "Total",
		"price": "Price",
		"sell": "Sell",
		"buy": "Buy",
		"allStation": "Market trades",
		"buyPrice": "Price",
		"buynum": "Amount",
		"sellPrice": "Price",
		"sellnum": "Amount",
		"tdPrice": "Total",
		"or": "Or",
		"tdStart": "Start trading",
		"pbPrice": "Please enter the bid price",
		"pbNum": "Please enter the purchase amount",
		"psPrice": "Please enter the selling price",
		"psNum": "Please enter the sales amount",
		"fixPrice": "Limit",
		"Mtrans": "Market",
		"available": "available",
		"revoke": "Undo",
		"loading": "Loading",
		"confirmCancel": "Are you sure you want to cancel? ",
		"confirm": "OK",
		"cancel": "Cancel",
		"nomore": "No more data",
		"evelPrice": "Average Price"
},
	"iepn": {
	"htitle": "A large number of coins are on sale, invite friends to earn coins together",
		"subtitle": "A large number of coins on sale, come and buy immediately, the number is limited~",
		"netname": "Intelligent Epidemic Prevention Network",
		"plannum": "Planned circulation",
		"totalnum": "Planned circulation",
		"pushednum": "Issued amount",
		"remindnum": "The remaining",
		"hotcommond": "Popular recommendation",
		"ctitle": "Global government medical management agency",
		"cdsp": "Medical care involves people's livelihood and it is impossible to avoid the role of the government. The Intelligent Epidemic Prevention Network's transparent chain characteristics can help global medical management institutions to better manage medical institutions, monitor national health, and make rapid and flexible medical resource allocation.",
		"starttime": "Start time",
		"startprice": "Start price",
		"endtime": "End time",
		"seemore": "See more",
		"baipishu": "Technical white paper",
		"gobuy": "Go buy"
},
"apitip":{
	"参数错误":"Parameter error",
	"数据未找到":"Data not found",
	"请选择需求类型":"Please select the type of requirement",
	"请选择交易方式":"Please select transaction method",
	"请填写单价":"Please fill in the unit price",
	"请填写数量":"Please fill in the quantity",
	"请选择币种":"Please select currency",
	"请输入正确的交易数量或价格":"Please enter the correct transaction quantity or price",
	"您还没有设置收款信息":"You haven't set up payment information",
	"取消次数已超出":"Number of cancellations exceeded",
	"次,明天再发布":"Time, release tomorrow",
	"用户钱包不存在":"User wallet does not exist",
	"对不起，您的钱包余额不足":"Sorry, your wallet balance is insufficient",
	"用户发布c2c交易法币出售，扣除法币余额":"The user released the c2c transaction to sell the legal currency, deducting the balance of the legal currency",
	"用户发布c2c交易法币出售,锁定余额增加":"The user released the c2c transaction to sell legal currency, and the locked balance increased",
	"发布成功":"Successful",
	"无此用户":"No such user",
	"用户不存在":"User does not exist",
	"参数错误2":"Parameter error 2",
	"无此币种":"No such currency",
	"该币不是法币":"The currency is not a legal currency",
	"无此记录":"No such record",
	"您还没有通过实名认证":"You have not passed the real-name authentication",
	"您还没有设置收款信息":"You haven't set up payment information",
	"无此记录":"No such record",
	"此条交易已完成":"This transaction has been completed",
	"未找到该发布用户":"The posting user was not found",
	"不能操作自己的":"Can't operate one's own",
	"您无此钱包账号":"You do not have this wallet account",
	"您的钱包已被锁定，请联系管理员":"Your wallet has been locked, please contact the administrator",
	"检测有未完成交易，请完成后再来！":"Check for unfinished transactions, please come back after completion!",
	"您的余额不足":"Your balance is insufficient",
	"您的法币冻结资金异常,请查看您是否有正在进行的挂单":"Your fiat frozen funds are abnormal, please check if you have pending orders",
	"出售给商家法币,余额减少":"Sold to merchants with fiat currency, the balance is reduced",
	"出售给商家法币,锁定余额增加":"Sold to merchants with fiat currency, lock-in balance increases",
	"您发布的求购信息有用户出售啦，请去 APP 查看吧～":"The purchase information you posted is sold by users, please go to APP to check it~",
	"您发布的出售信息有用户购买啦，请去 APP 查看吧～":"The sales information you posted has been purchased by users, please go to APP to check it~",
	"Operation successful，请联系商家确认订单":"Operation successful, please contact the merchant to confirm the order",
	",错误位于第":"The error is in the",
	",行":"Row",
	"无此币种":"No such currency",
	"该币不是法币":"The currency is not a legal currency",
	"用户信息不正确":"User information is incorrect",
	"无此记录":"No such record",
	"该订单已操作过，请勿重复操作":"This order has already been operated, please do not repeat the operation",
	"对不起，您无权操作":"Sorry, you are not authorized to operate",
	"buy":"buy",
	"对不起，您无权操作":"Sorry, you are not authorized to operate",
	"Operation successful，请联系卖家确认":"Operation successful, please contact the seller to confirm",
	"Operation successful，订单已取消":"Operation successful, the order has been cancelled",
	"您今天的取消次数已超出":"You have exceeded the number of cancellations today",
	"该订单已操作，请勿取消":"The order has been processed, please do not cancel",
	"对不起，这不是您的发布信息":"Sorry, this is not your post",
	"该订单还未付款或已经操作过":"The order has not been paid or has been processed",
	"您不能确认该订单":"You cannot confirm the order",
	"订单异常":"Order exception",
	"该用户没有此币种钱包":"The user does not have this currency wallet",
	"该买家没有此币种钱包":"The buyer does not have a wallet in this currency",
	"出售法币成功,扣除锁定余额":"Successfully sell legal currency, deduct the locked balance",
	"购买法币成功，增加法币余额":"Successful purchase of legal currency, increase the balance of legal currency",
	"在":"in",
	"确认成功":"Confirm success",
	"订单异常":"Order exception",
	"卖出法币成功":"Successfully sold legal currency",
	"购买法币成功":"Successful purchase of fiat currency",
	"确认成功":"Confirm success",
	"该发布信息下有交易产生无法删除":"There are transactions generated under the posted information that cannot be deleted",
	"对不起，您无权撤销此记录":"Sorry, you do not have the right to revoke this record",
	"用户钱包不存在":"User wallet does not exist",
	"对不起，您的账户冻结资金不足":"Sorry, your account has insufficient funds frozen",
	"商家撤回发布法币出售":"Merchants withdraw the release of fiat currency for sale",
	"商家撤回发布法币出售":"Merchants withdraw the release of fiat currency for sale",
	"撤回成功":"Withdraw successfully",
	"参数错误!":"Parameter error!",
	"转账账户不存在!":"The transfer account does not exist!",
	"不能给自己转账!":"Can't transfer money to myself!",
	"转账数量大于剩余数量!":"The transfer amount is greater than the remaining amount!",
	"余额不足!":"Insufficient balance!",
	"转账数量不能小于":"The transfer amount cannot be less than",
	"转账数量不能大于":"The transfer amount cannot be greater than",
	"通证转账成功!":"Pass transfer is successful!",
	"必填项信息不完整":"Required information is incomplete",
	"两次输入密码不一致":"The two passwords are inconsistent",
	"此用户不存在":"This user does not exist",
	"此交易账号已经设置":"This trading account has been set up",
	"此账号已经存在":"This account already exists",
	"交易账号设置成功":"The trading account is set successfully",
	"验证码错误":"Verification code error",
	"手机绑定成功":"Mobile phone binding successfully",
	"邮箱绑定成功":"Mailbox binding successfully",
	"手势密码至少连接6个点":"At least 6 points connected by gesture password",
	"两次手势密码不相同":"The two gesture passwords are not the same",
	"手势密码添加成功":"Gesture password added successfully",
	"取消手势密码成功":"Cancel the gesture password successfully",
	"密码只能在6-16位之间":"Password can only be between 6-16 digits",
	"两次密码不一致":"The two passwords are inconsistent",
	"交易密码设置成功":"The transaction password is set successfully",
	"暂时还没有邀请排行榜，快去邀请吧":"There is no invitation leaderboard yet, please go and invite",
	"会员未找到":"Member not found",
	"你已绑定，不可更改!":"You are bound and cannot be changed!",
	"该地址已被绑定,请重新输入":"The address has been bound, please re-enter",
	"绑定成功!":"Bind successfully!",
	"暂无钱包":"No wallet",
	"请提交完整的信息":"Please submit complete information",
	"请输入合法的身份证号码":"Please enter a legal ID number",
	"该身份证号已实名认证过!":"The ID number has been verified by real name!",
	"您已经申请过了":"You have already applied",
	"提交成功，等待审核":"Submitted successfully, waiting for review",
	"支付密码错误":"Incorrect payment password",
	"退出登录成功":"Log out successfully",
	"请输入支付密码":"Please enter payment password",
	"余额不足":"Insufficient balance",
	"无需升级":"No need to upgrade",
	"升级成功":"update successed",
	"升级会员":"Upgrade member",
	"没有此用户":"No such user",
	"该地址别人已经绑定过了":"This address has already been bound by someone else",
	"更新成功":"update completed",
	"请重试":"Please try again",
	"请把参数填写完整":"Please fill in the parameters completely",
	"推荐用户不存在":"The recommended user does not exist",
	"转入账户不能为空":"The transfer account cannot be empty",
	"转入数量不能为空":"The transferred quantity cannot be empty",
	"密码不能为空":"password can not be blank",
	"用户密码错误":"User password is wrong",
	"美丽链法币交易余额转入":"Plasma fiat currency transaction balance transfer in",
	"美丽链币币交易余额转入":"Plasmacoin transaction balance transfer in",
	"美丽链杠杆交易余额转入":"Plasma leveraged transaction balance transfer",
	"转入成功":"Successfully transferred",
	"密码修改成功":"Password reset complete",
	"用户未找到":"User not found",
	"绑定成功":"Bind successfully",
	"发送成功":"Sent successfully",
	"m月d日 H:i":"m month d day H",
	"币未找到":"Coin not found",
	"暂无数据":"No data",
	"文件大小超出":"File size exceeded",
	"该文件已存在":"The file already exists",
	"文件类型不对":"Incorrect file type",
	"上传失败":"upload failed",
	"内容不能为空":"the content can not be blank",
	"提交成功，我们会尽快给你回复":"The submission is successful, we will get back to you as soon as possible",
	"非法参数":"Illegal parameter",
	"该币种不支持购买保险":"This currency does not support the purchase of insurance",
	"错误的金额！":"Wrong amount!",
	"不存在的险种！":"Non-existent types of insurance!",
	"不存在的币种！":"Currency that does not exist!",
	"该币种不支持购买保险":"This currency does not support the purchase of insurance",
	"已经购买了该币种的险种！":"Already purchased insurance for this currency!",
	"可用余额不足，无法购买！":"The available balance is insufficient to purchase!",
	"购买成功！":"The purchase is successful!",
	"购买失败！原因：":"Failed purchase! the reason:",
	"未找到该保险":"The insurance was not found",
	"该保险正在处理中":"The insurance is being processed",
	"申请索赔失败":"Failed to apply for claim",
	"申请索赔成功！":"The claim application is successful!",
	"超出今日索赔次数!":"Exceeding the number of claims today!",
	"存在未平仓订单":"There is an open order",
	"受保资产为零":"Zero insured assets",
	"受保资产不符合可申请索赔条件1":"The insured asset does not meet the claimable conditions1",
	"受保资产不符合可申请索赔条件2":"The insured asset does not meet the claimable conditions2",
	"未知的险种类型":"Unknown type of insurance",
	"保险赔偿用户[清除受保金额]":"Insurance indemnity users [clear the insured amount]",
	"保险赔偿用户[赔偿受保金额]":"Insurance indemnity users [compensation for insured amount]",
	"保险解约，扣除保险金额":"Insurance termination, deduction of insurance amount",
	"未知受保金额去向状态":"Unknown insured amount whereabouts status",
	"处理成功！":"Successfully processed!",
	"处理失败：":"Processing failed:",
	"未找到该保险":"The insurance was not found",
	"该保险已失效":"The insurance has expired",
	"该保险正在索赔处理中":"The insurance is in the process of claim processing",
	"解约失败，存在未平仓订单":"Cancellation failed, there are open orders",
	"保险解约，赔付金额":"Insurance termination, compensation amount",
	"保险解约，扣除受保金额":"Insurance termination, deduct the insured amount",
	"保险解约，扣除保险金额":"Insurance termination, deduction of insurance amount",
	"解约成功！":"Successful termination!",
	"解约失败:":"Cancellation failed:",
	"订单取消成功":"Order cancelled successfully",
	"请选择需求类型":"Please select the type of requirement",
	"请选择交易方式":"Please select transaction method",
	"请填写单价":"Please fill in the unit price",
	"请填写数量":"Please fill in the quantity",
	"请填写最小交易数量":"Please fill in the minimum transaction quantity",
	"请选择币种":"Please select currency",
	"最小交易数量不能大于总数量":"The minimum transaction quantity cannot be greater than the total quantity",
	"请填写最大交易量":"Please fill in the maximum transaction volume",
	"请填写正确的最大交易量":"Please fill in the correct maximum transaction volume",
	"币种信息有误":"The currency information is wrong",
	"对不起，您不是该法币的商家":"Sorry, you are not the merchant of the fiat currency",
	"对不起，您的商家账户不足":"Sorry, your merchant account is insufficient",
	"商家发布出售":"Merchant releases and sells",
	"商家发布出售,冻结增加":"Merchants publish for sale, and freezes increase",
	"发布成功":"Successful",
	"无此商家":"No such business",
	"您不是商家":"You are not a business",
	"该币不是法币":"The currency is not a legal currency",
	"无此币种":"No such currency",
	"购买参数错误":"Purchase parameter error",
	"无此记录":"No such record",
	"请填写购买额":"Please fill in the purchase amount",
	"购买额请填写数字":"Please fill in the number for the purchase amount",
	"您还没有通过实名认证":"You have not passed the real-name authentication",
	"您还没有设置收款信息":"You haven't set up payment information",
	"未完成单子超过3单，请完成后再操作!":"There are more than 3 unfinished orders, please complete the operation again!",
	"商家挂单状态异常,暂时不能交易":"The merchant's pending order status is abnormal and cannot be traded temporarily",
	"商家挂单剩余可交易数量不足":"The remaining transaction quantity of the merchant's pending order is insufficient",
	"此条交易已完成":"This transaction has been completed",
	"非法提交，数量必须大于0":"Illegal submission, the quantity must be greater than 0",
	"您低于最低限额":"You are below the minimum",
	"您高于最高限额":"You are above the maximum limit",
	"您高于最大限制数量":"You are above the maximum limit",
	"未找到该商家":"The business was not found",
	"不能操作自己的":"Can't operate one's own",
	"您无此钱包账号":"You do not have this wallet account",
	"您的钱包已被锁定，请联系管理员":"Your wallet has been locked, please contact the administrator",
	"您的法币余额不足":"Your fiat currency balance is insufficient",
	"您的法币冻结资金异常,请查看您是否有正在进行的挂单":"Your fiat frozen funds are abnormal, please check if you have pending orders",
	"您出售数量大于商家剩余数量!":"The quantity you sell is greater than the remaining quantity of the merchant!",
	"出售给商家法币:扣除余额":"Fiat currency sold to merchants: deduct the balance",
	"出售给商家法币:增加冻结":"Fiat currency sold to merchants: increased freeze",
	"您购买数量大于商家剩余数量!":"Your purchase quantity is greater than the remaining quantity of the merchant!",
	",错误位于第":"The error is in the",
	"Operation successful，请联系商家确认订单":"Operation successful, please contact the merchant to confirm the order",
	"行":"Row",
	"该订单已操作过，请勿重复操作":"This order has already been operated, please do not repeat the operation",
	"请上传支付凭证":"Please upload payment voucher",
	"对不起，您无权操作":"Sorry, you are not authorized to operate",
	"Operation successful，请联系卖家确认":"Operation successful, please contact the seller to confirm",
	"该订单已操作，请勿取消":"The order has been processed, please do not cancel",
	"Operation successful，订单已取消":"Operation successful, the order has been cancelled",
	"对不起您不是商家":"Sorry you are not a business",
	"对不起，您不是该商家":"Sorry, you are not the business",
	"该订单还未付款或已经操作过":"The order has not been paid or has been processed",
	"对不起，您的商家冻结余额不足,当前余额:":"Sorry, your merchant’s frozen balance is insufficient. Current balance:",
	"您不能确认该订单":"You cannot confirm the order",
	"订单异常":"Order exception",
	"该用户没有此币种钱包":"The user does not have this currency wallet",
	"法币交易:在商家":"Fiat currency transactions: at the merchant",
	"购买法币成功":"Successful purchase of fiat currency",
	"法币交易:卖出成功":"Fiat currency trading: successful sale",
	"确认成功":"Confirm success",
	"该订单还未付款或已经操作过":"The order has not been paid or has been processed",
	"您不能确认该订单":"You cannot confirm the order",
	"该用户没有此币种钱包":"The user does not have this currency wallet",
	"法币交易:用户":"Fiat currency transactions: users",
	"向商家出售法币成功":"Successfully sold legal currency to merchants",
	"法币交易:购买成功":"Fiat currency transaction: successful purchase",
	"确认成功":"Confirm success",
	"此状态下无法下架":"Cannot be removed in this state",
	"对不起，您不是该法币的商家":"Sorry, you are not the merchant of the fiat currency",
	"发布下架成功,将不会再与新用户匹配":"The release is successful and will no longer be matched with new users",
	"必须下架后才可以撤销":"Must be removed before it can be revoked",
	"已撤回发布":"Release has been withdrawn",
	"当前发布状态无法撤销":"The current release status cannot be undone",
	"当前发布剩余数量不足,无法撤销":"The remaining number of the current release is insufficient and cannot be cancelled",
	"该发布信息下有交易正在进行中,请等待交易结束再撤回":"There is a transaction in progress under this posted information, please wait for the transaction to end before withdrawing",
	"对不起，您不是该法币的商家":"Sorry, you are not the merchant of the fiat currency",
	"对不起，您的商家账户冻结资金不足":"Sorry, your merchant account has insufficient funds frozen",
	"撤回失败:减少冻结资金失败":"Withdrawal failure: reduce the failure of frozen funds",
	"商家撤回":"Merchant withdrawal",
	"撤回失败:增加余额失败":"Withdrawal failed: failed to increase balance",
	"撤回成功":"Withdraw successfully",
	"该发布信息无异常":"The published information is normal",
	"该发布信息下有交易未完成，不能标记为异常":"There are uncompleted transactions under the posted information and cannot be marked as abnormal",
	"该发布剩余数量不足,不法标记异常":"The remaining number of the release is insufficient, and the illegal flag is abnormal",
	"当前发布状态无法标记异常":"Unable to flag exception in current release status",
	"对不起，您的商家账户冻结资金不足":"Sorry, your merchant account has insufficient funds frozen",
	"处理异常失败:减少冻结资金失败":"Failed to handle exceptions: reduce the failure of frozen funds",
	"处理异常失败:增加余额失败":"Failed to handle exception: Failed to increase balance",
	"商家处理异常":"Merchant processing exception",
	"商家处理异常":"Merchant processing exception",
	"缺少参数或传值错误":"Missing parameter or wrong value",
	"指定交易对不存在":"The specified trading pair does not exist",
	"您未开通本交易对的交易功能":"You have not activated the trading function of this trading pair",
	"手数必须是大于0的整数":"The lot size must be an integer greater than 0",
	"手数不能低于":"The number of hands cannot be less than",
	"手数不能高于":"The number of lots cannot be higher than",
	"选择倍数不在系统范围":"Selection multiple is not in the system",
	"您有正在平仓中的交易,暂不能进行买卖":"You have a transaction that is in the process of liquidation, and cannot be traded temporarily",
	"交易类型错误":"Wrong transaction type",
	"该功能暂未开放":"This feature is not yet open",
	"限价交易价格必须大于0":"The limit transaction price must be greater than 0",
	"当前没有获取到行情价格,请稍后重试":"The market price is not currently obtained, please try again later",
	"限价交易卖出不能低于当前价":"Limit trading cannot be sold below the current price",
	"限价交易买入价格不能高于当前价":"The purchase price of the limit transaction cannot be higher than the current price",
	"钱包未找到,请先添加钱包":"The wallet is not found, please add the wallet first",
	"余额不足,不能小于":"Insufficient balance, cannot be less than",
	"(手续费:":"(Handling fee:",
	"提交失败":"Submission Failed",
	"扣除保证金失败":"Failed to deduct margin",
	"扣除手续费失败":"Failed to deduct the handling fee",
	"此功能系统未开放":"This feature system is not open",
	"止盈止损价格不能为0":"The stop-profit and stop-loss price cannot be 0",
	"找不到该笔交易":"Can't find the transaction",
	"买入(做多)止盈价不能低于开仓价和当前价":"The buy (long) take profit price cannot be lower than the opening price and the current price",
	"买入(做多)止亏价不能高于开仓价和当前价":"Buy (go long) stop loss price cannot be higher than the opening price and current price",
	"卖出(做空)止盈价不能高于开仓价和当前价":"The sell (short) take profit price cannot be higher than the opening price and the current price",
	"卖出(做空)止亏价不能低于开仓价和当前价":"Sell (short) stop loss price cannot be lower than the opening price and current price",
	"设置成功":"Set successfully",
	"设置失败":"Setup failed",
	"数据未找到":"Data not found",
	"无权操作":"No right to operate",
	"交易状态异常,请勿重复提交":"The transaction status is abnormal, please do not submit repeatedly",
	"平仓失败,请重试":"Failed to close the position, please try again",
	"交易异常，无法平仓":"The transaction is abnormal and the position cannot be closed",
	"买入方向传参错误":"Incorrect parameter passing in the buying direction",
	"提交成功,请等待系统处理":"The submission is successful, please wait for the system to process",
	"未找到需要平仓的交易":"No transaction that needs to be closed",
	"提交成功,请等待系统处理":"The submission is successful, please wait for the system to process",
	"未找到需要平仓的交易":"No transaction that needs to be closed",
	"交易不存在或已撤单,请刷新后重试":"The transaction does not exist or the order has been cancelled, please refresh and try again",
	"撤单失败:用户钱包不存在":"Failed to cancel order: user wallet does not exist",
	"撤单失败":"Cancellation failed",
	"撤单失败:变更状态失败":"Failed to cancel the order: failed to change the status",
	"撤单成功":"Successful cancellation",
	"请输入账号":"please enter account",
	"请输入密码":"Please enter the password",
	"手势密码错误":"Gesture password error",
	"账号已锁定！请联系管理员":"Account is locked! Please contact the administrator",
	"两次密码不一致":"The two passwords are inconsistent",
	"密码只能在6-16位之间111111":"Password can only be between 6-16 digits 111111",
	"验证码错误":"Verification code error",
	"账号已存在":"Account already exists",
	"请填写正确的邀请码":"Please fill in the correct invitation code",
	"请输入账号":"please enter account",
	"请输入密码或确认密码":"Please enter the password or confirm the password",
	"输入两次密码不一致":"The two passwords are not consistent",
	"验证码不正确":"Incorrect verification code",
	"账号不存在":"Account does not exist",
	"修改密码成功":"successfully change password",
	"请输入验证码":"please enter verification code",
	"验证码错误":"Verification code error",
	"验证成功":"Verified successfully",
	"下单失败:超过最大持仓笔数限制":"Failed to place an order: Exceeded the maximum number of positions limit",
	"当前未获取到行情":"No market information is currently available",
	"尚未申购或理赔保险":"Not yet subscribed or claims insurance",
	"申购的保险T+1生效":"The purchased insurance T+1 becomes effective",
	"受保资产为零":"Zero insured assets",
	"受保资产小于等于可下单条件":"The insured assets are less than or equal to the condition that the order can be placed",
	"您已超过持仓限制，暂停下单。":"You have exceeded the position limit and the order is suspended.",
	"未知的险种类型":"Unknown type of insurance",
	"超过最大持仓数量限制":"Exceeded the maximum number of positions limit",
	"交易中的订单大于最大挂单数量":"The order in the transaction is greater than the maximum number of pending orders",
	"新闻不存在":"News does not exist",
	"无此币种":"No such currency",
	"该币不是法币":"The currency is not a legal currency",
	"用户名不能为空":"Username can not be empty",
	"名称不能为空":"Name is required",
	"账号不能为空":"Account cannot be empty",
	"资产不能为空":"Asset cannot be empty",
	"找不到此交易账号的用户":"Can't find the user with this trading account",
	"此用户还未通过实名认证":"This user has not passed the real-name authentication",
	"币种不存在":"Currency does not exist",
	"该币不是法币":"The currency is not a legal currency",
	"商家名称已存在":"Business name already exists",
	"此法币":"This fiat currency",
	"此用户已是此法币商家":"This user is already a merchant of this fiat currency",
	"申请成为商家，扣除USDT":"Apply to become a merchant, deduct USDT",
	"当前未获取到行情":"No market information is currently available",
	"系统配置错误，请联系系统管理员":"System configuration error, please contact the system administrator",
	"账号不能为空":"Account cannot be empty",
	"发送成功":"Sent successfully",
	"账号不能为空":"Account cannot be empty",
	"账号错误":"Account error",
	"账号已存在":"Account already exists",
	"请填写账号":"Please fill in the account number",
	"【BEF】若非您本人操作，请及时修改密码。":"[NEWBIT] If it is not your own operation, please change the password in time.",
	"发送成功":"Sent successfully",
	"账号不能为空":"Account cannot be empty",
	"账号错误":"Account error",
	"账号已存在":"Account already exists",
	"发送成功":"Sent successfully",
	"发送失败":"Failed to send",
	"ip参数不正确":"ip parameter is incorrect",
	"邮箱不能为空":"E-mail can not be empty",
	"账号错误":"Account error",
	"账号已存在":"Account already exists",
	"短信验证码":"SMS verification code",
	"您还没有交易记录":"You have no transaction records",
	"非法操作,不能撤回非自己发布的信息":"Illegal operation, cannot withdraw information that is not posted by yourself",
	"非法操作:(":"Illegal operation",
	"取销卖出交易失败":"Failed to cancel the sale transaction",
	"取消买入交易,解除币币余额锁定":"Cancel the buy transaction and unlock the currency balance",
	"取消买入交易,解除法币余额锁定":"Cancel the buy transaction and unlock the fiat currency balance",
	"用户未找到":"User not found",
	"取消成功":"Cancel success",
	"取销卖出交易失败":"Failed to cancel the sale transaction",
	"取消卖出交易,解除交易余额锁定":"Cancel the sell transaction and unlock the transaction balance",
	"取消卖出交易,解除交易余额锁定":"Cancel the sell transaction and unlock the transaction balance",
	"取消成功":"Cancel success",
	"类型错误":"Type error",
	"交易不存在":"Transaction does not exist",
	"资金不足":"Insufficient funds",
	"取消卖出交易,解除交易余额锁定":"Cancel the sell transaction and unlock the transaction balance",
	"取消卖出交易,解除交易余额锁定":"Cancel the sell transaction and unlock the transaction balance",
	"数据未找到":"Data not found",
	"请先添加钱包":"Please add wallet first",
	"您的币不足":"You don’t have enough coins",
	"提交卖出记录扣除":"Submission of sold records for deduction",
	"你今天的交易额度已达到上限!":"Your trading limit today has reached the upper limit!",
	"数据未找到":"Data not found",
	"余额不足":"Insufficient balance",
	"提交卖入记录扣除":"Submit a deduction from the sales record",
	"提交卖出记录(增加冻结)":"Submit selling records (increase freeze)",
	"价格和数量必须大于0":"Price and quantity must be greater than 0",
	"您的币不足":"You don’t have enough coins",
	"您的冻结资金异常，禁止挂卖":"Your frozen funds are abnormal, it is forbidden to sell",
	"真实姓名必须填写":"Real name must be filled in",
	"收款信息至少选填一项":"Fill in at least one item of payment information",
	"保存成功":"Saved successfully",
	"密码错误":"wrong password",
	"当前行情小于等于零!":"The current market is less than or equal to zero!",
	"资产兑换,减少持有币法币:":"Asset exchange, reduce holding currency and legal currency",
	"兑换数量大于持有资产!":"The exchange amount is greater than the holding assets!",
	"资产兑换,减少":"Asset exchange, reduction",
	"资产兑换,增加USDT杠杆币":"Asset exchange, increase USDT leveraged currency",
	"扣除手续费":"Deduction fee",
	"法币数量:":"Number of legal currency",
	"兑换数量大于剩余数量!":"The exchanged quantity is greater than the remaining quantity!",
	"资产兑换成功!":"The asset exchange is successful!",
	"参数错误!":"Parameter error!",
	"通证兑换,杠杆币增加":"Token exchange, increased leveraged currency",
	"通证兑换杠杆币增加失败":"Token exchange leveraged currency increase failed",
	"通证兑换成功!":"The token exchange is successful!",
	"必填项信息不完整":"Required information is incomplete",
	"两次输入密码不一致":"The two passwords are inconsistent",
	"此用户不存在":"This user does not exist",
	"此交易账号已经设置":"This trading account has been set up",
	"此账号已经存在":"This account already exists",
	"交易账号设置成功":"The trading account is set successfully",
	"邮箱绑定成功":"Mailbox binding successfully",
	"手机绑定成功":"Mobile phone binding successfully",
	"手势密码添加成功":"Gesture password added successfully",
	"两次手势密码不相同":"The two gesture passwords are not the same",
	"取消手势密码成功":"Cancel the gesture password successfully",
	"手势密码至少连接6个点":"At least 6 points connected by gesture password",
	"密码只能在6-16位之间":"Password can only be between 6-16 digits",
	"两次密码不一致":"The two passwords are inconsistent",
	"交易密码设置成功":"The transaction password is set successfully",
	"暂时还没有邀请排行榜，快去邀请吧":"There is no invitation leaderboard yet, please go and invite",
	"会员未找到":"Member not found",
	"该身份证号已实名认证过":"The ID number has been verified by real name",
	"你已绑定，不可更改!":"You are bound and cannot be changed!",
	"该地址已被绑定,请重新输入":"The address has been bound, please re-enter",
	"绑定成功!":"The binding is successful!",
	"请提交完整的信息":"Please submit complete information",
	"提交成功，等待审核":"Submitted successfully, waiting for review",
	"退出登录成功":"Log out successfully",
	"请输入支付密码":"Please enter payment password",
	"参数错误":"Parameter error",
	"无需升级":"No need to upgrade",
	"兑换成功":"Successful redemption",
	"暂无钱包":"No wallet",
	"支付密码错误":"Incorrect payment password",
	"余额不足":"Insufficient balance",
	"升级成功":"update successed",
	"该地址别人已经绑定过了":"This address has already been bound by someone else",
	"更新成功":"update completed",
	"请重试":"Please try again",
	"推荐用户不存在":"The recommended user does not exist",
	"请把参数填写完整":"Please fill in the parameters completely",
	"转入账户不能为空":"The transfer account cannot be empty",
	"密码不能为空":"password can not be blank",
	"转入数量不能为空":"The transferred quantity cannot be empty",
	"密码修改成功":"Password reset complete",
	"暂时还没有添加币种":"No currency has been added yet",
	"此币种不存在":"This currency does not exist",
	"已经有此提币地址":"There is already this withdrawal address",
	"添加提币地址成功":"Succeeded in adding withdrawal address",
	"此提币地址不存在":"This withdrawal address does not exist",
	"您没有权限删除此地址":"You do not have permission to delete this address",
	"删除提币地址成功":"Successfully delete the withdrawal address",
	"输入的金额不能为负数":"The amount entered cannot be a negative number",
	"您有正在进行中的杆杠交易,不能进行此操作":"You have an ongoing leverage transaction and cannot perform this operation",
	"钱包不存在":"Wallet does not exist",
	"划转成功":"Successful transfer",
	"操作失败:":"operation failed",
	"操作失败":"operation failed",
	"操作失败:Insufficient wallet balance":"operation failed：Insufficient wallet balance",
	"Insufficient wallet balance":"Insufficient wallet balance",
	"下单数量必须是10的整数倍":"The order quantity must be an integral multiple of 10",
	"Parameter error":"Parameter error",
	"提币申请已成功，等待审核":"The withdrawal application has been successful and is waiting for review",
	"两次密码不一致":"The two passwords are inconsistent",
	"数量不能小于等于0":"The quantity cannot be less than or equal to 0",
	"价格不能小于等于0":"Price cannot be less than or equal to 0",
	"兑换数量必须大于0":"The exchange quantity must be greater than 0",
	"钱包不存在":"Wallet does not exist",
	"转账至交易所钱包":"Transfer to exchange wallet",
	"支付密码错误":"Incorrect payment password",
	"钱包已添加,请勿重复添加":"The wallet has been added, please do not add it repeatedly",
	"您还没有钱包":"You don't have a wallet yet",
	"接收来自交易所的转账":"Receive transfers from exchanges",
	"地址输入有误":"The address is entered incorrectly",
	"请输入正确的值":"Please enter the correct value",
	"地址输入有误1":"The address is entered incorrectly 1",
	"不能转账给自己":"Can't transfer money to myself",
	"支付密码错误":"Incorrect payment password",
	"扣除卖方":"Deduct the seller!",
	"扣除卖方!":"Deduct the seller!",
	"买方增加":"Buyers increase!",
	"买方增加!":"Buyers increase!",
	"申请提币冻结余额":"Apply for withdrawal to freeze balance!",
	"申请提币冻结余额!":"Apply for withdrawal to freeze balance!",
	"币币 划转 币币":"Currency currency transfer currency currency",
	"币币 划转 合约":"Currency transfer contract",
	"币币 划转 秒合约":"Currency transfer second contract",
	"币币 划转 法币":"Currency currency transfer legal currency",
	"法币 划转 法币":"Fiat currency transfer",
	"法币 划转 币币":"Fiat currency transfer currency currency",
	"法币 划转 合约":"Legal currency transfer contract",
	"法币 划转 秒合约":"Fiat currency transfer second contract",
	"秒合约 划转 秒合约":"Second Contract Transfer Second Contract",
	"秒合约 划转 币币":"Second contract transfer currency",
	"秒合约 划转 法币":"Second contract transfer fiat currency",
	"秒合约 划转 合约":"Second contract transfer contract",
	"合约 划转 合约":"Contract transfer contract",
	"合约 划转 币币":"Contract transfer currency",
	"合约 划转 秒合约":"Contract transfer second contract",
	"合约 划转 法币":"Contract transfer fiat currency",
	"提交卖出记录(增加冻结)":"Submit selling records (increase freeze)",
	"提交卖出记录扣除":"Submission of sold records for deduction",
	"平仓资金处理":"Liquidation funds processing",
	"请先勾选同意用户协议":"Please check to agree to the user agreement",
	"您有未平仓的交易,操作禁止":"You have open trades, operations are prohibited",
	"操作失败:您有未平仓的交易,操作禁止":"Operation failed: you have an open trade, the operation is prohibited",
	"购买失败，购买金额必须大于":"Purchase failed, purchase amount must be greater than",
	"并且小于":"And less than",
	"用户购买保险":"User purchase insurance",
	"您的验证码是：":"Your verification code is:",
	"发送失败:":"Failed to send",
	"提交":"submit",
	"杠杆交易,价格":"Leveraged trading, price",
	",扣除保证金":", Deduct the margin",
	"杠杆交易,扣除手续费":"Leverage trading, deduction of handling fees",
	"修改邮箱":"Modify email",
	"您输入的邮箱或手机号不符合规则":"The email or phone number you entered does not meet the rules",
	"验证码":"Verification code",
	"秒":"second",
	"卖方增加法币":"Seller increases fiat currency",
	"后台审核充值通过":"Backstage review and recharge passed",
	"申请提币扣除余额":"Apply for withdrawal to deduct the balance",
	"秒合约订单平仓,盈利结算":"Second contract order closing, profit settlement",
	"秒合约订单,亏损结算":"Second contract order, loss settlement",
	"秒合约下单扣除":"Second contract order deduction",
	"下单扣除":"order deduction",
	"%手续费":"% Handling fee",
	"秒合约下单扣除本金":"Second contract orders deduct the principal",
	"停止售出":"Stop ",
	"买方扣除币币余额":"The buyer deducts the currency balance",
	"买方扣除法币余额":"The buyer deducts the fiat currency balance",
	"买方扣除合约余额":"Buyer deducts contract balance",
	"买方扣除秒合约余额":"The buyer deducts the second contract balance",
	"用户中心":"User Center",
	"注册成功,钱包状态：1":"Registration is successful, wallet status:1",
	"注册成功,钱包状态：2":"Registration is successful, wallet status:2",
	"注册成功,钱包状态：0":"Registration is successful, wallet status:0",
	"调节":"Adjust",
	"账户余额:":"account Balance ",
	"账户锁定余额:":"account locked balance ",
	"秒合约":"Second contract",
	"法币":"fiat currency",
	"杠杆":"lever",
	"币币":"coins",
	"请实名认证":"Please real-name authentication",
	"您的实名认证还未通过！":"Your real-name authentication has not passed!",
	"暂不支持该币种":"This currency is not currently supported",
	"充值":"Recharge",
	"余额不足":"Insufficient balance",
	"操作成功":"Successful operation",
	"取消成功":"Cancel success",
	"提交成功":"Submitted successfully",
	"最大下单数量不能超过:":"The maximum number of orders cannot exceed:",
	"投资数额 的最小长度为":"The minimum length of investment amount is ",
	"位":"Bit",
	"杠杆买入委托撤单,退回手续费":"Cancellation of leveraged buy orders, refund of handling fee",
	"杠杆买入委托撤单,退回保证金":"Cancellation of leveraged buy orders and refund of margin",
	"秒合约买入委托撤单,退回手续费":"Second contract buy order cancellation, refund of handling fee",
	"秒合约买入委托撤单,退回保证金":"Second contract buy order cancellation, return margin",
	"法币买入委托撤单,退回手续费":"Cancellation of fiat currency buy orders, refund of handling fee",
	"法币买入委托撤单,退回保证金":"Cancellation of fiat purchase orders and refund of margin",
	"币币买入委托撤单,退回手续费":"Cancellation of token purchase orders, refund of handling fee",
	"币币买入委托撤单,退回保证金":"Cancellation of token purchase orders and refund of deposit",
	"订单平仓结算,平局结算":"order closing settlement, tie settlement",
	"订单平仓,盈利结算":"order closing, profit settlement",
	"Account already exists":"賬戶已存在",
	"投资数额 字段必须填写":"The investment amount field must be filled in",
	"提币成功":"Withdraw successfully",
	"Operation successful":"Operation successful",
	"加载中...":"Loading...",
	"加载更多":"load more",
	"没有更多了...":"No more...",
	"确定平仓？":"Are you sure to close the position?",
	"请输入支付密码":"Please enter payment password",
	"请确认支付密码":"Please confirm payment password",
	"发行量":"Circulation",
	"请登录":"please sign in",
	"开始申购":"Apply start",
	"申购币种":"Subscription currency",
	"开始申购时间":"Application start time",
	"预计上线时间":"Expected launch time",
	"结束申购时间":"Application end time",
	"项目预热":"Project preheat",
	"公布结果":"Results release",
	"USDT余额不足":"Insufficient USDT balance",
	"BTC余额不足":"Insufficient BTC balance",
	"ETH余额不足":"Insufficient ETH balance",
	"申购成功":"Successful subscription",
	"兑换成功":"Successful redemption",
	"全部":"All",
	"立即申购":"Apply now",
	"结束申购":"Apply close",
	"申购周期":"Subscription cycle",
	"项目详情":"Project details",
	"请输入申购数量":"Please enter the purchase quantity",
	"申购确认":"Purchase confirmation",
	"确定":"OK",
	"取消":"Cancel",
	"您计划申购":"You plan to subscribe",
	"BEF钱包未找到":"BEF wallet not found",
	"USDT钱包未找到":"USDT wallet not found",
	"BTC钱包未找到":"BTC wallet not found",
	"ETH钱包未找到":"ETH wallet not found",
	"USDT 兑换 BEF ":"Exchange USDT to BEF",
	"BTC 兑换 BEF ":"Exchange BTC to BEF",
	"ETH 兑换 BEF ":"Exchange ETH to BEF",
	"申购历史":"Purchase history",
	"认购价格":"Subscription price",
	"认购数量":"Subscription quantity",
	"认购时间":"Subscription time",
	"结束时间":"End Time",
	"状态":"status",
	"解锁":"Unlock",
	"已完成":"Completed",
	"已锁仓":"Locked",
	"暂无记录":"No records",
	"操作":"Operating",
	"价值":"value",
	"认购币种":"Subscription currency",
	"申购记录":"Purchase record",
	"可用余额不足":"Insufficient available balance",
	"申购数量异常":"Abnormal subscription quantity",
	"认购异常":"Subscription exception",
	"认购成功":"Successfully subscribed",
	"下单数量必须是100的整数倍":"The order quantity must be an integral multiple of 100",
	"认购解锁到账":"Subscription unlocked",
	"充值自动到账":"Recharge automatically arrives",
	"发送失败。未包含正确的手机号":"Failed to send. Does not contain the correct phone number",
	"发送失败。余额不足":"Failed to send. Insufficient balance",
	"认购付款":"Subscription payment",
	"当前价值":"Current value",
	"解锁时间":"Unlock time",
	"天":"daya",
	"倒计时":"Countdown",
	"预计收益":"Estimated income",
	"资产记录":"Asset record",
	"知道了":"Got it",
	"冻结剩余时间":"Freeze remaining time",
	"小时":"hour",
	"aaaaaaaaaaaa":"1111111"
}
}