export default {
    "siteName": "NEWBIT",
    "scan": "掃一掃",
    "exchangeRate": "匯率",
    "none": "",
    "code": "發送驗證碼",
    "send": "發送驗證碼",
    "inMobile":"请您在移动端反馈",
    "resend": "{number}秒後重新發送",
    "welcome": "Hi,歡迎來到 NEWBIT",
    "pwd": "密碼",
    "accounts": "賬號",
    "registers": "註冊",
    "regiterStep1": "立即註冊",
    "regiterStep2": "保存資料",
    "xieyi": "我已閱讀並同意",
    "xieyi2": "《用戶協議》",
    "transferFromChange":"從幣幣賬戶轉入",
    "transferChange":"轉出到幣幣賬戶",
    "transferOk":"劃轉完成",
    "xieyi1": {
    "first": "國籍信息註冊後不能修改",
        "second": "驗證郵件可能被判定為垃圾郵件，請注意查收",
        "third": "請妥善保管您的賬號信息和密碼",
        "fourth": "請勿使用和其他網站相同的登錄密碼",
        "fifth": ""
},
    "confirm": "確認",
    "logins": "登錄",
    "notice": "公告",
    "more": "加載更多",
    "nomore": "暫無更多",
    "time": "時間",
    "price": "價格",
    "number": "數量",
    "info": "基本信息",
    "link": "相關鏈接",
    "cfprice": "眾籌價格",
    "totalc": "流通總量",
    "bname": "幣種名稱",
    "query": "區塊查詢",
    "dealed": "已成交",
    "notdeal": "未成交",
    "handicap": "盤口",
    "rtt": "實時成交",
    "timescreen": "時間篩選",
    "dealscreen": "交易對篩選",
    "dirscreen": "方向篩選",
    "to": "至",
    "startdate": "開始日期",
    "overdate": "結束日期",
    "do": "操作",
    "revoke": "撤銷",
    "nodata": "暫無數據",
    "loading": "加載中",
    "status": "狀態",
    "rate": "手續費",
    "back": "返回",
    "alipay": "支付寶",
    "wechat": "微信",
    "bankcard": "銀行卡",
    "inpCur": "搜索",
    "minAm": "最小數量",
    "regidters": "歡迎註冊",
    "alltread": "全站交易",
    "treadnum": "交易數量",
    "treaddetail": "成交明細",
    "tnum": "成交數量",
    "lastweek": "最近一周",
    "reload": "刷新",
    "dealtime": "成交時間",
    "way": "委託方式",
    "torg": "交易所",
    "dealno": "委託編號",
    "ttotal": "成交金額",
    "auto": "自助委託",
    "sum": "總計",
    "traedoff": "該交易對已下架，請重新切換交易對",
    "piece": "張",
    "hand": "手",
    "pbuynum": "請輸入購買張數",
    "bestsell": "以市場最優價格賣出",
    "lowbuy": "以市場最低價買入",
    "transin": "轉入",
    "transout": "轉出",
    "tansinmoney": "轉入本金",
    "tansoutmoney": "轉出本金",
    "from": "從",
    "jingdu": "精度",
    "m": "分鐘",
    "day": "天",
    "week": "週",
    "year": "年",
    "mon": "月",
    "hour": "時",
    "header": {
    "home": "首頁",
        "c2c": "法幣交易",
        "match": "合約大賽",
        "bbs": "論壇",
        "currency": "幣幣交易",
        "assets": "我的資產",
        "setting": "安全設置",
        "help": "幫助中心",
        "complaint": "投訴留言",
        "in": "登錄",
        "up": "註冊",
        "inTourist": "遊客登錄",
        "current": "當前委託",
        "his": "歷史委託",
        "code": "邀請碼",
        "shop": "我的商舖",
        "out": "退出登錄",
        "orders": "订单",
        "center": "個人中心",
        "pro": "項目公示",
        "cun": "存幣寶",
        "vote": "投票上幣",
        "sgr": "SGR返利",
        "inset": "收款設置",
        "tradelog": "交易日誌",
        "fastrecharge": "快捷充幣"
},
    "footer": {
    "hxex": "HXEX介紹",
        "legalTrade": "法幣交易",
        "tool": "鏈接",
        "about": "關於",
        "join": "加入我們",
        "connect": "聯繫我們",
        "download": "客戶端下載",
        "explain": "條款說明",
        "xieyi": "用戶協議",
        "yinsi": "隱私條款",
        "legal": "法律聲明",
        "email_info": "官方郵箱：：btchedge8899@gmail.com",
        "email_pre": "客服：",
        "address": "新加坡© 2013-2020 NEWBIT",
        "feilv": "交易費率",
        "notice": "公告中心",
        "nhelp": "新手幫助",
        "support": "用戶支持",
        "workorder": "提交工單",
        "apply": "上幣申請",
        "gonggao": "網站公告",
        "community": "社群",
        "wechat": "微信",
        "weibo": "微博",
        "email": "商務郵箱",
        "feedback": "問題反饋",
        "about2": "公司簡介",
        "coin": "數字資產介紹",
        "service": "服务",
        "apply_url": "https://qwe.shushuiji.net/dist/#/components/noticeDetail?id=198",
        "feedback_url": "https://qwe.shushuiji.net/dist/#/components/noticeDetail?id=202",
        "about2_url": "https://qwe.shushuiji.net/dist/#/components/noticeDetail?id=208",
        "coin_url": "https://qwe.shushuiji.net/dist/#/components/noticeDetail?id=213",
        "yinsi_url": "https://qwe.shushuiji.net/dist/#/components/noticeDetail?id=219",
        "feilv_url": "https://qwe.shushuiji.net/dist/#/components/noticeDetail?id=224",
        "legal_url": "https://qwe.shushuiji.net/dist/#/components/noticeDetail?id=229"
},
    "home": {
    "with": "對",
        "myMarkets": "自選",
        "markets": "交易區",
        "pair": "幣種對",
        "market": "",
        "price": "價格",
        "change": "漲跌",
        "high": "最高價",
        "min": "最低價",
        "volume": "交易量",
        "trade": "操作",
        "toTrade": "去交易",
        "c1": "全球化的數字資產配置及交易服務",
        "c2": "遍布全球的項目拓展及運營管理體系",
        "c3": "在多個國家設有本地交易服務中心",
        "c4": "服務超過130個國家的數百萬用戶",
        "c5": "SMART-Chain資產評估模型",
        "c6": "獨立專業的區塊鏈資產研究評估體系",
        "c7": "長期跟踪產業鏈並提供最權威中立的資產分析",
        "c8": "一站式的項目進度跟踪及信息披露系統",
        "c9": "依托4年的數字資產安全風控經驗",
        "c10": "建立先行賠付機制",
        "c11": "設立投資者保護基金",
        "c12": "隨時隨地 多平台終端交易",
        "c13": "覆蓋iOS、Android、Windows多個平台，支持全業務功能",
        "c14": "香港 首爾 新加坡 東京",
        "c15": "遍布世界各地的服務中心",
        "c16": "馬上交易",
        "c17": "歡迎加入我們的團隊，共同提升，超越更好的自己，創造更好的人生價值",
        "access": "多平台終端接入",
        "cover": "覆蓋IOS、Android、Windows多個平台，支持全業務功能",
        "cooper": "合作機構",
        "vol": "24H量",
        "c01": "全球領先的數字資產交易平台",
        "c02": "為全球超過130個國家的數百萬用戶提供安全、可信賴的數字資產交易及資產管理服務",
        "c03": "安全可信賴",
        "c04": "5 年數字資產金融服務經驗",
        "c05": "專業分佈式架構和防 DDOS 攻擊系統",
        "c06": "全球生態佈局",
        "c07": "多個國家設立本土化交易服務中心",
        "c08": "打造多業務形態為一體的區塊鏈生態圈",
        "c09": "用户至上",
        "sweep": "掃碼下載",
        "android": "Android",
        "pinput": "請輸入你的郵箱",
        "atrade": "註冊一個MS GROUP全球站賬號 開始交易旅程",
        "Gcoin": "HXEX",
        "eamil1": "商務合作郵箱",
        "eamil2": "客服郵箱",
        "kefu": "在線客服",
        "img1": "1_zh.png",
        "img2": "2_zh.png",
        "img3": "3_zh.png",
        "d1": "NEWBIT 全球站，近24小時成交量<b>{number}</b>美元",
        "d2": "為全球超過130個國家的數百萬用戶提供安全、可信賴的數字資產交易及資產管理服務",
        "d3": "交易量全球前3",
        "d4": "強大的流動性 交易種類齊全",
        "d5": "6年老牌交易所",
        "d6": "6年數字資產金融服務經驗",
        "d7": "專業分佈式架構和防DDOS攻擊系統",
        "d8": "千萬級用戶規模",
        "d9": "建立先行賠付機制 設立投資者保護基金",
        "dsp1": "金融级安全",
        "dsp10": "全方位金融风控系统和防盗系统，冷热钱包、多签系统保证资金安全",
        "dsp2": "极速充提",
        "dsp20": "充值提现最快3分钟完成，24H人工在线审核，保护客户不错过最佳投资机会",
        "dsp3": "全球服务",
        "dsp30": "全球业务服务网络覆盖，助您投资全球加密资产，与全球用户交易",
        "dsp4": "严选资产",
        "dsp40": "严格选择优质加密项目，为您过滤80%极高风险项目",
        "dsp5": "随时随地开启安全交易",
        "dsp50": "覆盖 iOS 、Android 、Windows 多个平台，支持全业务功能"
},
    "loginTourist": {
        "touristLogin" : "遊客登錄須知",
        "invite": "1. 遊客帳戶可使用所有功能",
        "invite1": "2. 試玩帳戶不可充值",
        "invite2": "3. 試玩帳戶不可充值",
        "invite3": "4. 试玩账户禁止充值和提现！",
        "invite4": "5. ",
        "autologin": "自動登錄"
    },
    "login": {
    "email": "郵箱登錄",
        "phone": "手機登錄",
        "welcome": "歡迎登錄",
        "account": "賬號",
        "forget": "忘記密碼",
        "dont": "還未註冊？",
        "invite": "立即註冊，在全球領先的數字資產交易平台開始交易",
        "autologin": "自动登錄"
},
    "register": {
    "phone": "手機號註冊",
        "email": "郵箱註冊",
        "phonenum": "請輸入手機號",
        "emailnum": "請輸入郵箱",
        "codenum": "請輸入驗證碼",
        "notice": "請先查看用戶協議，並勾選選項框，才可點擊發送驗證碼",
        "sendcode": "發送驗證碼",
        "logpwd": "請輸入登錄密碼",
        "repwd": "請確認密碼",
        "invitecode": "請輸入邀請碼",
        "xuan": "選填",
        "agree": "我已閱讀並同意",
        "pwd": "密碼在6-16位之間,由數字和字母組成",
        "mian": "《用戶協議》",
        "gologin": "已有賬號，前往登錄"
},
    "forget": {
    "fpwd": "忘記密碼",
        "getcode": "獲取驗證碼",
        "setpwd": "設置密碼",
        "inpwd": "請輸入密碼",
        "repwd": "請再次輸入密碼"
},
    "lpwd": {
    "pwd": "請輸入密碼",
        "setpwd": "設置資金密碼",
        "resetpwd": "重置資金密碼",
        "oldpwd": "請輸入原密碼",
        "newpwd": "請輸入新密碼",
        "repwd": "請再次輸入密碼",
        "spwd": "設置密碼",
        "rpwd": "重置密碼"
},
    "bdemail": {
    "bindemail": "綁定郵箱",
        "emailbox": "郵箱",
        "surebind": "確認綁定"
},
    "bdphone": {
    "bindphone": "綁定手機號",
        "phone": "手機號"
},
    "lgpwd": {
    "setpwd": "修改密碼",
        "renewpwd": "請再次輸入新密碼"
},
    "security": {
    "lever": "您的賬號安全等級 : ",
        "wan": "完善更多資料，保證賬號安全",
        "invite": "我的邀請碼",
        "copy": "複製邀請碼",
        "phone": "修改手機號",
        "email": "綁定郵箱",
        "logpwd": "登錄密碼",
        "zipwd": "資金密碼",
        "idcard": "身份認證",
        "suggest": "建議您定期更改密碼以保護賬戶安全。",
        "notbaind": "未綁定",
        "havebind": "已綁定",
        "gobind": "去綁定",
        "set": "修改",
        "haveau": "已認證",
        "reau": "已拒絕(請重新認證)",
        "goau": "去認證",
        "auing": "審核中",
        "goset": "去設置",
        "accountset": "賬戶設置",
        "setlogin": "設置登錄驗證方式",
        "setting": "設置",
        "setemail": "修改郵箱",
        "tbpw": "提幣密碼",
        "tip1": "請設置提幣專用密碼，建議提現密碼區別於登錄密碼。",
        "google": "谷歌驗證器",
        "bindGoogle": "綁定谷歌驗證器",
        "tip2": "用於登錄、提幣、找回密碼、修改安全設置時進行安全驗證。",
        "beforename": "請先進行實名認證。",
        "authentication": "認證",
        "myaddress": "我的地址",
        "low": "低",
        "fbPhone": "請先綁定手機號",
        "fbEmail": "請先綁定郵箱",
        "googleKey": "谷歌密鑰"
},
    "auth": {
    "auth": "身份認證",
        "back": "返回",
        "refause": "拒絕理由",
        "name": "姓名",
        "idnum": "證件號",
        "img": "請上傳證件的正反面及手持證件的正面照。",
        "ising": "審核中...",
        "ised": "已認證!",
        "truename": "請輸入真實姓名",
        "truenum": "請輸入證件號",
        "up": "提交",
        "note": "請上傳您的身份證、護照和駕照照片。"
},
    "auth2": {
    "tip": "請注意用戶認證信息一經驗證不能修改，請務必如實填寫準確信息認真填些個人信息",
        "name": "認證姓名",
        "id": "個人身份認證",
        "number": "證件號",
        "type": "證件類型",
        "content": "請輸入內容",
        "complete": "恭喜您已完成實名認證",
        "nextstep": "進行下一步身份認證",
        "positive": "證件照正面",
        "mb": "僅支持jpg、png、不超過4mb",
        "click": "點擊上傳證件照",
        "back": "證件照背面",
        "hand": "上傳手持證件照",
        "tip2": "請提供一張您手持證件照的照片，在同張照片中也要包含有“HXEX”、日期和您的簽名的註釋。請確保您的臉部清晰可見，且所有證件詳細信息都清楚可讀",
        "face": "面部清晰可見無遮擋",
        "clearly": "照片清晰可見",
        "notes": "帶有[HXEX]的文字註釋",
        "complete2": "恭喜您已完成身份認證",
        "complete3": "進行下一步資產認證",
        "inaudit": "身份認證提交成功，審核中",
        "notpass": "身份認證審未通過",
        "again": "請重新進行身份認證",
        "asset": "資產認證審核中",
        "nasset": "資產認證未通過",
        "again2": "請重新進行資產認證",
        "complete4": "恭喜您，資產認證審核通過",
        "reminder": "溫馨提示",
        "tip3": "當您的資產估值大於20BTC時，可以進行第三級實名認證，獲得更多提幣限額。",
        "tip4": " 請提供一張您本人的HXEX賬號的資產明細截圖或者照片，圖片中包含您的賬戶名、uid、持倉詳情、當前時間。所有信息清晰可讀。",
        "screenshot": "上傳資產截圖信息",
        "nowLever": "當前認證等級",
        "authCard": "身份證",
        "hu": "護照",
        "driverCard": "駕照",
        "other": "其他",
        "one": "一級",
        "two": "二級",
        "three": "三級",
        "selectType": "請選擇證件類型",
        "enterCardId": "請填寫證件號",
        "noWord": "證件號不能含有漢字",
        "enterRealName": "請填寫真實姓名",
        "noNumber": "姓名中不能含有數字",
        "noFour": "圖片不能超過4M",
        "frontImg": "請上傳證件照正面",
        "reverseImg": "請填上傳證件照背面",
        "handImg": "請手持證件照",
        "assetImg": "請上傳資產截圖"
},
    "legal": {
    "buy": "購買",
        "sell": "出售",
        "shoper": "商家",
        "number": "數量",
        "limit": "限額",
        "price": "單價",
        "pay": "支付方式",
        "do": "操作",
        "record": "訂單記錄",
        "traderecord": "交易記錄",
        "tredetype": "交易類型",
        "orderstatus": "訂單狀態",
        "nofinish": "未完成",
        "finished": "已完成",
        "ceiled": "已取消",
        "payed": "已付款",
        "paywait": "待付款",
        "waitplease": "請等待買家付款",
        "ordercomplete": "訂單已完成",
        "orderceiled": "訂單已取消",
        "orderpayed": "買家已付款，請核實後確認",
        "totalmoney": "交易總額",
        "time": "時間",
        "contact": "聯繫方式",
        "buyer": "買家",
        "seller": "賣家",
        "ordertime": "下單時間",
        "reference": "參考號",
        "ceilorder": "取消交易",
        "surepay": "確認已收款",
        "confirmceil": "確認取消交易",
        "ceil": "取消",
        "confirm": "確認",
        "paysure": "付款確認",
        "otherpay": "請確認買家已向您付款",
        "payplease": "請付款，請向以下賬戶付款",
        "payedwait": "已付款，等待商家确认收款",
        "tprice": "交易單價",
        "tnumber": "交易數量",
        "cardnum": "銀行卡號",
        "wechat": "微信",
        "alipay": "支付寶",
        "account": "賬戶",
        "phone": "電話",
        "orderceil": "取消訂單",
        "mypayed": "我已付款，點擊確認",
        "notceil": "如您已向賣家付款請千萬不要取消交易",
        "youpayed": "請確認您已向賣家付款",
        "freeze": "惡意點擊將直接凍結賬戶",
        "transaction": "交易",
        "sellall": "全部賣出",
        "buyall": "全部買入",
        "conactceil": "請在15分鐘內聯繫商家付款，超出15分鐘將自動取消",
        "autoceil": "自動取消",
        "placeorder": "下單",
        "inwant": "請輸入要",
        "total": "總額",
        "ceilnot": "如果買家已向您付款請千萬不要取消交易",
        "shopaccount": "商家賬戶",
        "bankName": "開戶行"
},
    "legaltrade": {
    "paymoney": "支付貨幣",
        "paytype": "支付方式",
        "select": "請選擇",
        "back": "返回",
        "desc": "簡介",
        "connect": "聯繫對方",
        "cankao": "參考號",
        "zhihang": "開戶行支行",
        "enterzhihang": "請輸入開戶行支行",
        "money_code": "收款碼",
        "confirm_tips": "請您盡快在15分鐘內確認操作",
        "cancel_tips": "注：每日取消三次後，將限制進行法幣交易",
        "send": "發送",
        "money_change": "資金變化",
        "depth": "深度圖"
},
    "shop": {
    "myshop": "我的店鋪",
        "name": "名稱",
        "tolegal": "所屬法幣",
        "retime": "註冊時間",
        "balance": "商家餘額",
        "lockbalance": "商家凍結餘額",
        "goshop": "進入店鋪",
        "allorder": "總單數",
        "dayorder": "30日成單",
        "comorder": "完成單",
        "comrate": "完成率",
        "release": "發佈",
        "email": "郵箱認證",
        "phone": "手機",
        "truename": "實名認證",
        "high": "高級認證",
        "type": "類型",
        "mysell": "我的出售",
        "mybuy": "我的求購",
        "abnormal": "异常",
        "seeorder": "查看訂單",
        "choosetype": "請選擇類型",
        "sell": "出售",
        "buy": "求購",
        "pchoose": "請選擇",
        "minnum": "最小交易數量",
        "maxnum": "最大交易數量",
        "ordercom": "訂單已完成",
        "orderceil": "訂單已取消",
        "buyerpayed": "買家已付款，請核實後確認",
        "sellerwait": "請等待賣家確認",
        "receivepay": "確認已收款",
        "onsell": "線上出售",
        "onbuy": "線上購買",
        "fatotal": "法幣總額",
        "sceil": "秒後自動取消",
        "nomin": "不能低於最低限額",
        "nomax": "不能超出最大限額",
        "nomaxnum": "不能超出最大數量",
        "cancel": "撤銷"
},
    "lgset": {
    "lgset": "法幣交易設定"
},
    "market": {
    "quotation": "市場行情",
        "market": "市場",
        "currency": "幣種",
        "lastprice": "最新價",
        "change": "漲幅",
        "zoushi": "走勢圖"
},
    "center": {
    "xian": "限價交易",
        "shi": "市價交易",
        "available": "可用",
        "buyprice": "買入價",
        "sellprice": "賣出價",
        "buynumber": "買入量",
        "sellnumber": "賣出量",
        "tradetotal": "交易額",
        "buyin": "買",
        "sellout": "賣",
        "inbuy": "買入",
        "outsell": "賣出",
        "all": "全部",
        "cdel": "當前委託",
        "hdeal": "歷史委託",
        "pairs": "交易對",
        "direction": "方向",
        "dealtotal": "委託總額",
        "tprice": "成交均價",
        "price": "成交價",
        "nothing": "無",
        "contran": "已成交",
        "newprice": "最新價",
        "alltrade": "全站交易",
        "volume": "交易量",
        "realtime": "分時",
        "or": "或",
        "start": "開始交易",
        "hour": "24小時資金變化",
        "inflow": "主力流入",
        "outflow": "主力流出",
        "inmoney": "24流入資金",
        "outmoney": "24流出資金"
},
    "account": {
    "mult100": "數量應為100的整數倍",
        "exchange": "兌換",
        "exchangeNum": "兌換數量",
        "hideSm": "隱藏小額資產",
        "assets": "資產",
        "taccount": "交易帳戶",
        "laccount": "法幣帳戶",
        "letransaction": "秒合約帳戶",
        "totalassets": "總資產折合",
        "upaddress": "提幣地址管理",
        "freezes": "凍結",
        "conversion": "折合",
        "charging": "充幣",
        "withdraw": "提幣",
        "record": "記錄",
        "copy": "複製",
        "code": "二維碼",
        "amount": "充值數量",
        "picture": "轉帳截圖",
        "choose": "選擇",
        "select": "請選擇檔案",
        "selected": "已選擇",
        "submitRecharge": "提交充值資訊",
        "cgaddress": "充幣地址",
        "whaddress": "提幣地址",
        "notice": "溫馨提示",
        "chaddress": "選擇提幣地址",
        "range": "範圍",
        "havenum": "到賬數目",
        "tibi": "請填入正確的BTC-OMNI提幣地址",
        "minnum": "最小提幣數量為",
        "uppwd": "交易密碼",
        "call": "為保障資金安全，當您帳戶安全性原則變更、密碼修改、我們會對提幣進行人工稽核，請耐心等待工作人員電話或郵件聯繫。",
        "a1": "請勿向上述地址充值任何非",
        "a2": "資產，否則資產將不可找回。",
        "a3": "充幣僅支持simple send的方法，使用其他方法（send all）的充幣暫時無法上帳，請您諒解。",
        "a4": "您充值至上述地址後，需要整個網絡節點的確認，1次網絡確認後到賬，6次網絡確認後可提幣。",
        "a5": "最小充值金額：",
        "a6": "小於最小金額的充值將不會上帳且無法退回。",
        "a7": "您的充值地址不會經常改變，可以重複充值；如有更改，我們會儘量通過網站公告或郵件通知您。",
        "a8": "請務必確認電腦及瀏覽器安全，防止資訊被篡改或洩露。",
        "a9": "充值至上述地址後，需要確認整個網絡節點。 確認網絡後，它將到達該帳戶。 經過2個網絡確認後，您可以提取貨幣。",
        "a10": "提交充值資訊後請耐心等待工作人員稽核",
        "legal": "法幣",
        "trade": "幣幣",
        "lever": "合約",
        "choosecoin": "選擇幣種",
        "huanum": "劃轉數量",
        "mrecord": "財務記錄",
        "allRec": "總財務記錄",
        "transfer": "劃轉"
},
    "set": {
    "secset": "安全設置",
        "msgcer": "資訊認證",
        "turemsg": "為了確保您的交易正常進行，請填寫您的真實資訊",
        "tname": "真實姓名",
        "kname": "選擇開戶行",
        "cnum": "銀行卡號",
        "ailipay": "支付寶帳號",
        "wename": "微信昵稱",
        "wenum": "微信帳號",
        "bank_address": "開戶行地址",
        "ailipay_nickname": "支付寶昵稱",
        "attestation": "認證詳情",
        "setpay": "設定收款類型",
        "remarks": "備註",
        "palipay": "請輸入支付寶",
        "premarks": "請輸入備註資訊",
        "addalipaycode": "添加支付寶收款碼",
        "pwenum": "請輸入微信",
        "nameplease": "請輸入姓名",
        "addwenumcode": "添加微信收款碼",
        "add": "添加"
},
    "ad": {
    "adrecord": "留言記錄",
        "release": "發佈",
        "reply": "回復",
        "none": "暫無",
        "releaseit": "發佈留言",
        "con": "請填寫留言內容",
        "up": "提交"
},
    "lay": {
    "paccount": "請輸入帳號！",
        "notcode": "驗證碼不能為空！",
        "pwdliu": "密碼不能小於六比特！",
        "slogin": "登入成功",
        "noemail": "您的郵箱不符合規則",
        "phonenot": "您輸入的手機號帳號不符合規則！",
        "nophone": "請輸入手機號",
        "nemail": "請輸入郵箱",
        "inpwd": "請輸入密碼",
        "pwdlength": "密碼只能在6-16比特之間",
        "pwdcom": "密碼必須由數位和字母組成",
        "repwd": "請再次輸入密碼",
        "twopwd": "兩次輸入的密碼不一致",
        "inprice": "請輸入買入價",
        "noinprice": "買入價不能為0",
        "innumber": "請輸入買入量",
        "outprice": "請輸入賣出價",
        "outinprice": "賣出價不能為0",
        "outnumber": "請輸入賣出量",
        "plogin": "請登入",
        "revoke": "確認要撤單麼？",
        "sure": "確定",
        "ceil": "取消",
        "notopen": "暫未開放",
        "caddress": "請選擇提幣地址",
        "cnumber": "請輸入提幣數量",
        "cpwd": "請輸入提幣密碼",
        "minnum": "請輸入最小數量",
        "copys": "複製成功",
        "fcopy": "複製失敗",
        "recopy": "請重新複製",
        "huanum": "請輸入劃轉數量",
        "truename": "真實姓名必須填寫",
        "pliu": "請填寫留言內容",
        "nameplease": "請輸入姓名！",
        "idplease": "請輸入證件號！",
        "comid": "請上傳完整的證件！",
        "intermediate": "中",
        "high": "高",
        "strong": "强",
        "buymoney": "請輸入欲購買總額",
        "buynum": "請輸入欲購買數量",
        "sellmoney": "請輸入欲出售總額",
        "sellnum": "請輸入欲出售數量",
        "tname": "請先進行實名認證再下單",
        "payset": "請先設定收款管道",
        "mpay": "請選擇支付方式",
        "mprice": "請輸入價格",
        "mnum": "請輸入數量",
        "lessnum": "最小數量不能小於",
        "morenum": "數量不能超出",
        "notnum": "數量不能大於總數量",
        "confirm": "請確認您的操作！",
		
		"canshucuowu":"參數錯誤",
		"chongzhiyitijiao":"充值申請已提交",
		"chongzhisqb":"充值申請提交失敗",
		"nodi100":"輸入的劃轉數量不能低於100",
		"pinputjine":"請輸入金額",
		"selcztype":"請選擇充值方式",
		"updateok":"更新成功",
		"ishuazhuan":"是否確認劃轉？",
		"pinputokphone":"請輸入正確的手機號",
		"usernofind":"用戶未找到"
},
    "c2c": {
    "myTc2c": "我交易的C2C",
        "pc2c": "發佈C2C",
        "mypc2c": "我發佈的C2C",
        "timer": "時間",
        "type": "類型",
        "price": "價格",
        "num": "數量",
        "totalmoney": "總金額",
        "minNums": "最小數量",
        "payType": "支付方式",
        "cancelp": "取消發佈",
        "buy": "購買",
        "sell": "出售",
        "currency": "幣種",
        "onePrice": "單價",
        "minTime": "最小時間",
        "pTime": "發佈時間",
        "opera": "操作",
        "money": "金額",
        "enter": "請輸入",
        "numIs": "數量在",
        "rangeL": "之間",
        "moneyIs": "金額在",
        "cancel": "取消",
        "maxNum": "數量不能超出",
        "minNum": "數量不能少於",
        "maxMoney": "金額不能超出",
        "minMoney": "金額不能少於",
        "notMore": "暫無更多",
        "state": "狀態",
        "cancelOrder": "取消訂單",
        "conPay": "確認付款",
        "conReceive": "確認收款",
        "payed": "已付款",
        "completed": "已完成",
        "canceled": "已取消",
        "detail": "詳情",
        "payAccount": "支付帳戶",
        "selCu": "請選擇幣種",
        "ailipay": "支付寶",
        "wx": "微信",
        "bankcard": "銀行卡",
        "pBuy": "發佈求購",
        "pSell": "發佈出售",
        "enterPrice": "請輸入價格",
        "enterNum": "請輸入數量",
        "enterMinnum": "請輸入最小數量",
        "tips": "最小數量不能大於總數量",
        "name": "姓名",
        "wxName": "微信昵稱",
        "bankName": "銀行名稱",
        "connect": "聯繫方式"
},
    "forLegalPsw": {
    "fpwd": "忘記資金密碼",
        "setpwd": "設定資金密碼"
},
    "cfc": {
    "balance": "餘額",
        "account": "轉出帳號",
        "num": "轉出數量",
        "pla_account": "請輸入轉出帳號",
        "pla_num": "請輸入轉出數量"
},
    "tpc": {
    "hot": "熱門項目",
        "all": "全部項目",
        "nodata": "暫無數據",
        "search": "蒐索",
        "tips": "*當前頁展示內容為項目方自行填寫，HXEX不對此內容真實性負責",
        "time": "發行時間",
        "total": "發行總量",
        "circulation": "代幣流通量",
        "price": "發行價",
        "web": "官網",
        "book": "白皮書",
        "tool": "社交工具",
        "recommend": "推薦機构",
        "introduce": "項目介紹",
        "notice": "公告",
        "rr": "項目研報",
        "news": "新聞資訊",
        "author": "作者：",
        "browse": "流覽量："
},
    "finance": {
    "selectState": "選擇鎖定狀態",
        "front": "變動前",
        "after": "變動後",
        "info": "資訊"
},
    "uc": {
    "ode": "邀請碼",
        "mymoney": "我的資金",
        "entrust": "委託管理",
        "deal": "成交管理",
        "pay": "支付方式",
        "id": "身份認證",
        "safe": "安全設置",
        "invitate": "邀請返利",
        "finance": "財務記錄",
        "loginlog": "登入日誌"
},
    "usercenter": {
    "change": "幣幣帳戶",
        "legal": "法幣帳戶",
        "lever": "合約帳戶"
},
    "account2": {
    "list": "提幣清單",
        "address": "地址標籤",
        "code": "驗證碼",
        "addtime": "添加時間",
        "suretime": "確認時間",
        "explain": "說明"
},
    "withdrawList": {
    "sel": "幣種篩選",
        "submitTime": "提交時間",
        "checkTime": "稽核時間",
        "tibiNum": "提幣數量",
        "applying": "申請中",
        "hash": "雜湊值",
        "agreeWait": "已同意，等待上鏈",
        "lianSucc": "鏈上成功",
        "lianFail": "鏈上失敗",
        "bohui": "駁回",
        "zhanSucc": "站內成功"
},
    "wAddress": {
    "enterAddress": "請輸入提幣地址",
        "enterMemo": "請輸入地址標籤",
        "enterMark": "請輸入備註",
        "tips": "溫馨提示：USDT請使用btc幣種添加地址，ERC20代幣請使用ETH地址添加",
        "addressList": "地址清單",
        "memo": "地址標籤",
        "del": "删除",
        "nospace": "提幣地址不能含有空格",
        "nospace02": "地址標籤不能含有空格",
        "nospace03": "地址標籤必須是數位或字母或者字母和數位組合",
        "allNum": "地址標籤必須是純數位",
        "bankNum": "銀行卡帳號",
        "enterCardNum": "請輸入銀行卡帳號",
        "addCard": "添加銀行收款碼",
        "addcode": "請上傳收款碼圖片",
        "moreAdd": "最多上傳",
        "pics": "張圖片"
},
    "authDetail": {
    "payType": "收款管道",
        "noreceiveType": "您還未添加收款管道，請去添加！"
},
    "identity": {
    "anquan": "帳號安全",
        "verify": "登入驗證",
        "openVerify": "開啟驗證",
        "closeVerify": "關閉驗證",
        "enterGooole": "請輸入穀歌金鑰",
        "verityType": "請選擇驗證管道"
},
    "caiwu": {
    "lock": "鎖定",
        "nolock": "未鎖定",
        "status": "請選擇鎖定狀態",
        "selCu": "請選擇幣種"
},
    "lang": {
        "en": "English",
        "kr": "한글",
        "jp": "日本語",
        "zh": "简体中文",
        "hk": "繁體中文",
        "spa": "España",
        "ara":"Arabia"
},
    "google": {
    "code": "穀歌驗證碼",
        "entergoogle": "請輸入穀歌驗證碼"
},
    "transfer": {
    "select": "請選擇劃轉方向",
        "change": "從幣幣帳戶到法幣帳戶",
        "legal": "從法幣帳戶到幣幣帳戶",
        "change_balance": "幣幣帳戶可用",
        "pswType": "密碼為6比特純數位",
        "newPsw": "新密碼須為6比特純數位",
        "changePhone": "更改手機號",
        "changeEmail": "更改郵箱",
        "emailCode": "請輸入郵箱驗證碼",
        "newPhone": "請輸入新手機號",
        "newEmail": "請輸入新郵箱號",
        "phoneCode": "請輸入手機驗證碼",
        "selCu": "請選擇幣種",
        "ok": "資金劃轉成功",
        "error": "資金劃轉失敗"
},
    "register01": {
    "country": "所屬國家",
        "selectCountry": "國籍資訊注册後不可修改，務必如實選擇",
        "noAccount": "還沒有帳號，前往"
},
    "invite": {
    "welcome": "一起賺取高額回報",
        "effective": "有效",
        "invalid": "無效",
        "list": "榜單",
        "commission": "獲得傭金",
        "link": "我的專屬推廣連結",
        "share": "複製連結分享",
        "mypromotion": "我的推廣記錄",
        "account": "被邀請人帳號",
        "nomore": "暫無更多數據",
        "rules": "活動規則",
        "tip1": "被邀人必須在通過二級認證；",
        "tip2_0": "好友接受邀請後，每產生一筆真實交易手續費，會產生",
        "tip2_1": "的返傭",
        "tip3": "結算方式為按照邀請制度進行結算",
        "tip4_0": "邀請人實際獲得的傭金將以",
        "tip4_1": "進行折合計算後返給邀請人的交易帳戶。返傭額",
        "tip4_2": " =實際產生交易量*手續費比例*返傭比例。",
        "tip5_0": "平臺將以每天北京时間08:00相應幣種交易的收盤價進行",
        "tip5_1": "換算"
},
    "crowd": {
    "mujiNum": "募集數量",
        "cur": "支持幣種",
        "endTime": "結束時間",
        "intro": "查看項目介紹",
        "can_edu": "本場可申購的額度",
        "now_edu": "當前已申購金額",
        "my_edu": "我的申購金額",
        "his": "歷史",
        "price": "申購價格",
        "money": "申購金額",
        "yue": "帳戶餘額",
        "enterMoney": "請輸入申購金額",
        "total": "本次申購總額",
        "rule": "申購細則",
        "startBuyTime": "開始搶購時間",
        "endBuyTime": "結束搶購時間"
},
    "dep": {
    "dep_rec": "存幣記錄",
        "dep": "存幣寶",
        "tip": "存幣獲利，閑幣生財。幣不妙，亦可生寶！",
        "plan": "天存幣計畫",
        "timer": "搶購週期",
        "rate": "年化收益率",
        "lock": "已鎖",
        "join": "人參與",
        "end": "搶購已結束",
        "buy": "馬上搶購",
        "date": "存幣日期",
        "day": "天",
        "total_money": "搶購總額度",
        "sup_num": "剩餘額度",
        "top": "存幣上限",
        "one_num": "單次起購數量",
        "num": "存幣數量",
        "min": "最小",
        "max": "最大",
        "yuji": "預計收益",
        "qiang": "搶購",
        "depEndTime": "存幣截止時間",
        "jixiTime": "計息時間",
        "lockTime": "解鎖時間",
        "enterNum": "請輸入搶購數量",
        "his": "存幣歷史",
        "money": "存幣金額",
        "cur_name": "幣種名稱",
        "do": "操作",
        "ti": "選取",
        "suoTime": "鎖倉時長",
        "leiji": "累計獲得收益",
        "rateNum": "應扣手續費數量：",
        "ti_num": "可選取數量：",
        "tiYet": "是否選取",
        "to": "至帳戶內？",
        "yes": "是",
        "no": "否"
},
    "vote": {
    "di": "第",
        "period": "期投票",
        "explain": "規則說明",
        "free": "當前免費獲得票數",
        "have": "持有",
        "num": "數量",
        "people": "支持人數",
        "tickets": "支持票數",
        "detail": "查看詳情",
        "jiangli": "投票獎勵",
        "fen": "瓜分",
        "sup_people": "人支持",
        "piao": "票",
        "vote": "投票",
        "enter": "請輸入投票票數",
        "vote_cur": "投票上幣",
        "paiming": "排名",
        "intro": "項目介紹",
        "taken": "Taken概況和分配",
        "total": "代幣總量",
        "cur_total": "當前流通總量",
        "price": "發行價格",
        "link": "相關連結",
        "web": "官方網站",
        "zh": "中文版白皮書",
        "en": "英文版白皮書"
},
    "lever": {
    "selHand": "選擇手數",
        "yijianping": "一鍵平倉",
        "phands": "請選擇手數",
        "yugu": "預估强平價",
        "transaction": "合約交易",
        "nowentrust": "當前委託",
        "hisentrust": "歷史委託",
        "lvchi": "合約持倉",
        "all": "全部",
        "or": "或",
        "gotrade": "開始交易",
        "tdrecord": "交易記錄",
        "dealed": "已成交",
        "notdeal": "未成交",
        "loading": "加載中…",
        "entotal": "委託總額",
        "canuse": "可用",
        "std": "市價交易",
        "xtd": "限價交易",
        "pprice": "請輸入價格",
        "phand": "請輸入購買手數",
        "pwd": "密碼",
        "ppwd": "請輸入交易密碼",
        "psw": "交易密碼",
        "equal": "等於",
        "times": "X",
        "timed": "倍數",
        "handbuy": "最少買入",
        "handsell": "最少賣出",
        "hands": "數量",
        "ptimes": "請選擇倍數",
        "contractVal": "交易額",
        "bail": "保證金",
        "canBail": "可用保證金",
        "charge": "交易服務費",
        "domore": "買入（做多）",
        "doshort": "賣出（做空）",
        "dmore": "做多",
        "dshort": "做空",
        "sureOd": "確認下單",
        "noless": "手數不能低於",
        "nomore": "手數不能高於",
        "tdnum": "交易量",
        "risk": "風險率",
        "allloss": "持倉總盈虧",
        "onehouse": "一鍵平倉",
        "type": "類型",
        "entrustPrice": "委託價",
        "openPrice": "開倉價",
        "nowPrice": "當前價",
        "styPrice": "止盈價",
        "stsPrice": "止損價",
        "openTime": "開倉時間",
        "weituoTime": "委託時間",
        "closeTime": "平倉時間",
        "closePrice": "平倉價",
        "revokeTime": "撤銷時間",
        "rate": "手續費",
        "nightFee": "隔夜費",
        "loss": "盈虧",
        "setloss": "設定止盈止損",
        "expectProfit": "預期盈利",
        "expectLoss": "預期虧損",
        "allClose": "全部平倉",
        "moreClose": "只平多單",
        "nullClose": "只平空單",
        "sureClose": "確認平倉？",
        "thanZearo": "設定的值必須大於0",
        "listin": "掛單中",
        "tdin": "交易中",
        "closein": "平倉中",
        "closed": "已平倉",
        "revoked": "已撤銷",
        "revokeOrder": "確認撤銷訂單麼？",
        "ping": "平倉",
        "revoke": "撤單",
        "sureping": "確定平倉？",
        "thanzone": "設定的值必須大於0",
        "zheng": "請輸入整數",
        "buyMin": "買入手數不能小於",
        "buyMax": "買入手數不能大於",
        "enterBuyPrice": "請輸入買入價格",
        "sellMin": "賣出手數不能小於",
        "sellMax": "賣出手數不能大於",
        "enterSellPrice": "請輸入賣出價格",
        "bestprice": "市場最優價格"
},
    "td1": {
    "do": "操作",
        "more": "加載更多",
        "loading": "加載中",
        "nomore": "沒有更多了",
        "nodata": "暫無數據",
        "canceil": "取消",
        "confirm": "確認",
        "status": "狀態"
},
    "accountset": {
    "name": "請填寫姓名"
},
    "sgr": {
    "sgrinfo": "SGR Demand模式介紹",
        "lock": "當前個人鎖定SGR總數量：",
        "signup": "立即注册",
        "sgrget": "當前注册可獲得鎖定SGR數量：",
        "cir": "全站流通數量",
        "loc": "全站鎖定數量",
        "richman": "今日暴富者",
        "user": "用戶",
        "amount": "贏得數量",
        "time": "時間",
        "todayrelease": "今日個人SGR待釋放數量",
        "releaserecords": "釋放記錄",
        "invite": "邀請好友",
        "link": "我的邀請連結",
        "copy": "複製連結",
        "totallock": "當前通過邀請已獲得SGR鎖定數量",
        "onelevel": "一級邀請人數",
        "twolevel": "二級邀請人數",
        "therelevel": "三級邀請人數",
        "get": "獲得",
        "rebate": "邀請返利"
},
    "miscro": {
    "quotation": "行情",
        "transaction": "秒合約交易",
        "symbol": "交易對",
        "balance": "餘額",
        "miscroAccount": "秒合約帳戶",
        "trade": "交易中",
        "buyPrice": "購買價",
        "finshPrice": "成交價",
        "loss": "預計盈虧",
        "times": "倒數計時",
        "mode": "交易模式",
        "num": "開倉數量",
        "rate": "盈利率",
        "up": "買漲",
        "down": "買跌",
        "openNum": "請輸入開倉數量",
        "success": "下單成功！",
        "c2c": "c2c帳戶",
        "complaint": "投訴建議",
        "reply": "請寫下您的問題，我們將儘快回復您…",
        "complaintList": "投訴建議清單",
        "complaintReply": "回復：",
        "complaintDescription": "請輸入描述內容",
        "resetpwd": "修改密碼",
        "foundedOn": "創建於",
        "commonProblem": "常見問題",
        "statement": "解釋說明",
        "about": "關於KiBiEx",
        "everyone": "人人都是CEO",
        "program": "KiBiEx全球合夥人計畫",
        "myMine": "我的礦場：",
        "friend": "我的礦友：",
        "myRank": "我的等級：",
        "accumulated": "累計傭金：",
        "copyLinks": "複製推廣連結",
        "moneyVein": "人脈變錢脈",
        "example": "傭金示例",
        "enterQuantity": "請輸入數量",
        "contractBalance": "合約帳戶餘額",
        "submitRepeatedly": "正在提交中，請不要反復提交",
        "alipayCode": "支付寶付款碼",
        "wechatCode": "微信付款碼",
        "realPrice": "實时價格",
        "currencyExchange": "兌出幣種",
        "currencyExchangeIn": "兌入幣種",
        "cashableBalance": "可兌出餘額：",
        "minimumCashable": "最小可兌出：",
        "maximumCashable": "最大可兌出：",
        "automaticallys": "點擊兌換之後將自動完成，不能返回",
        "match": "兌",
        "title": "資產兌換",
        "holdAssets": "持有資產",
        "dangerousCurrency": "持險生幣",
        "convertibleQuantity": "可兌換數量",
        "currencyExchanges": "幣幣兌換率：",
        "insuranceCurrency": "保險幣種",
        "insuranceType": "保險類型",
        "contractAsset": "合約資產保險額",
        "warehouses": "保險倉數量",
        "availableAssets": "可用資產：",
        "insuredAssets": "受保資產：",
        "insuredAssets1": "受保資產",
        "insuranceAssets": "保險資產：",
        "purchaseInsurance": "申購保險",
        "insuranceClaims": "保险理赔",
        "insuranceCancellation": "保險解約",
        "coinWallet": "保險幣錢包",
        "bmbWallet": "AITB錢包",
        "cumulativeCoins": "累計生幣：",
        "availableQuantity": "可用數量：",
        "rawCurrency": "生幣記錄",
        "contractInsurance": "合約保險",
        "tenThousand": "萬",
        "runningLow": "餘額不足",
        "purchase": "你輸入的數量不符合規則，你的購買額度在",
        "reach": "到",
        "between": "之間",
        "onlyEnter": "你只能輸入",
        "integersBetween": "之間的整數",
        "notReturned": "點擊申購保險，視為詳細瞭解保險條約並且同意保險倉自動生效，不能返回。",
        "settled": "受保資產虧損至無法下單時，保險理賠",
        "profitable": "倍，當資產盈利",
        "terminated": "時，保險自動解約。",
        "automatically": "當受保資產虧損50%時，必須申請保險理賠，否則無法交易，當盈利100%時，保險自動解約，保險倉每天只允許賠付兩次，超過兩次，t+1賠付",
        "termination": "保險解約後，受保資產方可兌換，解約後視為違約，保險倉自動清零。",
        "just": "正",
        "back": "反",
        "lowestNumber": "數量最低為",
        "confirmExchange": "確認兌換嗎？",
        "contractValuation": "合約帳戶資產估值",
        "secondValuation": "秒合約帳戶資產估值",
        "falshValuation": "幣幣帳戶資產估值",
        "c2cValuation": "法幣帳戶資產估值",
        "recordWithdrawal": "充提幣記錄",
        "category": "類別",
        "safetyCenter": "安全中心",
        "safeText1": "註冊、修改密碼，及安全設置時用以收取驗證簡訊",
        "safeText2": "互聯網帳號存在被盜風險，建議您定期更改密碼以保護帳戶安全。",
        "flashTrading": "幣幣兌換",
        "assetCenter": "資產中心",
        "promotionCode": "我的推廣碼",
        "loginAgain": "登入過時，請重新登入",
        "text10": "基於AITB的實时價格",
        "text11": "支付憑證"
},
    "cuy": {
    "direction": "方向",
        "total": "總計",
        "price": "價格",
        "sell": "賣",
        "buy": "買",
        "allStation": "全站交易",
        "buyPrice": "買入價",
        "buynum": "買入量",
        "sellPrice": "賣出價",
        "sellnum": "賣出量",
        "tdPrice": "交易額",
        "or": "或",
        "tdStart": "開始交易",
        "pbPrice": "請輸入買入價",
        "pbNum": "請輸入買入量",
        "psPrice": "請輸入賣出價",
        "psNum": "請輸入賣出量",
        "fixPrice": "限價交易",
        "Mtrans": "市價交易",
        "available": "可用",
        "revoke": "撤銷",
        "loading": "加載中",
        "confirmCancel": "您確定要撤銷嗎？",
        "confirm": "確定",
        "cancel": "取消",
        "nomore": "沒有更多數據了",
        "evelPrice": "成交均價"
},
    "td": {
    "buy": "購買",
        "sell": "出售",
        "currency": "幣種",
        "num": "數量",
        "time": "時間",
        "limit": "限額",
        "price": "單價",
        "method": "支付方式",
        "total": "總額",
        "inwant": "請輸入欲",
        "do": "操作",
        "more": "加載更多",
        "nomore": "沒有更多了",
        "nodata": "暫無數據",
        "trade": "交易",
        "buynum": "購買數量",
        "sellout": "賣出",
        "buyin": "買入",
        "all": "全部",
        "allbuy": "全部買入",
        "allsell": "全部賣出",
        "buytotal": "請輸入欲購買總額",
        "selltotal": "請輸入欲出售總額",
        "buyallnum": "請輸入欲購買數量",
        "sellallnum": "請輸入欲出售數量",
        "tradeTotal": "交易總額",
        "doceil": "秒後自動取消",
        "place": "下單",
        "waitpay": "待付款",
        "finished": "已完成",
        "ceiled": "已取消",
        "payed": "已付款",
        "nofinish": "未完成",
        "buyer": "買家",
        "seller": "賣家",
        "callWay": "聯系方式",
        "placeTime": "下單時間",
        "renum": "參考號",
        "canceil": "取消",
        "confirm": "確認",
        "pwd": "請輸入交易密碼",
        "coincode": "貨幣單位",
        "pcoin": "請選擇貨幣單位",
        "examine": "商家稽核中，請耐心等待"
},
    "iepn": {
    "htitle": "大量幣發售中，邀請好友共同賺幣",
        "subtitle": "平台發售大量新幣，快來立即購買，數量有限哦～",
        "netname": "智慧防疫網絡",
        "plannum": "計劃發行量",
        "totalnum": "總發行量",
        "pushednum": "已發行量",
        "remindnum": "剩餘數量",
        "hotcommond": "熱門推薦",
        "ctitle": "全球政府醫療管理機構",
        "cdsp": "醫療涉及民生，不可能迴避政府的角色。而Intelligent Epidemic Prevention Network其鏈條透明的特性，可以助力去全球醫療管理機構更好地管理醫療機關，監測國民健康，做出快速、靈活的醫療資源調配。",
        "starttime": "發售開始時間",
        "startprice": "發售價格",
        "endtime": "發售結束時間",
        "seemore": "查看更多",
        "baipishu": "技術白皮書",
        "gobuy": "去購買"
},
"apitip":{
	"参数错误":"參數錯誤",
	"数据未找到":"數據未找到",
	"请选择需求类型":"請選擇需求類型",
	"请选择交易方式":"請選擇交易方式",
	"请填写单价":"請填寫單價",
	"请填写数量":"請填寫數量",
	"请选择币种":"請選擇幣種",
	"请输入正确的交易数量或价格":"請輸入正確的交易數量或價格",
	"您还没有设置收款信息":"您還沒有設置收款信息",
	"取消次数已超出":"取消次數已超出",
	"次,明天再发布":"次,明天再發布",
	"用户钱包不存在":"用戶錢包不存在",
	"对不起，您的钱包余额不足":"對不起，您的錢包餘額不足",
	"用户发布c2c交易法币出售，扣除法币余额":"用戶發布c2c交易法幣出售，扣除法幣餘額",
	"用户发布c2c交易法币出售,锁定余额增加":"用戶發布c2c交易法幣出售,鎖定餘額增加",
	"发布成功":"發布成功",
	"无此用户":"無此用戶",
	"用户不存在":"用戶不存在",
	"参数错误2":"參數錯誤2",
	"无此币种":"無此幣種",
	"该币不是法币":"該幣不是法幣",
	"无此记录":"無此記錄",
	"您还没有通过实名认证":"您還沒有通過實名認證",
	"此条交易已完成":"此條交易已完成",
	"未找到该发布用户":"未找到該發布用戶",
	"不能操作自己的":"不能操作自己的",
	"您无此钱包账号":"您無此錢包賬號",
	"您的钱包已被锁定，请联系管理员":"您的錢包已被鎖定，請聯繫管理員",
	"检测有未完成交易，请完成后再来！":"檢測有未完成交易，請完成後再來！",
	"您的余额不足":"您的餘額不足",
	"您的法币冻结资金异常,请查看您是否有正在进行的挂单":"您的法幣凍結資金異常,請查看您是否有正在進行的掛單",
	"出售给商家法币,余额减少":"出售給商家法幣,餘額減少",
	"出售给商家法币,锁定余额增加":"出售給商家法幣,鎖定餘額增加",
	"您发布的求购信息有用户出售啦，请去 APP 查看吧～":"您發布的求購信息有用戶出售啦，請去 APP 查看吧",
	"您发布的出售信息有用户购买啦，请去 APP 查看吧～":"您發布的出售信息有用戶購買啦，請去 APP 查看吧",
	"Operation successful，请联系商家确认订单":"操作成功，請聯繫商家確認訂單",
	",错误位于第":"，錯誤位於第",
	",行":"，行",
	"用户信息不正确":"用戶信息不正確",
	"该订单已操作过，请勿重复操作":"該訂單已操作過，請勿重複操作",
	"对不起，您无权操作":"對不起，您無權操作",
	"buy":"買",
	"Operation successful，请联系卖家确认":"操作成功，請聯繫賣家確認",
	"Operation successful，订单已取消":"操作成功，訂單已取消",
	"您今天的取消次数已超出":"您今天的取消次數已超出",
	"该订单已操作，请勿取消":"該訂單已操作，請勿取消",
	"对不起，这不是您的发布信息":"對不起，這不是您的發布信息",
	"该订单还未付款或已经操作过":"該訂單還未付款或已經操作過",
	"您不能确认该订单":"您不能確認該訂單",
	"订单异常":"訂單異常",
	"该用户没有此币种钱包":"該用戶沒有此幣種錢包",
	"该买家没有此币种钱包":"該買家沒有此幣種錢包",
	"出售法币成功,扣除锁定余额":"出售法幣成功,扣除鎖定餘額",
	"购买法币成功，增加法币余额":"購買法幣成功，增加法幣餘額",
	"在":"在",
	"确认成功":"確認成功",
	"卖出法币成功":"賣出法幣成功",
	"购买法币成功":"購買法幣成功",
	"该发布信息下有交易产生无法删除":"該發布信息下有交易產生無法刪除",
	"对不起，您无权撤销此记录":"對不起，您無權撤銷此記錄",
	"用户钱包不存在":"用戶錢包不存在",
	"对不起，您的账户冻结资金不足":"對不起，您的賬戶凍結資金不足",
	"商家撤回发布法币出售":"商家撤回發布法幣出售",
	"撤回成功":"撤回成功",
	"参数错误!":"參數錯誤!",
	"转账账户不存在!":"轉賬賬戶不存在!",
	"不能给自己转账!":"不能給自己轉賬!",
	"转账数量大于剩余数量!":"轉賬數量大於剩餘數量!",
	"余额不足!":"餘額不足",
	"转账数量不能小于":"轉賬數量不能小於",
	"转账数量不能大于":"轉賬數量不能大於",
	"通证转账成功!":"通證轉賬成功",
	"必填项信息不完整":"必填項信息不完整",
	"两次输入密码不一致":"兩次輸入密碼不一致",
	"此用户不存在":"此用戶不存在",
	"此交易账号已经设置":"此交易賬號已經設置",
	"此账号已经存在":"此賬號已經存在",
	"交易账号设置成功":"交易賬號設置成功",
	"验证码错误":"驗證碼錯誤",
	"手机绑定成功":"手機綁定成功",
	"邮箱绑定成功":"郵箱綁定成功",
	"手势密码至少连接6个点":"手勢密碼至少連接6個點",
	"两次手势密码不相同":"兩次手勢密碼不相同",
	"手势密码添加成功":"手勢密碼添加成功",
	"取消手势密码成功":"取消手勢密碼成功",
	"密码只能在6-16位之间":"密碼只能在6-16位之間",
	"两次密码不一致":"兩次密碼不一致",
	"交易密码设置成功":"交易密碼設置成功",
	"暂时还没有邀请排行榜，快去邀请吧":"暫時還沒有邀請排行榜，快去邀請吧",
	"会员未找到":"會員未找到",
	"你已绑定，不可更改!":"你已綁定，不可更改!",
	"该地址已被绑定,请重新输入":"該地址已被綁定,請重新輸入",
	"绑定成功!":"綁定成功",
	"暂无钱包":"暫無錢包",
	"请提交完整的信息":"請提交完整的信息",
	"请输入合法的身份证号码":"請輸入合法的身份證號碼",
	"该身份证号已实名认证过!":"該身份證號已實名認證過",
	"您已经申请过了":"您已經申請過了",
	"提交成功，等待审核":"提交成功，等待審核",
	"支付密码错误":"支付密碼錯誤",
	"退出登录成功":"退出登錄成功",
	"请输入支付密码":"請輸入支付密碼",
	"余额不足":"餘額不足",
	"无需升级":"無需升級",
	"升级成功":"升級成功",
	"升级会员":"升級會員",
	"没有此用户":"沒有此用戶",
	"该地址别人已经绑定过了":"該地址別人已經綁定過了",
	"更新成功":"更新成功",
	"请重试":"請重試",
	"请把参数填写完整":"請把參數填寫完整",
	"推荐用户不存在":"推薦用戶不存在",
	"转入账户不能为空":"轉入賬戶不能為空",
	"转入数量不能为空":"轉入數量不能為空",
	"密码不能为空":"密碼不能為空",
	"用户密码错误":"用戶密碼錯誤",
	"美丽链法币交易余额转入":"美麗鏈法幣交易餘額轉入",
	"美丽链币币交易余额转入":"美麗鏈幣幣交易餘額轉入",
	"美丽链杠杆交易余额转入":"美麗鏈槓桿交易餘額轉入",
	"转入成功":"轉入成功",
	"密码修改成功":"密碼修改成功",
	"用户未找到":"用戶未找到",
	"绑定成功":"綁定成功",
	"发送成功":"發送成功",
	"m月d日 H:i":"m月d日 H:i",
	"币未找到":"幣未找到",
	"暂无数据":"暫無數據",
	"文件大小超出":"文件大小超出",
	"该文件已存在":"該文件已存在",
	"文件类型不对":"文件類型不對",
	"上传失败":"上傳失敗",
	"内容不能为空":"內容不能為空",
	"提交成功，我们会尽快给你回复":"提交成功，我們會盡快給你回复",
	"非法参数":"非法參數",
	"该币种不支持购买保险":"該幣種不支持購買保險",
	"错误的金额！":"錯誤的金額",
	"不存在的险种！":"不存在的險種",
	"不存在的币种！":"不存在的幣種",
	"已经购买了该币种的险种！":"已經購買了該幣種的險種",
	"可用余额不足，无法购买！":"可用餘額不足，無法購買",
	"购买成功！":"購買成功",
	"购买失败！原因：":"購買失敗！原因：",
	"未找到该保险":"未找到該保險",
	"该保险正在处理中":"該保險正在處理中",
	"申请索赔失败":"申請索賠失敗",
	"申请索赔成功！":"申請索賠成功",
	"超出今日索赔次数!":"超出今日索賠次數",
	"存在未平仓订单":"存在未平倉訂單",
	"受保资产为零":"受保資產為零",
	"受保资产不符合可申请索赔条件1":"保資產不符合可申請索賠條件1",
	"受保资产不符合可申请索赔条件2":"保資產不符合可申請索賠條件2",
	"未知的险种类型":"未知的險種類型",
	"保险赔偿用户[清除受保金额]":"保險賠償用戶[清除受保金額]",
	"保险赔偿用户[赔偿受保金额]":"保險賠償用戶[賠償受保金額]",
	"保险解约，扣除保险金额":"保險解約，扣除保險金額",
	"未知受保金额去向状态":"未知受保金額去向狀態",
	"处理成功！":"處理成功",
	"处理失败：":"處理失敗：",
	"该保险已失效":"該保險已失效",
	"该保险正在索赔处理中":"該保險正在索賠處理中",
	"解约失败，存在未平仓订单":"解約失敗，存在未平倉訂單",
	"保险解约，赔付金额":"保險解約，賠付金額",
	"保险解约，扣除受保金额":"保險解約，扣除受保金額",
	"保险解约，扣除保险金额":"保險解約，扣除保險金額",
	"解约成功！":"解約成功",
	"解约失败:":"解約失敗:",
	"订单取消成功":"訂單取消成功",
	"请选择需求类型":"請選擇需求類型",
	"请选择交易方式":"請選擇交易方式",
	"请填写单价":"請填寫單價",
	"请填写数量":"請填寫數量",
	"请填写最小交易数量":"請填寫最小交易數量",
	"请选择币种":"請選擇幣種",
	"最小交易数量不能大于总数量":"最小交易數量不能大於總數量",
	"请填写最大交易量":"請填寫最大交易量",
	"请填写正确的最大交易量":"請填寫正確的最大交易量",
	"币种信息有误":"幣種信息有誤",
	"对不起，您不是该法币的商家":"對不起，您不是該法幣的商家",
	"对不起，您的商家账户不足":"對不起，您的商家賬戶不足",
	"商家发布出售":"商家發佈出售",
	"商家发布出售,冻结增加":"商家發佈出售,凍結增加",
	"发布成功":"發布成功",
	"无此商家":"無此商家",
	"您不是商家":"您不是商家",
	"该币不是法币":"該幣不是法幣",
	"无此币种":"無此幣種",
	"购买参数错误":"購買參數錯誤",
	"无此记录":"無此記錄",
	"请填写购买额":"請填寫購買額",
	"购买额请填写数字":"購買額請填寫數字",
	"您还没有通过实名认证":"您還沒有通過實名認證",
	"您还没有设置收款信息":"您還沒有設置收款信息",
	"未完成单子超过3单，请完成后再操作!":"未完成單子超過3單，請完成後再操作!",
	"商家挂单状态异常,暂时不能交易":"商家掛單狀態異常,暫時不能交易",
	"商家挂单剩余可交易数量不足":"商家掛單剩餘可交易數量不足",
	"此条交易已完成":"此條交易已完成",
	"非法提交，数量必须大于0":"非法提交，數量必須大於0",
	"您低于最低限额":"您低於最低限額",
	"您高于最高限额":"您高於最高限額",
	"您高于最大限制数量":"您高於最大限制數量",
	"未找到该商家":"未找到該商家",
	"不能操作自己的":"不能操作自己的",
	"您无此钱包账号":"您無此錢包賬號",
	"您的钱包已被锁定，请联系管理员":"您的錢包已被鎖定，請聯繫管理員",
	"您的法币余额不足":"您的法幣餘額不足",
	"您的法币冻结资金异常,请查看您是否有正在进行的挂单":"您的法幣凍結資金異常,請查看您是否有正在進行的掛單",
	"您出售数量大于商家剩余数量!":"您出售數量大於商家剩餘數量",
	"出售给商家法币:扣除余额":"出售給商家法幣:扣除餘額",
	"出售给商家法币:增加冻结":"出售給商家法幣:增加凍結",
	"您购买数量大于商家剩余数量!":"您購買數量大於商家剩餘數量",
	",错误位于第":",錯誤位於第",
	"Operation successful，请联系商家确认订单":"操作成功，請聯繫商家確認訂單",
	"行":"行",
	"该订单已操作过，请勿重复操作":"該訂單已操作過，請勿重複操作",
	"请上传支付凭证":"請上傳支付憑證",
	"对不起，您无权操作":"對不起，您無權操作",
	"Operation successful，请联系卖家确认":"操作成功，請聯繫賣家確認",
	"该订单已操作，请勿取消":"該訂單已操作，請勿取消",
	"Operation successful，订单已取消":"操作成功，訂單已取消",
	"对不起您不是商家":"對不起您不是商家",
	"对不起，您不是该商家":"對不起，您不是該商家",
	"该订单还未付款或已经操作过":"該訂單還未付款或已經操作過",
	"对不起，您的商家冻结余额不足,当前余额:":"對不起，您的商家凍結餘額不足,當前餘額:",
	"您不能确认该订单":"您不能確認該訂單",
	"订单异常":"訂單異常",
	"该用户没有此币种钱包":"該用戶沒有此幣種錢包",
	"法币交易:在商家":"法幣交易:在商家",
	"购买法币成功":"購買法幣成功",
	"法币交易:卖出成功":"法幣交易:賣出成功",
	"确认成功":"確認成功",
	"法币交易:用户":"法幣交易:用戶",
	"向商家出售法币成功":"向商家出售法幣成功",
	"法币交易:购买成功":"法幣交易:購買成功",
	"此状态下无法下架":"此狀態下無法下架",
	"对不起，您不是该法币的商家":"對不起，您不是該法幣的商家",
	"发布下架成功,将不会再与新用户匹配":"發布下架成功,將不會再與新用戶匹配",
	"必须下架后才可以撤销":"必須下架後才可以撤銷",
	"已撤回发布":"已撤回發布",
	"当前发布状态无法撤销":"當前發布狀態無法撤銷",
	"当前发布剩余数量不足,无法撤销":"當前發布剩餘數量不足,無法撤銷",
	"该发布信息下有交易正在进行中,请等待交易结束再撤回":"該發布信息下有交易正在進行中,請等待交易結束再撤回",
	"对不起，您不是该法币的商家":"對不起，您不是該法幣的商家",
	"对不起，您的商家账户冻结资金不足":"對不起，您的商家賬戶凍結資金不足",
	"撤回失败:减少冻结资金失败":"撤回失敗:減少凍結資金失敗",
	"商家撤回":"商家撤回",
	"撤回失败:增加余额失败":"撤回失敗:增加餘額失敗",
	"撤回成功":"撤回成功",
	"该发布信息无异常":"該發布信息無異常",
	"该发布信息下有交易未完成，不能标记为异常":"該發布信息下有交易未完成，不能標記為異常",
	"该发布剩余数量不足,不法标记异常":"該發布剩餘數量不足,不法標記異常",
	"当前发布状态无法标记异常":"當前發布狀態無法標記異常",
	"对不起，您的商家账户冻结资金不足":"對不起，您的商家賬戶凍結資金不足",
	"处理异常失败:减少冻结资金失败":"處理異常失敗:減少凍結資金失敗",
	"处理异常失败:增加余额失败":"處理異常失敗:增加餘額失敗",
	"商家处理异常":"商家處理異常",
	"缺少参数或传值错误":"缺少參數或傳值錯誤",
	"指定交易对不存在":"指定交易對不存在",
	"您未开通本交易对的交易功能":"您未開通本交易對的交易功能",
	"手数必须是大于0的整数":"手數必須是大於0的整數",
	"手数不能低于":"手數不能低於",
	"手数不能高于":"手數不能高於",
	"选择倍数不在系统范围":"選擇倍數不在系統範圍",
	"您有正在平仓中的交易,暂不能进行买卖":"您有正在平倉中的交易,暫不能進行買賣",
	"交易类型错误":"交易類型錯誤",
	"该功能暂未开放":"該功能暫未開放",
	"限价交易价格必须大于0":"限價交易價格必須大於0",
	"当前没有获取到行情价格,请稍后重试":"當前沒有獲取到行情價格,請稍後重試",
	"限价交易卖出不能低于当前价":"限價交易賣出不能低於當前價",
	"限价交易买入价格不能高于当前价":"限價交易買入價格不能高於當前價",
	"钱包未找到,请先添加钱包":"錢包未找到,請先添加錢包",
	"余额不足,不能小于":"餘額不足,不能小於",
	"(手续费:":"(手續費:",
	"提交失败":"提交失敗",
	"扣除保证金失败":"扣除保證金失敗",
	"扣除手续费失败":"扣除手續費失敗",
	"此功能系统未开放":"此功能係統未開放",
	"止盈止损价格不能为0":"止盈止損價格不能為0",
	"找不到该笔交易":"找不到該筆交易",
	"买入(做多)止盈价不能低于开仓价和当前价":"買入(做多)止盈價不能低於開倉價和當前價",
	"买入(做多)止亏价不能高于开仓价和当前价":"買入(做多)止虧價不能高於開倉價和當前價",
	"卖出(做空)止盈价不能高于开仓价和当前价":"賣出(做空)止盈價不能高於開倉價和當前價",
	"卖出(做空)止亏价不能低于开仓价和当前价":"賣出(做空)止虧價不能低於開倉價和當前價",
	"设置成功":"設置成功",
	"设置失败":"設置失敗",
	"数据未找到":"數據未找到",
	"无权操作":"無權操作",
	"交易状态异常,请勿重复提交":"交易狀態異常,請勿重複提交",
	"平仓失败,请重试":"平倉失敗,請重試",
	"交易异常，无法平仓":"交易異常，無法平倉",
	"买入方向传参错误":"買入方向傳參錯誤",
	"提交成功,请等待系统处理":"提交成功,請等待系統處理",
	"未找到需要平仓的交易":"未找到需要平倉的交易",
	"提交成功,请等待系统处理":"提交成功,請等待系統處理",
	"交易不存在或已撤单,请刷新后重试":"交易不存在或已撤單,請刷新後重試",
	"撤单失败:用户钱包不存在":"撤單失敗:用戶錢包不存在",
	"撤单失败":"撤單失敗",
	"撤单失败:变更状态失败":"撤單失敗:變更狀態失敗",
	"撤单成功":"撤單成功",
	"请输入账号":"請輸入賬號",
	"请输入密码":"請輸入密碼",
	"手势密码错误":"手勢密碼錯誤",
	"账号已锁定！请联系管理员":"賬號已鎖定！請聯繫管理員",
	"两次密码不一致":"兩次密碼不一致",
	"密码只能在6-16位之间111111":"密碼只能在6-16位之間",
	"验证码错误":"驗證碼錯誤",
	"账号已存在":"賬號已存在",
	"请填写正确的邀请码":"請填寫正確的邀請碼",
	"请输入密码或确认密码":"請輸入密碼或確認密碼",
	"输入两次密码不一致":"輸入兩次密碼不一致",
	"验证码不正确":"驗證碼不正確",
	"账号不存在":"賬號不存在",
	"修改密码成功":"修改密碼成功",
	"请输入验证码":"請輸入驗證碼",
	"验证成功":"驗證成功",
	"下单失败:超过最大持仓笔数限制":"下單失敗:超過最大持倉筆數限制",
	"当前未获取到行情":"當前未獲取到行情",
	"尚未申购或理赔保险":"尚未申購或理賠保險",
	"申购的保险T+1生效":"申購的保險T+1生效",
	"受保资产为零":"受保資產為零",
	"受保资产小于等于可下单条件":"受保資產小於等於可下單條件",
	"您已超过持仓限制，暂停下单。":"您已超過持倉限制，暫停下單",
	"未知的险种类型":"未知的險種類型",
	"超过最大持仓数量限制":"超過最大持倉數量限制",
	"交易中的订单大于最大挂单数量":"交易中的訂單大於最大掛單數量",
	"新闻不存在":"新聞不存在",
	"无此币种":"無此幣種",
	"该币不是法币":"該幣不是法幣",
	"用户名不能为空":"用戶名不能為空",
	"名称不能为空":"名稱不能為空",
	"账号不能为空":"賬號不能為空",
	"资产不能为空":"資產不能為空",
	"找不到此交易账号的用户":"找不到此交易賬號的用戶",
	"此用户还未通过实名认证":"此用戶還未通過實名認證",
	"币种不存在":"幣種不存在",
	"商家名称已存在":"商家名稱已存在",
	"此法币":"此法幣",
	"此用户已是此法币商家":"此用戶已是此法幣商家",
	"申请成为商家，扣除USDT":"申請成為商家，扣除USDT",
	"系统配置错误，请联系系统管理员":"系統配置錯誤，請聯繫系統管理員",
	"发送成功":"發送成功",
	"账号错误":"賬號錯誤",
	"账号已存在":"賬號已存在",
	"请填写账号":"請填寫賬號",
	"【BEF】若非您本人操作，请及时修改密码。":"【NEWBIT】若非您本人操作，請及時修改密碼。",
	"发送失败":"發送失敗",
	"ip参数不正确":"ip參數不正確",
	"邮箱不能为空":"郵箱不能為空",
	"短信验证码":"短信驗證碼",
	"您还没有交易记录":"您還沒有交易記錄",
	"非法操作,不能撤回非自己发布的信息":"非法操作,不能撤回非自己發布的信息",
	"非法操作:(":"非法操作:(",
	"取销卖出交易失败":"取銷賣出交易失敗",
	"取消买入交易,解除币币余额锁定":"取消買入交易,解除幣幣餘額鎖定",
	"取消买入交易,解除法币余额锁定":"取消買入交易,解除法幣餘額鎖定",
	"用户未找到":"用戶未找到",
	"取消成功":"取消成功",
	"取消卖出交易,解除交易余额锁定":"取消賣出交易,解除交易餘額鎖定",
	"取消卖出交易,解除交易余额锁定":"取消賣出交易,解除交易餘額鎖定",
	"类型错误":"類型錯誤",
	"交易不存在":"交易不存在",
	"资金不足":"資金不足",
	"取消卖出交易,解除交易余额锁定":"取消賣出交易,解除交易餘額鎖定",
	"取消卖出交易,解除交易余额锁定":"取消賣出交易,解除交易餘額鎖定",
	"数据未找到":"數據未找到",
	"请先添加钱包":"請先添加錢包",
	"您的币不足":"您的幣不足",
	"提交卖出记录扣除":"提交賣出記錄扣除",
	"你今天的交易额度已达到上限!":"你今天的交易額度已達到上限",
	"余额不足":"餘額不足",
	"提交卖入记录扣除":"提交賣入記錄扣除",
	"提交卖出记录(增加冻结)":"提交賣出記錄(增加凍結)",
	"价格和数量必须大于0":"價格和數量必須大於0",
	"您的冻结资金异常，禁止挂卖":"您的凍結資金異常，禁止掛賣",
	"真实姓名必须填写":"真實姓名必須填寫",
	"收款信息至少选填一项":"收款信息至少選填一項",
	"保存成功":"保存成功",
	"密码错误":"密碼錯誤",
	"当前行情小于等于零!":"當前行情小於等於零!",
	"资产兑换,减少持有币法币:":"資產兌換,減少持有幣法幣:",
	"兑换数量大于持有资产!":"兌換數量大於持有資產!",
	"资产兑换,减少":"資產兌換,減少",
	"资产兑换,增加USDT杠杆币":"資產兌換,增加USDT槓桿幣",
	"扣除手续费":"扣除手續費",
	"法币数量:":"法幣數量",
	"兑换数量大于剩余数量!":"兌換數量大於剩餘數量",
	"资产兑换成功!":"資產兌換成功",
	"参数错误!":"參數錯誤",
	"通证兑换,杠杆币增加":"通證兌換,槓桿幣增加",
	"通证兑换杠杆币增加失败":"通證兌換槓桿幣增加失敗",
	"通证兑换成功!":"通證兌換成功",
	"必填项信息不完整":"必填項信息不完整",
	"两次输入密码不一致":"兩次輸入密碼不一致",
	"此用户不存在":"此用戶不存在",
	"此交易账号已经设置":"此交易賬號已經設置",
	"此账号已经存在":"此賬號已經存在",
	"交易账号设置成功":"交易賬號設置成功",
	"邮箱绑定成功":"郵箱綁定成功",
	"手机绑定成功":"手機綁定成功",
	"手势密码添加成功":"手勢密碼添加成功",
	"两次手势密码不相同":"兩次手勢密碼不相同",
	"取消手势密码成功":"取消手勢密碼成功",
	"手势密码至少连接6个点":"手勢密碼至少連接6個點",
	"密码只能在6-16位之间":"密碼只能在6-16位之間",
	"两次密码不一致":"兩次密碼不一致",
	"交易密码设置成功":"交易密碼設置成功",
	"暂时还没有邀请排行榜，快去邀请吧":"暫時還沒有邀請排行榜，快去邀請吧",
	"会员未找到":"會員未找到",
	"该身份证号已实名认证过":"該身份證號已實名認證過",
	"你已绑定，不可更改!":"你已綁定，不可更改",
	"该地址已被绑定,请重新输入":"該地址已被綁定,請重新輸入",
	"绑定成功!":"綁定成功",
	"请提交完整的信息":"請提交完整的信息",
	"提交成功，等待审核":"提交成功，等待審核",
	"退出登录成功":"退出登錄成功",
	"请输入支付密码":"請輸入支付密碼",
	
	"参数错误":"參數錯誤",
	"无需升级":"無需升級",
	"兑换成功":"兌換成功",
	"暂无钱包":"暫無錢包",
	"支付密码错误":"支付密碼錯誤",
	"余额不足":"餘額不足",
	"升级成功":"升級成功",
	"更新成功":"更新成功",
	"请重试":"請重試",
	"推荐用户不存在":"推薦用戶不存在",
	"请把参数填写完整":"請把參數填寫完整",
	"转入账户不能为空":"轉入賬戶不能為空",
	"密码不能为空":"密碼不能為空",
	"转入数量不能为空":"轉入數量不能為空",
	"密码修改成功":"密碼修改成功",
	"暂时还没有添加币种":"暫時還沒有添加幣種",
	"此币种不存在":"此幣種不存在",
	"已经有此提币地址":"已經有此提幣地址",
	"添加提币地址成功":"添加提幣地址成功",
	"此提币地址不存在":"此提幣地址不存在",
	"您没有权限删除此地址":"您沒有權限刪除此地址",
	"删除提币地址成功":"刪除提幣地址成功",
	"输入的金额不能为负数":"輸入的金額不能為負數",
	"您有正在进行中的杆杠交易,不能进行此操作":"您有正在進行中的桿槓交易,不能進行此操作",
	"钱包不存在":"錢包不存在",
	"划转成功":"劃轉成功",
	"操作失败:":"操作失敗",
	"操作失败":"操作失敗",
	"操作失败:Insufficient wallet balance":"操作失敗：錢包餘額不足",
	"Insufficient wallet balance":"錢包餘額不足",
	"下单数量必须是10的整数倍":"下單數量必須是10的整數倍",
	"Parameter error":"參數錯誤",
	"提币申请已成功，等待审核":"提幣申請已成功，等待審核",
	"两次密码不一致":"兩次密碼不一致",
	"数量不能小于等于0":"數量不能小於等於0",
	"价格不能小于等于0":"價格不能小於等於0",
	"兑换数量必须大于0":"兌換數量必須大於0",
	"转账至交易所钱包":"轉賬至交易所錢包",
	"支付密码错误":"支付密碼錯誤",
	"钱包已添加,请勿重复添加":"錢包已添加,請勿重複添加",
	"您还没有钱包":"您還沒有錢包",
	"接收来自交易所的转账":"接收來自交易所的轉賬",
	"地址输入有误":"地址輸入有誤",
	"请输入正确的值":"請輸入正確的值",
	"地址输入有误1":"地址輸入有誤1",
	"不能转账给自己":"不能轉賬給自己",
	"扣除卖方!":"扣除賣方",
	"扣除卖方":"扣除賣方",
	"买方增加":"買方增加",
	"买方增加!":"買方增加",
	"申请提币冻结余额":"申請提幣凍結餘額",
	"申请提币冻结余额!":"申請提幣凍結餘額",
	"币币 划转 币币":"幣幣 劃轉 幣幣",
	"币币 划转 合约":"幣幣 劃轉 合約",
	"币币 划转 秒合约":"幣幣 劃轉 秒合約",
	"币币 划转 法币":"幣幣 劃轉 法幣",
	"法币 划转 法币":"法幣 劃轉 法幣",
	"法币 划转 币币":"法幣 劃轉 幣幣",
	"法币 划转 合约":"法幣 劃轉 合約",
	"法币 划转 秒合约":"法幣 劃轉 秒合約",
	"秒合约 划转 秒合约":"秒合約 劃轉 秒合約",
	"秒合约 划转 币币":"秒合約 劃轉 幣幣",
	"秒合约 划转 法币":"秒合約 劃轉 法幣",
	"秒合约 划转 合约":"秒合約 劃轉 合約",
	"合约 划转 合约":"合約 劃轉 合約",
	"合约 划转 币币":"合約 劃轉 幣幣",
	"合约 划转 秒合约":"合約 劃轉 秒合約",
	"合约 划转 法币":"合約 劃轉 法幣",
	"提交卖出记录(增加冻结)":"提交賣出記錄(增加凍結)",
	"提交卖出记录扣除":"提交賣出記錄扣除",
	"平仓资金处理":"平倉資金處理",
	"请先勾选同意用户协议":"請先勾選同意用戶協議",
	"您有未平仓的交易,操作禁止":"您有未平倉的交易,操作禁止",
	"操作失败:您有未平仓的交易,操作禁止":"操作失敗:您有未平倉的交易,操作禁止",
	"购买失败，购买金额必须大于":"購買失敗，購買金額必須大於",
	"并且小于":"並且小於",
	"用户购买保险":"用戶購買保險",
	"您的验证码是：":"您的驗證碼是：",
	"发送失败:":"發送失敗:",
	"提交":"提交",
	"杠杆交易,价格":"槓桿交易,價格",
	",扣除保证金":",扣除保證金",
	"杠杆交易,扣除手续费":"槓桿交易,扣除手續費",
	"修改邮箱":"修改郵箱",
	"您输入的邮箱或手机号不符合规则":"您輸入的郵箱或手機號不符合規則",
	"验证码":"驗證碼",
	"秒":"秒",
	
	"扣除卖方":"扣除賣方",
	"买方增加":"買方增加",
	"申请提币冻结余额":"申請提幣凍結餘額",
	
	"卖方增加法币":"賣方增加法幣",
	"后台审核充值通过":"後台審核充值通過",
	"申请提币扣除余额":"申請提幣扣除餘額",
	"秒合约订单平仓,盈利结算":"秒合約訂單平倉,盈利結算",
	"秒合约订单,亏损结算":"秒合約訂單,虧損結算",
	"秒合约下单扣除":"秒合約下單扣除",
	"下单扣除":"下單扣除",
	"%手续费":"%手續費",
	"秒合约下单扣除本金":"秒合約下單扣除本金",
	"停止售出":"停止 ",
	"买方扣除币币余额":"買方扣除幣幣餘額",
	"买方扣除法币余额":"買方扣除法幣餘額",
	"买方扣除合约余额":"買方扣除合約餘額",
	"买方扣除秒合约余额":"買方扣除秒合約餘額",
	"用户中心":"用戶中心",
	"注册成功,钱包状态：1":"註冊成功,錢包狀態：1",
	"注册成功,钱包状态：2":"註冊成功,錢包狀態：2",
	"注册成功,钱包状态：0":"註冊成功,錢包狀態：0",
	"调节":"調節",
	"账户余额:":"賬戶餘額 ",
	"账户锁定余额:":"賬戶鎖定餘額 ",
	"秒合约":"秒合約",
	"法币":"法幣",
	"杠杆":"槓桿",
	"币币":"幣幣",
	"请实名认证":"請實名認證",
	"您的实名认证还未通过！":"您的實名認證還未通過！",
	"暂不支持该币种":"暫不支持該幣種",
	"充值":"充值",
	"余额不足":"餘額不足",
	"操作成功":"操作成功",
	"取消成功":"取消成功",
	"提交成功":"提交成功",
	"最大下单数量不能超过:":"最大下單數量不能超過",
	"投资数额 的最小长度为":"投資數額 的最小長度為",
	"位":"位",
	"杠杆买入委托撤单,退回手续费":"槓桿買入委託撤單,退回手續費",
	"杠杆买入委托撤单,退回保证金":"槓桿買入委託撤單,退回保證金",
	"秒合约买入委托撤单,退回手续费":"秒合約買入委託撤單,退回手續費",
	"秒合约买入委托撤单,退回保证金":"秒合約買入委託撤單,退回保證金",
	"法币买入委托撤单,退回手续费":"法幣買入委託撤單,退回手續費",
	"法币买入委托撤单,退回保证金":"法幣買入委託撤單,退回保證金",
	"币币买入委托撤单,退回手续费":"幣幣買入委託撤單,退回手續費",
	"币币买入委托撤单,退回保证金":"幣幣買入委託撤單,退回保證金",
	"订单平仓结算,平局结算":"訂單平倉結算,平局結算",
	"订单平仓,盈利结算":"訂單平倉,盈利結算",
	"Account already exists":"賬戶已存在",
	"投资数额 字段必须填写":"投資數額 字段必須填寫",
	"提币成功":"提幣成功",
	"Operation successful":"操作成功",
	"加载中...":"加載中...",
	"加载更多":"加載更多",
	"没有更多了...":"沒有更多了...",
	"确定平仓？":"確定平倉？",
	"发行量":"發行量",
	"请登录":"請登錄",
	"开始申购":"開始申購",
	"申购币种":"申購幣種",
	"开始申购时间":"開始申購時間",
	"预计上线时间":"預計上線時間",
	"结束申购时间":"結束申購時間",
	"项目预热":"項目預熱",
	"公布结果":"公佈結果",
	"USDT余额不足":"USDT餘額不足",
	"BTC余额不足":"BTC餘額不足",
	"ETH余额不足":"ETH餘額不足",
	"申购成功":"申購成功",
	"兑换成功":"兌換成功",
	"全部":"全部",
	"立即申购":"立即申購",
	"结束申购":"結束申購",
	"申购周期":"申購週期",
	"项目详情":"項目詳情",
	"请输入申购数量":"請輸入申購數量",
	"申购确认":"申購確認",
	"确定":"確定",
	"取消":"取消",
	"您计划申购":"您計劃申購",
	"BEF钱包未找到":"BEF錢包未找到",
	"USDT钱包未找到":"USDT錢包未找到",
	"BTC钱包未找到":"BTC錢包未找到",
	"ETH钱包未找到":"ETH錢包未找到",
	"USDT 兑换 BEF ":"USDT 兌換 BEF ",
	"BTC 兑换 BEF ":"BTC 兌換 BEF ",
	"ETH 兑换 BEF ":"ETH 兌換 BEF ",
	"申购历史":"申購歷史",
	"认购价格":"認購價格",
	"认购数量":"認購數量",
	"认购时间":"認購時間",
	"结束时间":"結束時間",
	"状态":"狀態",
	"解锁":"解鎖",
	"已完成":"已完成",
	"已锁仓":"已鎖倉",
	"暂无记录":"暫無記錄",
	"操作":"操作",
	"价值":"價值",
	"认购币种":"認購幣種",
	"申购记录":"申購記錄",
	"可用余额不足":"可用餘額不足",
	"申购数量异常":"申購數量異常",
	"认购异常":"認購異常",
	"认购成功":"認購成功",
	"下单数量必须是100的整数倍":"下單數量必須是100的整數倍",
	"认购解锁到账":"認購解鎖到賬",
	"充值自动到账":"充值自動到賬",
	"发送失败。未包含正确的手机号":"發送失敗。未包含正確的手機號",
	"发送失败。余额不足":"發送失敗。餘額不足",
	"认购付款":"認購付款",
	"当前价值":"當前價值",
	"解锁时间":"解鎖時間","天":"天",
	"倒计时":"倒計時",
	"预计收益":"預計收益",
	"资产记录":"資產記錄",
	"知道了":"知道了",
	"冻结剩余时间":"凍結剩餘時間",
	"小时":"小時",
	"aaaaaaaaaaaa":"1111111"
}
}