export default {
    "siteName": "NEWBIT",
    "scan": "さっと掃く",
    "exchangeRate": "為替レート",
    "none": "",
    "code": "検証コードを送信する",
    "send": "検証コードを送信する",
    "pwd": "パスワード",
    "inMobile":"モバイル端末でフィードバックしてください",
    "resend": "{number}秒後に再送信します",
    "welcome": "Hi,NEWBIT にようこそ",
    "accounts": "アカウント",
    "registers": "登録",
    "regiterStep1": "登録済み",
    "regiterStep2": "保存する",
    "xieyi": "を読み、同意します",
    "xieyi2": "「ユーザー契約」",
    "transferFromChange":"貨幣の口座から転入する",
    "transferChange":"貨幣の口座に振替する",
    "transferOk":"振り替え完了",
    "xieyi1": {
    "first": "登録後に国籍情報を変更することはできません",
        "second": "確認メールはスパムと判断される場合がありますので、ご確認ください",
        "third": "アカウント情報とパスワードを適切に保管してください",
        "fourth": "他のウェブサイトと同じログインパスワードを使用しないでください",
        "fifth": ""
},
    "confirm": "确认",
    "logins": "ログイン",
    "notice": "公告",
    "more": "より多くローディングする",
    "nomore": "まだそれ以上はない",
    "time": "時間",
    "price": "価格",
    "number": "数",
    "info": "基本情報",
    "link": "相関リンク",
    "cfprice": "ICO価格",
    "totalc": "流通総量",
    "bname": "つうか名称",
    "query": "ブロック検索",
    "dealed": "成約した",
    "notdeal": "成約未",
    "handicap": "ハンディキャップ",
    "rtt": "リアルタイム取引",
    "timescreen": "時間フィルタ",
    "dealscreen": "取引ペアフィルタ",
    "dirscreen": "方向フィルタ",
    "to": "到着する",
    "startdate": "開始",
    "overdate": "終了日",
    "do": "操作",
    "revoke": "撤回",
    "nodata": "暫定的に無数の根拠",
    "loading": "ローディング中",
    "status": "状态",
    "rate": "手数料",
    "back": "戻る",
    "alipay": "アリペイを支払う",
    "wechat": "wechat",
    "bankcard": "バンクカード",
    "inpCur": "貨幣名称を入力してください",
    "minAm": "最小数",
    "regidters": "登録を歓迎する",
    "alltread": "全駅取引",
    "treadnum": "取引の数量",
    "treaddetail": "取引の内訳",
    "tnum": "成交数量",
    "lastweek": "週間",
    "reload": "刷新",
    "dealtime": "成交時間",
    "way": "委托方式",
    "torg": "交易所",
    "dealno": "委托编号",
    "ttotal": "成交金额",
    "auto": "自助委托",
    "sum": "总计",
    "traedoff": "取引ペアはすでに下船しました。取引ペアを再び切り替えてください。",
    "piece": "张",
    "hand": "手",
    "pbuynum": "購入枚数を入力してください。",
    "bestsell": "市場で最高の価格で販売する",
    "lowbuy": "最低市場価格で購入する",
    "transin": "転送",
    "transout": "転出",
    "tansinmoney": "プリンシパルに転送",
    "tansoutmoney": "元本を転送する",
    "from": "从",
    "jingdu": "精度",
    "m": "分",
    "day": "日",
    "week": "週",
    "year": "月",
    "mon": "月",
    "hour": "時",
    "header": {
    "home": "トップページ",
        "c2c": "法貨取引",
        "match": "契約コンテスト",
        "currency": "コイン取引",
        "assets": "私の資産",
        "bbs": "フォーラム",
        "setting": "安全設定",
        "help": "ヘルプセンター",
        "complaint": "クレーム・メモ",
        "in": "ログイン",
        "shop": "私の店です",
        "up": "登録",
        "inTourist": "観光客登録",
        "current": "当面の依頼",
        "his": "歴史的依頼",
        "code": "招待コード",
        "out": "ログアウト",
        "orders": "受注",
        "center": "個人センター",
        "pro": "プロジェクト公示",
        "cun": "貯金宝",
        "vote": "投票する",
        "sgr": "SGR Demand",
        "inset": "入金の設定",
        "tradelog": "取引ログ",
        "fastrecharge": "充電する"
},
    "footer": {
    "hxex": "MSにてご绍介します",
        "legalTrade": "法貨取引",
        "tool": "リンク",
        "about": "について",
        "join": "私たちに加わる",
        "connect": "我々に連絡する",
        "download": "クライアントがダウンロードする",
        "explain": "条項説明",
        "xieyi": "ユーザープロトコル",
        "yinsi": "プライバシー条項",
        "legal": "公式サイト",
        "feilv": "取引手数料",
        "notice": "公告センター",
        "nhelp": "新米の援助",
        "support": "新米の援助",
        "workorder": "新米の援助",
        "apply": "お札を入れる",
        "gonggao": "网站公告",
        "community": "コミュニティ群",
        "wechat": "WeChat",
        "weibo": "微博",
        "email_info": "メールボックス：btchedge8899@gmail.com",
        "email_pre": "カスタマーサービス：",
        "address": "シンガポール© 2013-2020 NEWBIT",
        "email": "ビジネスメールアドレス",
        "feedback": "問題フィードバック",
        "about2": "会社の概要",
        "coin": "デジタル資産の紹介",
        "service": "サービス",
        "apply_url": "https://qwe.shushuiji.net/dist/#/components/noticeDetail?id=197",
        "feedback_url": "https://qwe.shushuiji.net/dist/#/components/noticeDetail?id=202",
        "about2_url": "https://qwe.shushuiji.net/dist/#/components/noticeDetail?id=208",
        "coin_url": "https://qwe.shushuiji.net/dist/#/components/noticeDetail?id=213",
        "yinsi_url": "https://qwe.shushuiji.net/dist/#/components/noticeDetail?id=218",
        "feilv_url": "https://qwe.shushuiji.net/dist/#/components/noticeDetail?id=223",
        "legal_url": "https://qwe.shushuiji.net/dist/#/components/noticeDetail?id=228"
},
    "home": {
    "with": "はい、",
        "myMarkets": "交易所",
        "markets": "交易所",
        "pair": "貨幣の種類が合っている",
        "market": "",
        "price": "価格",
        "change": "上がり下がり",
        "high": "高値",
        "min": "最低価格",
        "volume": "取引高",
        "trade": "操作",
        "toTrade": "取引に行く",
        "c1": "グローバル化のデジタル資産配置及び取引サービス",
        "c2": "全世界に分布しているプロジェクトの開拓と運営管理体系",
        "c3": "複数の国にローカル取引サービスセンターがあります",
        "c4": "130カ国以上の数百万人のユーザーにサービスを提供する",
        "c5": "Smart Charin 72&amp;27169",
        "c6": "独立専門のブロックチェーン資産研究評価体系",
        "c7": "産業チェーンを長期的に追跡し、最も権威のある中立的な資産分析を提供する",
        "c8": "ワンストップ式のプロジェクト進捗追跡及び情報開示システム",
        "c9": "4年のデジタル資産の安全風コントロール経験に頼って",
        "c10": "先払いの仕組みを作る",
        "c11": "投資家保護基金を設立する",
        "c12": "いつでもどこでもマルチプラットフォーム端末取引",
        "c13": "iOS、Android、Windowsの複数のプラットフォームをカバーし、全業務機能をサポートします。",
        "c14": "香港ソウル特別市シンガポール東京",
        "c15": "世界各地にあるサービスセンター",
        "c16": "直取引",
        "c17": "私達のチームに参加することを歓迎して、共に昇格させて、もっと良い自分を越えて、もっと良い人生の価値を創造します",
        "access": "多平台终端接入",
        "cover": "IOS、Android、Windowsの複数のプラットフォームをカバーし、全業務機能をサポートします",
        "cooper": "協力機構",
        "vol": "24 H量",
        "c01": "世界トップのデジタル資産取引プラットフォーム",
        "c02": "世界130カ国を超える数百万人のユーザーに安全で信頼できるデジタル資産取引及び資産管理サービスを提供する",
        "c03": "安全は信頼できる",
        "c04": "5年間のデジタル資産金融サービス経験",
        "c05": "プロの分散アーキテクチャとアンチDDOS攻撃システム",
        "c06": "全世界の生態配置",
        "c07": "複数の国に現地化取引サービスセンターを設立する。",
        "c08": "多業務形態を一体とするブロックチェーン生態圏を構築する。",
        "c09": "ユーザー至上",
        "sweep": "ダウンロード",
        "android": "アンドロイド",
        "pinput": "メールアドレスを入力してください",
        "atrade": "NEWBIT GROUPグローバルステーションのアカウントを登録して、取引の旅を開始します。",
        "Gcoin": "NEWBIT",
        "eamil1": "ビジネス協力メールボックス",
        "eamil2": "カスタマーメールボックス",
        "kefu": "オンラインサービス",
        "img1": "1_jp.png",
        "img2": "2_jp.png",
        "img3": "3_jp.png",
        "d1": "NEWBIT Global，24時間の出来高<b>{number}</b>ドル",
        "d2": "世界130カ国を超える数百万人のユーザーに安全で信頼できるデジタル資産取引及び資産管理サービスを提供する",
        "d3": "取引量は世界トップ3",
        "d4": "強力な流動性の取引の種類はそろっています",
        "d5": "6年老舗の取引所",
        "d6": "6年間のデジタル資産金融サービス経験",
        "d7": "プロの分散アーキテクチャとアンチDDOS攻撃システム",
        "d8": "千万級ユーザー規模",
        "d9": "先行賠償メカニズムを確立し、投資家保護基金を設立する。",
        "dsp1": "金融レベルの安全",
        "dsp10": "全方位金融風制御システムと盗難防止システム、冷熱財布、多署名システムは資金の安全を保証します",
        "dsp2": "急速チャージ",
        "dsp20": "チャージして今最も速い3分完成して、24 H人工オンライン審査、取引先を保護して最も良い投資の機会を逃さないでください",
        "dsp3": "グローバルサービス",
        "dsp30": "全世界の業務サービスネットワークをカバーし、全世界の暗号化資産に投資し、全世界のユーザーと取引することを助けます",
        "dsp4": "資産を厳選する",
        "dsp40": "品質の高い暗号化項目を厳選し、80%の極めて危険なプロジェクトをフィルタします",
        "dsp5": "いつでもどこでも安全取引を開始する",
        "dsp50": "iOS、Android、Windowsの複数のプラットフォームをカバーし、全業務機能をサポートします"
},
"loginTourist": {
        "touristLogin" : "観光客登録の注意事項",
        "invite": "1. 観光客口座はすべての機能を利用できます",
        "invite1": "2. 試遊口座はチャージできません",
        "invite2": "3. 試遊口座は現金化できません",
        "invite3": "4. 試遊口座はチャージと現金引き出しが禁止されています！",
        "invite4": "5. ",
        "autologin": "自動ログイン"
    },
    "login": {
    "email": "メールアドレス",
        "phone": "携帯の登録",
        "welcome": "ログインを歓迎します",
        "account": "アカウント",
        "forget": "パスワードを忘れます",
        "dont": "登録に移動？",
        "invite": "すぐに登録して、世界トップのデジタル資産取引プラットフォームで取引を開始します",
        "autologin": "自動ログイン"
},
    "register": {
    "phone": "携帯番号の登録",
        "email": "メールアドレス登録",
        "phonenum": "携帯の番号を入力してください",
        "emailnum": "メールボックスを入力してください",
        "codenum": "認証コードを入力してください",
        "notice": "先にユーザプロトコルを確認して、オプションボックスにチェックしてください。送信認証コードをクリックしてください",
        "sendcode": "認証コードを送信",
        "logpwd": "ログインパスワードを入力してください",
        "repwd": "パスワードを確認してください",
        "invitecode": "招待コードを入力してください",
        "xuan": "記入する",
        "agree": "同意を読む",
        "pwd": "パスワードは6-16桁の間にあります。数字とアルファベットからなります",
        "mian": "「ユーザプロトコル」",
        "gologin": "既にアカウントを持っていますか？ログイン"
},
    "forget": {
        "fpwd": "パスワードを忘れます",
        "getcode": "認証コードを",
        "setpwd": "パスワードの設定",
        "inpwd": "パスワードを入力してください",
        "repwd": "パスワードをもう一度入力してください"
},
    "lpwd": {
    "pwd": "パスワードを入力してください",
        "setpwd": "資金コードの設定",
        "resetpwd": "資金のパスワードをリセット",
        "oldpwd": "元のパスワードを入力してください",
        "newpwd": "新しいパスワードを入力してください",
        "repwd": "パスワードをもう一度入力してください",
        "spwd": "パスワードの設定",
        "rpwd": "パスワードをリセット"
},
    "bdemail": {
    "bindemail": "メールボックスをバインド",
        "emailbox": "メールボックス",
        "surebind": "バインディングの確認"
},
    "bdphone": {
    "bindphone": "携帯番号を結びつける",
        "phone": "携帯の番号"
},
    "lgpwd": {
    "setpwd": "パスワードを変更",
        "renewpwd": "新しいパスワードをもう一度入力してください"
},
    "security": {
    "lever": "あなたのアカウントのセキュリティレベル:",
        "wan": "もっと多くの資料を改善して、アカウントの安全を保証します。",
        "invite": "私の招待番号",
        "copy": "招待コードをコピー",
        "phone": "携帯の番号を修正します",
        "email": "メールボックスをバインド",
        "logpwd": "ログインパスワード",
        "zipwd": "資金コード",
        "idcard": "認証",
        "suggest": "定期的にパスワードを変更して口座の安全を守ることをお勧めします。",
        "notbaind": "バインディングされていません",
        "havebind": "バインディング済み",
        "gobind": "バインディングしない",
        "set": "変更",
        "haveau": "認証済み",
        "reau": "拒否しました（再認証してください）",
        "goau": "認証に行きます",
        "auing": "審査中",
        "goset": "設定に行きます",
        "accountset": "ユーザ設定",
        "setlogin": "ログイン認証方式を設定します。",
        "setting": "設定",
        "setemail": "メールボックスを変更",
        "tbpw": "トークンのパスワード",
        "tip1": "補助貨幣専用のパスワードを設定してください。登録パスワードとは異なるパスワードを提示してください。",
        "google": "Googleの検証器",
        "bindGoogle": "Googleの検証器を結びつける。",
        "tip2": "ログイン、お札の取得、パスワードの取得、安全設定の変更について安全検証を行います。",
        "beforename": "実名認証を先に行ってください。",
        "authentication": "認証",
        "myaddress": "私の住所",
        "low": "低い",
        "fbPhone": "まず携帯番号を結びつけてください。",
        "fbEmail": "先にメールボックスをバインドしてください",
        "googleKey": "Google鍵"
},
    "auth": {
    "auth": "個人認証",
        "back": "戻る",
        "refause": "拒絶理由",
        "name": "名前",
        "idnum": "証明書番号",
        "img": "証明書の表と裏と手に持っている証明書の正面写真をアップロードしてください。",
        "ising": "審査中…",
        "ised": "認証済み",
        "truename": "実名を入力してください。",
        "truenum": "証明書の番号を入力してください",
        "up": "送信",
        "note": "あなたのIDカード、パスポートと運転免許証の写真をアップロードしてください。"
},
    "auth2": {
    "tip": "ユーザ認証情報は検証されても、修正できませんので、必ず正確な情報を正直に記入してください",
        "name": "認証名",
        "id": "個人認証",
        "number": "証明書番号",
        "type": "証明書の種類",
        "content": "内容を入力してください",
        "complete": "実名認証が完了しました。おめでとうございます",
        "nextstep": "次の認証を行います",
        "positive": "証明書写真正面",
        "mb": "jpg、pngのみ対応し、4 mbを超えません",
        "click": "証明書をアップロードして正面を照らすことをクリックします",
        "back": "証明書の裏",
        "hand": "手持ちの証明書の写真をアップロードします",
        "tip2": "証明書を持って撮った写真を提供してください。同じ写真にも「NEWBIT」と日付とサインのコメントが含まれています。顔がよく見えるようにしてください。証明書の詳細は全部分かります",
        "face": "顔がくっきりと見える",
        "clearly": "写真が鮮明に見える",
        "notes": "[NEWBIT]の文字コメントが付いています",
        "complete2": "身分認証が完了しました。おめでとうございます",
        "complete3": "次の資産認証を行います",
        "inaudit": "認証の提出に成功しました。審査中です",
        "notpass": "身分認証の審査が通っていません",
        "again": "身分証明書の再発行をお願いします",
        "asset": "資産認証審査中",
        "nasset": "資産認証が通っていません",
        "again2": "資産認証を見直してください",
        "complete4": "おめでとうございます。資産認証審査に合格しました",
        "reminder": "暖かいヒント",
        "tip3": "あなたの資産評価値が20 BTCより大きい場合、第3級実名認証を行い、より多くの貨幣調達限度額を得ることができます。",
        "tip4": " ご本人のMSアカウントの資産明細のスクリーンショットまたは写真を提供してください。画像にはお客様の口座名、uid、在庫保持の詳細、現在の時間が含まれています。すべての情報をクリアして読むことができます。",
        "screenshot": "資産スクリーン情報をアップロードする",
        "nowLever": "現在の認証レベル",
        "authCard": "身分証",
        "hu": "パスポート",
        "driverCard": "運転免許証",
        "other": "その他",
        "one": "レベル1",
        "two": "二級",
        "three": "三级",
        "selectType": "証明書の種類を選んでください。",
        "enterCardId": "証明書の番号を記入してください",
        "noWord": "証明書の番号は漢字を含んではいけません",
        "enterRealName": "実名を記入してください",
        "noNumber": "名前には数字が含まれていません",
        "noFour": "写真は4 Mを超えてはいけません",
        "frontImg": "証明書の写真をアップロードしてください",
        "reverseImg": "身分証明書の裏に記入してください",
        "handImg": "証明書の写真を持ってください",
        "assetImg": "資産のスクリーンショットをアップロードしてください"
},
    "legal": {
    "buy": "買います",
        "sell": "売りに出す",
        "shoper": "商売人",
        "number": "数量",
        "limit": "限度額",
        "price": "単価",
        "pay": "支払い方法",
        "do": "操作",
        "record": "注文記録",
        "traderecord": "取引記録",
        "tredetype": "取引の種類",
        "orderstatus": "注文の状態",
        "nofinish": "未完成",
        "finished": "完了しました",
        "ceiled": "キャンセルしました",
        "payed": "支払い済み",
        "paywait": "未払い金",
        "waitplease": "バイヤーが支払うのを待ってください",
        "ordercomplete": "注文が完了しました",
        "orderceiled": "注文はキャンセルされました",
        "orderpayed": "バイヤーはすでに支払いました。確認して確認してください",
        "totalmoney": "取引総額",
        "time": "時間",
        "contact": "連絡先",
        "buyer": "買い手",
        "seller": "売り手",
        "ordertime": "注文時間",
        "reference": "参考号",
        "ceilorder": "取引をキャンセルする",
        "surepay": "入金確認",
        "confirmceil": "キャンセル確認",
        "ceil": "キャンセル",
        "confirm": "確認",
        "paysure": "支払い確認",
        "otherpay": "バイヤーがお支払いしたことを確認してください",
        "payplease": "お支払いは以下の口座でお願いします",
        "payedwait": "お金を払いました。店の確認を待っています",
        "tprice": "取引単価",
        "tnumber": "取引の数量",
        "cardnum": "銀行カード番号",
        "wechat": "WeChat",
        "alipay": "支付宝",
        "account": "アカウント",
        "phone": "電話",
        "orderceil": "注文をキャンセルする",
        "mypayed": "すでに支払いました。クリックして確認します",
        "notceil": "すでに売り手に支払ったら、取引をキャンセルしないでください",
        "youpayed": "販売者にお支払いいただいたことを確認してください",
        "freeze": "悪意のクリックは直接口座を凍結します",
        "transaction": "取引",
        "sellall": "全部売ります",
        "buyall": "全部買います",
        "conactceil": "15分以内に業者に連絡してください。15分を超えると自動的にキャンセルされます",
        "autoceil": "自動キャンセル",
        "placeorder": "注文する",
        "inwant": "入力してください",
        "total": "総額",
        "ceilnot": "もしバイヤーがすでにあなたにお金を支払ったら、取引をキャンセルしないでください",
        "shopaccount": "事業者口座",
        "bankName": "銀行を開く",
        "payinfo": "支払い情報"
},
    "legaltrade": {
    "paymoney": "通貨を支払う",
        "paytype": "支払い方法",
        "select": "選択してください",
        "back": "戻る",
        "desc": "概要",
        "connect": "連絡先",
        "cankao": "参考号",
        "zhihang": "銀行口座開設支店",
        "enterzhihang": "口座開設銀行の支店を入力してください",
        "money_code": "入金コード",
        "confirm_tips": "早く15分以内に操作を確認してください",
        "cancel_tips": "注：一日に三回キャンセルしたら、フランス円の取引を制限します",
        "send": "手紙を届ける",
        "money_change": "資金の変化",
        "depth": "深さ図"
},
    "shop": {
    "myshop": "マイショップ",
        "name": "名称",
        "tolegal": "所属法",
        "retime": "登時間",
        "balance": "事業体残高",
        "lockbalance": "商家凍結残高",
        "goshop": "店に入る",
        "allorder": "総単数",
        "dayorder": "30日のできばえ",
        "comorder": "完成書",
        "comrate": "達成率",
        "release": "発表する",
        "email": "メール認証",
        "phone": "携帯電話",
        "truename": "実名認証",
        "high": "高度な認定",
        "type": "タイプ",
        "mysell": "私の販売",
        "mybuy": "私の注文",
        "abnormal": "異常",
        "seeorder": "オーダーの表示",
        "choosetype": "タイプを選択してください",
        "sell": "売り出す",
        "buy": "購買を求める",
        "pchoose": "選択してください",
        "minnum": "最小取引数量",
        "maxnum": "最大取引数量",
        "ordercom": "注文が完了しました",
        "orderceil": "注文はキャンセルされました",
        "buyerpayed": "購入者がお支払いになりましたので、ご確認の上ご確認ください",
        "sellerwait": "売り手の確認を待ってください",
        "receivepay": "入金の確認",
        "onsell": "オンライン販売",
        "onbuy": "オンラインで購入する",
        "fatotal": "法幣総額",
        "sceil": "秒後に自動的にキャンセル",
        "nomin": "最低限度額を下回ってはいけない",
        "nomax": "最大限度を超えることはできません",
        "nomaxnum": "最大数量を超えてはいけない",
        "cancle": "取り下げる"
},
    "lgset": {
    "lgset": "法幣取引設定"
},
    "market": {
    "quotation": "市場価格",
        "market": "市場",
        "currency": "通貨",
        "lastprice": "最新価格",
        "change": "上げ幅",
        "zoushi": "傾向"
},
    "center": {
    "xian": "限定価格での取引",
        "shi": "市価取引",
        "available": "使える",
        "buyprice": "かいいれ価格",
        "sellprice": "うり相場",
        "buynumber": "買い入れ量",
        "sellnumber": "売出量",
        "tradetotal": "取引高",
        "buyin": "買う",
        "sellout": "売ります",
        "inbuy": "買い入れる",
        "outsell": "売りに出る",
        "all": "全部",
        "cdel": "目下の依頼",
        "hdeal": "れきし依頼",
        "pairs": "取引ペア",
        "direction": "方向",
        "dealtotal": "委託総額",
        "tprice": "だんあい平均",
        "nothing": "None",
        "contran": "取引が成立した",
        "newprice": "最新価格",
        "alltrade": "全駅取引",
        "volume": "取引高",
        "realtime": "時を分ける",
        "or": "あるいは",
        "start": "取引を始める",
        "hour": "24時間資金の変化",
        "inflow": "主力流入",
        "outflow": "主力流出",
        "inmoney": "24流入資金",
        "outmoney": "24流出資金"
},
    "account": {
    "mult100": "数は100の整数倍でなければならない",
        "exchange": "為替",
        "exchangeNum": "交換数量",
        "hideSm": "小口資産の非表示",
        "assets": "資産",
        "taccount": "取引口座",
        "laccount": "法幣勘定",
        "totalassets": "総資産換算",
        "letransaction": "秒契約アカウント",
        "upaddress": "払戻しアドレス管理",
        "freezes": "凍結",
        "conversion": "換算",
        "charging": "チャージ",
        "charrecord": "通貨記録",
        "fstaus": "追跡状況",
        "view": "表示する",
        "withdraw": "を受け取る",
        "record": "レコード枚",
        "copy": "コピー",
        "code": "2 次元コード",
        "amount": "チャージ数量",
        "picture": "振替スクリーンショット",
        "choose": "選択",
        "select": "ファイルを選択してください",
        "selected": "選択済み",
        "submitRecharge": "チャージ情報を提出する",
        "cgaddress": "入金された住所",
        "whaddress": "チップアドレス",
        "notice": "暖かいヒント",
        "chaddress": "お札の住所を選択します",
        "range": "範囲",
        "havenum": "入金数",
        "tibi": "正しいBTC OMNI撤退アドレスに記入してください",
        "minnum": "最小貨幣数は",
        "uppwd": "取引パスワード",
        "oncemaxnum": "1回の注文でのコインの最大数は",
        "daynum": "その日の最大コイン制限",
        "call": "資金の安全を保障するために、アカウントのセキュリティポリシーの変更、パスワードの変更、払戻しを手動で確認する場合は、従業員の電話またはメールへの連絡をお待ちください。",
        "a1": "上記のアドレスには一切チャージしないでください",
        "a2": "資産、そうでなければ、資産は取り戻すことができません。",
        "a3": "充電はｓｉｍｐｌｅ　ｓｅｎｄの方法しかサポートしていませんが、他の方法（ｓｅｎｄ　ａｌｌ）を使ったチャージはしばらくできませんのでご了承ください。",
        "a4": "上記のアドレスにチャージすると、ネットワークノード全体の確認が必要になり、 1 回のネットワーク確認後から、 6 回のネットワーク確認後に払えることができます。",
        "a5": "最小チャージ金額：",
        "a6": "最小金額以下のチャージはチャージされず返却できません。",
        "a7": "チャージされたアドレスは頻繁に変更されることはなく、再チャージが可能です。変更があった場合は、できるだけ通知またはメールでお知らせします。",
        "a8": "パソコンとブラウザの安全を必ず確認して、情報の改ざんや漏洩を防止してください。",
        "a9": "上記のアドレスにリチャージした後、ネットワークノード全体の確認が必要ですネットワークが確認されると、アカウントに到着します2回のネットワーク確認後、通貨を引き出すことができます。",
        "a10": "チャージ情報を提出してから、スタッフの審査を待ってください",
        "legal": "法幣",
        "trade": "取引",
        "lever": "Contracts",
        "choosecoin": "通貨の選択",
        "huanum": "回転数量",
        "mrecord": "財務記録",
        "allRec": "総財務記録",
        "transfer": "漕ぎ回す"
},
    "set": {
    "secset": "セキュリティ設定",
        "msgcer": "情報認証",
        "turemsg": "取引が正常に進行していることを確認するために、あなたの本当の情報を記入してください。",
        "tname": "実際の名前",
        "kname": "開戸行を選択する",
        "cnum": "銀行カード番号",
        "ailipay": "アリペイアカウント",
        "wename": "WeChatニックネーム",
        "wenum": "WeChatアカウント",
        "bank_address": "口座の住所",
        "ailipay_nickname": "アリペイのニックネーム",
        "attestation": "認証の詳細",
        "setpay": "入金タイプの設定",
        "remarks": "備考",
        "palipay": "支付宝を入力してください",
        "premarks": "コメントを入力してください",
        "addalipaycode": "支付宝入金コードの追加",
        "pwenum": "WeChatを入力してください",
        "nameplease": "名前を入力してください",
        "addwenumcode": "マイクロレターの入金コードの追加",
        "add": "添加する"
},
    "ad": {
    "adrecord": "伝言記録",
        "release": "発表する",
        "reply": "返答",
        "none": "しばらく",
        "releaseit": "投稿の投稿",
        "con": "メッセージを記入してください",
        "up": "コミット"
},
    "lay": {
    "paccount": "アカウントを入力してください!",
        "notcode": "CAPTCHAを空にすることはできません！",
        "pwdliu": "パスワードは6桁以下ではいけません！",
        "slogin": "ログイン成功",
        "noemail": "あなたのメールはルールに合わない",
        "phonenot": "あなたが入力した携帯電話番号のアカウントは規則に合わない！",
        "nophone": "携帯電話番号を入力してください",
        "nemail": "メールアドレスを入力してください",
        "inpwd": "パスワードを入力してください",
        "pwdlength": "パスワードは6から16桁までです",
        "pwdcom": "パスワードは数字と文字で構成する必要があります",
        "repwd": "もう一度パスワードを入力してください",
        "twopwd": "2回入力されたパスワードが一致しません",
        "inprice": "買値を入力してください",
        "noinprice": "買取価格は0にできません",
        "innumber": "購入量を入力してください",
        "outprice": "販売価格を入力してください。",
        "outinprice": "売り相場を0にすることはできない",
        "outnumber": "販売量を入力してください",
        "plogin": "ログインしてください",
        "revoke": "引換証を確認しますか？",
        "sure": "確定",
        "ceil": "Cancel",
        "notopen": "未",
        "caddress": "引き出し先を選択してください",
        "cnumber": "引き出した貨幣の数量を入力してください",
        "cpwd": "チップのパスワードを入力してください。",
        "minnum": "最小数量を入力してください",
        "copys": "コピー成功",
        "fcopy": "コピーに失敗",
        "recopy": "コピーし直してください",
        "huanum": "回転数を入力してください",
        "truename": "実名は記入しなければならない",
        "pliu": "メッセージを記入してください",
        "nameplease": "名前を入力してください!",
        "idplease": "証明書番号を入力してください!",
        "comid": "証明書をアップロードしてください!",
        "intermediate": "中",
        "high": "高",
        "strong": "强",
        "buymoney": "ご購入の総額を入力してください",
        "buynum": "購入する数量を入力してください",
        "sellmoney": "请输入欲出售总额",
        "sellnum": "販売したい数量を入力してください",
        "tname": "まず実名認証をしてから発注してください",
        "payset": "まず入金方法を設定してください",
        "mpay": "お支払い方法をお選びください",
        "mprice": "価格を入力してください",
        "mnum": "数量を入力してください",
        "lessnum": "最小数量は以下にできない",
        "morenum": "数量はオーバーしてはいけない",
        "notnum": "数量は全体の数量より大きくすることはできない",
        "confirm": "조작 을 확인 하 세 요!",
		
		"canshucuowu":"パラメータエラー",
		"chongzhiyitijiao":"トップアップ申請が提出されました",
		"chongzhisqb":"リチャージ申請書の提出に失敗しました",
		"nodi100":"入力される転送の数は100以上である必要があります",
		"pinputjine":"金額を入力してください",
		"selcztype":"充電方法を選択してください",
		"updateok":"更新が完了しました",
		"ishuazhuan":"転送を確認していますか？",
		"pinputokphone":"有効な電話番号を入力して下さい",
		"usernofind":"ユーザーが見つかりません"
},
    "forLegalPsw": {
    "fpwd": "パスワードを忘れた場合",
        "setpwd": "資金パスワードを設定する"
},
    "cfc": {
    "balance": "残高",
        "account": "口座から出る",
        "num": "転出数",
        "pla_account": "アカウントを入力してください",
        "pla_num": "転送数量を入力してください"
},
    "tpc": {
    "hot": "よく知られている",
        "all": "全通証",
        "nodata": "データがありません",
        "search": "索",
        "tips": "*現在のページの展示内容はプロジェクト側自身で記入して、MSはこの内容の真実性に責任を負わない",
        "time": "発行時間",
        "total": "総発行量",
        "circulation": "トークンフロー",
        "price": "発行価格",
        "web": "公式サイト",
        "book": "白書",
        "tool": "ソーシャルツール",
        "recommend": "推薦機構",
        "introduce": "通証紹介",
        "notice": "公告",
        "rr": "通証研報",
        "news": "ニュース",
        "author": "作成者：",
        "browse": "身の程をわきまえない："
},
    "finance": {
    "selectState": "ロック状態の選択",
        "front": "変更前",
        "after": "変更後",
        "info": "情報"
},
    "uc": {
    "code": "招待コード",
        "mymoney": "私の資金",
        "entrust": "委託管理",
        "deal": "成約管理",
        "pay": "支払い方式",
        "id": "ID認証",
        "safe": "セキュリティ設定",
        "invitate": "招待還利",
        "finance": "財務記録",
        "loginlog": "ログインログ"
},
    "usercenter": {
    "change": "貨幣勘定",
        "legal": "法幣勘定",
        "lever": "契約勘定"
},
    "account2": {
    "list": "引換貨幣のリスト",
        "address": "アドレスラベル",
        "code": "CAPTCHA",
        "addtime": "時間の追加",
        "suretime": "確認時間",
        "explain": "説明する"
},
    "withdrawList": {
    "sel": "通貨フィルタ",
        "submitTime": "提出時間",
        "checkTime": "審査時間",
        "tibiNum": "払戻し数量",
        "applying": "申し込み中",
        "hash": "ハッシュ値",
        "agreeWait": "チェーンを待つことに同意しました",
        "lianSucc": "チェーンアップ成功",
        "lianFail": "チェーン上の失敗",
        "bohui": "却下",
        "zhanSucc": "駅内成功"
},
    "wAddress": {
    "enterAddress": "引き出し先を入力してください",
        "enterMemo": "住所ラベルを入力してください",
        "enterMark": "コメントを入力してください",
        "tips": "暖かいヒント：USDTはbtc通貨で住所を追加し、ERC 20トークンはETHアドレスを使用して追加してください",
        "addressList": "アドレスリスト",
        "memo": "アドレスラベル",
        "del": "削除",
        "nospace": "引き出し先にスペースを含めることはできません",
        "nospace02": "アドレスラベルにスペースを含めることはできません",
        "nospace03": "アドレスラベルは、数字または文字、または文字と数字の組み合わせである必要があります",
        "allNum": "住所ラベルは純粋な数字でなければなりません",
        "bankNum": "銀行カード口座番号",
        "enterCardNum": "銀行カードのアカウントを入力してください",
        "addCard": "銀行入金コードの追加",
        "addcode": "入金コード画像をアップロードしてください",
        "moreAdd": "最大アップロード",
        "pics": "写真を見てください"
},
    "authDetail": {
    "payType": "入金方式",
        "noreceiveType": "入金方法がまだ追加されていません。追加してください！"
},
    "identity": {
    "anquan": "アカウントのセキュリティ",
        "verify": "ログイン認証",
        "openVerify": "検証を有効にする",
        "closeVerify": "検証を閉じる",
        "enterGooole": "Googleキーを入力してください",
        "verityType": "認証方式を選択してください"
},
    "caiwu": {
    "lock": "ロック",
        "nolock": "アンロック",
        "status": "ロック状態をお選びください",
        "selCu": "通貨を選択してください"
},
    "lang": {
        "en": "English",
        "kr": "한글",
        "jp": "日本語",
        "zh": "简体中文",
        "hk": "繁體中文",
        "spa": "España",
        "ara":"Arabia"
},
    "google": {
    "code": "Google 検証コード",
        "entergoogle": "Google CAPTCHAを入力してください"
},
    "transfer": {
    "select": "漕ぎ方をお選びください",
        "change": "通貨口座から法貨幣口座まで",
        "legal": "入金口座から入金口座への入金",
        "change_balance": "コインのアカウントが利用可能",
        "pswType": "パスワードは6桁の純数字",
        "newPsw": "新パスワードは6桁の数字でなければなりません",
        "changePhone": "携帯の番号を変更する",
        "changeEmail": "メールアドレスの変更",
        "emailCode": "メールアドレスの確認コードを入力してください",
        "newPhone": "新しい携帯番号を入力してください",
        "newEmail": "新しいメールアドレスを入力してください",
        "phoneCode": "携帯電話の確認コードを入力してください",
        "selCu": "通貨を選択してください",
        "ok": "資金の振り替えが成功した",
        "error": "資金の振り替えに失敗しました"
},
    "register01": {
    "country": "所属国",
        "selectCountry": "国籍情報は登録後に変更することはできません。必ず正直に選択してください",
        "noAccount": "まだアカウントがないので"
},
    "invite": {
    "welcome": "一緒に高額のリターンを稼ぎます",
        "effective": "効き目がある",
        "invalid": "無効",
        "list": "ランキング",
        "commission": "口銭をもらう",
        "link": "マイ・マーケティング・リンク",
        "share": "リンク共有のコピー",
        "mypromotion": "私の宣伝記録",
        "account": "被招待者アカウント",
        "nomore": "これ以上のデータはありません",
        "rules": "活動ルール",
        "tip1": "招待された人は必ず二級認証に合格しなければならない；",
        "tip2_0": "友達が招待を受けると、実際の取引手数料が発生するごとに、発生します",
        "tip2_1": "再発行されています",
        "tip3": "決済方式は招待制による決済である",
        "tip4_0": "招待者が実際に得たコミッションは",
        "tip4_1": "換算して招待者の取引口座を返すくりかえし高",
        "tip4_2": " =実際に発生した取引量*手数料の割合*返却率。",
        "tip5_0": "プラットフォームは毎日北京時間08：00対応通貨取引の終値で行われる",
        "tip5_1": "換算"
},
    "crowd": {
    "mujiNum": "募集数",
        "cur": "サポート通貨",
        "endTime": "終了時間",
        "intro": "プロジェクトの紹介を見る",
        "can_edu": "当施設の申込み可能額",
        "now_edu": "現在すでに購入した金額",
        "my_edu": "購入金額",
        "his": "歴史",
        "price": "購入価格",
        "money": "しんしゅう金",
        "yue": "勘定残高",
        "enterMoney": "申し込み金額を入力してください",
        "total": "今回の申し込み総額",
        "rule": "発注書",
        "startBuyTime": "時間稼ぎを始める",
        "endBuyTime": "買い付け時間を終了する"
},
    "dep": {
    "dep_rec": "入金レコード",
        "dep": "貨幣をたくわえる",
        "tip": "預金で利益を得ば，その貨幣は実財になる貨幣やまずい物でも宝を生むことができる！",
        "plan": "預金計画",
        "timer": "買い占め周期",
        "rate": "年利利回り",
        "lock": "ロック済み",
        "join": "人的関与",
        "end": "予約は終了しました",
        "buy": "すぐに買い取る",
        "date": "預金期日",
        "day": "くそっ",
        "total_money": "買い占め額",
        "sup_num": "余剰限度額",
        "top": "預金上限",
        "one_num": "単品引受数量",
        "num": "預金数量",
        "min": "最小",
        "max": "最大",
        "yuji": "予想収益",
        "qiang": "急いで買い入れる",
        "depEndTime": "預金締切時間",
        "jixiTime": "利息計算時間",
        "lockTime": "ロック解除時間",
        "enterNum": "予約数量を入力してください",
        "his": "預金歴史",
        "money": "預金額",
        "cur_name": "货币名称",
        "do": "アクション",
        "ti": "抜き出す",
        "suoTime": "じょうそう時間",
        "leiji": "累積収益",
        "rateNum": "手数料差し引き数量:",
        "ti_num": "抽出可能数量:",
        "tiYet": "抽出するかどうか",
        "to": "アカウント内？",
        "yes": "はい。",
        "no": "ノー"
},
    "vote": {
    "di": "3 日目",
        "period": "定期投票",
        "explain": "ルールの説明",
        "free": "ただで票を獲得しております",
        "have": "持っている",
        "num": "数量",
        "people": "サポート人数",
        "tickets": "票を支持する",
        "detail": "詳細を見る",
        "jiangli": "投票奨励",
        "fen": "分割する",
        "sup_people": "人のサポート",
        "piao": "チケット",
        "vote": "投票する",
        "enter": "投票を入力してください",
        "vote_cur": "投票で貨幣を投入する",
        "paiming": "ランキング",
        "intro": "プロジェクトの紹介",
        "taken": "Taken の概要と割り当て",
        "total": "トークン総量",
        "cur_total": "現在の流通総量",
        "price": "発行価格",
        "link": "関連リンク",
        "web": "公式サイト",
        "zh": "中国語版白書",
        "en": "英語版ホワイトペーパー"
},
    "td1": {
    "do": "アクション",
        "more": "より多くのロードをロード",
        "loading": "さい中",
        "nomore": "これ以上はありません",
        "nodata": "データがありません",
        "canceil": "取消",
        "confirm": "確認",
        "status": "状態"
},
    "accountset": {
    "name": "名前を記入してください"
},
    "sgr": {
    "sgrinfo": "SGR需要紹介",
        "lock": "現在の個人のSGRロック量: ",
        "signup": "ゴーサインアップ",
        "sgrget": "現在登録されているKYCはすぐに取得されます",
        "cir": "総循環量",
        "loc": "総ロック量",
        "richman": "今日の金持ち",
        "user": "ユーザー",
        "amount": "勝利",
        "time": "時間",
        "todayrelease": "今日の個人的なSGRリリースの量",
        "releaserecords": "リリースレコード",
        "invite": "SGR招待",
        "link": "招待リンク",
        "copy": "コピー",
        "totallock": "現在は総量をロックします",
        "onelevel": "現在の1レベルの招待数",
        "twolevel": "現在の2レベルの招待数",
        "therelevel": "現在のレベルの招待数",
        "get": "ゲット ",
        "rebate": "回復する"
},
    "lever": {
    "selHand": "量",
        "phands": "量",
        "transaction": "契約",
        "nowentrust": "現在の注文",
        "hisentrust": "歴史順序",
        "lvchi": "位置",
        "all": "すべて",
        "or": "または",
        "gotrade": "スタートトレード",
        "tdrecord": "取引記録",
        "dealed": "撤廃される",
        "notdeal": "撤廃されない",
        "loading": "読み込み...",
        "entotal": "注文金額",
        "canuse": "利用可能",
        "std": "市場",
        "xtd": "限界",
        "pprice": "価格を入力してください",
        "phand": "買い額",
        "pwd": "パスワード",
        "ppwd": "トレードパスワードを入力してください",
        "psw": "パスワード",
        "equal": "等しい",
        "times": "X",
        "timed": "レバレッジ",
        "handbuy": "ミニマム ",
        "handsell": "ミニマム",
        "hands": "量",
        "ptimes": "レバレッジを選択",
        "contractVal": "合計",
        "bail": "保険基金",
        "canBail": "利用可能資金",
        "charge": "料金",
        "domore": "買い/ロング",
        "doshort": "売り/ショート",
        "dmore": "ロング",
        "dshort": "ショート",
        "sureOd": "注文場所確認",
        "noless": "以上",
        "nomore": "以上",
        "tdnum": "転流量",
        "risk": "リスク率",
        "allloss": "オール・P/L",
        "onehouse": "クローズ",
        "type": "種類",
        "entrustPrice": "委託価格",
        "openPrice": "オープン価格",
        "nowPrice": "現在価格",
        "closePrice": "普通価格",
        "styPrice": "スティ価格",
        "stsPrice": "STS価格",
        "openTime": "公開時間",
        "weituoTime": "依頼時間",
        "closeTime": "クローズタイム",
        "revokeTime": "キャンセル時間",
        "rate": "料金",
        "nightFee": "ナイトフィー",
        "loss": "P/L",
        "setloss": "ストップロス",
        "expectProfit": "期待する",
        "expectLoss": "損失を期待します",
        "allClose": "終わり",
        "moreClose": "もっと近い",
        "nullClose": "ヌルクローズ",
        "sureClose": "閉じるこの動画はお気に入りから削除されています。",
        "thanZearo": "番号は0",
        "listin": "リスティング",
        "tdin": "取引",
        "closein": "閉鎖",
        "closed": "閉鎖",
        "revoked": "取り消した",
        "revokeOrder": "注文を取り消したいのですか。",
        "ping": "平倉",
        "revoke": "オーブを取り消すr",
        "sureping": "閉じるこの動画はお気に入りから削除されています。",
        "thanzone": "番号は0",
        "zheng": "Pリースの入力整数",
        "buyMin": "バイミン",
        "buyMax": "バイマックス",
        "enterBuyPrice": "購入価格",
        "sellMin": "売る",
        "sellMax": "販売最大",
        "enterSellPrice": "入力販売価格",
        "bestprice": "市場の最適価格"
},
    "miscro": {
    "quotation": "相場",
        "transaction": "秒契約取引",
        "symbol": "取引ペア",
        "balance": "残額",
        "miscroAccount": "秒契約アカウント",
        "trade": "取引中",
        "buyPrice": "買い入れ価格",
        "finshPrice": "出来値",
        "loss": "損益を予想する",
        "times": "カウントダウン",
        "mode": "取引モード",
        "num": "在庫数",
        "rate": "利潤率",
        "up": "上がる",
        "down": "転ぶ",
        "openNum": "開封数量を入力してください。",
        "success": "注文しました！",
        "c2c": "c 2 cアカウント",
        "complaint": "クレームの提案",
        "reply": "問題を書いてください。できるだけ早くご返事します。",
        "complaintList": "クレーム提案リスト",
        "complaintReply": "返信：",
        "complaintDescription": "説明内容を入力してください。",
        "resetpwd": "パスワードを変更",
        "foundedOn": "作成",
        "commonProblem": "よくある問題",
        "statement": "説明する",
        "about": "KiBiExについて",
        "everyone": "みんなCEOです",
        "program": "KiBiExグローバルパートナー計画",
        "myMine": "私の鉱場：",
        "friend": "私の鉱友：",
        "myRank": "我的等级：",
        "accumulated": "累計コミッション：",
        "copyLinks": "プロモーションリンクをコピー",
        "moneyVein": "人脈が銭脈に変わる",
        "example": "コミッションの例",
        "enterQuantity": "数量を入力してください",
        "contractBalance": "契約口座残高",
        "submitRepeatedly": "提出中ですので、繰り返し提出しないでください。",
        "alipayCode": "アリペイの支払いコード",
        "wechatCode": "WeChat決済コード",
        "realPrice": "リアルタイム価格",
        "currencyExchange": "通貨を割る",
        "currencyExchangeIn": "通貨を割る",
        "cashableBalance": "引出し可能残高：",
        "minimumCashable": "最小両替可能：",
        "maximumCashable": "最大両替可能:",
        "automaticallys": "両替をクリックしたら自動的に完了します。戻れません。",
        "match": "兑",
        "title": "資産交換",
        "holdAssets": "資産の保有",
        "dangerousCurrency": "保険",
        "convertibleQuantity": "交換可能な数量",
        "currencyExchanges": "為替レート：",
        "insuranceCurrency": "保険通貨",
        "insuranceType": "保険の種類",
        "contractAsset": "契約資産保険",
        "warehouses": "金庫の数",
        "availableAssets": "利用可能な資産：",
        "insuredAssets": "被保険資産：",
        "insuredAssets1": "被保険資産",
        "insuranceAssets": "保険資産：",
        "purchaseInsurance": "保険を購入する",
        "insuranceClaims": "保険金請求",
        "insuranceCancellation": "保険の終了",
        "coinWallet": "保険コインウォレット",
        "bmbWallet": "AITBウォレット",
        "cumulativeCoins": "蓄積されたコイン：",
        "availableQuantity": "利用可能な数量：",
        "rawCurrency": "通貨記録",
        "contractInsurance": "契約保険",
        "tenThousand": "万",
        "runningLow": "残高不足",
        "purchase": "入力した数量がルールを満たしていないため、購入制限は",
        "reach": "に",
        "between": "の間に",
        "onlyEnter": "あなただけが入ることができます",
        "integersBetween": "間の整数",
        "notReturned": "クリックして保険を購入すると、保険条約を詳細に理解し、保険倉庫が自動的に有効になり、返品できないことに同意したものとみなされます。。",
        "settled": "保険金は、被保険資産が注文できなくなるまで失った場合",
        "profitable": "資産が利益を生む時期",
        "terminated": "保険が自動的に終了する場合。",
        "automatically": "被保険資産が50％減った場合は保険金を申請しなければ取引できません。利益が100％の場合は自動的に解約されます。保険倉庫は1日2回以上の支払いが可能です。 、t + 1",
        "termination": "保険契約終了後は、被保険資産の交換のみが可能であり、契約終了後は契約違反とみなされ、保険倉庫は自動的に清算されます。",
        "just": "ポジティブ",
        "back": "反",
        "lowestNumber": "最小数量は",
        "confirmExchange": "償還を確認する?",
        "contractValuation": "契約勘定資産の評価",
        "secondValuation": "2番目の契約勘定資産評価",
        "falshValuation": "通貨勘定資産評価",
        "c2cValuation": "フィアット口座の資産評価",
        "recordWithdrawal": "カナダ通貨記録",
        "category": "カテゴリー",
        "safetyCenter": "セキュリティセンター",
        "safeText1": "登録、パスワードの変更、およびセキュリティ設定時に確認SMSを受信するには",
        "safeText2": "インターネットアカウントは盗まれる危険性があります。アカウントのセキュリティを保護するために、定期的にパスワードを変更することをお勧めします。",
        "flashTrading": "為替",
        "assetCenter": "資産センター",
        "promotionCode": "私のプロモーションコード",
        "loginAgain": "ログインが古くなっています。もう一度ログインしてください",
        "text10": "AITBに基づくリアルタイム価格",
        "text11": "支払い伝票"
},
    "td": {
    "buy": "購入",
        "sell": "売却",
        "currency": "幣種",
        "num": "数量",
        "time": "時間",
        "limit": "限度額",
        "price": "単価",
        "method": "支払い方式",
        "total": "総額",
        "inwant": "ご入力の、、、",
        "do": "操作",
        "more": "ローディング",
        "nomore": "以上",
        "nodata": "データなし",
        "trade": "取引",
        "buynum": "購入数量",
        "sellout": "売出す",
        "buyin": "購入",
        "all": "すべて",
        "allbuy": "全部買い取り",
        "allsell": "全部売り出し",
        "buytotal": "購入総額を入力してください",
        "selltotal": "売却総額を入力してください",
        "buyallnum": "購入希望数量を入力してください",
        "sellallnum": "売出す数量を入力してください",
        "tradeTotal": "取引総額",
        "doceil": "数秒後自動キャンセル",
        "place": "注文する",
        "waitpay": "支払いを待つ",
        "finished": "完成",
        "ceiled": "キャンセル",
        "payed": "支払済み",
        "nofinish": "未完成",
        "buyer": "買い手",
        "seller": "売り手",
        "callWay": "連絡方法",
        "placeTime": "注文時間",
        "renum": "参考番号",
        "canceil": "キャンセル",
        "confirm": "確認",
        "pwd": "取引のパスワード",
        "coincode": "通貨単位",
        "pcoin": "通貨単位を選択してください"
},
    "cuy": {
    "direction": "方向",
        "total": "総計",
        "price": "価格",
        "sell": "売る",
        "buy": "買う",
        "allStation": "全般取引",
        "buyPrice": "買入れ価格",
        "buynum": "購入量",
        "sellPrice": "売り値",
        "sellnum": "販売量",
        "tdPrice": "取引額",
        "or": "或いは",
        "tdStart": "取引開始",
        "pbPrice": "お手数ですが、買入価格をご入力してください",
        "pbNum": "お手数ですが、購入量をご入力してください",
        "psPrice": "お手数ですが、売り値をご入力してください",
        "psNum": "お手数ですが、販売量をご入力してください",
        "fixPrice": "取引限定値",
        "Mtrans": "取引マーケティング値",
        "available": "利用可能",
        "revoke": "撤回",
        "loading": "ローディング中",
        "confirmCancel": "キャンセルすることは確定しましたか?？",
        "confirm": "確定",
        "cancel": "キャンセル",
        "nomore": "これ以上のデータはございません",
        "evelPrice": "成約値"
},
    "iepn": {
    "htitle": "たくさんのコインが販売されています。友達を招待して一緒にコインを獲得しましょう",
        "subtitle": "プラットフォームはたくさんのコインを販売し、すぐに来て購入します、数は限られています〜",
        "netname": "スマートエピデミック予防ネットワーク",
        "plannum": "計画された循環",
        "totalnum": "계획된 유통",
        "pushednum": "発行済み",
        "remindnum": "残りの数量",
        "hotcommond": "人気のおすすめ",
        "ctitle": "世界政府の医療管理機関",
        "cdsp": "医療は人々の生活を含み、政府の役割を回避することは不可能です。 Intelligent Epidemic Prevention Networkの透過的なチェーン特性は、グローバルな医療管理機関が医療機関をより適切に管理し、国民の健康を監視し、迅速かつ柔軟な医療リソースの割り当てを行うのに役立ちます。",
        "starttime": "始まる時間",
        "startprice": "開始価格",
        "endtime": "終了時間",
        "seemore": "続きを見る",
        "baipishu": "技術白書",
        "gobuy": "買いに行きます"
},
"apitip":{
	"参数错误":"パラメータエラー",
	"数据未找到":"データが見つかりません",
	"请选择需求类型":"要件の種類を選択してください",
	"请选择交易方式":"取引方法を選択してください",
	"请填写单价":"単価をご記入ください",
	"请填写数量":"数量をご記入ください",
	"请选择币种":"通貨を選択してください",
	"请输入正确的交易数量或价格":"正しい取引数量または価格を入力してください",
	"您还没有设置收款信息":"支払い情報を設定していません",
	"取消次数已超出":"キャンセル数を超えました",
	"次,明天再发布":"時間、明日リリース",
	"用户钱包不存在":"ユーザーウォレットが存在しません",
	"对不起，您的钱包余额不足":"申し訳ありませんが、ウォレットの残高が不足しています",
	"用户发布c2c交易法币出售，扣除法币余额":"ユーザーは法定通貨を販売するためにc2cトランザクションをリリースし、法定通貨の残高を差し引きました",
	"用户发布c2c交易法币出售,锁定余额增加":"ユーザーが法定通貨を販売するためにc2cトランザクションをリリースし、ロックされた残高が増加しました",
	"发布成功":"成功",
	"无此用户":"ユーザーが存在しません",
	"用户不存在":"ユーザーは存在しません",
	"参数错误2":"パラメータエラー2",
	"无此币种":"そのような通貨はありません",
	"该币不是法币":"通貨は法定通貨ではありません",
	"无此记录":"そのような記録はありません",
	"您还没有通过实名认证":"実名認証に合格していません",
	"您还没有设置收款信息":"支払い情報を設定していません",
	"无此记录":"そのような記録はありません",
	"此条交易已完成":"このトランザクションは完了しました",
	"未找到该发布用户":"投稿ユーザーが見つかりませんでした",
	"不能操作自己的":"自分で操作することはできません",
	"您无此钱包账号":"このウォレットアカウントをお持ちではありません",
	"您的钱包已被锁定，请联系管理员":"ウォレットがロックされています。管理者に連絡してください",
	"检测有未完成交易，请完成后再来！":"未完了の取引がないか確認してください。完了後に戻ってきてください。",
	"您的余额不足":"あなたのバランスが不十分です",
	"您的法币冻结资金异常,请查看您是否有正在进行的挂单":"フィアット凍結資金が異常です。保留中の注文があるかどうかを確認してください",
	"出售给商家法币,余额减少":"法定通貨で商人に販売され、残高が減少します",
	"出售给商家法币,锁定余额增加":"法定通貨で商人に販売され、ロックイン残高が増加します",
	"您发布的求购信息有用户出售啦，请去 APP 查看吧～":"あなたが投稿した購入情報はユーザーによって販売されています。APPにアクセスして確認してください〜",
	"您发布的出售信息有用户购买啦，请去 APP 查看吧～":"あなたが投稿した販売情報はユーザーが購入したものです。APPにアクセスして確認してください〜",
	"Operation successful，请联系商家确认订单":"操作が成功しました。販売者に連絡して注文を確認してください",
	",错误位于第":"、エラーは",
	",行":"、行",
	"无此币种":"そのような通貨はありません",
	"该币不是法币":"通貨は法定通貨ではありません",
	"用户信息不正确":"ユーザー情報が正しくありません",
	"无此记录":"そのような記録はありません",
	"该订单已操作过，请勿重复操作":"この注文はすでに操作されています。操作を繰り返さないでください",
	"对不起，您无权操作":"申し訳ありませんが、あなたは操作する権限がありません",
	"buy":"購入",
	"对不起，您无权操作":"申し訳ありませんが、あなたは操作する権限がありません",
	"Operation successful，请联系卖家确认":"操作が成功しました。販売者に連絡して確認してください",
	"Operation successful，订单已取消":"操作は成功し、注文はキャンセルされました",
	"您今天的取消次数已超出":"今日のキャンセル数を超えました",
	"该订单已操作，请勿取消":"注文は処理されました。キャンセルしないでください",
	"对不起，这不是您的发布信息":"申し訳ありませんが、これはあなたの投稿ではありません",
	"该订单还未付款或已经操作过":"注文が支払われていないか、処理されています",
	"您不能确认该订单":"注文を確認できません",
	"订单异常":"注文の例外",
	"该用户没有此币种钱包":"ユーザーはこの通貨ウォレットを持っていません",
	"该买家没有此币种钱包":"買い手はこの通貨の財布を持っていません",
	"出售法币成功,扣除锁定余额":"法定通貨の販売に成功し、ロックされた残高を差し引く",
	"购买法币成功，增加法币余额":"法定通貨の購入に成功し、法定通貨の残高を増やします",
	"在":"に",
	"确认成功":"成功を確認する",
	"订单异常":"注文の例外",
	"卖出法币成功":"法定通貨の販売に成功",
	"购买法币成功":"法定通貨の購入の成功",
	"确认成功":"成功を確認する",
	"该发布信息下有交易产生无法删除":"投稿された情報の下で生成された削除できないトランザクションがあります",
	"对不起，您无权撤销此记录":"申し訳ありませんが、このレコードを取り消す権利はありません",
	"用户钱包不存在":"ユーザーウォレットが存在しません",
	"对不起，您的账户冻结资金不足":"申し訳ありませんが、アカウントの資金が不足しています",
	"商家撤回发布法币出售":"商人は販売のための法定通貨のリリースを撤回します",
	"商家撤回发布法币出售":"商人は販売のための法定通貨のリリースを撤回します",
	"撤回成功":"正常に撤回する",
	"参数错误!":"パラメータエラー！",
	"转账账户不存在!":"転送アカウントが存在しません！",
	"不能给自己转账!":"自分に送金できません！",
	"转账数量大于剩余数量!":"振込金額が残額より多い！",
	"余额不足!":"残高不足！",
	"转账数量不能小于":"振込金額は以下になりません",
	"转账数量不能大于":"送金金額は",
	"通证转账成功!":"パス転送が成功しました！",
	"必填项信息不完整":"必要な情報が不完全です",
	"两次输入密码不一致":"2つのパスワードに一貫性がありません",
	"此用户不存在":"このユーザーは存在しません",
	"此交易账号已经设置":"この取引口座は設定されています",
	"此账号已经存在":"このアカウントはすでに存在します",
	"交易账号设置成功":"取引口座が正常に設定されました",
	"验证码错误":"検証コードエラー",
	"手机绑定成功":"携帯電話のバインドに成功",
	"邮箱绑定成功":"メールボックスのバインドに成功",
	"手势密码至少连接6个点":"ジェスチャパスワードには少なくとも6つのポイントが接続されている必要があります",
	"两次手势密码不相同":"2つのジェスチャパスワードが同じではありません",
	"手势密码添加成功":"ジェスチャーパスワードが正常に追加されました",
	"取消手势密码成功":"ジェスチャーパスワードを正常にキャンセルする",
	"密码只能在6-16位之间":"パスワードは6〜16桁のみです",
	"两次密码不一致":"2つのパスワードに一貫性がありません",
	"交易密码设置成功":"トランザクションパスワードが正常に設定されました",
	"暂时还没有邀请排行榜，快去邀请吧":"招待リーダーボードはまだありません。行って招待してください",
	"会员未找到":"メンバーが見つかりません",
	"你已绑定，不可更改!":"あなたは拘束されており、変更することはできません！",
	"该地址已被绑定,请重新输入":"アドレスがバインドされています。再入力してください",
	"绑定成功!":"バインディングは成功しました！",
	"暂无钱包":"財布なし",
	"请提交完整的信息":"完全な情報を提出してください",
	"请输入合法的身份证号码":"正式なID番号を入力してください",
	"该身份证号已实名认证过!":"ID番号は本名で確認済みです！",
	"您已经申请过了":"あなたはすでに申請しました",
	"提交成功，等待审核":"正常に送信され、レビューを待っています",
	"支付密码错误":"間違った支払いパスワード",
	"退出登录成功":"正常にログアウトします",
	"请输入支付密码":"支払いパスワードを入力してください",
	"余额不足":"残高不足",
	"无需升级":"アップグレードする必要はありません",
	"升级成功":"更新に成功しました",
	"升级会员":"アップグレードメンバー",
	"没有此用户":"ユーザーが存在しません",
	"该地址别人已经绑定过了":"このアドレスはすでに他の人によってバインドされています",
	"更新成功":"更新が完了しました",
	"请重试":"もう一度やり直してください",
	"请把参数填写完整":"パラメータを完全に入力してください",
	"推荐用户不存在":"推奨ユーザーが存在しません",
	"转入账户不能为空":"転送アカウントを空にすることはできません",
	"转入数量不能为空":"転送された数量を空にすることはできません",
	"密码不能为空":"パスワードを空白にすることはできません",
	"用户密码错误":"ユーザーパスワードが間違っています",
	"美丽链法币交易余额转入":"プラズマ法定通貨取引残高の転送",
	"美丽链币币交易余额转入":"プラズマコインの取引残高の転送",
	"美丽链杠杆交易余额转入":"プラズマレバレッジ取引残高転送",
	"转入成功":"正常に転送されました",
	"密码修改成功":"パスワードのリセットが完了しました",
	"用户未找到":"ユーザーが見つかりません",
	"绑定成功":"正常にバインド",
	"发送成功":"正常に送信されました",
	"m月d日 H:i":"m月d日H：i",
	"币未找到":"コインが見つかりません",
	"暂无数据":"データなし",
	"文件大小超出":"ファイルサイズを超えました",
	"该文件已存在":"ファイルはすでに存在します",
	"文件类型不对":"不正なファイルタイプ",
	"上传失败":"アップロードに失敗しました",
	"内容不能为空":"コンテンツを空白にすることはできません",
	"提交成功，我们会尽快给你回复":"送信は成功しました。できるだけ早くご連絡いたします。",
	"非法参数":"不正なパラメータ",
	"该币种不支持购买保险":"この通貨は保険の購入をサポートしていません",
	"错误的金额！":"金額が間違っています！",
	"不存在的险种！":"存在しない種類の保険！",
	"不存在的币种！":"存在しない通貨！",
	"该币种不支持购买保险":"この通貨は保険の購入をサポートしていません",
	"已经购买了该币种的险种！":"この通貨の保険をすでに購入しています！",
	"可用余额不足，无法购买！":"利用可能な残高が購入するには不十分です！",
	"购买成功！":"購入は成功です！",
	"购买失败！原因：":"購入に失敗しました！ 理由：",
	"未找到该保险":"保険が見つかりませんでした",
	"该保险正在处理中":"保険は処理中です",
	"申请索赔失败":"請求の申請に失敗しました",
	"申请索赔成功！":"クレーム申請は成功です！",
	"超出今日索赔次数!":"今日のクレーム数を超えました！",
	"存在未平仓订单":"未処理の注文があります",
	"受保资产为零":"ゼロ保険資産",
	"受保资产不符合可申请索赔条件1":"被保険資産が請求可能な条件を満たしていない1",
	"受保资产不符合可申请索赔条件2":"被保険資産が請求可能な条件を満たしていない2",
	"未知的险种类型":"不明な種類の保険",
	"保险赔偿用户[清除受保金额]":"保険補償ユーザー[保険金額をクリア]",
	"保险赔偿用户[赔偿受保金额]":"保険補償利用者【保険金額補償】",
	"保险解约，扣除保险金额":"保険の終了、保険金額の控除",
	"未知受保金额去向状态":"不明な保険金額の所在状況",
	"处理成功！":"正常に処理されました！",
	"处理失败：":"処理に失敗しました：",
	"未找到该保险":"保険が見つかりませんでした",
	"该保险已失效":"保険の有効期限が切れています",
	"该保险正在索赔处理中":"保険は請求処理中です",
	"解约失败，存在未平仓订单":"キャンセルに失敗しました。未処理の注文があります",
	"保险解约，赔付金额":"保険の解約、補償額",
	"保险解约，扣除受保金额":"保険の終了、保険金額を差し引く",
	"保险解约，扣除保险金额":"保険の終了、保険金額の控除",
	"解约成功！":"正常終了！",
	"解约失败:":"キャンセルに失敗しました",
	"订单取消成功":"注文は正常にキャンセルされました",
	"请选择需求类型":"要件の種類を選択してください",
	"请选择交易方式":"取引方法を選択してください",
	"请填写单价":"単価をご記入ください",
	"请填写数量":"数量をご記入ください",
	"请填写最小交易数量":"最小取引数量を入力してください",
	"请选择币种":"通貨を選択してください",
	"最小交易数量不能大于总数量":"最小取引数量は合計数量を超えることはできません",
	"请填写最大交易量":"最大取引量を入力してください",
	"请填写正确的最大交易量":"正しい最大取引量を入力してください",
	"币种信息有误":"通貨情報が間違っています",
	"对不起，您不是该法币的商家":"申し訳ありませんが、あなたは法定通貨の商人ではありません",
	"对不起，您的商家账户不足":"申し訳ありませんが、マーチャントアカウントが不十分です",
	"商家发布出售":"マーチャントはリリースして販売します",
	"商家发布出售,冻结增加":"商人は売りに出され、凍結が増加します",
	"发布成功":"成功",
	"无此商家":"そのようなビジネスはありません",
	"您不是商家":"あなたはビジネスではありません",
	"该币不是法币":"通貨は法定通貨ではありません",
	"无此币种":"そのような通貨はありません",
	"购买参数错误":"購入パラメータエラー",
	"无此记录":"そのような記録はありません",
	"请填写购买额":"購入金額をご記入ください",
	"购买额请填写数字":"購入金額の番号を入力してください",
	"您还没有通过实名认证":"実名認証に合格していません",
	"您还没有设置收款信息":"支払い情報を設定していません",
	"未完成单子超过3单，请完成后再操作!":"未完了の注文が3つ以上あります。もう一度操作を完了してください!",
	"商家挂单状态异常,暂时不能交易":"マーチャントの保留中の注文ステータスは異常であり、一時的に取引することはできません",
	"商家挂单剩余可交易数量不足":"マーチャントの保留中の注文の残りの取引可能な数量が不十分です",
	"此条交易已完成":"このトランザクションは完了しました",
	"非法提交，数量必须大于0":"不正な提出、数量は0より大きくなければなりません",
	"您低于最低限额":"最小値を下回っています",
	"您高于最高限额":"上限を超えています",
	"您高于最大限制数量":"上限を超えています",
	"未找到该商家":"ビジネスが見つかりませんでした",
	"不能操作自己的":"自分で操作することはできません",
	"您无此钱包账号":"このウォレットアカウントをお持ちではありません",
	"您的钱包已被锁定，请联系管理员":"ウォレットがロックされています。管理者に連絡してください",
	"您的法币余额不足":"法定通貨の残高が不足しています",
	"您的法币冻结资金异常,请查看您是否有正在进行的挂单":"フィアット凍結資金が異常です。保留中の注文があるかどうかを確認してください",
	"您出售数量大于商家剩余数量!":"あなたが売る数量は、商人の残りの数量よりも多いです！",
	"出售给商家法币:扣除余额":"商人に販売された法定通貨：残高を差し引く",
	"出售给商家法币:增加冻结":"商人に販売された法定通貨：凍結の増加",
	"您购买数量大于商家剩余数量!":"あなたの購入数量は商人の残りの数量よりも多いです！",
	",错误位于第":"、エラーは",
	"Operation successful，请联系商家确认订单":"操作が成功しました。販売者に連絡して注文を確認してください",
	"行":"行",
	"该订单已操作过，请勿重复操作":"この注文はすでに操作されています。操作を繰り返さないでください",
	"请上传支付凭证":"支払い伝票をアップロードしてください",
	"对不起，您无权操作":"申し訳ありませんが、あなたは操作する権限がありません",
	"Operation successful，请联系卖家确认":"操作が成功しました。販売者に連絡して確認してください",
	"该订单已操作，请勿取消":"注文は処理されました。キャンセルしないでください",
	"Operation successful，订单已取消":"操作は成功し、注文はキャンセルされました",
	"对不起您不是商家":"申し訳ありませんが、あなたはビジネスではありません",
	"对不起，您不是该商家":"申し訳ありませんが、あなたはビジネスではありません",
	"该订单还未付款或已经操作过":"注文が支払われていないか、処理されています",
	"对不起，您的商家冻结余额不足,当前余额:":"申し訳ありませんが、販売者の凍結残高が不足しています。現在の残高：",
	"您不能确认该订单":"注文を確認できません",
	"订单异常":"注文の例外",
	"该用户没有此币种钱包":"ユーザーはこの通貨ウォレットを持っていません",
	"法币交易:在商家":"法定通貨取引：商人で",
	"购买法币成功":"法定通貨の購入の成功",
	"法币交易:卖出成功":"フィアット通貨取引：成功した販売",
	"确认成功":"成功を確認する",
	"该订单还未付款或已经操作过":"注文が支払われていないか、処理されています",
	"您不能确认该订单":"注文を確認できません",
	"该用户没有此币种钱包":"ユーザーはこの通貨ウォレットを持っていません",
	"法币交易:用户":"法定通貨取引：ユーザー",
	"向商家出售法币成功":"商人への法定通貨の販売に成功",
	"法币交易:购买成功":"法定通貨取引：購入の成功",
	"确认成功":"成功を確認する",
	"此状态下无法下架":"この状態では削除できません",
	"对不起，您不是该法币的商家":"申し訳ありませんが、あなたは法定通貨の商人ではありません",
	"发布下架成功,将不会再与新用户匹配":"リリースは成功し、新しいユーザーとは一致しなくなります",
	"必须下架后才可以撤销":"取り消す前に削除する必要があります",
	"已撤回发布":"リリースは取り下げられました",
	"当前发布状态无法撤销":"現在のリリースステータスは元に戻せません",
	"当前发布剩余数量不足,无法撤销":"現在のリリースの残りの数は不十分であり、元に戻すことはできません",
	"该发布信息下有交易正在进行中,请等待交易结束再撤回":"この投稿情報の下で進行中の取引があります。取引が終了するのを待ってから撤回してください",
	"对不起，您不是该法币的商家":"申し訳ありませんが、あなたは法定通貨の商人ではありません",
	"对不起，您的商家账户冻结资金不足":"申し訳ありませんが、マーチャントアカウントの資金が不足しています",
	"撤回失败:减少冻结资金失败":"引き出しの失敗：凍結された資金の失敗を減らす",
	"商家撤回":"商人の撤退",
	"撤回失败:增加余额失败":"引き出しに失敗しました：バランスを上げることができませんでした",
	"撤回成功":"正常に撤回する",
	"该发布信息无异常":"公開されている情報は正常です",
	"该发布信息下有交易未完成，不能标记为异常":"投稿された情報の下に未完了のトランザクションがあり、異常としてマークすることはできません",
	"该发布剩余数量不足,不法标记异常":"残りのリリース数が不足しており、不正フラグが異常です",
	"当前发布状态无法标记异常":"現在のリリースステータスを例外としてフラグを立てることはできません",
	"对不起，您的商家账户冻结资金不足":"申し訳ありませんが、マーチャントアカウントの資金が不足しています",
	"处理异常失败:减少冻结资金失败":"例外の処理に失敗しました：凍結された資金の失敗を減らします",
	"处理异常失败:增加余额失败":"例外の処理に失敗しました：バランスを上げることができませんでした",
	"商家处理异常":"マーチャント処理の例外",
	"商家处理异常":"マーチャント処理の例外",
	"缺少参数或传值错误":"パラメータがないか、値が間違っています",
	"指定交易对不存在":"指定されたトレーディングペアは存在しません",
	"您未开通本交易对的交易功能":"このトレーディングペアのトレーディング機能を有効にしていません",
	"手数必须是大于0的整数":"ロットサイズは0より大きい整数である必要があります",
	"手数不能低于":"ハンドの数は以下にすることはできません",
	"手数不能高于":"ロット数はこれより多くすることはできません",
	"选择倍数不在系统范围":"複数選択がシステムにありません",
	"您有正在平仓中的交易,暂不能进行买卖":"清算中の取引があり、一時的に取引することはできません",
	"交易类型错误":"間違ったトランザクションタイプ",
	"该功能暂未开放":"この機能はまだ開いていません",
	"限价交易价格必须大于0":"指値取引価格は0より大きくなければなりません",
	"当前没有获取到行情价格,请稍后重试":"現在、市場価格は取得されていません。しばらくしてからもう一度お試しください",
	"限价交易卖出不能低于当前价":"指値取引は現在の価格を下回って販売することはできません",
	"限价交易买入价格不能高于当前价":"指値取引の購入価格は現在の価格より高くすることはできません",
	"钱包未找到,请先添加钱包":"ウォレットが見つかりません。最初にウォレットを追加してください",
	"余额不足,不能小于":"バランスが不十分で、",
	"(手续费:":"（手数料：",
	"提交失败":"送信に失敗しました",
	"扣除保证金失败":"証拠金の控除に失敗しました",
	"扣除手续费失败":"手数料の控除に失敗しました",
	"此功能系统未开放":"この機能システムは開いていません",
	"止盈止损价格不能为0":"ストッププロフィットとストップロスの価格を0にすることはできません",
	"找不到该笔交易":"トランザクションが見つかりません",
	"买入(做多)止盈价不能低于开仓价和当前价":"バイ（ロング）テイクプロフィット価格は、始値と現在の価格より低くすることはできません",
	"买入(做多)止亏价不能高于开仓价和当前价":"買い（長く行く）ストップロス価格は始値と現在の価格より高くすることはできません",
	"卖出(做空)止盈价不能高于开仓价和当前价":"売り（ショート）テイク利益価格は始値と現在の価格より高くすることはできません",
	"卖出(做空)止亏价不能低于开仓价和当前价":"売り（ショート）ストップロス価格は始値と現在の価格より低くすることはできません",
	"设置成功":"正常に設定",
	"设置失败":"セットアップに失敗しました",
	"数据未找到":"データが見つかりません",
	"无权操作":"操作する権利がありません",
	"交易状态异常,请勿重复提交":"取引状況が異常ですので、繰り返し提出しないでください。",
	"平仓失败,请重试":"ポジションを閉じることができませんでした。もう一度やり直してください",
	"交易异常，无法平仓":"取引が異常でポジションをクローズできません",
	"买入方向传参错误":"購入方向に渡される誤ったパラメーター",
	"提交成功,请等待系统处理":"送信は成功しました。システムが処理するまでお待ちください",
	"未找到需要平仓的交易":"閉じる必要のあるトランザクションはありません",
	"提交成功,请等待系统处理":"送信は成功しました。システムが処理するまでお待ちください",
	"未找到需要平仓的交易":"閉じる必要のあるトランザクションはありません",
	"交易不存在或已撤单,请刷新后重试":"トランザクションが存在しないか、注文がキャンセルされました。更新して再試行してください",
	"撤单失败:用户钱包不存在":"注文のキャンセルに失敗しました：ユーザーウォレットが存在しません",
	"撤单失败":"キャンセルに失敗しました",
	"撤单失败:变更状态失败":"注文のキャンセルに失敗しました：ステータスの変更に失敗しました",
	"撤单成功":"キャンセルの成功",
	"请输入账号":"アカウントを入力してください",
	"请输入密码":"パスワードを入力してください",
	"手势密码错误":"ジェスチャーパスワードエラー",
	"账号已锁定！请联系管理员":"アカウントがロックされています！ 管理者に連絡してください",
	"两次密码不一致":"2つのパスワードに一貫性がありません",
	"密码只能在6-16位之间111111":"パスワードは6〜16桁のみにすることができます111111",
	"验证码错误":"検証コードエラー",
	"账号已存在":"アカウントはすでに存在しています",
	"请填写正确的邀请码":"正しい招待コードを入力してください",
	"请输入账号":"アカウントを入力してください",
	"请输入密码或确认密码":"パスワードを入力するか、パスワードを確認してください",
	"输入两次密码不一致":"2つのパスワードに一貫性がありません",
	"验证码不正确":"不正な確認コード",
	"账号不存在":"アカウントが存在しません",
	"修改密码成功":"パスワードの変更に成功",
	"请输入验证码":"確認コードを入力してください",
	"验证码错误":"検証コードエラー",
	"验证成功":"正常に検証されました",
	"下单失败:超过最大持仓笔数限制":"注文に失敗しました：ポジションの最大数の制限を超えました",
	"当前未获取到行情":"現在、市場情報はありません",
	"尚未申购或理赔保险":"まだ加入していないか、保険に加入していない",
	"申购的保险T+1生效":"購入した保険T + 1が有効になります",
	"受保资产为零":"ゼロ保険資産",
	"受保资产小于等于可下单条件":"被保険資産が発注可能条件以下",
	"您已超过持仓限制，暂停下单。":"ポジション制限を超え、注文が一時停止されました。",
	"未知的险种类型":"不明な種類の保険",
	"超过最大持仓数量限制":"ポジション制限の最大数を超えました",
	"交易中的订单大于最大挂单数量":"トランザクション内の注文が保留中の注文の最大数を超えています",
	"新闻不存在":"ニュースは存在しません",
	"无此币种":"そのような通貨はありません",
	"该币不是法币":"通貨は法定通貨ではありません",
	"用户名不能为空":"ユーザー名を空にすることはできません",
	"名称不能为空":"名前が必要です",
	"账号不能为空":"アカウントを空にすることはできません",
	"资产不能为空":"アセットを空にすることはできません",
	"找不到此交易账号的用户":"この取引口座のユーザーが見つかりません",
	"此用户还未通过实名认证":"このユーザーは実名認証に合格していません",
	"币种不存在":"通貨は存在しません",
	"该币不是法币":"通貨は法定通貨ではありません",
	"商家名称已存在":"商号はすでに存在します",
	"此法币":"この法定通貨",
	"此用户已是此法币商家":"このユーザーはすでにこの法定通貨の商人です",
	"申请成为商家，扣除USDT":"商人になるために申請し、USDTを差し引く",
	"当前未获取到行情":"現在、市場情報はありません",
	"系统配置错误，请联系系统管理员":"システム構成エラー、システム管理者に連絡してください",
	"账号不能为空":"アカウントを空にすることはできません",
	"发送成功":"正常に送信されました",
	"账号不能为空":"アカウントを空にすることはできません",
	"账号错误":"アカウントエラー",
	"账号已存在":"アカウントはすでに存在しています",
	"请填写账号":"口座番号をご記入ください",
	"【BEF】若非您本人操作，请及时修改密码。":"【NEWBIT】ご自身の操作でない場合は、時間内にパスワードを変更してください。",
	"发送成功":"正常に送信されました",
	"账号不能为空":"アカウントを空にすることはできません",
	"账号错误":"アカウントエラー",
	"账号已存在":"アカウントはすでに存在しています",
	"发送成功":"正常に送信されました",
	"发送失败":"送信に失敗しました",
	"ip参数不正确":"ipパラメータが正しくありません",
	"邮箱不能为空":"電子メールを空にすることはできません",
	"账号错误":"アカウントエラー",
	"账号已存在":"アカウントはすでに存在しています",
	"短信验证码":"SMS確認コード",
	"您还没有交易记录":"取引記録がありません",
	"非法操作,不能撤回非自己发布的信息":"違法な操作、自分で投稿していない情報を引き出すことはできません",
	"非法操作:(":"違法な操作",
	"取销卖出交易失败":"販売取引のキャンセルに失敗しました",
	"取消买入交易,解除币币余额锁定":"購入トランザクションをキャンセルし、通貨残高のロックを解除します",
	"取消买入交易,解除法币余额锁定":"購入トランザクションをキャンセルし、法定通貨の残高のロックを解除します",
	"用户未找到":"ユーザーが見つかりません",
	"取消成功":"成功をキャンセル",
	"取销卖出交易失败":"販売取引のキャンセルに失敗しました",
	"取消卖出交易,解除交易余额锁定":"販売トランザクションをキャンセルし、トランザクション残高のロックを解除します",
	"取消卖出交易,解除交易余额锁定":"販売トランザクションをキャンセルし、トランザクション残高のロックを解除します",
	"取消成功":"成功をキャンセル",
	"类型错误":"タイプエラー",
	"交易不存在":"トランザクションが存在しません",
	"资金不足":"残高不足",
	"取消卖出交易,解除交易余额锁定":"販売トランザクションをキャンセルし、トランザクション残高のロックを解除します",
	"取消卖出交易,解除交易余额锁定":"販売トランザクションをキャンセルし、トランザクション残高のロックを解除します",
	"数据未找到":"データが見つかりません",
	"请先添加钱包":"最初にウォレットを追加してください",
	"您的币不足":"コインが足りません",
	"提交卖出记录扣除":"控除のための販売記録の提出",
	"你今天的交易额度已达到上限!":"今日の取引限度額は上限に達しました!",
	"数据未找到":"データが見つかりません",
	"余额不足":"残高不足",
	"提交卖入记录扣除":"販売記録からの控除を提出する",
	"提交卖出记录(增加冻结)":"販売記録を提出する（凍結を増やす）",
	"价格和数量必须大于0":"価格と数量は0より大きくなければなりません",
	"您的币不足":"コインが足りません",
	"您的冻结资金异常，禁止挂卖":"あなたの凍結された資金は異常です、それは売ることを禁じられています",
	"真实姓名必须填写":"本名を入力する必要があります",
	"收款信息至少选填一项":"支払い情報を少なくとも1つ入力してください",
	"保存成功":"正常に保存",
	"密码错误":"間違ったパスワード",
	"当前行情小于等于零!":"現在の市場はゼロ以下です!",
	"资产兑换,减少持有币法币:":"資産交換、保有通貨と法定通貨の削減",
	"兑换数量大于持有资产!":"交換金額は保有資産を上回っています！",
	"资产兑换,减少":"資産交換、削減",
	"资产兑换,增加USDT杠杆币":"資産交換、USDTレバレッジ通貨の増加",
	"扣除手续费":"控除手数料",
	"法币数量:":"法定通貨の数",
	"兑换数量大于剩余数量!":"交換された数量が残りの数量よりも多い!",
	"资产兑换成功!":"資産交換の成功!",
	"参数错误!":"パラメータエラー!",
	"通证兑换,杠杆币增加":"トークン交換、レバレッジ通貨の増加",
	"通证兑换杠杆币增加失败":"トークン交換レバレッジ通貨の増加に失敗しました",
	"通证兑换成功!":"トークン交換に成功!",
	"必填项信息不完整":"必要な情報が不完全です",
	"两次输入密码不一致":"2つのパスワードに一貫性がありません",
	"此用户不存在":"このユーザーは存在しません",
	"此交易账号已经设置":"この取引口座は設定されています",
	"此账号已经存在":"このアカウントはすでに存在します",
	"交易账号设置成功":"取引口座が正常に設定されました",
	"邮箱绑定成功":"メールボックスのバインドに成功",
	"手机绑定成功":"携帯電話のバインドに成功",
	"手势密码添加成功":"ジェスチャーパスワードが正常に追加されました",
	"两次手势密码不相同":"2つのジェスチャパスワードが同じではありません",
	"取消手势密码成功":"ジェスチャーパスワードを正常にキャンセルする",
	"手势密码至少连接6个点":"ジェスチャパスワードには少なくとも6つのポイントが接続されている必要があります",
	"密码只能在6-16位之间":"パスワードは6〜16桁のみです",
	"两次密码不一致":"2つのパスワードに一貫性がありません",
	"交易密码设置成功":"トランザクションパスワードが正常に設定されました",
	"暂时还没有邀请排行榜，快去邀请吧":"招待リーダーボードはまだありません。行って招待してください",
	"会员未找到":"メンバーが見つかりません",
	"该身份证号已实名认证过":"ID番号は本名で確認済みです",
	"你已绑定，不可更改!":"あなたは拘束されており、変更することはできません！",
	"该地址已被绑定,请重新输入":"アドレスがバインドされています。再入力してください",
	"绑定成功!":"正常にバインド!",
	"请提交完整的信息":"完全な情報を提出してください",
	"提交成功，等待审核":"正常に送信され、レビューを待っています",
	"退出登录成功":"正常にログアウトします",
	"请输入支付密码":"支払いパスワードを入力してください",
	"参数错误":"パラメータエラー",
	"无需升级":"アップグレードする必要はありません",
	"兑换成功":"償還の成功",
	"暂无钱包":"財布なし",
	"支付密码错误":"間違った支払いパスワード",
	"余额不足":"残高不足",
	"升级成功":"更新に成功しました",
	"该地址别人已经绑定过了":"このアドレスはすでに他の人によってバインドされています",
	"更新成功":"更新が完了しました",
	"请重试":"もう一度やり直してください",
	"推荐用户不存在":"推奨ユーザーが存在しません",
	"请把参数填写完整":"パラメータを完全に入力してください",
	"转入账户不能为空":"転送アカウントを空にすることはできません",
	"密码不能为空":"パスワードを空白にすることはできません",
	"转入数量不能为空":"転送された数量を空にすることはできません",
	"密码修改成功":"パスワードのリセットが完了しました",
	"暂时还没有添加币种":"通貨はまだ追加されていません",
	"此币种不存在":"この通貨は存在しません",
	"已经有此提币地址":"すでにこの引き出しアドレスがあります",
	"添加提币地址成功":"引き出しアドレスの追加に成功しました",
	"此提币地址不存在":"この引き出しアドレスは存在しません",
	"您没有权限删除此地址":"このアドレスを削除する権限がありません",
	"删除提币地址成功":"引き出しアドレスを正常に削除します",
	"输入的金额不能为负数":"入力する金額を負の数にすることはできません",
	"您有正在进行中的杆杠交易,不能进行此操作":"進行中のレバレッジトランザクションがあり、この操作を実行できません",
	"钱包不存在":"ウォレットが存在しません",
	"划转成功":"転送の成功",
	"操作失败:":"操作に失敗しました",
	"操作失败":"操作に失敗しました",
	"操作失败:Insufficient wallet balance":"パラメータエラー:ウォレットのバランスが不十分",
	"Insufficient wallet balance":"ウォレットのバランスが不十分",
	"下单数量必须是10的整数倍":"注文数量は10の整数倍でなければなりません",
	"Parameter error":"參數錯誤",
	"提币申请已成功，等待审核":"引き出し申請は成功し、審査を待っています",
	"两次密码不一致":"2つのパスワードに一貫性がありません",
	"数量不能小于等于0":"数量は0以下にすることはできません",
	"价格不能小于等于0":"価格は0以下にすることはできません",
	"兑换数量必须大于0":"交換数量は0より大きくなければなりません",
	"钱包不存在":"ウォレットが存在しません",
	"转账至交易所钱包":"交換ウォレットに転送",
	"支付密码错误":"間違った支払いパスワード",
	"钱包已添加,请勿重复添加":"ウォレットが追加されました。繰り返し追加しないでください。",
	"您还没有钱包":"あなたはまだ財布を持っていません",
	"接收来自交易所的转账":"取引所からの送金を受け取る",
	"地址输入有误":"アドレスが間違って入力されている",
	"请输入正确的值":"正しい値を入力してください",
	"地址输入有误1":"アドレスが間違って入力されている1",
	"不能转账给自己":"自分に送金できません",
	"支付密码错误":"支払い",
	"扣除卖方!":"売り手を差し引く!",
	"买方增加!":"バイヤーの増加!",
	"扣除卖方":"売り手を差し引く!",
	"买方增加":"バイヤーの増加!",
	"申请提币冻结余额":"残高を凍結するために引き出しを申請する!",
	"申请提币冻结余额!":"残高を凍結するために引き出しを申請する!",
	"币币 划转 币币":"通貨通貨振替通貨通貨",
	"币币 划转 合约":"通貨振替契約",
	"币币 划转 秒合约":"通貨振替2回目の契約",
	"币币 划转 法币":"通貨通貨転送法定通貨",
	"法币 划转 法币":"フィアット通貨送金",
	"法币 划转 币币":"フィアット通貨転送通貨通貨",
	"法币 划转 合约":"法定通貨譲渡契約",
	"法币 划转 秒合约":"フィアット通貨送金第2契約",
	"秒合约 划转 秒合约":"2番目の契約の転送2番目の契約",
	"秒合约 划转 币币":"2番目の契約転送通貨",
	"秒合约 划转 法币":"2番目の契約転送法定通貨",
	"秒合约 划转 合约":"2回目の契約譲渡契約",
	"合约 划转 合约":"契約譲渡契約",
	"合约 划转 币币":"契約転送通貨",
	"合约 划转 秒合约":"契約譲渡第2契約",
	"合约 划转 法币":"契約転送法定通貨",
	"提交卖出记录(增加冻结)":"販売記録を提出する（凍結を増やす）",
	"提交卖出记录扣除":"控除のための販売記録の提出",
	"平仓资金处理":"清算資金処理",
	"请先勾选同意用户协议":"ユーザー同意書に同意することを確認してください",
	"您有未平仓的交易,操作禁止":"あなたはオープントレードを持っています、操作は禁止されています",
	"操作失败:您有未平仓的交易,操作禁止":"操作が失敗しました：あなたはオープントレードを持っています、操作は禁止されています",
	"购买失败，购买金额必须大于":"購入に失敗しました。購入金額は以下でなければなりません",
	"并且小于":"そして未満",
	"用户购买保险":"ユーザー購入保険",
	"您的验证码是：":"確認コードは次のとおりです：",
	"发送失败:":"送信に失敗しました：",
	"提交":"参加する",
	"杠杆交易,价格":"レバレッジ取引、価格",
	",扣除保证金":"、マージンを差し引く",
	"杠杆交易,扣除手续费":"レバレッジ取引、手数料の控除",
	"修改邮箱":"メールを変更する",
	"验证码":"検証コード",
	"秒":"2番目",
	"您输入的邮箱或手机号不符合规则":"入力したメールアドレスまたは電話番号がルールを満たしていません",
	"卖方增加法币":"売り手は法定通貨を増やします",
	"后台审核充值通过":"舞台裏のレビューと再充電に合格",
	"申请提币扣除余额":"残高を差し引くために引き出しを申請する",
	"秒合约订单平仓,盈利结算":"2回目の契約締結、利益決済",
	"秒合约订单,亏损结算":"2回目の契約注文、損失決済",
	"秒合约下单扣除":"2回目の契約注文控除",
	"下单扣除":"注文控除",
	"%手续费":"％ 手数料",
	"秒合约下单扣除本金":"2番目の契約注文は元本を差し引きます",
	"停止售出":"やめる ",
	"买方扣除币币余额":"買い手は通貨残高を差し引きます",
	"买方扣除法币余额":"買い手は法定通貨の残高を差し引きます",
	"买方扣除合约余额":"買い手は契約残高を差し引く",
	"买方扣除秒合约余额":"買い手は2番目の契約残高を差し引きます",
	"用户中心":"ユーザーセンター",
	"注册成功,钱包状态：1":"登録は成功しました。ウォレットのステータス：1",
	"注册成功,钱包状态：2":"登録は成功しました。ウォレットのステータス：2",
	"注册成功,钱包状态：0":"登録は成功しました。ウォレットのステータス：0",
	"调节":"調整する",
	"账户余额:":"勘定残高 ",
	"账户锁定余额:":"アカウントロック残高 ",
	"秒合约":"2番目の契約",
	"法币":"法定通貨",
	"杠杆":"レバー",
	"币币":"コイン",
	"请实名认证":"実名認証をお願いします",
	"您的实名认证还未通过！":"実名認証に合格していません！",
	"暂不支持该币种":"この通貨は現在サポートされていません",
	"充值":"充電する",
	"余额不足":"残高不足です",
	"操作成功":"正常な操作",
	"取消成功":"成功をキャンセル",
	"提交成功":"正常に送信されました",
	"最大下单数量不能超过:":"注文の最大数は以下を超えることはできません:",
	"投资数额 的最小长度为":"投資額の最小の長さは",
	"位":"ビット",
	"杠杆买入委托撤单,退回手续费":"レバレッジ購入注文のキャンセル、手数料の返金",
	"杠杆买入委托撤单,退回保证金":"レバレッジ購入注文のキャンセルと証拠金の返金",
	"秒合约买入委托撤单,退回手续费":"2回目の契約購入注文のキャンセル、手数料の返金",
	"秒合约买入委托撤单,退回保证金":"回目の契約購入注文のキャンセル、証拠金の返還",
	"法币买入委托撤单,退回手续费":"法定通貨の買い注文のキャンセル、手数料の返金",
	"法币买入委托撤单,退回保证金":"フィアット発注書のキャンセルと証拠金の返金",
	"币币买入委托撤单,退回手续费":"トークン発注書のキャンセル、手数料の返金",
	"币币买入委托撤单,退回保证金":"トークンの発注書のキャンセルとデポジットの返金",
	"订单平仓结算,平局结算":"注文決済決済、ネクタイ決済",
	"订单平仓,盈利结算":"受注処理、利益決済",
	"Account already exists":"アカウントはすでに存在しています",
	"投资数额 字段必须填写":"投資額フィールドに入力する必要があります",
	"提币成功":"正常に撤回する",
	"Operation successful":"操作が成功しました",
	"加载中...":"読み込んでいます...",
	"加载更多":"もっと読み込む",
	"没有更多了...":"もういや...",
	"确定平仓？":"ポジションを閉じてもよろしいですか？",
	"发行量":"サーキュレーション",
	"请登录":"サインインしてください",
	"开始申购":"購入を開始",
	"申购币种":"購入申請通貨種類",
	"开始申购时间":"購入開始時間",
	"预计上线时间":"発行予定時間",
	"结束申购时间":"購入申請終了時間",
	"项目预热":"プロジェクト準備",
	"公布结果":"結果を発表する",
	"USDT余额不足":"不十分なUSDTバランス",
	"BTC余额不足":"不十分なBTCバランス",
	"ETH余额不足":"不十分なETHバランス",
	"申购成功":"サブスクリプションの成功",
	"兑换成功":"償還の成功",
	"全部":"すべて",
	"立即申购":"今すぐ購読",
	"结束申购":"サブスクリプションの終了",
	"申购周期":"サブスクリプションサイクル",
	"项目详情":"プロジェクトの詳細",
	"请输入申购数量":"購入数量を入力してください",
	"申购确认":"購入確認",
	"确定":"決定する",
	"取消":"キャンセル",
	"您计划申购":"購読する予定です",
	"BEF钱包未找到":"BEFウォレットが見つかりません",
	"USDT钱包未找到":"USDTウォレットが見つかりません",
	"BTC钱包未找到":"BTCウォレットが見つかりません",
	"ETH钱包未找到":"ETHウォレットが見つかりません",
	"USDT 兑换 BEF ":"USDTからBEF ",
	"BTC 兑换 BEF ":"BTCからBEFへ ",
	"ETH 兑换 BEF ":"ETHをBEFに交換する ",
	"申购历史":"購入履歴",
	"认购价格":"サブスクリプション価格",
	"认购数量":"サブスクリプション数量",
	"认购时间":"サブスクリプション時間",
	"结束时间":"終了時間",
	"状态":"状態",
	"解锁":"ロックを解除する",
	"已完成":"完了",
	"已锁仓":"ロック済み",
	"暂无记录":"記録なし",
	"操作":"オペレーティング",
	"价值":"値",
	"认购币种":"サブスクリプション通貨",
	"申购记录":"購入記録",
	"可用余额不足":"利用可能な残高が不足しています",
	"申购数量异常":"サブスクリプション数の異常",
	"认购异常":"サブスクリプションの例外",
	"认购成功":"正常にサブスクライブしました",
	"下单数量必须是100的整数倍":"注文数量は100の整数倍でなければなりません",
	"认购解锁到账":"サブスクリプションのロックが解除されました",
	"充值自动到账":"チャージは自動的にアカウントに入金されます",
	"发送失败。未包含正确的手机号":"送信に失敗しました。 正しい電話番号が含まれていません",
	"发送失败。余额不足":"送信に失敗しました。 残高不足です",
	"认购付款":"サブスクリプションの支払い",
	"当前价值":"現在の価値",
	"解锁时间":"時間のロックを解除する",
	"天":"일",
	"倒计时":"秒読み",
	"预计收益":"推定収入",
	"资产记录":"資産記録",
	"知道了":"とった",
	"冻结剩余时间":"残り時間を凍結する",
	"小时":"時間",
	"aaaaaaaaaaaa":"1111111"
}
}