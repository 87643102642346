<template>
 
	<div class="c2c-l">
		<div class="leftNav ft14">
			<ul class="fColor1">
			 <li class="active" @click="goto(0,'c2cOrder')">
			<span>C2C</span>
			</li>
			<li class="" @click="goto(0,'c2cRelease')">
			<span >{{$t('c2c.mypc2c')}}</span>
			</li>
			<li class="" @click="goto(0,'c2cTrade')">
				<span>{{$t('c2c.myTc2c')}}</span>
				</li>
			 </ul>
			 </div>
		 </div>
</template>
<script>
	export default {
 
		data() {
			return {
				curActive:0, 
				}
		},
		created(){
			let name = this.$route.name;
			this.curActive = this.array.findIndex( val => val.page === name || (val.children && val.children.includes(name))) ;

		},
		mounted(){
			 
		},
		methods: {
			goto(index,name) {
				this.curActive=index;
				this.$router.push({name: name, params: {lang: this.lang}})
			},

		}, 
	}
</script>
<style lang="scss" scoped>
	.leftNav{
    .mr10 {
      margin-right: 10px;
    }

    .flex {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
		ul{
			height:820px;
			li{
				height:54px;
				line-height:54px;
				padding-left:38px;
				margin-bottom:4px;
				cursor:pointer;
				img{
					margin-right:12px;
					margin-top:-4px;
					width:20px;
					vertical-align: middle;
				}
			}
			.active{
				color:#ffc90c;
				background-color: #181d25;
				border-bottom: none;
				display: block;
			}
		}
	}
</style>


 