<template>
  <div class="cbai">
    <indexHeader></indexHeader>
    <div class="sgbox wapp">
		<div class="info">
			<div class="bigtitle">{{ $t("apitip.申购记录") }}</div>
		</div>
	</div>
	<div class="wapp">
		<div class="lsbox">
			<div class="nodata" v-if="list.length<1">{{$t("apitip.暂无记录")}}</div>
			<div class="" v-else>
				<table class="newtable">
					<thead>
						<tr>
							<th>{{$t("apitip.认购币种")}}</th>
							<th>{{$t("apitip.认购时间")}}</th>
							<th>{{$t("apitip.认购价格")}}</th>
							<th>{{$t("apitip.认购数量")}}</th>
							<th>{{$t("apitip.结束时间")}}</th>
							<th>{{$t("apitip.操作")}}</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(item,index) in list">
							<td>{{item.currency_name}}</td>
							<td>{{item.created_at}}</td>
							<td>{{item.price}} USDT</td>
							<td>{{item.amount}} {{item.currency_name}}</td>
							<td>{{item.endtime_at}}</td>
							<td>
								<div class="btn btn-ok" v-if="item.status==3">{{$t("apitip.已完成")}}</div>
								<div class="btn btn-info" v-if="item.status==1">{{$t("apitip.已锁仓")}}</div>
								<div class="btn btn-err" v-if="item.status==2" @click="jiesuo(item,index)">{{$t("apitip.解锁")}}</div>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
	
    <indexFooter></indexFooter>
	
  </div>

</template>

<script>
import indexHeader from "@/view/indexHeader";
import indexFooter from "@/view/indexFooter";

import mixin from "@/mixin";
export default {
	mixins: [mixin],
  name: "dealCenter",
  components: {
    indexHeader,indexFooter,
    load: 1,
  },
  data() {
    return {
      uid:null,
	  biInfo:{
		  line_time:"",
		  start_time2:"",
		  end_time2:"",
		  c_name:"",
		  step:1
	  },
	  user:{
		  USDT:0,
		  BTC:0,
		  ETH:0
	  },
	  huilv:0,
	  sel:{
		  type:"USDT",
		  num:0,
		  value:0
	  },
	  dialogVisible:false,
	  jihua:{
		  type:"",
		  num:0,
		  huilv:0
	  },
	  pages:{
		limit:500,
		page:1  
	  },
	  list:[]
    }
  },
  created() {
	  clearInterval(this.intervalId8888)
    this.init();
  },
  mounted() {
	
  },
  computed:{
	  abc:function(){
		  //this.seltype();
		  if(!this.sel.num){
			  this.sel.num=0;
		  }
		  this.sel.num=this.sel.num*1;
	  }
  },
  methods: {
	  jiesuo:function(data,index){
	  	const loading = this.$loading()
	  	let that = this;
	  	this.$http({
	  	    method: 'post',
	  	    url: '/api/user/unlock',
	  		data:{
	  			id:data.id,
	  		},
	  	    headers: {
	  	        Authorization: localStorage.getItem("token")
	  	    }
	  	}).then(res => {
	  		loading.close();
	  	    if(res.data.type=="ok"){
	  			this.list[index].status=3;
	  			this.$toast.success(this.$t("diy.解锁成功"));
	  		}else{
	  			this.$toast.error(this.$t("diy.解锁失败"));
	  		}
	  	})
	  },
	  init:function(){
		 let uid=localStorage.getItem("user_id");
		 if(!uid){
			 this.$message.error(this.$t("apitip.请登录"));
			 //this.$router.push("/components/login");
			 window.location.href="/#/components/login"
		 }else{
			 this.gethl();
			 this.intvalHl();
			 this.getinfo();
		 }
		 /*const loading = this.$loading({
		   lock: true,
		   text: 'Loading',
		   spinner: 'el-icon-loading',
		   background: 'rgba(0, 0, 0, 0.7)'
		 });*/
		 this.getlist();
	  },
	  gobuy:function(){
		  if(!this.sel.num){
			  this.$message.error(this.$t("apitip.请输入申购数量"));
			  return;
		  }
		  let arg={
			  type:this.sel.type,
			  num:this.sel.num,
			  huilv:(this.sel.num/this.huilv).toFixed(6)
		  }
		  if(arg.num>this.user[arg.type]){
			  this.$message.error(this.$t("apitip."+arg.type+"余额不足"));
			  return;
		  }
		  this.jihua=arg;
		  this.dialogVisible=true;
		  //console.log(JSON.stringify(arg))  
	  },
	  togobuy:function(){
		  this.dialogVisible=false;
		  const loading = this.$loading({
		    lock: true,
		    text: 'Loading',
		    spinner: 'el-icon-loading',
		    background: 'rgba(0, 0, 0, 0.7)'
		  });
		  let arg={"amount":this.jihua.num,"buymoney":this.huilv}
		 
		  this.$http({
		    url: "/api/" + "user/up_order",
		    data: arg,
		    method: "post",
		  	headers: {'Authorization': localStorage.getItem('token')},
		  }).then(res => {
		  	let r=res.data;
			if(r.type=="ok"){
				this.$message.success(this.$t("apitip.申购成功"))
				loading.close();
				//this.$router.push("/change");
				this.$router.push("/uplist");
				//this.getinfo();
				//this.getlist()
			}else{
				this.$message.error(this.$t("apitip."+r.message))
				loading.close();
			}
		  });
	  },
	  getinfo:function(){
		  //const loading=this.$loading()
		  this.$http({
		    url: "/api/" + "user/upstart",
		    data: {},
		    method: "get",
			headers: {'Authorization': localStorage.getItem('token')},
		  }).then(res => {
			  
			  //console.log(JSON.stringify(data.message))
				this.biInfo=res.data.message;
				this.user.USDT=res.data.message.detail.balance;
				//console.log(res.data.detail.balance)
				//this.user=data.user;
		  });
	  },
	  seltype:function(e){
		 this.sel.num=0
	  },
	  gethl:function(){
		  this.$http({
		    url: "/api/" + "bi/price",
		    data: {},
		    method: "post",
		  	headers: {'Authorization': localStorage.getItem('token')},
		  }).then(res => {
		  		let data=res.data.message;
				this.huilv=data.price;
				
		  });
	  },
	  intvalHl:function(){
		   clearInterval(this.intervalId8888)
		   this.intervalId8888 = setInterval(() => {
				//this.gethl();
		   }, 1000)
	  },
	  selall(){
	  		  this.sel.num=this.user[this.sel.type]
	  },
	  num2fixed:function(num){
		  let newnum=0;
		  if(!num){
			  newnum=0;
		  }
		  newnum=num.toFixed(6)
		  return newnum;
	  },
	  getlist:function(){
		  this.$http({
		    url: "/api/" + "user/uplists",
		    data: this.pages,
		    method: "post",
		  	headers: {'Authorization': localStorage.getItem('token')}
		  }).then(res => {
		  			  this.list=res.data.message.data;
					  /*this.pages={
						  limit:res.data.message.limit,
						  page:res.data.message.page+1
					  }*/
		  });
	  }
	  
  }
};
</script>

<style scoped lang="scss">
	.nodata{text-align: center;line-height: 80px;}
	.lsbox{min-height: 540px;}
	.newtable{width: 100%;border-collapse:collapse;}
	.newtable th,.newtable tr,.newtable td{border-collapse:collapse;}
	.newtable tr{border-bottom: 1px solid #333333;}
	.newtable th,.newtable td{padding: 12px 10px;}
	.btn{cursor: default;color: #fff;border-radius: 3px;background: #666;text-align: center;line-height: 28px;height: 28px;}
	.btn.btn-ok{background:#4caf50;}
	.btn.btn-err{background:#f44336;cursor: pointer;}
	
	.poptbox{text-align: center;line-height: 34px;}
	.poptbox .t{}
	.poptbox .c{font-size: 20px;}
	.wapp{width: 1200px;margin: auto;font-size: 14px;}
	.cbai{color:#fff;}
	.guochengbox{width:820px; display: flex;flex-direction: row;margin: auto;}
	.guochengbox .agc{flex: 1;display: block;text-align: center;position: relative;}
	.guochengbox .agc .ico{display: block;width: 48px;height: 48px;margin: auto;position: relative;line-height: 48px;margin-bottom: 30px;}
	.guochengbox .agc .ico>i{display: none;position: absolute;left:0;top:0;bottom:0;right:0;background: url(../assets/images/sel_cur.png) center center no-repeat;border-radius: 50%;}
	.guochengbox .agc .ico>span{display: block;position: absolute;left:0;top:0;right:0;bottom: 0; background: #516285;color: #fff;border-radius: 50%;font-size: 22px;}
	.guochengbox .agc.cur .ico>i{display: block;}
	.guochengbox .agc.cur .ico>span{display: none;}
	.guochengbox .agc em{position: absolute;content: "";width:120px;height: 2px;background: #516285;top:23px;left:50%;margin-left: 44px;}
	.guochengbox .agc.cur em{background: #3573ff;}
	.guochengbox .agc:last-child em{display: none;}
	.guochengbox .agc .txt1{font-size: 18px;height: 36px;}
	.guochengbox .agc .time{color: rgba(132,149,183,1);}
	.ajia:first-child{margin-right: 40px;}
	.ajia {line-height: 20px;white-space: nowrap;display: block;}
	.ajia .tit{color:rgba(132,149,183,1);white-space: nowrap;display: block;height: 27px;}
	.ajia .text{color:#b4c1da;white-space: nowrap;display: block;margin-bottom: 20px;}
	.sgbox{display: flex;flex-direction: row;padding: 60px 0 20px 0;color: #fff;}
	.bigtitle{color:#ff;font-size: 18px; padding-bottom: 30px;}
	.info{flex:1;}
	.flex_row{display: flex;flex-direction: row;}
	.nowbii{line-height: 24px;}
	select{
		border: none;
		    background: none;
		    font-size: 16px;
		    font-family: PingFangSC-Regular,PingFang SC;
		    font-weight: 400;
		    color: rgba(81,98,133,1);
		    line-height: 22px;
		    padding: 5px 20px;
		    border-bottom: 1px solid #516285;
	}
	.ipt{margin: 10px 0;
		padding: 6px 0;
		border: none;
		border-bottom: 1px solid #516285;
		background: none;
		color: #fff;
	}
	
	.btnnn{
		height: 36px;
		    background: linear-gradient(90deg,rgba(54,124,239,1) 0%,rgba(47,175,240,1) 100%);
		    border-radius: 2px;
		    border: none;
		    font-size: 14px;
		    font-family: PingFangSC-Regular,PingFang SC;
		    font-weight: 400;
		    color: rgba(255,255,255,1);
		    line-height: 20px;
		    padding: 0 15px;cursor: pointer;
	}
	.btnnn.mr888{margin-right: 80px;}
</style>
