<template>
  <div id="app" :class="(night_mode==null||parseInt(night_mode)==0)?'night':'day'">
    <router-view/>
  </div>
</template>

<style lang="scss">
//@import "//fonts.googleapis.com/css?family=Roboto:300,400,500,700,400italic";
//@import "https://cdn.bootcss.com/material-design-icons/3.0.1/iconfont/material-icons.css";
//@import "//at.alicdn.com/t/font_2302506_dnypp4o56e5.css";

.flex {
  display: flex;
  flex-direction: row;
}

.column {
  flex-direction: column;
}

.flex-all {
  flex: 1;
}
</style>

<script>

export default {
  name: "App",
  data() {
    return {
      'night_mode': '1'
    }
  },
  created() {
    this.night_mode = localStorage.getItem('night_mode');
    this.$http({
      url: "/api/" + "currency/quotation_new",
      method: "get",
      data: {},
      headers: {Authorization: localStorage.getItem("token")}
    })
        .then(res => {
          if (res.data.type == "ok") {
            this.tabList = res.data.message;
            var msg = res.data.message;
            var arr_quota = [];
            for (var i = 0; i < msg.length; i++) {
              arr_quota[i] = msg[i].quotation;
            }
            if (!window.localStorage.getItem("downUp")) {
              window.localStorage.setItem("downUp", arr_quota[0][0].change);
            }
            if (!window.localStorage.getItem("legal_id_cur")) {
              window.localStorage.setItem(
                  "legal_id_cur",
                  arr_quota[0][0].currency_id
              );
            }
            if (
                !localStorage.getItem("legal_id") &&
                !localStorage.getItem("currency_id") &&
                !localStorage.getItem("legal_name") &&
                !localStorage.getItem("currency_name")
            ) {
              window.localStorage.setItem(
                  "legal_id",
                  msg[0].quotation[0].legal_id
              );
              window.localStorage.setItem(
                  "currency_id",
                  msg[0].quotation[0].currency_id
              );
              window.localStorage.setItem(
                  "legal_name",
                  msg[0].quotation[0].legal_name
              );
              window.localStorage.setItem(
                  "currency_name",
                  msg[0].quotation[0].currency_name
              );
              var symbol =
                  msg[0].quotation[0].currency_name +
                  "/" +
                  msg[0].quotation[0].legal_name;
              window.localStorage.setItem(
                  "shareNum",
                  msg[0].quotation[0].lever_share_num
              );
              // var priceScale=Math.pow(10,5);
              var priceScale = 100000;
              window.localStorage.setItem("priceScale", priceScale);
              window.localStorage.setItem("symbol", symbol);
            }
          }
        })
        .catch(error => {
          console.log(error);
        });
  }
};
</script>

<style lang='scss'>
#app {
  .el-slider__runway {
    background-color: #2e3244;
  }
}
</style>

