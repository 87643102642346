export default {
	"siteName": "NEWBIT",
	"scan": "扫一扫",
	"inMobile":"请您在移动端反馈",
	"exchangeRate": "汇率",
	"none": "",
	"code": "发送验证码",
	"send": "发送验证码",
	"pwd": "密码",
	"accounts": "账号",
	"registers": "注册",
	"welcome": "Hi, مرحبا بكم في  NEWBIT",
	"resend": "{number}秒后重新发送",
	"xieyi": "我已阅读并同意",
	"xieyi2": "《用户协议》",
	"transferFromChange":"从币币账户转入",
	"transferChange":"转出到币币账户",
	"transferOk":"划转完成",
	"xieyi1": {
	"first": "国籍信息注册后不能修改",
		"second": "验证邮件可能被判定为垃圾邮件，请注意查收",
		"third": "请妥善保管您的账号信息和密码",
		"fourth": "请勿使用和其他网站相同的登录密码",
		"fifth": ""
},
	"confirm": "确认",
	"regiterStep1": "立即注册",
	"regiterStep2": "保存资料",
	"logins": "登录",
	"notice": "公告",
	"more": "加载更多",
	"nomore": "暂无更多",
	"time": "时间",
	"price": "价格",
	"number": "数量",
	"info": "基本信息",
	"link": "相关链接",
	"cfprice": "众筹价格",
	"totalc": "流通总量",
	"bname": "币种名称",
	"query": "区块查询",
	"dealed": "已成交",
	"notdeal": "未成交",
	"handicap": "盘口",
	"rtt": "实时成交",
	"timescreen": "时间筛选",
	"dealscreen": "交易对筛选",
	"dirscreen": "方向筛选",
	"to": "至",
	"startdate": "开始日期",
	"overdate": "结束日期",
	"do": "操作",
	"revoke": "撤销",
	"nodata": "暂无数据",
	"loading": "加载中",
	"status": "状态",
	"rate": "手续费",
	"back": "返回",
	"alipay": "支付宝",
	"wechat": "微信",
	"bankcard": "银行卡",
	"inpCur": "搜索",
	"minAm": "最小数量",
	"regidters": "欢迎注册",
	"alltread": "全站交易",
	"treadnum": "交易数量",
	"treaddetail": "成交明细",
	"tnum": "成交数量",
	"lastweek": "最近一周",
	"reload": "刷新",
	"dealtime": "成交时间",
	"way": "委托方式",
	"torg": "交易所",
	"dealno": "委托编号",
	"ttotal": "成交金额",
	"auto": "自助委托",
	"sum": "总计",
	"traedoff": "该交易对已下架，请重新切换交易对",
	"piece": "张",
	"hand": "手",
	"pbuynum": "请输入购买张数",
	"bestsell": "以市场最优价格卖出",
	"lowbuy": "以市场最低价买入",
	"transin": "转入",
	"transout": "转出",
	"tansinmoney": "转入本金",
	"tansoutmoney": "转出本金",
	"from": "从",
	"jingdu": "精度",
	"m": "分钟",
	"day": "天",
	"week": "周",
	"year": "年",
	"mon": "月",
	"hour": "时",
	"header": {
	"num": "开仓数量",
		"mode": "交易模式",
		"quotation": "行情",
		"home": "首页",
		"c2c": "法币交易",
		"match": "合约大赛",
		"currency": "币币交易",
		"assets": "我的资产",
		"setting": "安全设置",
		"help": "帮助中心",
		"bbs": "论坛",
		"complaint": "投诉留言",
		"in": "登录",
		"up": "注册",
		"current": "当前委托",
		"his": "历史委托",
		"code": "邀请码",
		"shop": "我的商铺",
		"out": "退出登录",
		"orders": "订单",
		"center": "个人中心",
		"pro": "项目公示",
		"cun": "存币宝",
		"vote": "投票上币",
		"sgr": "SGR返利",
		"inset": "收款设置",
		"tradelog": "交易日志",
		"fastrecharge": "快捷充币"
},
	"footer": {
	"openNum": "请输入开仓数量",
		"finshPrice": "成交价",
		"times": "倒计时",
		"loss": "预计盈亏",
		"buyPrice": "购买价",
		"num": "数量",
		"symbol": "交易对",
		"trade": "交易中",
		"up": "买涨",
		"down": "买跌",
		"balance": "余额",
		"rate": "盈利率",
		"hxex": "NEWBIT 介绍",
		"legalTrade": "法币交易",
		"tool": "链接",
		"about": "关于",
		"join": "加入我们",
		"connect": "联系我们",
		"download": "客户端下载",
		"explain": "条款说明",
		"xieyi": "用户协议",
		"yinsi": "隐私条款",
		"legal": "法律声明",
		"feilv": "交易费率",
		"notice": "公告中心",
		"nhelp": "新手帮助",
		"support": "用户支持",
		"workorder": "提交工单",
		"apply": "上币申请",
		"gonggao": "网站公告",
		"community": "社群",
		"wechat": "微信",
		"weibo": "微博",
		"email": "商务邮箱",
		"email_pre": "客服：",
		"email_info": "官方邮箱：Facebookdiem0@gmail.com",
		"feedback": "问题反馈",
		"address": "新加坡© 2013-2022 NEWBIT",
		"about2": "公司简介",
		"service": "服务",
		"coin": "数字资产介绍",
		"apply_url": "https://qwe.shushuiji.net/dist/#/components/noticeDetail?id=195",
		"feedback_url": "https://qwe.shushuiji.net/dist/#/components/noticeDetail?id=200",
		"about2_url": "https://qwe.shushuiji.net/dist/#/components/noticeDetail?id=206",
		"coin_url": "https://qwe.shushuiji.net/dist/#/components/noticeDetail?id=211",
		"yinsi_url": "https://qwe.shushuiji.net/dist/#/components/noticeDetail?id=216",
		"feilv_url": "https://qwe.shushuiji.net/dist/#/components/noticeDetail?id=221",
		"legal_url": "https://qwe.shushuiji.net/dist/#/components/noticeDetail?id=226"
},
	"home": {
	"with": "对",
		"myMarkets": "自选",
		"markets": "交易区",
		"pair": "币种对",
		"market": "",
		"price": "价格",
		"change": "涨跌",
		"high": "最高价",
		"min": "最低价",
		"volume": "交易量",
		"trade": "操作",
		"toTrade": "去交易",
		"c1": "全球化的数字资产配置及交易服务",
		"c2": "遍布全球的项目拓展及运营管理体系",
		"c3": "在多个国家设有本地交易服务中心",
		"c4": "服务超过130个国家的数百万用户",
		"c5": "SMART-Chain资产评估模型",
		"c6": "独立专业的区块链资产研究评估体系",
		"c7": "长期跟踪产业链并提供最权威中立的资产分析",
		"c8": "一站式的项目进度跟踪及信息披露系统",
		"c9": "依托4年的数字资产安全风控经验",
		"c10": "建立先行赔付机制",
		"c11": "设立投资者保护基金",
		"c12": "随时随地 多平台终端交易",
		"c13": "覆盖iOS、Android、Windows多个平台，支持全业务功能",
		"c14": "香港  首尔  新加坡  东京",
		"c15": "遍布世界各地的服务中心",
		"c16": "马上交易",
		"c17": "欢迎加入我们的团队，共同提升，超越更好的自己，创造更好的人生价值",
		"access": "多平台终端接入",
		"cover": "覆盖IOS、Android、Windows多个平台，支持全业务功能",
		"cooper": "合作机构",
		"vol": "24H量",
		"c01": "全球领先的数字资产交易平台",
		"c02": "为全球超过130个国家的数百万用户提供安全、可信赖的数字资产交易及资产管理服务",
		"c03": "安全可信赖",
		"c04": "5 年数字资产金融服务经验",
		"c05": "专业分布式架构和防 DDOS 攻击系统",
		"c06": "全球生态布局",
		"c07": "多个国家设立本土化交易服务中心",
		"c08": "打造多业务形态为一体的区块链生态圈",
		"c09": "用户至上",
		"sweep": "扫码下载",
		"android": "Android",
		"pinput": "请输入你的邮箱",
		"atrade": "注册一个 NEWBIT GROUP全球站账号 开始交易旅程",
		"Gcoin": "NEWBIT",
		"eamil1": "商务合作邮箱",
		"eamil2": "客服邮箱",
		"kefu": "在线客服",
		"img1": "1_zh.png",
		"img2": "2_zh.png",
		"img3": "3_zh.png",
		"d1": "NEWBIT 全球站，近24小时成交量<b>{number}</b>美元",
		"d2": "为全球超过130个国家的数百万用户提供安全、可信赖的数字资产交易及资产管理服务",
		"d3": "交易量全球前3",
		"d4": "强大的流动性 交易种类齐全",
		"d5": "6年老牌交易所",
		"d6": "6年数字资产金融服务经验",
		"d7": "专业分布式架构和防DDOS攻击系统",
		"d8": "千万级用户规模",
		"d9": "建立先行赔付机制 设立投资者保护基金",
		"dsp1": "金融级安全",
		"dsp10": "全方位金融风控系统和防盗系统，冷热钱包、多签系统保证资金安全",
		"dsp2": "极速充提",
		"dsp20": "充值提现最快3分钟完成，24H人工在线审核，保护客户不错过最佳投资机会",
		"dsp3": "全球服务",
		"dsp30": "全球业务服务网络覆盖，助您投资全球加密资产，与全球用户交易",
		"dsp4": "严选资产",
		"dsp40": "严格选择优质加密项目，为您过滤80%极高风险项目",
		"dsp5": "随时随地开启安全交易",
		"dsp50": "覆盖 iOS 、Android 、Windows 多个平台，支持全业务功能"
},
	"login": {
	"email": "邮箱登录",
		"phone": "手机登录",
		"welcome": "欢迎登录 NEWBIT",
		"account": "账号",
		"forget": "忘记密码",
		"dont": "还未注册？",
		"invite": "立即注册，在全球领先的数字资产交易平台开始交易",
		"autologin": "自动登录"
},
	"register": {
	"phone": "手机号注册",
		"email": "تسجيل البريد",
		"phonenum": "请输入手机号",
		"emailnum": "请输入邮箱",
		"codenum": "请输入验证码",
		"notice": "请先查看用户协议，并勾选选项框，才可点击发送验证码",
		"sendcode": "发送验证码",
		"logpwd": "请输入登录密码",
		"repwd": "请确认密码",
		"invitecode": "请输入邀请码",
		"xuan": "选填",
		"agree": "我已阅读并同意",
		"pwd": "密码在6-16位之间,由数字和字母组成",
		"mian": "《用户协议》",
		"gologin": "已有账号，前往登录"
},
	"forget": {
	"fpwd": "忘记密码",
		"getcode": "获取验证码",
		"setpwd": "设置密码",
		"inpwd": "请输入密码",
		"repwd": "请再次输入密码"
},
	"lpwd": {
	"pwd": "请输入密码",
		"setpwd": "设置资金密码",
		"resetpwd": "重置资金密码",
		"oldpwd": "请输入原密码",
		"newpwd": "请输入新密码",
		"repwd": "请再次输入密码",
		"spwd": "设置密码",
		"rpwd": "重置密码"
},
	"bdemail": {
	"bindemail": "绑定邮箱",
		"emailbox": "邮箱",
		"surebind": "确认绑定"
},
	"bdphone": {
	"bindphone": "绑定手机号",
		"phone": "手机号"
},
	"lgpwd": {
	"setpwd": "修改密码",
		"renewpwd": "请再次输入新密码"
},
	"security": {
	"lever": "您的账号安全等级 : ",
		"wan": "完善更多资料，保证账号安全",
		"invite": "我的邀请码",
		"copy": "复制邀请链接",
		"phone": "修改手机号",
		"email": "绑定邮箱",
		"logpwd": "登录密码",
		"zipwd": "资金密码",
		"idcard": "身份认证",
		"suggest": "建议您定期更改密码以保护账户安全。",
		"notbaind": "未绑定",
		"havebind": "已绑定",
		"gobind": "去绑定",
		"set": "修改",
		"haveau": "已认证",
		"reau": "已拒绝(请重新认证)",
		"goau": "去认证",
		"auing": "审核中",
		"goset": "去设置",
		"accountset": "账户设置",
		"setlogin": "设置登录验证方式",
		"setting": "设置",
		"setemail": "修改邮箱",
		"tbpw": "提币密码",
		"tip1": "请设置提币专用密码，建议提现密码区别于登录密码。",
		"google": "谷歌验证器",
		"bindGoogle": "绑定谷歌验证器",
		"tip2": "用于登录、提币、找回密码、修改安全设置时进行安全验证。",
		"beforename": "请先进行实名认证。",
		"authentication": "认证",
		"myaddress": "我的地址",
		"low": "低",
		"fbPhone": "请先绑定手机号",
		"fbEmail": "请先绑定邮箱",
		"googleKey": "谷歌密钥"
},
	"auth": {
	"auth": "身份认证",
		"back": "返回",
		"refause": "拒绝理由",
		"name": "姓名",
		"idnum": "证件号",
		"img": "请上传证件的正反面及手持证件的正面照。",
		"ising": "审核中...",
		"ised": "已认证!",
		"truename": "请输入真实姓名",
		"truenum": "请输入证件号",
		"up": "提交",
		"note": "请上传身份证正反面，第一张为正面，第二张为反面。"
},
	"auth2": {
	"tip": "请注意用户认证信息一经验证不能修改，请务必如实填写准确信息认真填些个人信息",
		"name": "认证姓名",
		"id": "个人身份认证",
		"number": "证件号",
		"type": "证件类型",
		"content": "请输入内容",
		"complete": "恭喜您已完成实名认证",
		"nextstep": "进行下一步身份认证",
		"positive": "证件照正面",
		"mb": "仅支持jpg、png、不超过4mb",
		"click": "点击上传证件照",
		"back": "证件照背面",
		"hand": "上传手持证件照",
		"tip2": "请提供一张您手持证件照的照片，在同张照片中也要包含有“NEWBIT”、日期和您的签名的注释。请确保您的脸部清晰可见，且所有证件详细信息都清楚可读",
		"face": "面部清晰可见无遮挡",
		"clearly": "照片清晰可见",
		"notes": "带有[NEWBIT]的文字注释",
		"complete2": "恭喜您已完成身份认证",
		"complete3": "进行下一步资产认证",
		"inaudit": "身份认证提交成功，审核中",
		"notpass": "身份认证审未通过",
		"again": "请重新进行身份认证",
		"asset": "资产认证审核中",
		"nasset": "资产认证未通过",
		"again2": "请重新进行资产认证",
		"complete4": "恭喜您，资产认证审核通过",
		"reminder": "温馨提示",
		"tip3": "当您的资产估值大于20BTC时，可以进行第三级实名认证，获得更多提币限额。",
		"tip4": " 请提供一张您本人的SAFEEX账号的资产明细截图或者照片，图片中包含您的账户名、uid、持仓详情、当前时间。所有信息清晰可读。",
		"screenshot": "上传资产截图信息",
		"nowLever": "当前认证等级",
		"authCard": "身份证",
		"hu": "护照",
		"driverCard": "驾照",
		"other": "其他",
		"one": "一级",
		"two": "二级",
		"three": "三级",
		"selectType": "请选择证件类型",
		"enterCardId": "请填写证件号",
		"noWord": "证件号不能含有汉字",
		"enterRealName": "请填写真实姓名",
		"noNumber": "姓名中不能含有数字",
		"noFour": "图片不能超过4M",
		"frontImg": "请上传证件照正面",
		"reverseImg": "请填上传证件照背面",
		"handImg": "请手持证件照",
		"assetImg": "请上传资产截图"
},
	"legal": {
		"buy": "购买",
		"sell": "出售",
		"shoper": "商家",
		"number": "数量",
		"limit": "限额",
		"price": "单价",
		"pay": "支付方式",
		"do": "操作",
		"record": "订单记录",
		"traderecord": "交易记录",
		"tredetype": "交易类型",
		"orderstatus": "订单状态",
		"nofinish": "未完成",
		"finished": "已完成",
		"ceiled": "已取消",
		"payed": "已付款",
		"paywait": "待付款",
		"waitplease": "请等待买家付款",
		"ordercomplete": "订单已完成",
		"orderceiled": "订单已取消",
		"orderpayed": "买家已付款，请核实后确认",
		"totalmoney": "交易总额",
		"time": "时间",
		"contact": "联系方式",
		"buyer": "买家",
		"seller": "卖家",
		"ordertime": "下单时间",
		"reference": "参考号",
		"ceilorder": "取消交易",
		"surepay": "确认已收款",
		"confirmceil": "确认取消交易",
		"ceil": "取消",
		"confirm": "确认",
		"paysure": "付款确认",
		"otherpay": "请确认买家已向您付款",
		"payplease": "请付款，请向以下账户付款",
		"payedwait": "已付款，等待商家确认收款",
		"tprice": "交易单价",
		"tnumber": "交易数量",
		"cardnum": "银行卡号",
		"wechat": "微信",
		"alipay": "支付宝",
		"account": "账户",
		"phone": "电话",
		"orderceil": "取消订单",
		"mypayed": "我已付款，点击确认",
		"notceil": "如您已向卖家付款请千万不要取消交易",
		"youpayed": "请确认您已向卖家付款",
		"freeze": "恶意点击将直接冻结账户",
		"transaction": "交易",
		"sellall": "全部卖出",
		"buyall": "全部买入",
		"conactceil": "请在15分钟内联系商家付款，超出15分钟将自动取消",
		"autoceil": "自动取消",
		"placeorder": "下单",
		"inwant": "请输入要",
		"total": "总额",
		"ceilnot": "如果买家已向您付款请千万不要取消交易",
		"shopaccount": "商家账户",
		"bankName": "开户行"
},
	"legaltrade": {
	"paymoney": "支付货币",
		"paytype": "支付方式",
		"select": "请选择",
		"back": "返回",
		"desc": "简介",
		"connect": "联系对方",
		"cankao": "参考号",
		"zhihang": "开户行支行",
		"enterzhihang": "请输入开户行支行",
		"money_code": "收款码",
		"confirm_tips": "请您尽快在15分钟内确认操作",
		"cancel_tips": "注：每日取消三次后，将限制进行法币交易",
		"send": "发送",
		"money_change": "资金变化",
		"depth": "深度图"
},
	"shop": {
	"myshop": "我的店铺",
		"name": "名称",
		"tolegal": "所属法币",
		"retime": "注册时间",
		"balance": "商家余额",
		"lockbalance": "商家冻结余额",
		"goshop": "进入店铺",
		"allorder": "总单数",
		"dayorder": "30日成单",
		"comorder": "完成单",
		"comrate": "完成率",
		"release": "发布",
		"email": "邮箱认证",
		"phone": "手机",
		"truename": "实名认证",
		"high": "高级认证",
		"type": "类型",
		"mysell": "我的出售",
		"mybuy": "我的求购",
		"abnormal": "异常",
		"seeorder": "查看订单",
		"choosetype": "请选择类型",
		"sell": "出售",
		"buy": "求购",
		"cancel": "撤销",
		"pchoose": "请选择",
		"minnum": "最小交易数量",
		"maxnum": "最大交易数量",
		"ordercom": "订单已完成",
		"orderceil": "订单已取消",
		"buyerpayed": "买家已付款，请核实后确认",
		"sellerwait": "请等待卖家确认",
		"receivepay": "确认已收款",
		"onsell": "在线出售",
		"onbuy": "在线购买",
		"fatotal": "法币总额",
		"sceil": "秒后自动取消",
		"nomin": "不能低于最低限额",
		"nomax": "不能超出最大限额",
		"nomaxnum": "不能超出最大数量"
},
	"lgset": {
	"lgset": "法币交易设置"
},
	"market": {
	"quotation": "市场行情",
		"market": "市场",
		"currency": "币种",
		"lastprice": "最新价",
		"change": "涨幅",
		"zoushi": "走势图"
},
	"center": {
	"xian": "限价交易",
		"shi": "市价交易",
		"available": "可用",
		"buyprice": "买入价",
		"sellprice": "卖出价",
		"buynumber": "买入量",
		"sellnumber": "卖出量",
		"tradetotal": "交易额",
		"buyin": "买",
		"sellout": "卖",
		"inbuy": "买入",
		"outsell": "卖出",
		"all": "全部",
		"cdel": "当前委托",
		"hdeal": "历史委托",
		"pairs": "交易对",
		"direction": "方向",
		"dealtotal": "委托总额",
		"tprice": "成交均价",
		"price": "成交价",
		"nothing": "无",
		"contran": "已成交",
		"newprice": "最新价",
		"alltrade": "全站交易",
		"volume": "交易量",
		"realtime": "分时",
		"or": "或",
		"start": "开始交易",
		"hour": "24小时资金变化",
		"inflow": "主力流入",
		"outflow": "主力流出",
		"inmoney": "24流入资金",
		"outmoney": "24流出资金"
},
	"account": {
	"mult100": "数量应为100的整数倍",
		"exchange": "兑换",
		"exchangeNum": "兑换数量",
		"hideSm": "隐藏小额资产",
		"assets": "我的资产",
		"taccount": "合约账户",
		"letransaction": "秒合约账户",
		"laccount": "法币账户",
		"totalassets": "总资产折合",
		"upaddress": "提币地址管理",
		"freezes": "冻结",
		"conversion": "折合",
		"charging": "充币",
		"charrecord": "充币记录",
		"fstaus": "跟踪状态",
		"view": "查看",
		"withdraw": "提币",
		"record": "记录",
		"copy": "复制",
		"code": "二维码",
		"cgaddress": "充币地址",
		"whaddress": "提币地址",
		"amount": "充值数量",
		"picture": "转账截图",
		"choose": "选择图片",
		"select": "请选择图片",
		"selected": "已选择",
		"submitRecharge": "提交充值信息",
		"notice": "温馨提示",
		"chaddress": "选择提币地址",
		"range": "范围",
		"havenum": "到账数目",
		"minnum": "最小提币数量为",
		"oncemaxnum": "单笔最高提币数量为",
		"daynum": "当天最高提币限额",
		"uppwd": "交易密码",
		"tibi": "请填入正确的BTC-OMNI提币地址",
		"call": "为保障资金安全，当您账户安全策略变更、密码修改、我们会对提币进行人工审核，请耐心等待工作人员电话或邮件联系。",
		"a1": "请勿向上述地址充值任何非",
		"a2": "资产，否则资产将不可找回。",
		"a3": "充币仅支持simple send的方法，使用其他方法（send all）的充币暂时无法上账，请您谅解。",
		"a4": "您充值至上述地址后，需要整个网络节点的确认，1次网络确认后到账，6次网络确认后可提币。",
		"a5": "最小充值金额：",
		"a6": "小于最小金额的充值将不会上账且无法退回。",
		"a7": "您的充值地址不会经常改变，可以重复充值；如有更改，我们会尽量通过网站公告或邮件通知您。",
		"a8": "请务必确认电脑及浏览器安全，防止信息被篡改或泄露。",
		"a9": "您充值至上述地址后，需要整个网络节点的确认，1次网络确认后到账，2次网络确认后可提币。",
		"a10": "提交充值信息后请耐心等待工作人员审核",
		"legal": "法币",
		"trade": "币币",
		"lever": "合约",
		"choosecoin": "选择币种",
		"huanum": "划转数量",
		"mrecord": "财务记录",
		"allRec": "总财务记录",
		"transfer": "划转"
},
	"set": {
	"secset": "安全设置",
		"msgcer": "信息认证",
		"turemsg": "为了确保您的交易正常进行，请填写您的真实信息",
		"tname": "真实姓名",
		"kname": "选择开户行",
		"cnum": "银行卡号",
		"ailipay": "支付宝账号",
		"wename": "微信昵称",
		"wenum": "微信账号",
		"bank_address": "开户行地址",
		"ailipay_nickname": "支付宝昵称",
		"attestation": "认证详情",
		"setpay": "设置收款类型",
		"remarks": "备注",
		"palipay": "请输入支付宝",
		"premarks": "请输入备注信息",
		"addalipaycode": "添加支付宝收款码",
		"pwenum": "请输入微信",
		"nameplease": "请输入姓名",
		"addwenumcode": "添加微信收款码",
		"add": "添加"
},
	"ad": {
	"adrecord": "留言记录",
		"release": "发布",
		"reply": "回复",
		"none": "暂无",
		"releaseit": "发布留言",
		"con": "请填写留言内容",
		"up": "提交"
},
	"lay": {
	"paccount": "请输入账号!",
		"notcode": "验证码不能为空!",
		"pwdliu": "密码不能小于六位!",
		"slogin": "登录成功",
		"noemail": "您的邮箱不符合规则",
		"phonenot": "您输入的手机号账号不符合规则!",
		"nophone": "请输入手机号",
		"nemail": "请输入邮箱",
		"inpwd": "请输入密码",
		"pwdlength": "密码只能在6-16位之间",
		"pwdcom": "密码必须由数字和字母组成",
		"repwd": "请再次输入密码",
		"twopwd": "两次输入的密码不一致",
		"inprice": "请输入买入价",
		"noinprice": "买入价不能为0",
		"innumber": "请输入买入量",
		"outprice": "请输入卖出价",
		"outinprice": "卖出价不能为0",
		"outnumber": "请输入卖出量",
		"plogin": "请登录",
		"revoke": "确认要撤单么？",
		"sure": "确定",
		"ceil": "取消",
		"notopen": "暂未开放",
		"caddress": "请选择提币地址",
		"cnumber": "请输入提币数量",
		"cpwd": "请输入提币密码",
		"minnum": "请输入最小数量",
		"copys": "复制成功",
		"fcopy": "复制失败",
		"recopy": "请重新复制",
		"huanum": "请输入划转数量",
		"truename": "真实姓名必须填写",
		"pliu": "请填写留言内容",
		"nameplease": "请输入姓名!",
		"idplease": "请输入证件号!",
		"comid": "请上传完整的证件!",
		"intermediate": "中",
		"high": "高",
		"strong": "强",
		"buymoney": "请输入欲购买总额",
		"buynum": "请输入欲购买数量",
		"sellmoney": "请输入欲出售总额",
		"sellnum": "请输入欲出售数量",
		"tname": "请先进行实名认证再下单",
		"payset": "请先设置收款方式",
		"mpay": "请选择支付方式",
		"mprice": "请输入价格",
		"mnum": "请输入数量",
		"lessnum": "最小数量不能小于",
		"morenum": "数量不能超出",
		"notnum": "数量不能大于总数量",
		"confirm": "请确认您的操作!",
		
		"canshucuowu":"参数错误",
		"chongzhiyitijiao":"充值申请已提交",
		"chongzhisqb":"充值申请提交失败",
		"nodi100":"输入的划转数量不能低于100",
		"pinputjine":"请输入金额",
		"selcztype":"请选择充值方式",
		"updateok":"更新成功",
		"ishuazhuan":"是否确认划转？",
		"pinputokphone":"请输入正确的手机号",
		"usernofind":"用户未找到"
		
},
	"c2c": {
	"myTc2c": "我交易的C2C",
		"pc2c": "发布C2C",
		"mypc2c": "我发布的C2C",
		"timer": "时间",
		"type": "类型",
		"price": "价格",
		"num": "数量",
		"totalmoney": "总金额",
		"minNums": "最小数量",
		"payType": "支付方式",
		"cancelp": "取消发布",
		"buy": "购买",
		"sell": "出售",
		"currency": "币种",
		"onePrice": "单价",
		"minTime": "最小时间",
		"pTime": "发布时间",
		"opera": "操作",
		"money": "金额",
		"enter": "请输入",
		"numIs": "数量在",
		"rangeL": "之间",
		"moneyIs": "金额在",
		"cancel": "取消",
		"maxNum": "数量不能超出",
		"minNum": "数量不能少于",
		"maxMoney": "金额不能超出",
		"minMoney": "金额不能少于",
		"notMore": "暂无更多",
		"state": "状态",
		"cancelOrder": "取消订单",
		"conPay": "确认付款",
		"conReceive": "确认收款",
		"payed": "已付款",
		"completed": "已完成",
		"canceled": "已取消",
		"detail": "详情",
		"payAccount": "支付账户",
		"selCu": "请选择币种",
		"ailipay": "支付宝",
		"wx": "微信",
		"bankcard": "银行卡",
		"pBuy": "发布求购",
		"pSell": "发布出售",
		"enterPrice": "请输入价格",
		"enterNum": "请输入数量",
		"enterMinnum": "请输入最小数量",
		"tips": "最小数量不能大于总数量",
		"name": "姓名",
		"wxName": "微信昵称",
		"bankName": "银行名称",
		"connect": "联系方式"
},
	"forLegalPsw": {
	"fpwd": "忘记资金密码",
		"setpwd": "设置资金密码"
},
	"cfc": {
	"balance": "余额",
		"account": "转出账号",
		"num": "转出数量",
		"pla_account": "请输入转出账号",
		"pla_num": "请输入转出数量"
},
	"tpc": {
	"hot": "热门项目",
		"all": "全部项目",
		"nodata": "暂无数据",
		"search": "搜索",
		"tips": "*当前页展示内容为项目方自行填写，SAFEEX不对此内容真实性负责",
		"time": "发行时间",
		"total": "发行总量",
		"circulation": "代币流通量",
		"price": "发行价",
		"web": "官网",
		"book": "白皮书",
		"tool": "社交工具",
		"recommend": "推荐机构",
		"introduce": "项目介绍",
		"notice": "公告",
		"rr": "项目研报",
		"news": "新闻资讯",
		"author": "作者：",
		"browse": "浏览量："
},
	"finance": {
	"selectState": "选择锁定状态",
		"front": "变动前",
		"after": "变动后",
		"info": "信息"
},
	"uc": {
	"code": "邀请码",
		"mymoney": "我的资金",
		"entrust": "委托管理",
		"deal": "成交管理",
		"pay": "支付方式",
		"id": "身份认证",
		"safe": "安全设置",
		"invitate": "邀请返利",
		"finance": "财务记录",
		"loginlog": "登录日志"
},
	"usercenter": {
	"change": "币币账户",
		"legal": "法币账户",
		"lever": "合约账户",
		"second": "秒合约账户"
},
	"account2": {
	"list": "提币列表",
		"address": "地址标签",
		"code": "验证码",
		"addtime": "添加时间",
		"suretime": "确认时间",
		"explain": "说明"
},
	"withdrawList": {
	"sel": "币种筛选",
		"submitTime": "提交时间",
		"checkTime": "审核时间",
		"tibiNum": "提币数量",
		"applying": "申请中",
		"hash": "哈希值",
		"agreeWait": "已同意，等待上链",
		"lianSucc": "链上成功",
		"lianFail": "链上失败",
		"bohui": "驳回",
		"zhanSucc": "站内成功"
},
	"wAddress": {
	"enterAddress": "请输入提币地址",
		"enterMemo": "请输入地址标签",
		"enterMark": "请输入备注",
		"tips": "温馨提示：USDT请使用btc币种添加地址，ERC20代币请使用ETH地址添加",
		"addressList": "地址列表",
		"memo": "地址标签",
		"del": "删除",
		"nospace": "提币地址不能含有空格",
		"nospace02": "地址标签不能含有空格",
		"nospace03": "地址标签必须是数字或字母或者字母和数字组合",
		"allNum": "地址标签必须是纯数字",
		"bankNum": "银行卡账号",
		"enterCardNum": "请输入银行卡账号",
		"addCard": "添加银行收款码",
		"addcode": "请上传收款码图片",
		"moreAdd": "最多上传",
		"pics": "张图片"
},
	"authDetail": {
	"payType": "收款方式",
		"noreceiveType": "您还未添加收款方式，请去添加！"
},
	"identity": {
	"anquan": "账号安全",
		"verify": "登录验证",
		"openVerify": "开启验证",
		"closeVerify": "关闭验证",
		"enterGooole": "请输入谷歌密钥",
		"verityType": "请选择验证方式"
},
	"caiwu": {
	"lock": "锁定",
		"nolock": "未锁定",
		"status": "请选择锁定状态",
		"selCu": "请选择币种"
},
	"lang": {
		"en": "English",
		"kr": "한글",
		"jp": "日本語",
		"zh": "简体中文",
		"hk": "繁體中文",
		"spa": "España",
		"ara":"Arabia"
},
	"google": {
	"code": "谷歌验证码",
		"entergoogle": "请输入谷歌验证码"
},
	"transfer": {
	"select": "请选择划转方向",
		"change": "从币币账户 到 法币账户",
		"legal": "从法币账户 到 币币账户",
		"change_balance": "币币账户可用",
		"pswType": "密码为6位纯数字",
		"newPsw": "新密码须为6位纯数字",
		"changePhone": "更改手机号",
		"changeEmail": "更改邮箱",
		"emailCode": "请输入邮箱验证码",
		"newPhone": "请输入新手机号",
		"newEmail": "请输入新邮箱号",
		"phoneCode": "请输入手机验证码",
		"selCu": "请选择币种",
		"ok": "资金划转成功",
		"error": "资金划转失败"
},
	"register01": {
	"country": "所属国家",
		"selectCountry": "国籍信息注册后不可修改，务必如实选择",
		"noAccount": "还没有账号，前往"
},
	"invite": {
	"welcome": "一起赚取高额回报",
		"effective": "有效",
		"invalid": "无效",
		"list": "榜单",
		"commission": "获得佣金",
		"link": "我的专属推广链接",
		"share": "复制链接分享",
		"mypromotion": "我的推广记录",
		"account": "被邀请人账号",
		"nomore": "暂无更多数据",
		"rules": "活动规则",
		"tip1": "被邀人必须在通过二级认证；",
		"tip2_0": "好友接受邀请后，每产生一笔真实交易手续费，会产生",
		"tip2_1": "的返佣",
		"tip3": "结算方式为按照邀请制度进行结算",
		"tip4_0": "邀请人实际获得的佣金将以",
		"tip4_1": "进行折合计算后返给邀请人的合约账户。返佣额",
		"tip4_2": " = 实际产生交易量 * 手续费比例*返佣比例。",
		"tip5_0": "平台将以每天北京时间08：00相应币种交易的收盘价进行",
		"tip5_1": "换算"
},
	"crowd": {
	"mujiNum": "募集数量",
		"cur": "支持币种",
		"endTime": "结束时间",
		"intro": "查看项目介绍",
		"can_edu": "本场可申购的额度",
		"now_edu": "当前已申购金额",
		"my_edu": "我的申购金额",
		"his": "历史",
		"price": "申购价格",
		"money": "申购金额",
		"yue": "账户余额",
		"enterMoney": "请输入申购金额",
		"total": "本次申购总额",
		"rule": "申购细则",
		"startBuyTime": "开始抢购时间",
		"endBuyTime": "结束抢购时间"
},
	"dep": {
	"dep_rec": "存币记录",
		"dep": "存币宝",
		"tip": "存币获利，闲币生财。币不妙，亦可生宝！",
		"plan": "天存币计划",
		"timer": "抢购周期",
		"rate": "年化收益率",
		"lock": "已锁",
		"join": "人参与",
		"end": "抢购已结束",
		"buy": "马上抢购",
		"date": "存币日期",
		"day": "天",
		"total_money": "抢购总额度",
		"sup_num": "剩余额度",
		"top": "存币上限",
		"one_num": "单次起购数量",
		"num": "存币数量",
		"min": "最小",
		"max": "最大",
		"yuji": "预计收益",
		"qiang": "抢购",
		"depEndTime": "存币截止时间",
		"jixiTime": "计息时间",
		"lockTime": "解锁时间",
		"enterNum": "请输入抢购数量",
		"his": "存币历史",
		"money": "存币金额",
		"cur_name": "币种名称",
		"do": "操作",
		"ti": "提取",
		"suoTime": "锁仓时长",
		"leiji": "累计获得收益",
		"rateNum": "应扣手续费数量:",
		"ti_num": "可提取数量:",
		"tiYet": "是否提取",
		"to": "至账户内？",
		"yes": "是",
		"no": "否"
},
	"vote": {
	"di": "第",
		"period": "期投票",
		"explain": "规则说明",
		"free": "当前免费获得票数",
		"have": "持有",
		"num": "数量",
		"people": "支持人数",
		"tickets": "支持票数",
		"detail": "查看详情",
		"jiangli": "投票奖励",
		"fen": "瓜分",
		"sup_people": "人支持",
		"piao": "票",
		"vote": "投票",
		"enter": "请输入投票票数",
		"vote_cur": "投票上币",
		"paiming": "排名",
		"intro": "项目介绍",
		"taken": "Taken概况和分配",
		"total": "代币总量",
		"cur_total": "当前流通总量",
		"price": "发行价格",
		"link": "相关链接",
		"web": "官方网站",
		"zh": "中文版白皮书",
		"en": "英文版白皮书"
},
	"lever": {
	"selHand": "选择手数",
		"yijianping": "一键平仓",
		"phands": "请选择手数",
		"yugu": "预估强平价",
		"transaction": "合约交易",
		"nowentrust": "当前委托",
		"hisentrust": "历史委托",
		"lvchi": "合约持仓",
		"all": "全部",
		"or": "或",
		"gotrade": "开始交易",
		"tdrecord": "交易记录",
		"dealed": "已成交",
		"notdeal": "未成交",
		"loading": "加载中...",
		"entotal": "委托总额",
		"canuse": "可用",
		"std": "市价交易",
		"xtd": "限价交易",
		"pprice": "请输入价格",
		"phand": "请输入购买手数",
		"pwd": "密码",
		"ppwd": "请输入交易密码",
		"psw": "交易密码",
		"equal": "等于",
		"times": "X",
		"timed": "倍数",
		"handbuy": "最少买入 ",
		"handsell": "最少卖出 ",
		"hands": "数量",
		"ptimes": "请选择倍数",
		"contractVal": "交易额",
		"bail": "保证金",
		"canBail": "可用保证金",
		"charge": "服务费",
		"domore": "买入 (做多)",
		"doshort": "卖出 (做空)",
		"dmore": "做多",
		"dshort": "做空",
		"sureOd": "确认下单",
		"noless": "手数不能低于",
		"nomore": "手数不能高于",
		"tdnum": "交易量",
		"risk": "风险率",
		"allloss": "持仓总盈亏",
		"onehouse": "一键平仓",
		"type": "类型",
		"entrustPrice": "委托价",
		"openPrice": "开仓价",
		"nowPrice": "当前价",
		"closePrice": "平仓价",
		"styPrice": "止盈价",
		"stsPrice": "止损价",
		"openTime": "开仓时间",
		"weituoTime": "委托时间",
		"closeTime": "平仓时间",
		"revokeTime": "撤销时间",
		"rate": "手续费",
		"nightFee": "隔夜费",
		"loss": "盈亏",
		"setloss": "设置止盈止损",
		"expectProfit": "预期盈利",
		"expectLoss": "预期亏损",
		"allClose": "全部平仓",
		"moreClose": "只平多单",
		"nullClose": "只平空单",
		"sureClose": "确认平仓？",
		"thanZearo": "设置的值必须大于0",
		"listin": "挂单中",
		"tdin": "交易中",
		"closein": "平仓中",
		"closed": "已平仓",
		"revoked": "已撤销",
		"revokeOrder": "确认撤销订单么？",
		"ping": "平仓",
		"revoke": "撤单",
		"sureping": "确定平仓？",
		"thanzone": "设置的值必须大于0",
		"zheng": "请输入整数",
		"buyMin": "买入手数不能小于",
		"buyMax": "买入手数不能大于",
		"enterBuyPrice": "请输入买入价格",
		"sellMin": "卖出手数不能小于",
		"sellMax": "卖出手数不能大于",
		"enterSellPrice": "请输入卖出价格",
		"bestprice": "市场最优价格"
},
	"second": {
	"transaction": "秒合约交易",
		"newprice": "最新价",
		"zhangfu": "涨幅",
		"hourday": "24H量",
		"conversion": "折合人民币"
},
	"td1": {
	"do": "操作",
		"more": "加载更多",
		"loading": "加载中",
		"nomore": "没有更多了",
		"nodata": "暂无数据",
		"canceil": "取消",
		"confirm": "确认",
		"status": "状态"
},
	"accountset": {
	"name": "请填写姓名"
},
	"sgr": {
	"sgrinfo": "SGR Demand模式介绍",
		"lock": "当前个人锁定SGR总数量: ",
		"signup": "立即注册",
		"sgrget": "当前注册可获得锁定SGR数量:",
		"cir": "全站流通数量",
		"loc": "全站锁定数量",
		"richman": "今日暴富者",
		"user": "用户",
		"amount": "赢得数量",
		"time": "时间",
		"todayrelease": "今日个人SGR待释放数量",
		"releaserecords": "释放记录",
		"invite": "邀请好友",
		"link": "我的邀请链接",
		"copy": "复制链接",
		"totallock": "当前通过邀请已获得SGR锁定数量",
		"onelevel": "一级邀请人数",
		"twolevel": "二级邀请人数",
		"therelevel": "三级邀请人数",
		"get": "获得 ",
		"rebate": " 邀请返利"
},
	"miscro": {
		"quotation": "行情",
		"transaction": "秒合约交易",
		"symbol": "交易对",
		"balance": "余额",
		"miscroAccount": "秒合约账户",
		"trade": "交易中",
		"buyPrice": "购买价",
		"finshPrice": "成交价",
		"loss": "预计盈亏",
		"times": "倒计时",
		"mode": "交易模式",
		"num": "开仓数量",
		"rate": "盈利率",
		"up": "买涨",
		"down": "买跌",
		"openNum": "请输入开仓数量",
		"success": "下单成功！",
		"c2c": "c2c账户",
		"complaint": "投诉建议",
		"reply": "请写下您的问题，我们将尽快回复您...",
		"complaintList": "投诉建议列表",
		"complaintReply": "回复：",
		"complaintDescription": "请输入描述内容",
		"resetpwd": "修改密码",
		"foundedOn": "创建于",
		"commonProblem": "常见问题",
		"statement": "解释说明",
		"about": "关于KiBiEx",
		"everyone": "人人都是CEO",
		"program": "KiBiEx全球合伙人计划",
		"myMine": "我的矿场：",
		"friend": "我的矿友：",
		"myRank": "我的等级：",
		"accumulated": "累计佣金：",
		"copyLinks": "复制推广链接",
		"moneyVein": "人脉变钱脉",
		"example": "佣金示例",
		"enterQuantity": "请输入数量",
		"contractBalance": "合约账户余额",
		"submitRepeatedly": "正在提交中，请不要反复提交",
		"alipayCode": "支付宝付款码",
		"wechatCode": "微信付款码",
		"realPrice": "实时价格",
		"currencyExchange": "兑出币种",
		"currencyExchangeIn": "兑入币种",
		"cashableBalance": "可兑出余额：",
		"minimumCashable": "最小可兑出：",
		"maximumCashable": "最大可兑出：",
		"automaticallys": "点击兑换之后将自动完成，不能返回",
		"match": "兑",
		"title": "资产兑换",
		"holdAssets": "持有资产",
		"dangerousCurrency": "持险生币",
		"convertibleQuantity": "可兑换数量",
		"currencyExchanges": "币币兑换率：",
		"insuranceCurrency": "保险币种",
		"insuranceType": "保险类型",
		"contractAsset": "合约资产保险额",
		"warehouses": "保险仓数量",
		"availableAssets": "可用资产：",
		"insuredAssets": "受保资产：",
		"insuredAssets1": "受保资产",
		"insuranceAssets": "保险资产：",
		"purchaseInsurance": "申购保险",
		"insuranceClaims": "保险理赔",
		"insuranceCancellation": "保险解约",
		"coinWallet": "保险币钱包",
		"bmbWallet": "AITB钱包",
		"cumulativeCoins": "累计生币：",
		"availableQuantity": "可用数量：",
		"rawCurrency": "生币记录",
		"contractInsurance": "合约保险",
		"tenThousand": "万",
		"runningLow": "余额不足",
		"purchase": "你输入的数量不符合规则，你的购买额度在",
		"reach": "到",
		"between": "之间",
		"onlyEnter": "你只能输入",
		"integersBetween": "之间的整数",
		"notReturned": "点击申购保险，视为详细了解保险条约并且同意保险仓自动生效，不能返回。",
		"settled": "受保资产亏损至无法下单时，保险理赔",
		"profitable": "倍，当资产盈利",
		"terminated": "时，保险自动解约。",
		"automatically": "当受保资产亏损50%时，必须申请保险理赔，否则无法交易，当盈利100%时，保险自动解约,保险仓每天只允许赔付两次，超过两次，t+1赔付",
		"termination": "保险解约后，受保资产方可兑换，解约后视为违约，保险仓自动清零。",
		"just": "正",
		"back": "反",
		"lowestNumber": "数量最低为",
		"confirmExchange": "确认兑换吗?",
		"contractValuation": "合约账户资产估值",
		"secondValuation": "秒合约账户资产估值",
		"falshValuation": "币币账户资产估值",
		"c2cValuation": "法币账户资产估值",
		"recordWithdrawal": "充提币记录",
		"category": "类别",
		"safetyCenter": "安全中心",
		"safeText1": "注册、修改密码，及安全设置时用以收取验证短信",
		"safeText2": "互联网账号存在被盗风险，建议您定期更改密码以保护账户安全。",
		"flashTrading": "币币兑换",
		"assetCenter": "资产中心",
		"promotionCode": "我的推广码",
		"loginAgain": "登录过时，请重新登录",
		"text10": "基于AITB的实时价格",
		"text11": "支付凭证"
},
	"td": {
	"buy": "购买",
		"sell": "出售",
		"currency": "币种",
		"num": "数量",
		"time": "时间",
		"limit": "限额",
		"price": "单价",
		"method": "支付方式",
		"total": "总额",
		"inwant": "请输入欲",
		"do": "操作",
		"more": "加载更多",
		"nomore": "没有更多了",
		"nodata": "暂无数据",
		"trade": "交易",
		"buynum": "购买数量",
		"sellout": "卖出",
		"buyin": "买入",
		"all": "全部",
		"allbuy": "全部买入",
		"allsell": "全部卖出",
		"buytotal": "请输入欲购买总额",
		"selltotal": "请输入欲出售总额",
		"buyallnum": "请输入欲购买数量",
		"sellallnum": "请输入欲出售数量",
		"tradeTotal": "交易总额",
		"doceil": "秒自动取消",
		"place": "下单",
		"waitpay": "待付款",
		"finished": "已完成",
		"ceiled": "已取消",
		"payed": "已付款",
		"nofinish": "未完成",
		"buyer": "买家",
		"seller": "卖家",
		"callWay": "联系方式",
		"placeTime": "下单时间",
		"renum": "参考号",
		"canceil": "取消",
		"confirm": "确认",
		"pwd": "请输入交易密码",
		"coincode": "货币单位",
		"pcoin": "请选择货币单位",
		"examine": "商家审核中，请耐心等待"
},
	"cuy": {
	"direction": "方向",
		"total": "总计",
		"price": "价格",
		"sell": "卖",
		"buy": "买",
		"allStation": "全站交易",
		"buyPrice": "买入价",
		"buynum": "买入量",
		"sellPrice": "卖出价",
		"sellnum": "卖出量",
		"tdPrice": "交易额",
		"or": "或",
		"tdStart": "开始交易",
		"pbPrice": "请输入买入价",
		"pbNum": "请输入买入量",
		"psPrice": "请输入卖出价",
		"psNum": "请输入卖出量",
		"fixPrice": "限价交易",
		"Mtrans": "市价交易",
		"available": "可用",
		"revoke": "撤销",
		"loading": "加载中",
		"confirmCancel": "您确定要撤销吗？",
		"confirm": "确定",
		"cancel": "取消",
		"nomore": "没有更多数据了",
		"evelPrice": "成交均价"
},
	"iepn": {
	"htitle": "大量币发售中，邀请好友共同赚币",
		"subtitle": "平台发售大量新币，快来立即购买，数量有限哦～",
		"netname": "智慧防疫网络",
		"plannum": "计划发行量",
		"totalnum": "总发行量",
		"pushednum": "已发行量",
		"remindnum": "剩余数量",
		"hotcommond": "热门推荐",
		"ctitle": "全球政府医疗管理机构",
		"cdsp": "医疗涉及民生，不可能回避政府的角色。而Intelligent Epidemic Prevention Network其链条透明的特性，可以助力去全球医疗管理机构更好地管理医疗机关，监测国民健康，做出快速、灵活的医疗资源调配。",
		"starttime": "发售开始时间",
		"startprice": "发售价格",
		"endtime": "发售结束时间",
		"seemore": "查看更多",
		"baipishu": "技术白皮书",
		"gobuy": "去购买"
},
"apitip":{
	"参数错误":"参数错误",
	"数据未找到":"数据未找到",
	"请选择需求类型":"请选择需求类型",
	"请选择交易方式":"请选择交易方式",
	"请填写单价":"请填写单价",
	"请填写数量":"请填写数量",
	"请选择币种":"请选择币种",
	"请输入正确的交易数量或价格":"请输入正确的交易数量或价格",
	"您还没有设置收款信息":"您还没有设置收款信息",
	"取消次数已超出":"取消次数已超出",
	"次,明天再发布":"次,明天再发布",
	"用户钱包不存在":"用户钱包不存在",
	"对不起，您的钱包余额不足":"对不起，您的钱包余额不足",
	"用户发布c2c交易法币出售，扣除法币余额":"用户发布c2c交易法币出售，扣除法币余额",
	"用户发布c2c交易法币出售,锁定余额增加":"用户发布c2c交易法币出售,锁定余额增加",
	"发布成功":"发布成功",
	"无此用户":"无此用户",
	"用户不存在":"用户不存在",
	"参数错误2":"参数错误2",
	"无此币种":"无此币种",
	"该币不是法币":"该币不是法币",
	"无此记录":"无此记录",
	"您还没有通过实名认证":"您还没有通过实名认证",
	"此条交易已完成":"此条交易已完成",
	"未找到该发布用户":"未找到该发布用户",
	"不能操作自己的":"不能操作自己的",
	"您无此钱包账号":"您无此钱包账号",
	"您的钱包已被锁定，请联系管理员":"您的钱包已被锁定，请联系管理员",
	"检测有未完成交易，请完成后再来！":"检测有未完成交易，请完成后再来！",
	"您的余额不足":"您的余额不足",
	"您的法币冻结资金异常,请查看您是否有正在进行的挂单":"您的法币冻结资金异常,请查看您是否有正在进行的挂单",
	"出售给商家法币,余额减少":"出售给商家法币,余额减少",
	"出售给商家法币,锁定余额增加":"出售给商家法币,锁定余额增加",
	"您发布的求购信息有用户出售啦，请去 APP 查看吧～":"您发布的求购信息有用户出售啦，请去 APP 查看吧～",
	"您发布的出售信息有用户购买啦，请去 APP 查看吧～":"您发布的出售信息有用户购买啦，请去 APP 查看吧～",
	"Operation successful，请联系商家确认订单":"操作成功，请联系商家确认订单",
	",错误位于第":"，错误位于第",
	",行":"，行",
	"用户信息不正确":"用户信息不正确",
	"该订单已操作过，请勿重复操作":"该订单已操作过，请勿重复操作",
	"对不起，您无权操作":"对不起，您无权操作",
	"buy":"买",
	"Operation successful，请联系卖家确认":"操作成功，请联系卖家确认",
	"Operation successful，订单已取消":"操作成功，订单已取消",
	"您今天的取消次数已超出":"您今天的取消次数已超出",
	"该订单已操作，请勿取消":"该订单已操作，请勿取消",
	"对不起，这不是您的发布信息":"对不起，这不是您的发布信息",
	"该订单还未付款或已经操作过":"该订单还未付款或已经操作过",
	"您不能确认该订单":"您不能确认该订单",
	"订单异常":"订单异常",
	"该用户没有此币种钱包":"该用户没有此币种钱包",
	"该买家没有此币种钱包":"该买家没有此币种钱包",
	"出售法币成功,扣除锁定余额":"出售法币成功,扣除锁定余额",
	"购买法币成功，增加法币余额":"购买法币成功，增加法币余额",
	"在":"在",
	"确认成功":"确认成功",
	"卖出法币成功":"卖出法币成功",
	"购买法币成功":"购买法币成功",
	"该发布信息下有交易产生无法删除":"该发布信息下有交易产生无法删除",
	"对不起，您无权撤销此记录":"对不起，您无权撤销此记录",
	"用户钱包不存在":"用户钱包不存在",
	"对不起，您的账户冻结资金不足":"对不起，您的账户冻结资金不足",
	"商家撤回发布法币出售":"商家撤回发布法币出售",
	"撤回成功":"撤回成功",
	"参数错误!":"参数错误!",
	"转账账户不存在!":"转账账户不存在!",
	"不能给自己转账!":"不能给自己转账!",
	"转账数量大于剩余数量!":"转账数量大于剩余数量!",
	"余额不足!":"余额不足",
	"转账数量不能小于":"转账数量不能小于",
	"转账数量不能大于":"转账数量不能大于",
	"通证转账成功!":"通证转账成功",
	"必填项信息不完整":"必填项信息不完整",
	"两次输入密码不一致":"两次输入密码不一致",
	"此用户不存在":"此用户不存在",
	"此交易账号已经设置":"此交易账号已经设置",
	"此账号已经存在":"此账号已经存在",
	"交易账号设置成功":"交易账号设置成功",
	"验证码错误":"验证码错误",
	"手机绑定成功":"手机绑定成功",
	"邮箱绑定成功":"邮箱绑定成功",
	"手势密码至少连接6个点":"手势密码至少连接6个点",
	"两次手势密码不相同":"两次手势密码不相同",
	"手势密码添加成功":"手势密码添加成功",
	"取消手势密码成功":"取消手势密码成功",
	"密码只能在6-16位之间":"密码只能在6-16位之间",
	"两次密码不一致":"两次密码不一致",
	"交易密码设置成功":"交易密码设置成功",
	"暂时还没有邀请排行榜，快去邀请吧":"暂时还没有邀请排行榜，快去邀请吧",
	"会员未找到":"会员未找到",
	"你已绑定，不可更改!":"你已绑定，不可更改!",
	"该地址已被绑定,请重新输入":"该地址已被绑定,请重新输入",
	"绑定成功!":"绑定成功",
	"暂无钱包":"暂无钱包",
	"请提交完整的信息":"请提交完整的信息",
	"请输入合法的身份证号码":"请输入合法的身份证号码",
	"该身份证号已实名认证过!":"该身份证号已实名认证过",
	"您已经申请过了":"您已经申请过了",
	"提交成功，等待审核":"提交成功，等待审核",
	"支付密码错误":"支付密码错误",
	"退出登录成功":"退出登录成功",
	"请输入支付密码":"请输入支付密码",
	"余额不足":"余额不足",
	"无需升级":"无需升级",
	"升级成功":"升级成功",
	"升级会员":"升级会员",
	"没有此用户":"没有此用户",
	"该地址别人已经绑定过了":"该地址别人已经绑定过了",
	"更新成功":"更新成功",
	"请重试":"请重试",
	"请把参数填写完整":"请把参数填写完整",
	"推荐用户不存在":"推荐用户不存在",
	"转入账户不能为空":"转入账户不能为空",
	"转入数量不能为空":"转入数量不能为空",
	"密码不能为空":"密码不能为空",
	"用户密码错误":"用户密码错误",
	"美丽链法币交易余额转入":"美丽链法币交易余额转入",
	"美丽链币币交易余额转入":"美丽链币币交易余额转入",
	"美丽链杠杆交易余额转入":"美丽链杠杆交易余额转入",
	"转入成功":"转入成功",
	"密码修改成功":"密码修改成功",
	"用户未找到":"用户未找到",
	"绑定成功":"绑定成功",
	"发送成功":"发送成功",
	"m月d日 H:i":"m月d日 H:i",
	"币未找到":"币未找到",
	"暂无数据":"暂无数据",
	"文件大小超出":"文件大小超出",
	"该文件已存在":"该文件已存在",
	"文件类型不对":"文件类型不对",
	"上传失败":"上传失败",
	"内容不能为空":"内容不能为空",
	"提交成功，我们会尽快给你回复":"提交成功，我们会尽快给你回复",
	"非法参数":"非法参数",
	"该币种不支持购买保险":"该币种不支持购买保险",
	"错误的金额！":"错误的金额",
	"不存在的险种！":"不存在的险种",
	"不存在的币种！":"不存在的币种",
	"已经购买了该币种的险种！":"已经购买了该币种的险种",
	"可用余额不足，无法购买！":"可用余额不足，无法购买",
	"购买成功！":"购买成功",
	"购买失败！原因：":"购买失败！原因：",
	"未找到该保险":"未找到该保险",
	"该保险正在处理中":"该保险正在处理中",
	"申请索赔失败":"申请索赔失败",
	"申请索赔成功！":"申请索赔成功",
	"超出今日索赔次数!":"超出今日索赔次数",
	"存在未平仓订单":"存在未平仓订单",
	"受保资产为零":"受保资产为零",
	"受保资产不符合可申请索赔条件1":"受保资产不符合可申请索赔条件1",
	"受保资产不符合可申请索赔条件2":"受保资产不符合可申请索赔条件2",
	"未知的险种类型":"未知的险种类型",
	"保险赔偿用户[清除受保金额]":"保险赔偿用户[清除受保金额]",
	"保险赔偿用户[赔偿受保金额]":"保险赔偿用户[赔偿受保金额]",
	"保险解约，扣除保险金额":"保险解约，扣除保险金额",
	"未知受保金额去向状态":"未知受保金额去向状态",
	"处理成功！":"处理成功",
	"处理失败：":"处理失败：",
	"该保险已失效":"该保险已失效",
	"该保险正在索赔处理中":"该保险正在索赔处理中",
	"解约失败，存在未平仓订单":"解约失败，存在未平仓订单",
	"保险解约，赔付金额":"保险解约，赔付金额",
	"保险解约，扣除受保金额":"保险解约，扣除受保金额",
	"保险解约，扣除保险金额":"保险解约，扣除保险金额",
	"解约成功！":"解约成功",
	"解约失败:":"解约失败:",
	"订单取消成功":"订单取消成功",
	"请选择需求类型":"请选择需求类型",
	"请选择交易方式":"请选择交易方式",
	"请填写单价":"请填写单价",
	"请填写数量":"请填写数量",
	"请填写最小交易数量":"请填写最小交易数量",
	"请选择币种":"请选择币种",
	"最小交易数量不能大于总数量":"最小交易数量不能大于总数量",
	"请填写最大交易量":"请填写最大交易量",
	"请填写正确的最大交易量":"请填写正确的最大交易量",
	"币种信息有误":"币种信息有误",
	"对不起，您不是该法币的商家":"对不起，您不是该法币的商家",
	"对不起，您的商家账户不足":"对不起，您的商家账户不足",
	"商家发布出售":"商家发布出售",
	"商家发布出售,冻结增加":"商家发布出售,冻结增加",
	"发布成功":"发布成功",
	"无此商家":"无此商家",
	"您不是商家":"您不是商家",
	"该币不是法币":"该币不是法币",
	"无此币种":"无此币种",
	"购买参数错误":"购买参数错误",
	"无此记录":"无此记录",
	"请填写购买额":"请填写购买额",
	"购买额请填写数字":"购买额请填写数字",
	"您还没有通过实名认证":"您还没有通过实名认证",
	"您还没有设置收款信息":"您还没有设置收款信息",
	"未完成单子超过3单，请完成后再操作!":"未完成单子超过3单，请完成后再操作!",
	"商家挂单状态异常,暂时不能交易":"商家挂单状态异常,暂时不能交易",
	"商家挂单剩余可交易数量不足":"商家挂单剩余可交易数量不足",
	"此条交易已完成":"此条交易已完成",
	"非法提交，数量必须大于0":"非法提交，数量必须大于0",
	"您低于最低限额":"您低于最低限额",
	"您高于最高限额":"您高于最高限额",
	"您高于最大限制数量":"您高于最大限制数量",
	"未找到该商家":"未找到该商家",
	"不能操作自己的":"不能操作自己的",
	"您无此钱包账号":"您无此钱包账号",
	"您的钱包已被锁定，请联系管理员":"您的钱包已被锁定，请联系管理员",
	"您的法币余额不足":"您的法币余额不足",
	"您的法币冻结资金异常,请查看您是否有正在进行的挂单":"您的法币冻结资金异常,请查看您是否有正在进行的挂单",
	"您出售数量大于商家剩余数量!":"您出售数量大于商家剩余数量",
	"出售给商家法币:扣除余额":"出售给商家法币:扣除余额",
	"出售给商家法币:增加冻结":"出售给商家法币:增加冻结",
	"您购买数量大于商家剩余数量!":"您购买数量大于商家剩余数量",
	",错误位于第":",错误位于第",
	"Operation successful，请联系商家确认订单":"操作成功，请联系商家确认订单",
	"行":"行",
	"该订单已操作过，请勿重复操作":"该订单已操作过，请勿重复操作",
	"请上传支付凭证":"请上传支付凭证",
	"对不起，您无权操作":"对不起，您无权操作",
	"Operation successful，请联系卖家确认":"操作成功，请联系卖家确认",
	"该订单已操作，请勿取消":"该订单已操作，请勿取消",
	"Operation successful，订单已取消":"操作成功，订单已取消",
	"对不起您不是商家":"对不起您不是商家",
	"对不起，您不是该商家":"对不起，您不是该商家",
	"该订单还未付款或已经操作过":"该订单还未付款或已经操作过",
	"对不起，您的商家冻结余额不足,当前余额:":"对不起，您的商家冻结余额不足,当前余额:",
	"您不能确认该订单":"您不能确认该订单",
	"订单异常":"订单异常",
	"该用户没有此币种钱包":"该用户没有此币种钱包",
	"法币交易:在商家":"法币交易:在商家",
	"购买法币成功":"购买法币成功",
	"法币交易:卖出成功":"法币交易:卖出成功",
	"确认成功":"确认成功",
	"法币交易:用户":"法币交易:用户",
	"向商家出售法币成功":"向商家出售法币成功",
	"法币交易:购买成功":"法币交易:购买成功",
	"此状态下无法下架":"此状态下无法下架",
	"对不起，您不是该法币的商家":"对不起，您不是该法币的商家",
	"发布下架成功,将不会再与新用户匹配":"发布下架成功,将不会再与新用户匹配",
	"必须下架后才可以撤销":"必须下架后才可以撤销",
	"已撤回发布":"已撤回发布",
	"当前发布状态无法撤销":"当前发布状态无法撤销",
	"当前发布剩余数量不足,无法撤销":"当前发布剩余数量不足,无法撤销",
	"该发布信息下有交易正在进行中,请等待交易结束再撤回":"该发布信息下有交易正在进行中,请等待交易结束再撤回",
	"对不起，您不是该法币的商家":"对不起，您不是该法币的商家",
	"对不起，您的商家账户冻结资金不足":"对不起，您的商家账户冻结资金不足",
	"撤回失败:减少冻结资金失败":"撤回失败:减少冻结资金失败",
	"商家撤回":"商家撤回",
	"撤回失败:增加余额失败":"撤回失败:增加余额失败",
	"撤回成功":"撤回成功",
	"该发布信息无异常":"该发布信息无异常",
	"该发布信息下有交易未完成，不能标记为异常":"该发布信息下有交易未完成，不能标记为异常",
	"该发布剩余数量不足,不法标记异常":"该发布剩余数量不足,不法标记异常",
	"当前发布状态无法标记异常":"当前发布状态无法标记异常",
	"处理异常失败:减少冻结资金失败":"处理异常失败:减少冻结资金失败",
	"处理异常失败:增加余额失败":"处理异常失败:增加余额失败",
	"商家处理异常":"商家处理异常",
	"缺少参数或传值错误":"缺少参数或传值错误",
	"指定交易对不存在":"指定交易对不存在",
	"您未开通本交易对的交易功能":"您未开通本交易对的交易功能",
	"手数必须是大于0的整数":"手数必须是大于0的整数",
	"手数不能低于":"手数不能低于",
	"手数不能高于":"手数不能高于",
	"选择倍数不在系统范围":"选择倍数不在系统范围",
	"您有正在平仓中的交易,暂不能进行买卖":"您有正在平仓中的交易,暂不能进行买卖",
	"交易类型错误":"交易类型错误",
	"该功能暂未开放":"该功能暂未开放",
	"限价交易价格必须大于0":"限价交易价格必须大于0",
	"当前没有获取到行情价格,请稍后重试":"当前没有获取到行情价格,请稍后重试",
	"限价交易卖出不能低于当前价":"限价交易卖出不能低于当前价",
	"限价交易买入价格不能高于当前价":"限价交易买入价格不能高于当前价",
	"钱包未找到,请先添加钱包":"钱包未找到,请先添加钱包",
	"余额不足,不能小于":"余额不足,不能小于",
	"(手续费:":"(手续费:",
	"提交失败":"提交失败",
	"扣除保证金失败":"扣除保证金失败",
	"扣除手续费失败":"扣除手续费失败",
	"此功能系统未开放":"此功能系统未开放",
	"止盈止损价格不能为0":"止盈止损价格不能为0",
	"找不到该笔交易":"找不到该笔交易",
	"买入(做多)止盈价不能低于开仓价和当前价":"买入(做多)止盈价不能低于开仓价和当前价",
	"买入(做多)止亏价不能高于开仓价和当前价":"买入(做多)止亏价不能高于开仓价和当前价",
	"卖出(做空)止盈价不能高于开仓价和当前价":"卖出(做空)止盈价不能高于开仓价和当前价",
	"卖出(做空)止亏价不能低于开仓价和当前价":"卖出(做空)止亏价不能低于开仓价和当前价",
	"设置成功":"设置成功",
	"设置失败":"设置失败",
	"数据未找到":"数据未找到",
	"无权操作":"无权操作",
	"交易状态异常,请勿重复提交":"交易状态异常,请勿重复提交",
	"平仓失败,请重试":"平仓失败,请重试",
	"交易异常，无法平仓":"交易异常，无法平仓",
	"买入方向传参错误":"买入方向传参错误",
	"提交成功,请等待系统处理":"提交成功,请等待系统处理",
	"未找到需要平仓的交易":"未找到需要平仓的交易",
	"交易不存在或已撤单,请刷新后重试":"交易不存在或已撤单,请刷新后重试",
	"撤单失败:用户钱包不存在":"撤单失败:用户钱包不存在",
	"撤单失败":"撤单失败",
	"撤单失败:变更状态失败":"撤单失败:变更状态失败",
	"撤单成功":"撤单成功",
	"请输入账号":"请输入账号",
	"请输入密码":"请输入密码",
	"手势密码错误":"手势密码错误",
	"账号已锁定！请联系管理员":"账号已锁定！请联系管理员",
	"两次密码不一致":"两次密码不一致",
	"密码只能在6-16位之间111111":"密码只能在6-16位之间",
	"验证码错误":"验证码错误",
	"账号已存在":"账号已存在",
	"请填写正确的邀请码":"请填写正确的邀请码",
	"请输入密码或确认密码":"请输入密码或确认密码",
	"输入两次密码不一致":"输入两次密码不一致",
	"验证码不正确":"验证码不正确",
	"账号不存在":"账号不存在",
	"修改密码成功":"修改密码成功",
	"请输入验证码":"请输入验证码",
	"验证成功":"验证成功",
	"下单失败:超过最大持仓笔数限制":"下单失败:超过最大持仓笔数限制",
	"当前未获取到行情":"当前未获取到行情",
	"尚未申购或理赔保险":"尚未申购或理赔保险",
	"申购的保险T+1生效":"申购的保险T+1生效",
	"受保资产为零":"受保资产为零",
	"受保资产小于等于可下单条件":"受保资产小于等于可下单条件",
	"您已超过持仓限制，暂停下单。":"您已超过持仓限制，暂停下单",
	"未知的险种类型":"未知的险种类型",
	"超过最大持仓数量限制":"超过最大持仓数量限制",
	"交易中的订单大于最大挂单数量":"交易中的订单大于最大挂单数量",
	"新闻不存在":"新闻不存在",
	"无此币种":"无此币种",
	"该币不是法币":"该币不是法币",
	"用户名不能为空":"用户名不能为空",
	"名称不能为空":"名称不能为空",
	"账号不能为空":"账号不能为空",
	"资产不能为空":"资产不能为空",
	"找不到此交易账号的用户":"找不到此交易账号的用户",
	"此用户还未通过实名认证":"此用户还未通过实名认证",
	"币种不存在":"币种不存在",
	"商家名称已存在":"商家名称已存在",
	"此法币":"此法币",
	"此用户已是此法币商家":"此用户已是此法币商家",
	"申请成为商家，扣除USDT":"申请成为商家，扣除USDT",
	"系统配置错误，请联系系统管理员":"系统配置错误，请联系系统管理员",
	"发送成功":"发送成功",
	"账号错误":"账号错误",
	"账号已存在":"账号已存在",
	"请填写账号":"请填写账号",
	"【BEF】若非您本人操作，请及时修改密码。":"【NEWBIT】若非您本人操作，请及时修改密码。",
	"发送失败":"发送失败",
	"ip参数不正确":"ip参数不正确",
	"邮箱不能为空":"邮箱不能为空",
	"短信验证码":"短信验证码",
	"您还没有交易记录":"您还没有交易记录",
	"非法操作,不能撤回非自己发布的信息":"非法操作,不能撤回非自己发布的信息",
	"非法操作:(":"非法操作:(",
	"取销卖出交易失败":"取销卖出交易失败",
	"取消买入交易,解除币币余额锁定":"取消买入交易,解除币币余额锁定",
	"取消买入交易,解除法币余额锁定":"取消买入交易,解除法币余额锁定",
	"用户未找到":"用户未找到",
	"取消成功":"取消成功",
	"取消卖出交易,解除交易余额锁定":"取消卖出交易,解除交易余额锁定",
	"取消卖出交易,解除交易余额锁定":"取消卖出交易,解除交易余额锁定",
	"类型错误":"类型错误",
	"交易不存在":"交易不存在",
	"资金不足":"资金不足",
	"数据未找到":"数据未找到",
	"请先添加钱包":"请先添加钱包",
	"您的币不足":"您的币不足",
	"提交卖出记录扣除":"提交卖出记录扣除",
	"你今天的交易额度已达到上限!":"你今天的交易额度已达到上限",
	"余额不足":"余额不足",
	"提交卖入记录扣除":"提交卖入记录扣除",
	"提交卖出记录(增加冻结)":"提交卖出记录(增加冻结)",
	"价格和数量必须大于0":"价格和数量必须大于0",
	"您的冻结资金异常，禁止挂卖":"您的冻结资金异常，禁止挂卖",
	"真实姓名必须填写":"真实姓名必须填写",
	"收款信息至少选填一项":"收款信息至少选填一项",
	"保存成功":"保存成功",
	"密码错误":"密码错误",
	"当前行情小于等于零!":"当前行情小于等于零!",
	"资产兑换,减少持有币法币:":"资产兑换,减少持有币法币:",
	"兑换数量大于持有资产!":"兑换数量大于持有资产!",
	"资产兑换,减少":"资产兑换,减少",
	"资产兑换,增加USDT杠杆币":"资产兑换,增加USDT杠杆币",
	"扣除手续费":"扣除手续费",
	"法币数量:":"法币数量",
	"兑换数量大于剩余数量!":"兑换数量大于剩余数量",
	"资产兑换成功!":"资产兑换成功",
	"参数错误!":"参数错误",
	"通证兑换,杠杆币增加":"通证兑换,杠杆币增加",
	"通证兑换杠杆币增加失败":"通证兑换杠杆币增加失败",
	"通证兑换成功!":"通证兑换成功",
	"必填项信息不完整":"必填项信息不完整",
	"两次输入密码不一致":"两次输入密码不一致",
	"此用户不存在":"此用户不存在",
	"此交易账号已经设置":"此交易账号已经设置",
	"此账号已经存在":"此账号已经存在",
	"交易账号设置成功":"交易账号设置成功",
	"邮箱绑定成功":"邮箱绑定成功",
	"手机绑定成功":"手机绑定成功",
	"手势密码添加成功":"手势密码添加成功",
	"两次手势密码不相同":"两次手势密码不相同",
	"取消手势密码成功":"取消手势密码成功",
	"手势密码至少连接6个点":"手势密码至少连接6个点",
	"密码只能在6-16位之间":"密码只能在6-16位之间",
	"两次密码不一致":"两次密码不一致",
	"交易密码设置成功":"交易密码设置成功",
	"暂时还没有邀请排行榜，快去邀请吧":"暂时还没有邀请排行榜，快去邀请吧",
	"会员未找到":"会员未找到",
	"该身份证号已实名认证过":"该身份证号已实名认证过",
	"你已绑定，不可更改!":"你已绑定，不可更改",
	"该地址已被绑定,请重新输入":"该地址已被绑定,请重新输入",
	"绑定成功!":"绑定成功",
	"请提交完整的信息":"请提交完整的信息",
	"提交成功，等待审核":"提交成功，等待审核",
	"退出登录成功":"退出登录成功",
	"请输入支付密码":"请输入支付密码",
	
	"参数错误":"参数错误",
	"无需升级":"无需升级",
	"兑换成功":"兑换成功",
	"暂无钱包":"暂无钱包",
	"支付密码错误":"支付密码错误",
	"余额不足":"余额不足",
	"升级成功":"升级成功",
	"更新成功":"更新成功",
	"请重试":"请重试",
	"推荐用户不存在":"推荐用户不存在",
	"请把参数填写完整":"请把参数填写完整",
	"转入账户不能为空":"转入账户不能为空",
	"密码不能为空":"密码不能为空",
	"转入数量不能为空":"转入数量不能为空",
	"密码修改成功":"密码修改成功",
	"暂时还没有添加币种":"暂时还没有添加币种",
	"此币种不存在":"此币种不存在",
	"已经有此提币地址":"已经有此提币地址",
	"添加提币地址成功":"添加提币地址成功",
	"此提币地址不存在":"此提币地址不存在",
	"您没有权限删除此地址":"您没有权限删除此地址",
	"删除提币地址成功":"删除提币地址成功",
	"输入的金额不能为负数":"输入的金额不能为负数",
	"您有正在进行中的杆杠交易,不能进行此操作":"您有正在进行中的杆杠交易,不能进行此操作",
	"钱包不存在":"钱包不存在",
	"划转成功":"划转成功",
	"操作失败:":"操作失败",
	"操作失败:Insufficient wallet balance":"操作失败：钱包余额不足",
	"Insufficient wallet balance":"钱包余额不足",
	"下单数量必须是10的整数倍":"下单数量必须是10的整数倍",
	"Parameter error":"Parameter error",
	"提币申请已成功，等待审核":"提币申请已成功，等待审核",
	"两次密码不一致":"两次密码不一致",
	"数量不能小于等于0":"数量不能小于等于0",
	"价格不能小于等于0":"价格不能小于等于0",
	"兑换数量必须大于0":"兑换数量必须大于0",
	"转账至交易所钱包":"转账至交易所钱包",
	"支付密码错误":"支付密码错误",
	"钱包已添加,请勿重复添加":"钱包已添加,请勿重复添加",
	"您还没有钱包":"您还没有钱包",
	"接收来自交易所的转账":"接收来自交易所的转账",
	"地址输入有误":"地址输入有误",
	"请输入正确的值":"请输入正确的值",
	"地址输入有误1":"地址输入有误1",
	"不能转账给自己":"不能转账给自己",
	"扣除卖方!":"扣除卖方",
	"扣除卖方":"扣除卖方",
	"买方增加":"买方增加",
	"买方增加!":"买方增加",
	"申请提币冻结余额":"申请提币冻结余额",
	"申请提币冻结余额!":"申请提币冻结余额",
	"币币 划转 币币":"币币 划转 币币",
	"币币 划转 合约":"币币 划转 合约",
	"币币 划转 秒合约":"币币 划转 秒合约",
	"币币 划转 法币":"币币 划转 法币",
	"法币 划转 法币":"法币 划转 法币",
	"法币 划转 币币":"法币 划转 币币",
	"法币 划转 合约":"法币 划转 合约",
	"法币 划转 秒合约":"法币 划转 秒合约",
	"秒合约 划转 秒合约":"秒合约 划转 秒合约",
	"秒合约 划转 币币":"秒合约 划转 币币",
	"秒合约 划转 法币":"秒合约 划转 法币",
	"秒合约 划转 合约":"秒合约 划转 合约",
	"合约 划转 合约":"合约 划转 合约",
	"合约 划转 币币":"合约 划转 币币",
	"合约 划转 秒合约":"合约 划转 秒合约",
	"合约 划转 法币":"合约 划转 法币",
	"提交卖出记录(增加冻结)":"提交卖出记录(增加冻结)",
	"提交卖出记录扣除":"提交卖出记录扣除",
	"平仓资金处理":"平仓资金处理",
	"请先勾选同意用户协议":"请先勾选同意用户协议",
	"您有未平仓的交易,操作禁止":"您有未平仓的交易,操作禁止",
	"操作失败:您有未平仓的交易,操作禁止":"操作失败:您有未平仓的交易,操作禁止",
	"购买失败，购买金额必须大于":"购买失败，购买金额必须大于",
	"并且小于":"并且小于",
	"用户购买保险":"用户购买保险",
	"您的验证码是：":"您的验证码是：",
	"发送失败:":"发送失败:",
	"提交":"提交",
	"杠杆交易,价格":"杠杆交易,价格",
	",扣除保证金":",扣除保证金",
	"杠杆交易,扣除手续费":"杠杆交易,扣除手续费",
	"修改邮箱":"修改邮箱",
	"您输入的邮箱或手机号不符合规则":"您输入的邮箱或手机号不符合规则",
	"验证码":"验证码",
	"秒":"秒",
	
	"扣除卖方":"扣除卖方",
	"买方增加":"买方增加",
	"申请提币冻结余额":"申请提币冻结余额",
	
	"卖方增加法币":"卖方增加法币",
	"后台审核充值通过":"后台审核充值通过",
	"申请提币扣除余额":"申请提币扣除余额",
	"秒合约订单平仓,盈利结算":"秒合约订单平仓,盈利结算",
	"秒合约订单,亏损结算":"秒合约订单,亏损结算",
	"秒合约下单扣除":"秒合约下单扣除",
	"下单扣除":"下单扣除",
	"%手续费":"%手续费",
	"秒合约下单扣除本金":"秒合约下单扣除本金",
	"停止售出":"停止售出",
	"买方扣除币币余额":"买方扣除币币余额",
	"买方扣除法币余额":"买方扣除法币余额",
	"买方扣除合约余额":"买方扣除合约余额",
	"买方扣除秒合约余额":"买方扣除秒合约余额",
	"用户中心":"用户中心",
	"注册成功,钱包状态：1":"注册成功,钱包状态：1",
	"注册成功,钱包状态：2":"注册成功,钱包状态：2",
	"注册成功,钱包状态：0":"注册成功,钱包状态：0",
	"调节":"调节",
	"账户余额:":"账户余额 ",
	"账户锁定余额:":"账户锁定余额 ",
	"秒合约":"秒合约",
	"法币":"法币",
	"杠杆":"杠杆",
	"币币":"币币",
	"请实名认证":"请实名认证",
	"您的实名认证还未通过！":"您的实名认证还未通过！",
	"暂不支持该币种":"暂不支持该币种",
	"充值":"充值",
	"余额不足":"余额不足",
	"操作成功":"操作成功",
	"取消成功":"取消成功",
	"提交成功":"提交成功",
	"最大下单数量不能超过:":"最大下单数量不能超过",
	"投资数额 的最小长度为":"投资数额 的最小长度为",
	"位":"位",
	"杠杆买入委托撤单,退回手续费":"杠杆买入委托撤单,退回手续费",
	"杠杆买入委托撤单,退回保证金":"杠杆买入委托撤单,退回保证金",
	"秒合约买入委托撤单,退回手续费":"秒合约买入委托撤单,退回手续费",
	"秒合约买入委托撤单,退回保证金":"秒合约买入委托撤单,退回保证金",
	"法币买入委托撤单,退回手续费":"法币买入委托撤单,退回手续费",
	"法币买入委托撤单,退回保证金":"法币买入委托撤单,退回保证金",
	"币币买入委托撤单,退回手续费":"币币买入委托撤单,退回手续费",
	"币币买入委托撤单,退回保证金":"币币买入委托撤单,退回保证金",
	"订单平仓结算,平局结算":"订单平仓结算,平局结算",
	"订单平仓,盈利结算":"订单平仓,盈利结算",
	"Account already exists":"账户已存在",
	"投资数额 字段必须填写":"投资数额 字段必须填写",
	"提币成功":"提币成功",
	"Operation successful":"操作成功",
	"加载中...":"加载中...",
	"加载更多":"加载更多",
	"没有更多了...":"没有更多了...",
	"确定平仓？":"确定平仓？",
	"发行量":"发行量",
	"请登录":"请登录",
	"开始申购":"开始申购",
	"申购币种":"申购币种",
	"开始申购时间":"开始申购时间",
	"预计上线时间":"预计上线时间",
	"结束申购时间":"结束申购时间",
	"项目预热":"项目预热",
	"公布结果":"公布结果",
	"USDT余额不足":"USDT余额不足",
	"BTC余额不足":"BTC余额不足",
	"ETH余额不足":"ETH余额不足",
	"申购成功":"申购成功",
	"兑换成功":"兑换成功",
	"全部":"全部",
	"立即申购":"立即申购",
	"结束申购":"结束申购",
	"申购周期":"申购周期",
	"项目详情":"项目详情",
	"请输入申购数量":"请输入申购数量",
	"申购确认":"申购确认",
	"确定":"确定",
	"取消":"取消",
	"您计划申购":"您计划申购",
	"BEF钱包未找到":"BEF钱包未找到",
	"USDT钱包未找到":"USDT钱包未找到",
	"BTC钱包未找到":"BTC钱包未找到",
	"ETH钱包未找到":"ETH钱包未找到",
	"USDT 兑换 BEF ":"USDT 兑换 BEF ",
	"BTC 兑换 BEF ":"BTC 兑换 BEF ",
	"ETH 兑换 BEF ":"ETH 兑换 BEF ",
	"申购历史":"申购历史",
	"认购价格":"认购价格",
	"认购数量":"认购数量",
	"认购时间":"认购时间",
	"结束时间":"结束时间",
	"状态":"状态",
	"解锁":"解锁",
	"已完成":"已完成",
	"已锁仓":"已锁仓",
	"暂无记录":"暂无记录",
	"操作":"操作",
	"价值":"价值",
	"认购币种":"认购币种",
	"申购记录":"申购记录",
	"可用余额不足":"可用余额不足",
	"申购数量异常":"申购数量异常",
	"认购异常":"认购异常",
	"认购成功":"认购成功",
	"下单数量必须是100的整数倍":"下单数量必须是100的整数倍",
	"认购解锁到账":"认购解锁到账",
	"充值自动到账":"充值自动到账",
	"发送失败。未包含正确的手机号":"发送失败。未包含正确的手机号",
	"发送失败。余额不足":"发送失败。余额不足",
	"认购付款":"认购付款",
	"当前价值":"当前价值",
	"解锁时间":"解锁时间","天":"天","倒计时":"倒计时",
	"预计收益":"预计收益",
	"资产记录":"资产记录",
	"知道了":"知道了",
	"冻结剩余时间":"冻结剩余时间",
	"小时":"小时",
	"币币[锁定]":"币币[锁定]",
	"aaaaaaaaaaaa":"1111111"
}
}

