import { render, staticRenderFns } from "./second-trade.vue?vue&type=template&id=282c00d6&scoped=true"
import script from "./second-trade.vue?vue&type=script&lang=js"
export * from "./second-trade.vue?vue&type=script&lang=js"
import style0 from "./second-trade.vue?vue&type=style&index=0&id=282c00d6&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "282c00d6",
  null
  
)

export default component.exports